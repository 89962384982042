export class Constants {
    public static DeleteMsg: string = 'Are you sure you want to delete this ?';
    public static ApprovedMsg: string = 'Are you sure you want to approve this ?';
    public static extensionmsg: string = 'Extension of this file is wrong!';
    public static imageValidationMsg: string = 'Please upload the correct image format!';
    public static DeleteSuccessMsg: string = 'Deleted successfully.';
    public static ErrorMessage: string = 'Something went wrong, please try again later.'
    public static FlashMessageTime: number = 2000;
    public static DateFormat:string = 'YYYY-MM-DD';
    public static TimeFormat:string = 'H:i:s';
    public static RunningVehicleIcon = '/assets/image/running-vehicle.png';
    public static StopVehicleIcon = '/assets/image/stop-vehicle.png';
    public static IdleVehicleIcon = '/assets/image/idle-vehicle.png';
    public static InactiveVehicleIcon = '/assets/image/inactive-vehicle.png';
    public static NoDataVehicleIcon = '/assets/image/nodata-vehicle.png';
    public static CancelMsg: string = 'Are you sure you want to cancel this ?';
    public static CalculateDistance: string = '(In km)';
    public static CalculateTime: string = '(In min)';
    public static CalculateAmount: string = '(In rs)';
    public static GeofenceRadius: string = '(In meter)';

    public static TruckMovingVehicleIcon = 'public/image/tracking/Truck/running.png';
    public static BusMovingVehicleIcon = 'public/image/tracking/Bus/running.png';
    public static CarMovingVehicleIcon = 'public/image/tracking/Car/running.png';
    public static TankerMovingVehicleIcon = 'public/image/tracking/Tanker/running.png';
    public static BikeMovingVehicleIcon = 'public/image/tracking/Motorcycle/running.png';
    public static LoaderMovingVehicleIcon = 'public/image/tracking/Loader/running.png';
    public static MowerMovingVehicleIcon = 'public/image/tracking/Mower/running.png';
    public static TrailerMovingVehicleIcon = 'public/image/tracking/Trailer/running.png';
    public static VanMovingVehicleIcon = 'public/image/tracking/Van/running.png';

    public static TRUCK_RUNNING_ICON = 'assets/image/vehicles-icon/Truck/running.png';
    public static BUS_RUNNING_ICON = 'assets/image/vehicles-icon/Bus/running.png';
    public static CAR_RUNNING_ICON = 'assets/image/vehicles-icon/Car/running.png';
    public static TANKER_RUNNING_ICON = 'assets/image/vehicles-icon/Tanker/running.png';
    public static BIKE_RUNNING_ICON = 'assets/image/vehicles-icon/Bike/running.png';
    
    public static GeofenceType = {
                                    Circle : 1,
                                    Rectangle : 2,
                                    Polygone : 3
                                  }
    public  static imageextensionarray = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];                           public  static mapStyles = [];
    // public  static mapStyles = [
    //                             {
    //                               "featureType": "administrative",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#d6e2e6"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "administrative",
    //                               "elementType": "geometry.stroke",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#cfd4d5"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "administrative",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#7492a8"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "administrative.country",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "visibility": "on"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "administrative.country",
    //                               "elementType": "labels.icon",
    //                               "stylers": [
    //                                 {
    //                                   "visibility": "on"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "administrative.neighborhood",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "lightness": 25
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "landscape.man_made",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#dde2e3"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "landscape.man_made",
    //                               "elementType": "geometry.stroke",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#cfd4d5"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "landscape.natural",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#dde2e3"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "landscape.natural",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#7492a8"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "landscape.natural.terrain",
    //                               "elementType": "all",
    //                               "stylers": [
    //                                 {
    //                                   "visibility": "off"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#dde2e3"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#588ca4"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi",
    //                               "elementType": "labels.icon",
    //                               "stylers": [
    //                                 {
    //                                   "saturation": -100
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi.park",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#a9de83"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi.park",
    //                               "elementType": "geometry.stroke",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#bae6a1"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi.sports_complex",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#c6e8b3"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "poi.sports_complex",
    //                               "elementType": "geometry.stroke",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#bae6a1"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#41626b"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road",
    //                               "elementType": "labels.icon",
    //                               "stylers": [
    //                                 {
    //                                   "saturation": -45
    //                                 },
    //                                 {
    //                                   "lightness": 10
    //                                 },
    //                                 {
    //                                   "visibility": "on"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.highway",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#c1d1d6"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.highway",
    //                               "elementType": "geometry.stroke",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#a6b5bb"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.highway",
    //                               "elementType": "labels.icon",
    //                               "stylers": [
    //                                 {
    //                                   "visibility": "on"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.highway.controlled_access",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#9fb6bd"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.arterial",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#ffffff"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "road.local",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#ffffff"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit",
    //                               "elementType": "labels.icon",
    //                               "stylers": [
    //                                 {
    //                                   "saturation": -70
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit.line",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#b4cbd4"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit.line",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#588ca4"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit.station",
    //                               "elementType": "all",
    //                               "stylers": [
    //                                 {
    //                                   "visibility": "off"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit.station",
    //                               "elementType": "labels.text.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#008cb5"
    //                                 },
    //                                 {
    //                                   "visibility": "on"
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "transit.station.airport",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "saturation": -100
    //                                 },
    //                                 {
    //                                   "lightness": -5
    //                                 }
    //                               ]
    //                             },
    //                             {
    //                               "featureType": "water",
    //                               "elementType": "geometry.fill",
    //                               "stylers": [
    //                                 {
    //                                   "color": "#a6cbe3"
    //                                 }
    //                               ]
    //                             }
    //                           ];
}