import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  	constructor(private _httpHelper: HttpHelper) {
    }

    addGeofence(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-vehicle-geofence', request);
        }
        catch (e) {
        }
    }

    getGeofenceList(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-geofence-list', request);
        }
        catch (e) {
        }
    }

    updateGeofence(request){
    	try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-vehicle-geofence', request);
        }
        catch (e) {
        }
    }

    deleteGeofence(request){
    	try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-vehicle-geofence', request);
        }
        catch (e) {
        }
    }

    addGroup(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-geofence-group', request);
        }
        catch (e) {
        }
    }

    getGroupList(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-geofence-group-list', request);
        }
        catch (e) {
        }
    }

    updateGroup(request){
    	try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-geofence-group', request);
        }
        catch (e) {
        }
    }

    deleteGroup(request){
    	try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-geofence-group', request);
        }
        catch (e) {
        }
    }

    getSingleGeofence(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-vehicle-geofence', request);
        }
        catch (e) {
        }
    }

    getGeofenceItemList(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-geofence-basic-list');
        }
        catch (e) {
        }
    }

    getAssignGeofenceVehicleList(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-non-geofence-vehicles', request);
        }
        catch (e) {
        }
    }

    getSingleGroupDetails(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-geofence-group', request);
        }
        catch (e) {
        }
    }

    getGeofenceGroupItemList(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-geofence-group-items');
        }
        catch (e) {
        }
    }
}
