import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

@Injectable()
export class MessageService {
    constructor(private _httpHelper: HttpHelper) {
    }
    getUserList() {
        try {           
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-all-receiver');
        }
        catch (e) {
        }
    }

    getMessageList(userId) {
        try {   
            let requestParameter = '?id='+userId;        
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'message-list' + requestParameter);
        }
        catch (e) {
        }
    }

    sendMessage(userId:string, message:string){
        try { 
            var request = {
                "to_user_id": userId,
                "message":message
              };          
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'send-message', request);
        }
        catch (e) {
        }
    }
}
