import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { GroupsService } from '../../../services/groups/groups.service';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import { PlaceService } from 'src/app/services/place/place.service';
import { Config } from './../../../config/config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

declare var moment: any;
declare var $: any;
declare var google: any;
declare var Autocomplete: any;
declare var map: any;
declare var cityCircle: any;

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
	loading: boolean;
    groupDetails: any = {};
  
    userDetails: any = {};
    placeList: any = [];
    modalRef: BsModalRef;
    searchControl: FormControl;
    @ViewChild("search")
    searchElementRef: ElementRef;
    
    renderOptions = {
        draggable: true,
        suppressMarkers: false,
    }
    zoom: any;
    lat: any;
    lng: any;
    message: any;
    mapStyles: any;
    saveButton: boolean = false;
    
    // markerOptions: any = {}
    iconUrl: any;
    serverUrl: any;
    markerWayPoint: any = {}
    placeDetails: any = {}
    placeLocationRequired: any;
    placeNameRequired: any;
    map: any;
    markers = [];
   
    drawingManager: any;
    shapeType: number;
    geofenceDraw: boolean = false;
    polygonPaths: any = [];
    placeSelectedBtn: boolean = true;
    
    config = {}
    placeSelectedItem = [];
    availableSelectedIds = [];
    userInfo:any;
    allOverlays: any = [];
    drawGeofance : any;
    setMapGeofance: boolean = false;
    polygonCordinates: any = [];
    geofenceList: any = [];
    geofenceSelectedItems: any = [];
    geofenceSettings: any = {};
    vehicleList: any = [];
    vehicleSelectedItems: any = [];
    vehicleSettings: any = {};
    editGroupId: any = '';

    constructor(
        private modalService: BsModalService,
        private router: Router,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private http: HttpClient,
        public groupsService: GroupsService,
        public commonService: CommonService,
        public placeService: PlaceService,
        private customErrorHandler: CustomErrorHandler,
		private translate: TranslateService,
		private broadcaster: Broadcaster
    ) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
    }

    ngOnInit() {
        this.editGroupId = localStorage.getItem('editGroupId');
        if(this.editGroupId == undefined || this.editGroupId == null){
            this.editGroupId = ''; 
        }
        this.getGeofenceList();
    	this.translate.get('selectors').subscribe((res) => {
    		
	    	this.geofenceSettings = {
	            singleSelection: false,
	            text: res.SelectGeofence,
	            selectAllText: 'Select All',
	            unSelectAllText: 'UnSelect All',
	            enableSearchFilter: true,
	            classes: "singleSelection req_input"
	        };
            this.vehicleSettings = {
                singleSelection: false,
                text: res.SelectVehicle,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            };
    	 })

        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        
        this.loading = false;
        this.mapStyles = Constants.mapStyles;
        this.zoom = 15;
        this.lat = Number(this.userDetails.lat);
        this.lng = Number(this.userDetails.lng);
       
        this.serverUrl = Config.AuthenticationURL;

        this.iconUrl = this.serverUrl + 'public/image/route-icon/';
        this.searchControl = new FormControl();

        let mapStyle = this.mapStyles;
        let this1 = this;
       	setTimeout(()=> {
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: this.zoom,
                center: { lat: this.lat, lng: this.lng },
                scrollwheel: true,
                styles: mapStyle,
                sensor: false
            });
        }, 500);
    }

    getGeofenceList(){
        this.groupsService.getGeofenceItemList().subscribe(
            res => {
                this.loading = false;
                if (res.success == true) {
                    this.geofenceList = res.data;
                } else {
                    this.commonService.showErrorMessage(res);
                }
                this.getAssignGeofenceVehicleList();
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addGroup');
            }
        )
    }

    getAssignGeofenceVehicleList(){
        let param = {
            group_id: this.editGroupId
        }
        this.groupsService.getAssignGeofenceVehicleList(param).subscribe(
            res => {
                this.loading = false;
                if (res.success == true) {
                    this.vehicleList = res.data;
                } else {
                    this.commonService.showErrorMessage(res);
                }
                if(this.editGroupId){
                    this.getSingleGroupDetails();
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addGroup');
            }
        )
    }

    getSingleGroupDetails(){
        let param = {
            id: this.editGroupId
        }
        this.groupsService.getSingleGroupDetails(param).subscribe(
            res => {
                this.loading = false;
                if (res.success == true) {
                    this.groupDetails = res.data;
                    this.geofenceSelectedItems = [];
                    let geofenceIds = this.groupDetails.geofence_ids;
                    for (var i = 0; i < geofenceIds.length; i++) {
                        let geofanceTemp = this.geofenceList.find(s => s.id == geofenceIds[i]);
                        if(geofanceTemp != undefined){
                            this.geofenceSelectedItems.push(geofanceTemp);
                        }
                    }
                            this.drawingGeofence();

                    this.vehicleSelectedItems = [];
                    for (let i = 0; i < this.groupDetails.vehicle_ids.length; i++) {
                        let vehicleId = this.groupDetails.vehicle_ids[i];
                        let vehicleTemp = this.vehicleList.find(s => s.id == vehicleId);
                        if(vehicleTemp != undefined){
                            this.vehicleSelectedItems.push(vehicleTemp);
                        }
                    }
                } else {
                    this.commonService.showErrorMessage(res);
                }
               
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addGroup');
            }
        )
    }

    addGroup() {
        let vehicleIds = []
        console.log(this.vehicleSelectedItems)
        for (var i = 0; i < this.vehicleSelectedItems.length; i++) {
            vehicleIds.push(this.vehicleSelectedItems[i].id);
        }
        this.groupDetails.vehicle_ids = vehicleIds;
        this.groupDetails.geofence_ids = [];
        for (var i = 0; i < this.geofenceSelectedItems.length; i++) {
            this.groupDetails.geofence_ids.push( this.geofenceSelectedItems[i].id);
        }
       
        if(this.editGroupId){
            this.groupsService.updateGroup(this.groupDetails).subscribe(
                res => {
                    this.loading = false;
                    this.saveButton = false;
                    if (res.success == true) {
                        this.commonService.showSuccessMessage(res.message);
                        this.router.navigate(['./groups/group-list']);
                    } else {
                        this.commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.loading = false;
                    this.customErrorHandler.handleHttpError(error, 'addGroup');
                }
            )
        }else{
            this.groupsService.addGroup(this.groupDetails).subscribe(
                res => {
                    this.loading = false;
                    this.saveButton = false;
                    if (res.success == true) {
                        this.commonService.showSuccessMessage(res.message);
                        this.router.navigate(['./groups/group-list']);
                    } else {
                        this.commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.loading = false;
                    this.customErrorHandler.handleHttpError(error, 'addGroup');
                }
            )
        }

    }

    onItemSelectGeofence(item: any){
        this.drawingGeofence();
    }

    onItemDeSelectGeofence(item: any){
        // this.geofenceSelectedItems = [];
        // this.geofenceSelectedItems.push(item);
        this.drawingGeofence();
    }
    
    drawingGeofence(){
        this.clearGeofanceArea();
        
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.zoom,
            center: { lat: this.lat, lng: this.lng },
            scrollwheel: true,
            styles: this.mapStyles,
            sensor: false
        });
        console.log(this.geofenceSelectedItems)
        let boundriesArr = []
        for (var i = 0; i < this.geofenceSelectedItems.length; i++) {
            let item = this.geofenceSelectedItems[i];
            console.log(item);

            this.allOverlays = [];
            let _this = this;
            if(item.geofence_type == 1){
                if(item.geofence_boundries.length > 0){

                    item.geofence_boundries.lat = Number(item.geofence_boundries[0].lat);
                    item.geofence_boundries.lng = Number(item.geofence_boundries[0].lng);
                    boundriesArr.push({lat:  item.geofence_boundries.lat, lng :  item.geofence_boundries.lng});
                    this.drawGeofance = new google.maps.Circle({
                        map: _this.map,
                        radius: item.circle_radius,    // 10 miles in metres
                        fillColor: '#262525',
                        center: { lat: item.geofence_boundries.lat, lng: item.geofence_boundries.lng},
                    });
                    let myLatLng = new google.maps.LatLng(item.geofence_boundries.lat, item.geofence_boundries.lng);
                    let bounds = new google.maps.LatLngBounds();
                    bounds.extend(myLatLng);
                    this.map.fitBounds(bounds);
                    this.map.setZoom(this.zoom);
                    this.drawGeofance.setMap(this.map);
                    this.setMapGeofance = true;
                }
            }else{
                let bounds = new google.maps.LatLngBounds();
                this.polygonCordinates = item.geofence_boundries
                this.drawGeofance = [];
                for(let i = 0;i < this.polygonCordinates.length; i++){
                    this.polygonCordinates[i].lat = Number(this.polygonCordinates[i].lat);
                    this.polygonCordinates[i].lng = Number(this.polygonCordinates[i].lng)
                    boundriesArr.push({lat:  this.polygonCordinates[i].lat, lng :  this.polygonCordinates[i].lng});
                    
                }
                console.log(this.polygonCordinates)
                this.drawGeofance = new google.maps.Polygon({
                    map: _this.map,
                    paths: this.polygonCordinates,
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#262525',
                    fillOpacity: 0.35,
                    draggable: true,
                    geodesic: true
                });
                this.drawGeofance.setMap(this.map);
                let paths = this.drawGeofance.getPaths();
                let path;
                for (let i = 0; i < paths.length; i++) {
                    path = paths.getAt(i);
                    for (let ii = 0; ii < path.getLength(); ii++) {
                        bounds.extend(path.getAt(ii));
                    }
                }
                this.map.fitBounds(bounds);
            }
        }
        if(boundriesArr.length > 0){
            this.setFitBounds(boundriesArr);
        }
    }

    clearGeofanceArea(){
        if(this.setMapGeofance == true){
            this.drawGeofance.setMap(null);
            this.setMapGeofance = false;
        }

        // if(this.drawPolygonGeofanceMapSet == true){
        //     this.drawPolygonGeofance.setMap(null);
        //     this.drawPolygonGeofanceMapSet = false;
        // }
    }

    setFitBounds(boundryArray){
        let bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < boundryArray.length; i++) {
            let myLatLng = new google.maps.LatLng(boundryArray[i].lat, boundryArray[i].lng);
            bounds.extend(myLatLng);
        }
        this.map.fitBounds(bounds);
    }
}
