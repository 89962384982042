import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userDetails: any;
  constructor(private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     if (localStorage.getItem('isLogin') == '1') {
       this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
        let allModule = this.userDetails.all_modules;
        let url = state.url.substr(1);
        let urlArr = url.split('?');
        if(urlArr.length > 1){
          url = urlArr[0];
        }
        let accessModuleData = this.userDetails.access_module_list;
        if(accessModuleData == undefined  || accessModuleData == ''){
          localStorage.clear();
          window.location.reload();
        }
        let urlTemp = accessModuleData.find(s => s.link == url);
        if(typeof urlTemp != 'undefined'){
          return true;
        }
    }
    // not logged in so redirect to login page with the return url
    let urlCheck = this.router.url.substr(1);
    console.log('in auth', !urlCheck.startsWith('login') && urlCheck != '')
    let loginchk = localStorage.getItem('isLogin');
    if(!urlCheck.startsWith('login') && urlCheck != ''){
      return true;
      window.location.reload();
    }
    else if(loginchk == '1')
    {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return true;
    }
    else{
      console.log('in else');

      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    }
    return false;
    // if (localStorage.getItem('isLogin') == '1') {
    //   return true;
    // }
    // else{
    //   this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    // }
    // return false;
  }
}
