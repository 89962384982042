import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import * as moment from 'moment';
@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {
	countrySelectedItems = [];
	countrySettings = {};
	assignvehicleSelectedItems = [];
	assignvehicleSettings = {};
	timezoneSelectedItems = [];
	timezoneSettings = {};
	loading:boolean;
 	driverDetails: any = {};
	userInfo: any;
	roleList: any;
	timeZoneList: any;
	countryList: any;
	editDriverId: any;
	serverUrl: any;
	dateTime = new Date();
	dateTimeValidity = new Date();
	vehicleList: any = [];
	currentpage : any;
	imageextension : any = [];
	isParent:boolean = false;
	userRoleList: any;
	userSelectedRoleItems = [];
	userRoleSettings = {};
  	constructor(
			private router: Router, 
			private http: HttpClient, 
			public vehicleService: VehicleService,
			public userService: UserService, 
			public commonService: CommonService, 
			private customErrorHandler:CustomErrorHandler,
			private translate: TranslateService,
    	private broadcaster: Broadcaster 
		) {
				this.userInfo = localStorage.getItem('userInfo');
				this.userRoleList = [{
					id: 0,
					itemName: 'Driver'
				}, {
					id: 1,
					itemName: 'Conductor'
				}]
        if(this.userInfo){
					this.userInfo = JSON.parse(this.userInfo);
					this.currentpage = "Add";
					// this.isParent = this.userInfo.is_parent == 1 ? true : false;
					this.isParent = false;
				}
				this.translate.get('selectors').subscribe((res) => {
					setTimeout(() => {
						this.countrySettings = {
							singleSelection: true,
							text: res.SelectCountry,
							selectAllText: 'Select All',
							unSelectAllText: 'UnSelect All',
							enableSearchFilter: true,
							classes: "singleSelection"
						};
						this.timezoneSettings = {
							singleSelection: true,
							text: res.SelectTimeZone,
							selectAllText: 'Select All',
							unSelectAllText: 'UnSelect All',
							enableSearchFilter: true,
							classes: "singleSelection"
						};
						this.userRoleSettings = {
							singleSelection: true,
							text: res.SelectRole,
							selectAllText: 'Select All',
							unSelectAllText: 'UnSelect All',
							enableSearchFilter: true,
							classes: "singleSelection"
						};
					});
				});
		
				this.broadcaster.on<string>('langEvent').subscribe(message => {
					this.translate.get('selectors').subscribe((res) => {
						setTimeout(() => {
							this.countrySettings = {
								singleSelection: true,
								text: res.SelectCountry,
								selectAllText: 'Select All',
								unSelectAllText: 'UnSelect All',
								enableSearchFilter: true,
								classes: "singleSelection"
							};
							this.timezoneSettings = {
								singleSelection: true,
								text: res.SelectTimeZone,
								selectAllText: 'Select All',
								unSelectAllText: 'UnSelect All',
								enableSearchFilter: true,
								classes: "singleSelection"
							};
							this.userRoleSettings = {
								singleSelection: true,
								text: res.SelectRole,
								selectAllText: 'Select All',
								unSelectAllText: 'UnSelect All',
								enableSearchFilter: true,
								classes: "singleSelection"
							};
						});
					});
				});
    }

  	ngOnInit() {
			this.imageextension= Constants.imageextensionarray;
			this.assignvehicleSettings = {
				singleSelection: true,
				text: "Select assign vehicle",
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				enableSearchFilter: true,
				classes: "singleSelection"
			};
			this.loading = false;
  		this.driverDetails.company_id = this.userInfo.company_id;
  		this.driverDetails.company_name = this.userInfo.company_name;
  		this.driverDetails.time_zone = '';
  		this.driverDetails.country = '';
			this.getCountry();
			this.dateTime.setDate(this.dateTime.getDate() -6574); 
  		// this.getTimeZone();
			// this.getVehicleDetails();
  			// get driver details for edit driver
				this.editDriverId = localStorage.getItem('editDriverId');
				if(this.editDriverId){
					// this.getDriverDetails(this.editDriverId);
					this.currentpage = "Update";
				}
				else{
					this.driverDetails.dob = '';
				}
			this.serverUrl = Config.AuthenticationURL;
			
		this.commonService.stickyHeader();
		this.driverDetails.vehicle_id = '';
  	}

  	addDriver(data){
			this.loading =true;
			this.driverDetails.dob = moment(data.dob).format('D MMM YYYY');
  		if(this.editDriverId){
  			this.userService.updateDriver(this.driverDetails).subscribe(
		        res => {
							this.loading =false; 
		           	if (res.success == true) {
		          		this.commonService.showSuccessMessage(res.message);
		           		this.router.navigate(['./user-management/driver-list']);
		          	} else {
		            	this.commonService.showErrorMessage(res);
		          	}
		        },
		        error => {
							this.loading =false; 
		          this.customErrorHandler.handleHttpError(error, 'updateDriver');
		        }
		    )
  		}else{
  			this.userService.addDriver(this.driverDetails).subscribe(
		        res => {
							this.loading =false; 
		           	if (res.success == true) {
		          		this.commonService.showSuccessMessage(res.message);
		           		this.router.navigate(['./user-management/driver-list']);
		          	} else {
		            	this.commonService.showErrorMessage(res);
		          	}
		        },
		        error => {
							this.loading =false; 
		          this.customErrorHandler.handleHttpError(error, 'addDriver');
		        }
		    )
  		}
  	}

  	changeListener($event): void {
        let eventName = $event.target.name;
        let inputValue = $event.target;
        let file:File = inputValue.files[0];
        let myReader:FileReader = new FileReader();
				let imgName = $event.target.files[0].name;
				let imageextensionarray=  imgName.split('.').pop();
				if (imageextensionarray === 'jpg'|| imageextensionarray=== 'png' || imageextensionarray==='jpeg'|| imageextensionarray=== 'bmp') {
        myReader.onloadend = (e) => {
            // this.driverDetails.photo = myReader.result;
            if(eventName == 'photo'){
								this.driverDetails.photo = myReader.result;
								$('.driver-img').attr('src', this.driverDetails.photo);
            }
            if(eventName == 'id_proof'){
								this.driverDetails.id_proof = myReader.result;
								$('.Id-img').attr('src', this.driverDetails.id_proof);
            }
            if(eventName == 'licence_image'){
								this.driverDetails.licence_image = myReader.result;
								$('.licence-img').attr('src',this.driverDetails.licence_image);
            }
				}
			}
			
			else {
				$event.target.files.name = '';
				 $('.driver-img').val('');
				this.driverDetails.photo = '';
				$('input[name=photo]').val('');
				$('.Id-img').val('');
				this.driverDetails.id_proof = '';
				$('input[name=id_proof]').val('');
				$('.licence_image').val('');
				this.driverDetails.licence_image = '';
				$('input[name=licence_image]').val('');
				swal({
					type: 'error',
					text: Constants.extensionmsg,
		confirmButtonText: 'ok',
			})
			}
			
        myReader.readAsDataURL(file);
    }

  //   getUserRole(){
	// 	this.userService.getUserRole(this.userInfo.company_id).subscribe(	        
	// 		res => {
	//           if (res.success == true) {
	//           	this.roleList = res.data;
	//           } else {
	// 					this.commonService.showErrorMessage(res);
	//           }
	//         },
	//         error => {
	// 					this.customErrorHandler.handleHttpError(error, 'getUserRole');
	//         }
	//       )
	// }

	getTimeZone(){
		this.userService.getTimeZone().subscribe(res => {
	          if (res.success == true) {
							this.timeZoneList = res.data;
							this.getVehicleDetails();
	          } else {
						this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.customErrorHandler.handleHttpError(error, 'getTimeZone');
	        }
	      )
	}

	getCountry(){
		this.commonService.getCountry().subscribe(res => {
	          if (res.success == true) {
							this.countryList = res.data;	
							this.getTimeZone();
	          } else {
						this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.customErrorHandler.handleHttpError(error, 'getCountry');
	        }
	      )
	}

	getDriverDetails(driverId){
		this.loading =true;
		this.userService.getSingleDriver(driverId).subscribe(res => {
			if (res.success == true || res.success == true) {
				this.driverDetails = res.data;
				this.driverDetails.password = '';
				this.driverDetails.photo_url = this.driverDetails.photo;
				this.driverDetails.photo = '';
				this.driverDetails.licence_image_url = this.driverDetails.licence_image;
				this.driverDetails.licence_image = '';
				this.driverDetails.id_proof_url = this.driverDetails.id_proof;
				this.driverDetails.id_proof = '';
				this.setCountry();
				this.setTimeZone();	
				this.setAssignvehicle();		
				this.setRole();
			} else {
				this.commonService.showErrorMessage(res);
			}
			this.loading =false;
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getSingleDriver');
			this.loading =false;
		});
	}

	numberValidation(event: any) {

        const pattern = /[0-9\+\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        //    console.log(event.keyCode);
        if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
    }

    getVehicleDetails(){
		this.vehicleService.getVehicle(0, 0).subscribe(
	        res => {
	          if (res.success == true) {
							this.vehicleList = res.data.rows;
							this.editDriverId = localStorage.getItem('editDriverId');
							if(this.editDriverId){
								this.getDriverDetails(this.editDriverId);
							}
	          } else {
						this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	      )
	}

	setCountry(){
		let selectedCounry = this.countryList.find(s => s.itemName == this.driverDetails.country);
		if(selectedCounry != undefined && this.countrySelectedItems.length == 0){
							this.countrySelectedItems = [];// [{ "id": "107", "itemName": "India" }];//[];
							this.countrySelectedItems.push(selectedCounry);
		}
	}

	setRole(){
		let selectedRole = this.userRoleList.find(s => s.id == this.driverDetails.is_conductor);
		if(selectedRole != undefined && this.userSelectedRoleItems.length == 0){
			this.userSelectedRoleItems = [];// [{ "id": "107", "itemName": "India" }];//[];
			this.userSelectedRoleItems.push(selectedRole);
			this.driverDetails.is_conductor = this.userSelectedRoleItems[0].id;
		}
	}
	setTimeZone(){
		let timezone = this.timeZoneList.find(s=>s.itemName == this.driverDetails.time_zone);
		if(timezone!= undefined && this.timezoneSelectedItems.length == 0){
			this.timezoneSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
			this.timezoneSelectedItems.push(timezone);
		}
	}
	setAssignvehicle(){
		let vehicle = this.vehicleList.find(s=>s.id == this.driverDetails.vehicle_id);
		if(vehicle!= undefined && this.assignvehicleSelectedItems.length == 0){
			this.assignvehicleSelectedItems = [];
			this.assignvehicleSelectedItems.push(vehicle);
		}
	}

	onItemSelectCounry(item: any) {
		this.driverDetails.country = item.itemName;
	}
	onItemSelectTimezone(item: any) {
		this.driverDetails.time_zone = item.itemName;
	}
	onItemSelectAssignvehicle(item: any) {
		this.driverDetails.vehicle_id = item.id;
	}
	onOpenCountry(event: any) {
		this.countryList = this.countryList.map((item) => {
			return {
				id: item.id,
				itemName: item.itemName
			}
		});
	}
	onOpenAssignvehicle(event: any) {
	    this.vehicleList = this.vehicleList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
	}

	onOpenTimezone(event: any) {
			this.timeZoneList = this.timeZoneList.map((item) => {
				return {
					id: item.id,
					itemName: item.itemName
				}
			});
	}

	getYearSlab() {
		var d = new Date();
		var s = d.getFullYear() - 18;
		var e:any = s - 80;
		e = e + ':' + s;
		return e;
	}

	onSelectRole(item: any) {
		this.driverDetails.is_conductor = item.id;
	}
	ngOnDestroy() {
		localStorage.removeItem('editDriverId');   	
	}
}
