import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

@Injectable()
export class CompanySettingsService {

    
    constructor(private _httpHelper: HttpHelper) {
    }

    getCompanySettings() {
        try {  
            // let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            // let requestParameter = '?company_id='+userInfo.company_id;         
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-company');
        }
        catch (e) {
        }
    }

    saveCompanySettings(company_name:string,logo:string,address:string,city:string,state:string,zip:string,country:string,contact_number:string,industry:string, trip_start_buffer, trip_end_buffer, working_hour) {
        try {  
            var request = {
                "company_name": company_name,
                "logo": logo,
                "address": address,
                "city": city,
                "state": state,
                "zip": zip,
                "country": country,
                "contact_number": contact_number,
                "industry": industry,
                "trip_start_buffer":trip_start_buffer,
                "trip_end_buffer":trip_end_buffer,
                "working_hour":working_hour
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'company-settings',request);
        }
        catch (e) {
        }
    }

    getIndustryList() {
        try {                 
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-industry');
        }
        catch (e) {
        }
    }

    getUserAlertSetting(){
        try {                 
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-user-alert-setting');
        }
        catch (e) {
        }
    }

    updateManageAlert(request){
        try {                 
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-user-alert-setting', request);
        }
        catch (e) {
        }
    }
}
