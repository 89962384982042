import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
