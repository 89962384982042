import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CommonService } from 'src/app/services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from 'src/app/services/user/user.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { Angular2Csv } from 'angular2-csv';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';


@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.css']
})
export class TripComponent implements OnInit {

 	loading:boolean;
	public isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	tripDetails: any = [];
	page: number = 2;
	userDetails: any;
	searchDetails: any ={};
	userInfo: any;
	calculateTime: any;
	calculateDistance: any;
	calculateAmount: any;
	searchTempVal: any;
	start_date;
	end_date;
	tempSearch;
	searchTextValue = '';
	selected: any;
	alwaysShowCalendars: boolean;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
	vehicleList: any;
  	VehicleSelectedItems = [];
  	VehicleSettings = {};
	vehicleId = '';
	isGps:boolean = false;
	constructor(
		private modalService: BsModalService,
		private router: Router, 
		private http: HttpClient, 
		public reportService: ReportService,
		private commonService:CommonService, 
		private userService:UserService, 
		private customErroHandler:CustomErrorHandler,
		private translate: TranslateService,
		private broadcaster: Broadcaster
	) {
  		this.userDetails = localStorage.getItem('userInfo');
		this.userDetails = JSON.parse(this.userDetails);
		this.userInfo = localStorage.getItem('userInfo');
		if (this.userInfo){
			this.userInfo = JSON.parse(this.userInfo);
		}
		this.alwaysShowCalendars = true;
		this.isGps = localStorage.getItem('isGps') == 'true' ? true : false;
  	}

  	ngOnInit() {
  		this.searchTempVal = '';
			this.calculateTime = Constants.CalculateTime;
			this.calculateDistance = Constants.CalculateDistance;
			this.calculateAmount = Constants.CalculateAmount;
			this.loading = false;
			this.totalItem = 0;
			this.translate.get('selectors').subscribe((res) => {
				this.VehicleSettings = {
					 singleSelection: true,
					 text: res.SelectVehicle,
					 enableSearchFilter: true,
					 classes: "singleSelection"
				};
		 });
		 this.broadcaster.on<string>('langEvent').subscribe(message => {
				 this.translate.get('selectors').subscribe((res) => {
					 this.VehicleSettings = {
							singleSelection: true,
							text: res.SelectVehicle,
							enableSearchFilter: true,
							classes: "singleSelection"
					 };
				 });
		 }); 
		// this.start_date  = ''//moment().toDate();
		// this.end_date  = ''//moment().toDate();
  		// this.searchDetails.search_by = '';
		this.getTripDetails(1);
		this.getVehicle();
  		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getTripDetails(currentPage){
		this.loading =true;
		this.page = currentPage;
		let requestParameter = {};
		let queryParams = {
			vehicle_id: this.vehicleId,
			row_per_page: this.perPage,
			page_no:this.page,
			search: this.searchTextValue,
			start_date: this.start_date == undefined ? '' : this.start_date,
			end_date: this.end_date == undefined ? '' : this.end_date,
		}
		// this.searchDetails.export = '';
		// this.searchDetails.start_date = moment(this.searchDetails.start_date).format('D-MMM-YYYY');
		// this.searchDetails.end_date = moment(this.searchDetails.end_date).format('D-MMM-YYYY');
		this.reportService.getTrip(queryParams).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
					this.totalItem = res.data.count;
					this.tripDetails = res.data.rows;
				} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	        	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
	}

	// searchByFilter(search = null){
	// 	if(search == 'title'){
	// 		if(this.searchTempVal != this.searchDetails.search_by){
	// 			this.searchTempVal = this.searchDetails.search_by;
	// 			this.pageNo = 1;
	// 			this.getTripDetails(1);
	// 		}
	// 	}else{
	// 		this.pageNo = 1;
	// 		this.getTripDetails(1);
	// 	}
	// }

	searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getTripDetails(1);
		} 
		this.tempSearch = this.searchTextValue;
	}

	exportTripReport(){
		let fileName = "Trip Report";
		if(this.start_date != undefined && this.end_date != undefined){
			fileName = "Trip Report From "+this.start_date+" To "+this.end_date;
		}

		// console.log(this.VehicleSelectedItems);return;
		if(this.VehicleSelectedItems.length){
			fileName = this.VehicleSelectedItems[0].itemName + ' ' + fileName;
		}
		this.searchDetails.export = true;
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title:''
		  };
		  let queryParams = {
				vehicle_id: this.vehicleId,
				row_per_page: this.perPage,
				page_no:this.page,
				search: this.searchTextValue,
				start_date: this.start_date == undefined ? '' : this.start_date,
				end_date: this.end_date == undefined ? '' : this.end_date,
				export: 'yes'
			}
		
		  this.reportService.getTrip(queryParams).subscribe(res => {
			this.loading =false; 
			
			options.title = `Trip Report
				Tenant Name: ` + res.companyName + `
				Date Range: ` +res.dateRange;
			new Angular2Csv(res.data, fileName, options);
		  }, error => {
				this.loading =false; 
				this.customErroHandler.handleHttpError(error, 'exportUserReport');
		  });
	}	

	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getTripDetails(1);
	}

	getVehicle() {
    this.commonService.getAssignVehicle().subscribe(res => {
      if (res.success == true) {
        this.vehicleList = res.data;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErroHandler.handleHttpError(error, 'addInspection');
    });
	}
	onInspectionSelect(item: any) {
		this.vehicleId = item.id;
    this.getTripDetails(1);
  }

  onInspectionDeSelect(item: any) {
		this.vehicleId = '';
    this.getTripDetails(1);
  }
}
