import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { Config } from 'src/app/config/config';
import * as moment from 'moment';

@Component({
  selector: 'app-vehicle-wise-expense',
  templateUrl: './vehicle-wise-expense.component.html',
  styleUrls: ['./vehicle-wise-expense.component.css']
})
export class VehicleWiseExpenseComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
  	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	vehicleDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	driverRequestParameter: any = {};
	userInfo: any;
	serverUrl: any;
	searchTempVal: any;
	start_date;
	end_date;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  

  	constructor( private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.searchTempVal = '';
  		this.searchDetails.search_by = '';
  		this.serverUrl = Config.AuthenticationURL;
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.assignment_status = '';
  		this.searchDetails.current_status = '';
		this.getVehicleDetails(this.pageNo);
		localStorage.removeItem('editVehicleId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getVehicleDetails(currentPage){
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		this.searchDetails.export = '';
		let requestParameter = {
			page_no: this.pageNo,
			row_per_page: this.perPage,
			search: this.searchDetails.search_by,
			export: ''
		};
		this.reportService.getVehicleWiseExpense(requestParameter).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
	          		this.totalItem = res.data.count;
	          		this.vehicleDetails = res.data.rows;
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getVehicleDetails(this.pageNo);
		}
	}

	exportVehicleReport(){
		this.searchDetails.export = true;
		let requestParameter = {
			page_no: this.pageNo,
			row_per_page: this.perPage,
			search: this.searchDetails.search_by,
			export: 'yes'
		};
		this.reportService.getVehicleWiseExpense(requestParameter).subscribe(
	        res => {
	        	
				// this.loading =false; 
				// this.message = res.message;
		  //       if (res.success == true) {
		  //         	this.totalItem = res.data.count;
		  //         	this.vehicleDetails = res.data.rows;
		  //       } else {
				// 	this.commonService.showErrorMessage(res);
		  //       }
	        },
	        error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	    )
	}
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getVehicleDetails(1);
	}

}
