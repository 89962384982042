import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { Config } from 'src/app/config/config';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  	loading:boolean;
	public isDemoTenant:boolean;
  	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	vehicleDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	driverRequestParameter: any = {};
	userInfo: any;
	serverUrl: any;
	searchTempVal: any;

  	constructor( private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.searchTempVal = '';
  		this.searchDetails.search_by = '';
  		this.serverUrl = Config.AuthenticationURL;
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.assignment_status = '';
  		this.searchDetails.current_status = '';
		this.getVehicleDetails(this.pageNo);
		localStorage.removeItem('editVehicleId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getVehicleDetails(currentPage){
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {};
		this.searchDetails.export = '';
		this.reportService.getVehicle(this.pageNo, this.perPage, this.searchDetails).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
	          		this.totalItem = res.data.count;
	          		this.vehicleDetails = res.data.rows;
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getVehicleDetails(this.pageNo);
		}
	}

	exportVehicleReport(){
		this.searchDetails.export = true;
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "Vehicle List"
		  };
		
		  this.reportService.getVehicle(0, 0, this.searchDetails).subscribe(res => {
			this.loading =false; 
			new Angular2Csv(res.data, 'Vehicle List', options);
		  }, error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'exportVehicleReport');
		  });
	}
}
