import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class DueSerService {
    constructor(private _httpHelper: HttpHelper) {
    }

    addService(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-service', request);
        }
        catch (e) {
        }
    }

    updateService(request){
    	try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-service', request);
        }
        catch (e) {
        }
    }

    getService(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-service', request);
        }
        catch (e) {
        }
    }

    getSingleService(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-service', request);
        }
        catch (e) {
        }
    }

    deleteService(request){
        try {
           return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-service', request);
       }
       catch (e) {
       }
    }

    viewService(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-service-result', request);
        }
        catch (e) {
        }
    }
    
}
