import { Component, OnInit , NgZone, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Config } from '../../../config/config';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { Constants } from 'src/app/constants/constants';
import { FormControl } from '@angular/forms';

declare var $: any;
declare var google: any;
import * as moment from 'moment';
declare var map: any;
declare var OverlappingMarkerSpiderfier: any;
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-near-by-vehicle',
  templateUrl: './near-by-vehicle.component.html',
  styleUrls: ['./near-by-vehicle.component.css']
})
export class NearByVehicleComponent implements OnInit {
	map: any;
	mapStyles: any;
	userInfo: any;
	zoom: number;
	lat: number;
	lng: number;
	directionsDisplay: any;
    directionsService: any;
    infowindow: any;
    serverUrl: any;
    oms: any;
    loading: boolean = false;
    vehicleList: any = [];
    locationDetails: any = {};
    checkPlaceAddress: any = '';
    circle: any ;
    searchControl: FormControl;
    @ViewChild("search")
  	searchElementRef: ElementRef;
  	circleGeofance: any;
  	setMapCircle:boolean = false;
  	vehicleMarkerArray: any = [];
  	count: number = 0;

	constructor(
        private router: Router,
        private customErrorHandler:CustomErrorHandler, 
        private commonService:CommonService, 
        private vehicleService: VehicleService,
        private translate: TranslateService,
        private broadcaster: Broadcaster,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
    ) { 
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

	ngOnInit() {
		this.locationDetails.radius = 1;
        this.mapStyles = Constants.mapStyles;
        localStorage.removeItem('playbackVehicleId');
        localStorage.removeItem('playbackDriverId');	
        
  		this.zoom = 14;
	    this.lat = Number(this.userInfo.lat);
	    this.lng = Number(this.userInfo.lng);
	    if(this.lat == undefined || this.lng == undefined){
	    	this.lat = 22.7196;
	    	this.lng = 75.8577;
	    }
	    this.locationDetails.lat = this.lat;
	    this.locationDetails.lng = this.lng;
        var this1 = this;

     	setTimeout( () => {
            this.directionsService = new google.maps.DirectionsService;
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 14,
                center: { lat: this.lat, lng: this.lng },
                scrollwheel: true,
                styles: this.mapStyles,
                sensor: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                },
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                },
            });
            let geocoder = new google.maps.Geocoder;
        	let infowindow = new google.maps.InfoWindow;
            this.geocodeLatLng(geocoder, this.map, infowindow);
         	this.directionsDisplay.setMap(this.map);
            this.infowindow = new google.maps.InfoWindow();
            
            this.serverUrl = Config.AuthenticationURL;
            this.oms = new OverlappingMarkerSpiderfier(this.map, {
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: true,
                spiralFootSeparation: 40,
                spiralLengthStart: 15,
                spiralLengthFactor: 10,
                legWeight: 0,
                keepSpiderfied: true,
                nudgeStackedMarkers: false
            });
            this.getNearByVehicles();
        }, 1000);
        this.loadAutoComple();
    }

    getNearByVehicles(){
        if(this.locationDetails.radius <= 0){
            return false;
        }
    	if(this.setMapCircle == true){
            this.circleGeofance.setMap(null);
            this.setMapCircle = false;
        }
        let radius = Number(this.locationDetails.radius) * 1000;
        // this.locationDetails.radius = radius;
    	this.circleGeofance = new google.maps.Circle({
			map: this.map,
			radius: radius,    // 10 miles in metres
			fillColor: '#262525',
			center: { lat: this.lat, lng: this.lng },
		});
		this.circleGeofance.setMap(this.map);
		this.setMapCircle = true;
    	
        this.map.setCenter(new google.maps.LatLng(this.lat, this.lng));
        // this.circleGeofance.setRadius(radius);
        // this.map.setCenter(this.circleGeofance.getPosition())
       
        this.loading = true;
        let requestParam = {
        	lat: this.locationDetails.lat,
        	lng: this.locationDetails.lng,
        	radius: radius
        }
        for(let i = 0; i < this.vehicleMarkerArray.length; i++) {
            this.vehicleMarkerArray[i].setMap(null);
        }
        this.vehicleService.getNearByVehicle(requestParam).subscribe(
            res => {
                this.loading = false;
                if (res.success == true) {
                    this.vehicleList = res.data;
                    for (var i = 0; i < this.vehicleList.length;i++) {
                    	this.addMarker(this.vehicleList[i]['id'], this.vehicleList[i].vehicle_name, this.vehicleList[i].lat, this.vehicleList[i].lng,  this.vehicleList[i]['status'],  this.vehicleList[i]['vehicle_type']);
                    }
                } else {
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addGroup');
            }
        )
    }

    placeLocationAddress(event) {
        let geocoder = new google.maps.Geocoder();
        var _this = this;
        // _this.placeSelectedBtn = true;
        setTimeout(function () {
            let address = $('#locationAddress').val().toString();
            _this.checkPlaceAddress = '';
            if (address.length > 4) {
                geocoder.geocode({ 'address': address }, function (results, status) {
                    _this.checkPlaceAddress = status;
                    if (_this.checkPlaceAddress != 'OK') {
                        _this.locationDetails.address = '';
                        _this.commonService.showCustomError('Please select valid address');
                    } else {
                        // _this.placeSelectedBtn = false;
                        $('input[name="address"]').click();
                    }
                });
            }
            if (address.length < 5) {
                _this.locationDetails.address = '';
                _this.commonService.showCustomError('Please select valid address');
            }
        }, 500)
    }

    loadAutoComple(){
		this.searchControl = new FormControl();

	    //load Places Autocomplete
	    this.mapsAPILoader.load().then(() => {
	    	let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
	        	
	      	});
	      	autocomplete.addListener("place_changed", () => {
	      		this.ngZone.run(() => {
	          		this.checkAddress();
		          	let place: google.maps.places.PlaceResult = autocomplete.getPlace();
		          	//verify result
		          	if (place.geometry === undefined || place.geometry === null) {
		            	return;
		          	}

		          	//set latitude, longitude and zoom
		          	this.lat = place.geometry.location.lat();
		          	this.lng = place.geometry.location.lng();
		          	this.zoom = 16;
		          	this.locationDetails.lat = this.lat;
 					this.locationDetails.lng = this.lng;
		        
		          	let radius = Number(this.locationDetails.radius);
				    this.circle = [
				      	{lat: this.lat, lng: this.lng, radius: radius, color: 'red'}
				    ];
				    this.locationDetails.address = place.formatted_address;
		        	this.locationDetails.place_image_url = place.url;
		        	this.locationDetails.place_name = place.name;
		        });
	      	});
	    });
	}

    checkAddress():Promise<Boolean>{
		return new Promise((resolve,reject)=>{
			let geocoder = new google.maps.Geocoder();
			let address = this.locationDetails.address;
			this.checkPlaceAddress = '';
			geocoder.geocode( { 'address': address}, function(results, status) {
				this.checkPlaceAddress = status;
				if(this.checkPlaceAddress != 'OK') {
					reject(false);
				}
				else{
					resolve(true);
				}
			});
 		
		})
	}

	geocodeLatLng(geocoder, map, infowindow){
        let input = this.userInfo.lat+','+this.userInfo.lng;
        let latlngStr = input.split(',', 2);
        let latlng = {lat: parseFloat(latlngStr[0]), lng: parseFloat(latlngStr[1])};
        geocoder.geocode({'location': latlng}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
             	this.locationDetails.address = results[0].formatted_address;
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
    }

    addMarker(vehicleId, name, lat, long, status, vehicleType) {
        vehicleId = 1;
        lat = Number(lat);
        long = Number(long);
        console.log(vehicleId, name, lat, long, status, vehicleType);
        var this1 = this;
        var vehicleImage = Config.AuthenticationURL + "public/image/tracking/Car/stop.png";
        if(vehicleType == null || vehicleType == undefined){
            vehicleType = 'Car';
        }
        if(vehicleType != 'Car' && vehicleType != 'Truck' && vehicleType != 'Bus' && vehicleType != 'Motorcycle' && vehicleType != 'Tanker' && vehicleType != 'Loader' && vehicleType != 'Mower' && vehicleType != 'Trailer' && vehicleType != 'Van'){
            vehicleType = 'Car';
        }
        if(status == 'Stop' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Fire-Truck' || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/stop.png";
        }
        if(status == 'Running' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Fire-Truck' || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/running.png";
        }
        if(status == 'Idle' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Fire-Truck' || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/idle.png";
        }
        if(status == 'Inactive' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Fire-Truck' || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/inactive.png";
        }
        if(status == 'No Data' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Fire-Truck' || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/nodata.png";
        }
	    
        let icon = {
	      	url: vehicleImage,
	      	scaledSize: new google.maps.Size(30, 50), // scaled size
	      	origin: new google.maps.Point(0, 0), // origin
	      	anchor: new google.maps.Point(0, 0), // anchor
	      	shape: { coords: [17, 17, 18], type: 'circle' }
	    };
	    let latlngset = new google.maps.LatLng(lat, long);
	    let _this = this;
	    let marker = new google.maps.Marker({
	      	map: _this.map,
	      	// title: name,
	      	position: latlngset,
	      	icon: icon,
            vehicle_id: vehicleId,
            anchor: new google.maps.Point(lat, long)
        });     
        this.vehicleMarkerArray.push(marker);
	    if (this.count != 1) {
	    }
	      	this.map.setCenter(marker.getPosition())
        

        let setStatus = status;
        if(status == 'Stop'){
            setStatus = 'Parked';
        }
	    var content = "<div class='infoTooltip' style='min-width: 180px;'><div>";
	    content += "<div style='width: 50%;float:left;font-size:14px;position:absolute'><span>Vehicle Name</span><br><p style='font-weight: bold;'>" + name + "</p></div>";
	    content += "<div style='width: 50%;float:right;font-size:14px;'><span>Status</span><br><p style='font-weight: bold;'>" + setStatus + "</p></div>";
        content += "</div><div style='clear:both'></div>";
        content += "<div>";
	         
        content += "<div style='clear:both'></div>";
      
        
        var infowindow = this.infowindow;
	    var router = this.router;
        
	    google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
	      	return function () {
	        	infowindow.close();
	       		infowindow.setContent(content);
	       		infowindow.open(_this.map, marker);
                setTimeout(function(){
                    document.getElementById("playbackTracking").addEventListener("click", function () {
    	          		localStorage.setItem('playbackVehicleId', vehicleId);
                        router.navigate(['./play-back']);
                    });
                },200)
	      	};
	    })(marker, content, infowindow));

        this.oms.addMarker(marker);
        
    }

    numberValidation(event: any) {

        const pattern = /[0-9\+\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        //    console.log(event.keyCode);
        if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
    }

    setFitBounds(boundryArray){
        let bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < boundryArray.length; i++) {
            let myLatLng = new google.maps.LatLng(boundryArray[i].lat, boundryArray[i].lng);
            bounds.extend(myLatLng);
        }
        this.map.fitBounds(bounds);
    }
}
