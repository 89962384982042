import { VehicleAssignmentReportComponent } from './modules/report-management/vehicle-assignment-report/vehicle-assignment-report.component';
import { VisitorComponent } from './modules/visitor/visitor.component';
import { RoleService } from './services/role/role.service';
import { RoleManagementComponent } from './modules/role-management/role-management.component';
import { CompanySettingsService } from 'src/app/services/company-settings/companySettingsService.service';
import { CompanySettingsComponent } from 'src/app/modules/company-settings/company-settings.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { HeaderComponent } from 'src/app/modules/header/header.component';
import { SignupComponent } from 'src/app/modules/signup/signup.component';
import { ResetPasswordComponent } from 'src/app/modules/reset-password/reset-password.component';
import { VehicleManagementComponent } from 'src/app/modules/vehicle-management/vehicle-management.component';
import { VehicleListComponent } from 'src/app/modules/vehicle-management/vehicle-list/vehicle-list.component';
import { AddVehicleComponent } from 'src/app/modules/vehicle-management/add-vehicle/add-vehicle.component';
import { PlacesComponent } from 'src/app/modules/places/places.component';
import { AddPlacesComponent } from 'src/app/modules/places/add-places/add-places.component';
import { PlacesListComponent } from 'src/app/modules/places/places-list/places-list.component';
import { VehicleGroupListComponent } from 'src/app/modules/vehicle-management/vehicle-group-list/vehicle-group-list.component';
import { TripsComponent } from 'src/app/modules/trips/trips.component';
import { AddTripComponent } from 'src/app/modules/trips/add-trip/add-trip.component';
import { TripListComponent } from 'src/app/modules/trips/trip-list/trip-list.component';
import { TripMapComponent } from 'src/app/modules/trips/trip-map/trip-map.component';
import { WorkOrdersComponent } from 'src/app/modules/work-orders/work-orders.component';
import { AddOrderComponent } from 'src/app/modules/work-orders/add-order/add-order.component';
import { OrderListComponent } from 'src/app/modules/work-orders/order-list/order-list.component';
import { UserManagementComponent } from 'src/app/modules/user-management/user-management.component';
import { AddDriverComponent } from 'src/app/modules/user-management/add-driver/add-driver.component';
import { DriverListComponent } from 'src/app/modules/user-management/driver-list/driver-list.component';
import { EmployeeListComponent } from 'src/app/modules/user-management/employee-list/employee-list.component';
import { AddEmployeeComponent } from 'src/app/modules/user-management/add-employee/add-employee.component';
import { PlayBackComponent } from 'src/app/modules/play-back/play-back.component';
import { ReportManagementComponent } from 'src/app/modules/report-management/report-management.component';
import { UserComponent } from 'src/app/modules/report-management/user/user.component';
import { VehicleComponent } from 'src/app/modules/report-management/vehicle/vehicle.component';
import { TripComponent } from 'src/app/modules/report-management/trip/trip.component';
import { DriverComponent } from 'src/app/modules/report-management/driver/driver.component';
// import { VehicleAssignmentComponent } from 'src/app/modules/report-management/vehicle-assignment/vehicle-assignment.component';
import { DriverPerformanceComponent } from 'src/app/modules/report-management/driver-performance/driver-performance.component';
import { VehicleDetailedReportComponent } from 'src/app/modules/report-management/vehicle-detailed-report/vehicle-detailed-report.component';
import { VehicleIdleReportComponent } from 'src/app/modules/report-management/vehicle-idle-report/vehicle-idle-report.component';
import { NearByVehicleComponent } from 'src/app/modules/vehicle-management/near-by-vehicle/near-by-vehicle.component';
import { VehicleEngineOperatingTimeComponent } from 'src/app/modules/report-management/vehicle-engine-operating-time/vehicle-engine-operating-time.component';
import { VehicleSpeedIgnitionGraphComponent } from 'src/app/modules/report-management/vehicle-speed-ignition-graph/vehicle-speed-ignition-graph.component';
import { VehicleAcMisuseComponent } from 'src/app/modules/report-management/vehicle-ac-misuse/vehicle-ac-misuse.component';
import { ManageAlertComponent } from 'src/app/modules/manage-alert/manage-alert.component';


import { ModulesModule } from 'src/app/modules/modules.module';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap';
import { HttpHelper } from './helpers/http.helper';
import { LoginService } from './services/login/login.service';
import { VehicleService } from './services/vehicle/vehicle.service';
import { PlaceService } from './services/place/place.service';
import { TripService } from './services/trip/trip.service';
import { UserService } from './services/user/user.service';
import { WorkOrderService } from './services/work-order/work-order.service';
import { Ng2PaginationModule} from 'ng2-pagination';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CommonService } from 'src/app/services/common/common.service';
import { FooterComponent } from 'src/app/modules/footer/footer.component';
import { AngularFireModule } from 'angularfire2';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import {CalendarModule} from 'primeng/calendar';
import { PlayBackService } from 'src/app/services/play-back/play-back.service';
import { RoleComponent } from 'src/app/modules/role-management/role/role.component';
import { LiveTrackingComponent } from './modules/live-tracking/live-tracking.component';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import { GeoLiveTrackingComponent } from './modules/geo-live-tracking/geo-live-tracking.component';
import { GeoLiveTrackingService } from 'src/app/services/geo-live-tracking/geo-live-tracking.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ShiftsComponent } from './modules/shifts/shifts.component';
import { AddShiftComponent } from './modules/shifts/add-shift/add-shift.component';
import { ShiftListComponent } from './modules/shifts/shift-list/shift-list.component';
import { RolelistComponent } from './modules/role-management/role-list/role-list.component';
import { ReportService } from 'src/app/services/report/report.service';
import { ReportDashboardComponent } from './modules/report-management/report-dashboard/report-dashboard.component';
import { MessageComponent } from './modules/message/message.component';
import { VehicleAssignmentComponent } from 'src/app/modules/vehicle-management/vehicle-assignment/vehicle-assignment.component';
import { MessageService } from 'src/app/services/message/message.service';
import { InspectionComponent } from './modules/inspection/inspection.component';
import { InspectionListComponent } from './modules/inspection/inspection-list/inspection-list.component';
import { AddInspectionComponent } from './modules/inspection/add-inspection/add-inspection.component';
import { InspectionAssignmentComponent } from './modules/inspection/inspection-assignment/inspection-assignment.component';
import { ServiceDueManagementComponent } from './modules/service-due-management/service-due-management.component';
import { ServiceDueListComponent } from './modules/service-due-management/service-due-list/service-due-list.component';
import { CreateDueServiceComponent } from './modules/service-due-management/create-due-service/create-due-service.component';
import { IssueListComponent } from './modules/issue-list/issue-list.component';
import { FuelManagementComponent } from './modules/fuel-management/fuel-management.component';
import { RouteManagementComponent } from './modules/route-management/route-management.component';
import { RouteListComponent } from './modules/route-management/route-list/route-list.component';
import { CreateRouteComponent } from './modules/route-management/create-route/create-route.component';
import { ExpenseManagementComponent } from './modules/expense-management/expense-management.component';
import { ExpenseListComponent } from './modules/expense-management/expense-list/expense-list.component';
import { ViewInspectionComponent } from './modules/inspection/view-inspection/view-inspection.component';
import { LeaveManagementComponent } from './modules/leave-management/leave-management.component';
import { ViewServiceComponent } from './modules/service-due-management/view-service/view-service.component';
import { ChartModule } from 'angular2-chartjs';
import { NgxPayPalModule } from 'ngx-paypal';

import { ChecklistComponent } from './modules/checklist/checklist.component';
import { VehicleWiseServiceComponent } from './modules/report-management/vehicle-wise-service/vehicle-wise-service.component';
import { DriverWiseServiceComponent } from './modules/report-management/driver-wise-service/driver-wise-service.component';
import { DriverWiseFuelComponent } from './modules/report-management/driver-wise-fuel/driver-wise-fuel.component';
import { VehicleWiseFuelComponent } from './modules/report-management/vehicle-wise-fuel/vehicle-wise-fuel.component';
import { VehicleWiseExpenseComponent } from './modules/report-management/vehicle-wise-expense/vehicle-wise-expense.component';
import { DriverWiseExpenseComponent } from './modules/report-management/driver-wise-expense/driver-wise-expense.component';
import { DriverWisePartsComponent } from './modules/report-management/driver-wise-parts/driver-wise-parts.component';
import { VehicleWisePartsComponent } from './modules/report-management/vehicle-wise-parts/vehicle-wise-parts.component';
import { LeaveComponent } from './modules/report-management/leave/leave.component';
import { VehicleCurrentPositionComponent } from './modules/report-management/vehicle-current-position/vehicle-current-position.component';
import { GroupsComponent } from './modules/groups/groups.component';
import { AddGroupComponent } from './modules/groups/add-group/add-group.component';
import { GroupListComponent } from './modules/groups/group-list/group-list.component';
import { GeofenceComponent } from './modules/groups/geofence/geofence.component';
import { GeofenceListComponent } from './modules/groups/geofence-list/geofence-list.component';
// services
import { InspectionService } from './services/inspection/inspection.service';
import { DueSerService } from './services/due-service/dueService.service';
import { PartsAndFuleService } from './services/parts-fule/parts-fule.service';
import { ExpenseService } from './services/expense/expense.service';
import { LeaveService } from './services/leave-service/leave.service';
import { ChecklistService } from './services/checklist/checklist.service';
import { ConfigService } from './services/config/config.service';
// Directive
import { NumberOnlyDirective } from './directive/number.directive';
import { VehicleInspectionReportComponent } from './modules/report-management/vehicle-inspection-report/vehicle-inspection-report.component';
import { LiveLocationTrackingComponent } from './modules/live-location-tracking/live-location-tracking.component';
import {GalleriaModule} from 'primeng/galleria';

import { DriverAttendanceComponent } from './modules/report-management/driver-attendance/driver-attendance.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { UiSwitchModule } from 'ngx-ui-switch';

// import { ContractorService } from './services/contractor/contractor.service';
// Define the firebase database configuration keys, get it from your Firebase application console
export const firebaseConfig = {
    apiKey: "AIzaSyBcQWg-njMTm0bitdMvD_9SXtM1TWgISCY",
    authDomain: "fleetpro-24da3.firebaseapp.com",
    databaseURL: "https://fleetpro-24da3.firebaseio.com",
    projectId: "fleetpro-24da3",
    storageBucket: "fleetpro-24da3.appspot.com",
    messagingSenderId: "443844985096"
};

// soket
import { Config } from './config/config';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// Live
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const config: SocketIoConfig = { url:'https://fleetpro.basecampscout.com:5001' , options: {} };
// Dev
//const config: SocketIoConfig = { url:'https://enode.fleetpro.co.in' , options: {} };

// QA
// const config: SocketIoConfig = { url:'http://fleetproqa.basecampscout.in:4000' , options: {} };

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Broadcaster } from './services/common/broadcaster';
import { PartManagementComponent } from './modules/part-management/part-management.component';
import { StudentManagementComponent } from './modules/student-management/student-management.component';
import { StudentListComponent } from './modules/student-management/student-list/student-list.component';
import { CreateStudentComponent } from './modules/student-management/create-student/create-student.component';
import { AssignVehicleComponent } from './modules/vehicle-management/assign-vehicle/assign-vehicle.component';
import { VehicleCurrentLocationComponent } from './modules/vehicle-current-location/vehicle-current-location.component';
import { VehicleSummaryComponent } from './modules/report-management/vehicle-summary/vehicle-summary.component';
import { VehicleStoppageComponent } from './modules/report-management/vehicle-stoppage/vehicle-stoppage.component';
import { IgnitionSummaryComponent } from './modules/report-management/ignition-summary/ignition-summary.component';
import { DailyFuelConsumptionComponent } from './modules/report-management/daily-fuel-consumption/daily-fuel-consumption.component';
import { AverageFuelConsumptionComponent } from './modules/report-management/average-fuel-consumption/average-fuel-consumption.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { KendoExampleComponent } from './modules/kendo-example/kendo-example.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PagerModule } from '@progress/kendo-angular-pager';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { LiveTrackingDataComponent } from './modules/live-tracking-data/live-tracking.component';
import { VehicleIgnitionReportComponent } from 'src/app/modules/report-management/vehicle-ignition-report/vehicle-ignition-report.component';

import { LocationMappingComponent } from 'src/app/modules/location-mapping/location-mapping.component';
import { AddLocationMappingComponent } from 'src/app/modules/location-mapping/add-location-mapping/add-location-mapping.component';
import { LocationMappingListComponent } from 'src/app/modules/location-mapping/location-mapping-list/location-mapping-list.component';

import { LocationMappingService } from './services/location-mapp/location-mapp.service';
// import { ContractorListComponent } from 'src/app/modules/contractor/contractor-list/contractor-list.component';
// import { ContractorComponent } from 'src/app/modules/contractor/contractor.component';
// import { AddContractorComponent } from 'src/app/modules/contractor/add-contractor/add-contractor.component';
import { ChartsModule } from 'ng2-charts';
export function initFactory(config: ConfigService) {
    return () => config.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    SignupComponent,
    ResetPasswordComponent,
    VehicleManagementComponent,
    VehicleListComponent,
    AddVehicleComponent,
    PlacesComponent,
    AddPlacesComponent,
    PlacesListComponent,
    VehicleGroupListComponent,
    TripsComponent,
    AddTripComponent,
    TripListComponent,
    TripMapComponent,
    WorkOrdersComponent,
    AddOrderComponent,
    OrderListComponent,
    UserManagementComponent, 
    AddDriverComponent, 
    DriverListComponent, 
    EmployeeListComponent, 
    AddEmployeeComponent,
    FooterComponent,
    PlayBackComponent,
    ChangePasswordComponent,
    CompanySettingsComponent,
    RoleComponent,
    RolelistComponent,
    LiveTrackingComponent,
    GeoLiveTrackingComponent,
    LiveTrackingDataComponent,
    RoleManagementComponent,
    VisitorComponent,
    ShiftsComponent,
    AddShiftComponent,
    ShiftListComponent,
    ReportManagementComponent, 
    UserComponent, 
    VehicleComponent, 
    TripComponent, 
    DriverComponent,
    DriverPerformanceComponent,
    ReportDashboardComponent,
    MessageComponent,
    VehicleAssignmentComponent,
    InspectionComponent,
    InspectionListComponent,
    AddInspectionComponent,
    InspectionAssignmentComponent,
    ViewInspectionComponent,
    VehicleAssignmentReportComponent,
    ServiceDueManagementComponent,
    ServiceDueListComponent,
    ViewServiceComponent,
    CreateDueServiceComponent,
    IssueListComponent,
    FuelManagementComponent,
    RouteManagementComponent,
    RouteListComponent,
    CreateRouteComponent,
    ExpenseManagementComponent,
    ExpenseListComponent,
    NumberOnlyDirective,
    LeaveManagementComponent,
    ChecklistComponent,
    VehicleWiseServiceComponent,
    DriverWiseServiceComponent,
    DriverWiseFuelComponent,
    VehicleWiseFuelComponent,
    VehicleWiseExpenseComponent,
    DriverWiseExpenseComponent,
    DriverWisePartsComponent,
    VehicleWisePartsComponent,
    LeaveComponent,
    VehicleInspectionReportComponent,
    LiveLocationTrackingComponent,
    DriverAttendanceComponent,
    PartManagementComponent,
    StudentManagementComponent,
    StudentListComponent,
    CreateStudentComponent,
    AssignVehicleComponent,
    VehicleDetailedReportComponent,
    VehicleIdleReportComponent,
    VehicleCurrentPositionComponent,
    VehicleCurrentLocationComponent,
    VehicleSummaryComponent, 
    VehicleStoppageComponent,
    IgnitionSummaryComponent,
    GroupsComponent, 
    AddGroupComponent, 
    GroupListComponent, 
    GeofenceComponent, 
    GeofenceListComponent,
    NearByVehicleComponent,
    VehicleEngineOperatingTimeComponent,
    VehicleSpeedIgnitionGraphComponent,
    VehicleAcMisuseComponent,
    ManageAlertComponent,
    DailyFuelConsumptionComponent,
    AverageFuelConsumptionComponent,
    PaymentComponent,
    KendoExampleComponent,
    VehicleIgnitionReportComponent,
    LocationMappingComponent,
    AddLocationMappingComponent,
    LocationMappingListComponent,
    // ContractorListComponent,
    // ContractorComponent,
    // AddContractorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModulesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    Ng2PaginationModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    CalendarModule,
    AngularMultiSelectModule,
    AgmCoreModule.forRoot({
      libraries: ["places","drawing"]
    }),
    AgmDirectionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ChartModule,
    MalihuScrollbarModule.forRoot(),
    SocketIoModule.forRoot(config),
    GalleriaModule, 
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SelectDropDownModule,
    UiSwitchModule,
    NgxPayPalModule,GridModule,PagerModule,InfiniteScrollModule,
    ChartsModule
  ],
  providers: [
      HttpHelper,
      LoginService,
      VehicleService,
      PlaceService,
      TripService,
      CommonService,
      WorkOrderService,
      DashboardService,
      UserService,
      PlayBackService,
      LiveTrackingService,
      GeoLiveTrackingService,
      CompanySettingsService,
      RoleService,
      NotificationService,
      ReportService,
      MessageService,
      CustomErrorHandler,
      InspectionService,
      DueSerService,
      PartsAndFuleService,
      ExpenseService,
      LeaveService,
      ChecklistService,
      ConfigService,
      {
          provide: APP_INITIALIZER,
          useFactory: initFactory,
          deps: [ConfigService],
          multi: true
      },
      {
        provide: ErrorHandler, 
        useClass: CustomErrorHandler
      },
      Broadcaster,
      LocationMappingService
      // ContractorService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
