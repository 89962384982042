import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { RoleService } from '../../../services/role/role.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  loading: boolean;
  roleDetails: any = {};
  userInfo: any;
  roleList: any;
  serverUrl: any;
  dateTime = new Date();
  currentpage: any;
  menulist: any = [];
  editRoleId: any = {};
  menuItems: any = [];
  accessObj: any = {};
  roleId: any;
  constructor(private router: Router, private http: HttpClient, public roleService: RoleService, public commonService: CommonService, private customErrorHandler: CustomErrorHandler) {
    this.userInfo = localStorage.getItem('userInfo');
    if (this.userInfo) {
      this.userInfo = JSON.parse(this.userInfo);
      this.currentpage = "Add";
    }
  }
  ngOnInit() {
    this.loading = false;
    this.editRoleId = '';
    this.editRoleId = localStorage.getItem('editRoleId');
    if (this.editRoleId) {
      this.getRoleDetails(this.editRoleId);
      this.currentpage = "Update";
    }
    else {
      this.getAllModules();
    }
    this.accessObj.id = '';
    this.accessObj.is_checked = '';
  }

  createRole() {
    this.loading = true;
    for(let i = 0; i <this.roleDetails.access_modules.length; i++){
      if(this.roleDetails.access_modules[i].module_name == 'Live Tracking'){
       this.roleDetails.access_modules[i].is_checked = true;
      }
    }
    if (this.editRoleId) {
      this.roleDetails.id = this.editRoleId;
      this.roleService.editRole(this.roleDetails).subscribe(
        res => {
          this.loading = false;
          localStorage.removeItem('editRoleId');
          if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(['role-management']);
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.loading = false;
          this.customErrorHandler.handleHttpError(error, 'updateRole');
        }
      )
    } else {
      this.roleService.createRole(this.roleDetails).subscribe(
        res => {
          this.loading = false;
          if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(['role-management']);
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.loading = false;
          this.customErrorHandler.handleHttpError(error, 'createRole');
        }
      )
    }
  }

  getAllModules() {
    this.roleService.getMenuModules().subscribe(
      res => {
        if (res.success == true) {
          this.roleDetails.access_modules = res.data;
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getAllModules');
      }
    )
  }

  getRoleDetails(editroleId) {
    this.roleService.getSingleRole(editroleId).subscribe(res => {
      if (res.success == true) {
        this.roleDetails = res.data;
      } else {
        this.commonService.showErrorMessage(res);
      }
    },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getSingleRole');
      }
    )
  }

  updateSelectedItems(item) {
    let updateItem = this.roleDetails.access_modules.find(s => s.id == item.id);
    let index = this.roleDetails.access_modules.indexOf(updateItem);
    updateItem.is_checked = !item.is_checked;
    this.roleDetails.access_modules[index] = updateItem;
  }

  ngOnDestroy() {
    localStorage.removeItem('editRoleId');
  }
}
