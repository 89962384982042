import { Component, OnInit ,ElementRef, NgZone,ViewChild} from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  userInfo: any = {};
  private language:string;
  public checkPlaceAddress: any;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  public lat: number;
  public lng: number;
  public searchControl: FormControl;

  constructor (
    private router: Router, 
    public _loginService: LoginService, 
    private customErrorHandler:CustomErrorHandler, 
    private commonService:CommonService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    // this.userInfo.gender = '';
    this.userInfo.address = '';
    document.body.classList.add("login-page");
    this.language = navigator.language;
    let loginchk = localStorage.getItem('isLogin');
    if(loginchk == '1'){
      this.doLogout();
    }
    this.searchControl = new FormControl();

      //load Places Autocomplete
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            
          });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                //verify result
                if (place.geometry === undefined || place.geometry === null) {
                  return;
                }

                //set latitude, longitude and zoom
                this.lat = place.geometry.location.lat();
                this.lng = place.geometry.location.lng();
                this.userInfo.lat = this.lat;
                this.userInfo.lng = this.lng;
                console.log(this.userInfo)
                this.userInfo.address = place.formatted_address;
            
            });
          });
      });
  }	

  ngOnDestroy() {
    document.body.classList.remove("login-page");
  }

  onInputFocus(e:any) {
    e.target.parentElement.classList.add("focusWithText");
  }

  onInputBlur(e:any) {
    if(e.target.value == "") {
        e.target.parentElement.classList.remove("focusWithText");
    }
  }
  userRegistration(){
    this.userInfo.lang =  this.language;
    if(this.userInfo.lat == '' || this.userInfo.lat == null){
      let response = {message: 'Please select valid address'}
      this.commonService.showErrorMessage(response);
    }
    this._loginService.signup(this.userInfo).subscribe(res => {
      if (res.success == true) {
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(['./login']);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'signup');
    });
  }
  doLogout() {
    this.commonService.logout();
  }
  cancelSignup(){
    this.router.navigate(['./login']);
  }
  
  removeSpecialChar(event : any) {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 0 || k == 32 || (k >= 48 && k <= 57)); 
  }

  checkMobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    //    console.log(event.keyCode);
    if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  placeLocationAddress(event){
    let geocoder = new google.maps.Geocoder();
    var _this = this;
    setTimeout(function(){
      let address = $('#locationAddress').val().toString();
      _this.checkPlaceAddress = '';
        if(address.length > 4){

          // geocoder.geocode( { 'address': address}, function(results, status) {
          //     _this.checkPlaceAddress = status;
          //     if(_this.checkPlaceAddress != 'OK') {
          //       _this.userInfo.address = '';
          //       _this.commonService.showCustomError('Please select valid address');
          //     }
          //   });
        }

          if(address.length < 5){
            // _this.locationValidation = 'Select valid address';
            _this.userInfo.address = '';
            // _this.commonService.showCustomError('Please select valid address');
          }
    },500)
  }

  checkAddress():Promise<Boolean>{
    return new Promise((resolve,reject)=>{
      let geocoder = new google.maps.Geocoder();
      let address = this.userInfo.address;
      this.checkPlaceAddress = '';
      geocoder.geocode( { 'address': address}, function(results, status) {
        this.checkPlaceAddress = status;
        if(this.checkPlaceAddress != 'OK') {
          reject(false);
        }
        else{
          resolve(true);
        }
      });
     
    })
  }

}
