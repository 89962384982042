import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.css']
})
export class IssueListComponent implements OnInit {
	isDemoTenant: boolean;
  	constructor() { }

  	ngOnInit() {
  		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
  	}

}
