import { Injectable } from '@angular/core';
import { HttpHelper } from 'src/app/helpers/http.helper';
import { Config } from 'src/app/config/config';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _httpHelper: HttpHelper) {
  }

  subscriptionPayment(request){
    try {
          return this._httpHelper.post(Config.AuthenticationBaseURL + 'subscription-payment', request)
      }
      catch (e) {
      }
  }

  getSubscriptionList(request){
    try {
          // r/eturn this._httpHelper.get(Config.AuthenticationBaseURL + '/admin/get-subscription', request)
          let requestParameter = '?page='+request.page+'&per_page='+request.per_page+'&search='+request.search+ '&export='+ request.export;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'admin/get-subscription-list'+requestParameter)
      }
      catch (e) {
      }
  }
}
