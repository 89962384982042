import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { TripService } from '../../../services/trip/trip.service';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { ChecklistService } from '../../../services/checklist/checklist.service';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import { PlaceService } from 'src/app/services/place/place.service';
import { WorkOrderService } from '../../../services/work-order/work-order.service';
import { Config } from './../../../config/config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

declare var moment: any;
declare var $: any;
declare var google: any;
declare var Autocomplete: any;
declare var map: any;
declare var cityCircle: any;

@Component({
    selector: 'app-add-trip',
    templateUrl: './add-trip.component.html',
    styleUrls: ['./add-trip.component.css']
})
export class AddTripComponent implements OnInit {
    vehiclenameSelectedItems = [];
    vehiclenameSettings = {};
    workorderSelectedItems = [];
    workorderSettings = {};
    pickupplaceSelectedItems = [];
    pickupplaceSettings = {};
    dropplaceSelectedItems = [];
    dropplaceSettings = {};
    otherplaceSelectedItems = [];
    otherplaceSettings = {};
    loading: boolean;
    tripDetails: any = {};
    vehicleList: any = [];
    userDetails: any = {};
    tripWaypoints: any = [];
    wayRouteLength: number = 0;
    placeList: any = [];
    modalRef: BsModalRef;
    searchControl: FormControl;
    @ViewChild("search")
    searchElementRef: ElementRef;
    wayPoint: any = {};

    waypoints: any = []
    renderOptions = {
        draggable: true,
        suppressMarkers: false,
    }
    dateTime = new Date();
    wayPointInput: any = [];
    zoom: any;
    lat: any;
    lng: any;
    origin: any = {};
    destination: any = {};
    waypointLocation: any = {};
    wayPointData: any = {}
    orderList: any = [];
    message: any;
    wayPointInputLength: number = 0;
    mapStyles: any;
    selectedWayPoint: any = [];
    saveButton: boolean = false;
    distanceTimeRequest: any = {};
    tripETATime: any;
    waypts: any = [];
    routeDistance: any;
    routeDistanceKm: any;
    routeETAMin: any;
    routeETA: any;
    markerOptions: any = {}
    iconUrl: any;
    serverUrl: any;
    markerWayPoint: any = {}

    wayMarkerOptions = []
    wayCount: number = 2;
    placeDetails: any = {}
    placeLocationRequired: any;
    placeNameRequired: any;
    map: any;
    markers = [];
    routePaths: any[] = [];
    routeOrigin: any;
    routeDestination: any;
    directionsDisplay: any;
    directionsService: any;
    mapInitializeCount: number = 0;

    drawingManager: any;
    boundries: any = [];
    circleRadius: any;
    circleLat: string;
    circleLng: string;
    shapeType: number;
    geofenceDraw: boolean = false;
    polygonPaths: any = [];
    rectangleBounds: any = {};
    drawGeofencingType: number;
    drawGeofencing: any;
    checkPlaceAddress: any;
    placeSelectedBtn: boolean = true;
    checklistItemList: any = [];
    checklistSettings: any = {};
    checklistSelectedItems: any = [];
    endDateTime: any;
    startDateTime: any;
    timeDiffMsg: any = '';
    config = {}
    placeSelectedItem = [];
    placeSelectedIds = [];
    availableSelectedIds = [];
    minDateOnTrip: number = 0;
    userInfo:any;
    allowModule = [];
    workOrderModule: boolean = false;
    checklistModule: boolean = false;

    constructor(
        private modalService: BsModalService,
        private router: Router,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private http: HttpClient,
        public tripService: TripService,
        public _commonService: CommonService,
        public vehicleService: VehicleService,
        public placeService: PlaceService,
        private customErrorHandler: CustomErrorHandler,
        public workOrderService: WorkOrderService,
        public checklistService: ChecklistService,
		private translate: TranslateService,
		private broadcaster: Broadcaster
    ) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
    }

    ngOnInit() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(this.userInfo.add_trip == 0){
            this.router.navigate(['./trips']);
        }
        this.allowModule = JSON.parse(this.userInfo.access_module.allow_modules);
        this.loading = false;
        this.tripWaypoints[0] = '';
        this.mapStyles = Constants.mapStyles;
        this.zoom = 12;
        this.lat = Number(this.userDetails.lat);
        this.lng = Number(this.userDetails.lng);
        // this.addWayPoint();
        this.getVehicle();
        this.getChecklist();
        this.tripDetails.vehicle_id = '';

        this._commonService.stickyHeader();

        this.getPlaceDetails();
        this.getDirection();
        this.tripDetails.order_id = [];
        this.tripETATime = '0 m';
        this.routeDistanceKm = '0 km';
        this.tripDetails.pickup_point = '';
        this.tripDetails.drop_point = '';
        this.tripDetails.trip_fare = 0;
        this.tripDetails.trip_tax = 0;
        this.tripDetails.trip_discount = 0;
        this.tripDetails.trip_total = 0;
        this.tripDetails.activate_geofence = false;
        this.serverUrl = Config.AuthenticationURL;

        this.iconUrl = this.serverUrl + 'public/image/route-icon/';
        this.markerOptions = {
            origin: {
                icon: this.iconUrl + '1.png',
                draggable: true,
            },
            destination: {
                icon: this.iconUrl + '2.png',
                draggable: true
            }
        }
        this.searchControl = new FormControl();

        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                
            });
            //types: ["address"]
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {

                    let place = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.placeDetails.lat = place.geometry.location.lat();
                    this.placeDetails.lng = place.geometry.location.lng();
                    
                    this.placeDetails.address = place.formatted_address;
                    this.placeDetails.place_name = place.name;
                    this.placeDetails.place_image_url = place.url;
                });
            });


        });
        let mapStyle = this.mapStyles;
        let this1 = this;
        setTimeout(function () {
            this.directionsService = new google.maps.DirectionsService;
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 8,
                center: { lat: this1.lat, lng: this1.lng },
                scrollwheel: true,
                styles: mapStyle,
                sensor: false
            });
            this.directionsDisplay.setMap(this.map);
        }, 1000);
        this.translate.get('selectors').subscribe((res) => {
            this.vehiclenameSettings = {
                singleSelection: true,
                text: res.SelectVehicle,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            };
            this.workorderSettings = {
                singleSelection: false,
                text:res.SelectWorkOrder,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "req_input"
            };
            this.checklistSettings = {
                singleSelection: true,
                text:res.SelectChecklist,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            }

            this.pickupplaceSettings = {
                singleSelection: false,
                text: res.SelectPickupPlace,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            };
            this.dropplaceSettings = {
                singleSelection: true,
                text: res.SelectDropPlace,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            };
            this.otherplaceSettings = {
                singleSelection: true,
                text: res.SelectOtherPlace,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
                this.vehiclenameSettings = {
                    singleSelection: true,
                    text: res.SelectVehicle,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection req_input"
                };
                this.workorderSettings = {
                    singleSelection: false,
                    text:res.SelectWorkOrder,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "req_input"
                };
                this.checklistSettings = {
                    singleSelection: true,
                    text:res.SelectChecklist,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection req_input"
                }
                this.pickupplaceSettings = {
                    singleSelection: true,
                    text: res.SelectPickupPlace,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection req_input"
                };
                this.dropplaceSettings = {
                    singleSelection: true,
                    text: res.SelectDropPlace,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection req_input"
                };
                this.otherplaceSettings = {
                    singleSelection: true,
                    text: res.SelectOtherPlace,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection"
                };
            });
        }); 
        this.setMenu();
    }


    addTrip() {
        if(this.minDateOnTrip <= -1){
            return false;
        }
        if(this.placeSelectedItem.length < 2){
            return false;
        }
        this.tripDetails.shape_type = this.shapeType;
        if (this.tripDetails.shape_type == 0) {
            this.tripDetails.activate_geofence = false;
        }
        this.tripDetails.geofence = this.boundries;

        this.loading = true;
        if (this.tripDetails.trip_fare == '') {
            this.tripDetails.trip_fare = 0;
        }
        if (this.tripDetails.trip_tax == '') {
            this.tripDetails.trip_tax = 0;
        }
        if (this.tripDetails.trip_discount == '') {
            this.tripDetails.trip_discount = 0;
        }
        if (this.tripDetails.trip_total == '') {
            this.tripDetails.trip_total = 0;
        }
        this.saveButton = true;
        
        this.tripDetails.order_id = [];
        for(let i = 0;i < this.workorderSelectedItems.length;i++){
            this.tripDetails.order_id.push(this.workorderSelectedItems[i].id);
        }
        if(this.checklistSelectedItems.length > 0){
            this.tripDetails.checklist_id = this.checklistSelectedItems[0].id;
        }else{
            this.tripDetails.trip_total = '';
        }
        this.tripDetails.added_by = this.userDetails.id;
        this.tripDetails.company_id = this.userDetails.company_id;
        this.tripService.addTrip(this.tripDetails).subscribe(
            res => {
                this.loading = false;
                this.saveButton = false;
                if (res.success == true) {
                    this.router.navigate(['./trips']);
                } else {
                    this._commonService.showErrorMessage(res);
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addTrip');
            }
        )
    }

    getVehicle() {

        let requestParameter = {}
        this._commonService.getAssignVehicle().subscribe(
            res => {
                if (res.success == true) {
                    this.vehicleList = res.data;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getVehicle');
            }
        )
    }

    getChecklist(){
        let requestParameter = {}
        this.checklistService.getChecklistItem(requestParameter).subscribe(
            res => {
                if (res.success == true) {
                    this.checklistItemList = res.data;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getVehicle');
            }
        )
    }

    numberValidation(event: any) {

        const pattern = /[0-9\+\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    getDirection() {
        // this.origin = { lat: 22.7529391, lng: 75.8893152 }
        // this.destination = { lat: 22.6902247, lng: 75.8214172 }
    }

    getPlaceDetails() {
        this.placeService.getPlace(0, 0).subscribe(
            res => {
                if (res.success == true) {
                    this.placeList = res.data.rows;
                    this.config = {
                        displayKey:"itemName", //if objects array passed which key to be displayed defaults to description
                        search:true, //true/false for the search functionlity defaults to false,
                        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
                        placeholder:'Select Places', // text to be displayed when no item is selected defaults to Select,
                        customComparator: ()=>{} ,// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
                        limitTo: this.placeList.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
                        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
                        noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
                        searchPlaceholder:'Search', // label thats displayed in search input,
                        searchOnKey: 'itemName' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
                    }
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getPlace');
            }
        )
    }

    // changePickupPoint(pickupId) {
    //     if (this.mapInitializeCount == 0) {
    //         this.directionsService = new google.maps.DirectionsService;
    //         this.directionsDisplay = new google.maps.DirectionsRenderer({
    //             suppressMarkers: true
    //         });

    //         this.map = new google.maps.Map(document.getElementById('map'), {
    //             zoom: 8,
    //             center: { lat: 22.7664707, lng: 75.8824308 },
    //             gestureHandling: 'cooperative',
    //             styles: this.mapStyles,
    //             scrollwheel: true,
    //             sensor: false
    //         });
    //         this.directionsDisplay.setMap(this.map);
    //     }
    //     this.mapInitializeCount++;

    //     this.origin = {}
    //     // let pickupId = event.target.value;
    //     for (let i = 0; i < this.placeList.length; i++) {
    //         if (this.placeList[i].id == pickupId) {
    //             this.origin.id = this.placeList[i].id;
    //             this.origin.lat = this.placeList[i].lat;
    //             this.origin.lng = this.placeList[i].lng;
    //             this.origin.name = this.placeList[i].place_name;
    //             this.origin.url = this.placeList[i].place_image_url;
    //             this.calculateTripDistanceTime();
    //             return false;
    //         }
    //     }

    //     if (pickupId == '') {
    //         this.calculateTripDistanceTime();
    //     }
    // }

    // changeDropPoint(dropId) {
    //     this.destination = {}
    //     for (let i = 0; i < this.placeList.length; i++) {
    //         if (this.placeList[i].id == dropId) {
    //             this.destination.id = this.placeList[i].id;
    //             this.destination.lat = this.placeList[i].lat;
    //             this.destination.lng = this.placeList[i].lng;
    //             this.destination.name = this.placeList[i].place_name;
    //             this.destination.url = this.placeList[i].place_image_url;
    //             this.calculateTripDistanceTime();
    //             return false;
    //         }
    //     }

    //     if (dropId == '') {
    //         this.calculateTripDistanceTime();
    //     }
    // }

    // changeWayPoint() {
    //     this.waypoints = [];
    //     this.waypts = [];
    //     this.markerOptions.waypoints = [];
    //     this.wayMarkerOptions = []
    //     this.wayCount = 2;
    //     for (let i = 0; i < this.tripWaypoints.length; i++) {
    //         for (let j = 0; j < this.placeList.length; j++) {
    //             if (this.tripWaypoints[i] != '' && (this.placeList[j].id == this.tripWaypoints[i][0].id)) {
    //                 this.wayPointData = {}
    //                 this.waypointLocation = {}
    //                 this.waypointLocation.id = this.placeList[j].id;
    //                 this.waypointLocation.lat = this.placeList[j].lat;
    //                 this.waypointLocation.lng = this.placeList[j].lng;
    //                 this.waypointLocation.name = this.placeList[j].place_name;
    //                 this.waypointLocation.url = this.placeList[j].place_image_url;

    //                 this.wayPointData.location = this.waypointLocation;
    //                 this.wayPointData.stopover = true;
    //                 this.waypoints.push(this.wayPointData);

    //                 let routeWayPoint = this.waypointLocation.lat + ',' + this.waypointLocation.lng;
    //                 this.waypts.push({
    //                     location: routeWayPoint,
    //                     stopover: true
    //                 });
    //                 this.markerWayPoint = {}
    //                 this.markerWayPoint = {
    //                     icon: this.iconUrl + '' + this.wayCount + '.png',
    //                     draggable: true,
    //                     lat: this.waypointLocation.lat,
    //                     lng: this.waypointLocation.lng
    //                 }
    //                 this.wayMarkerOptions.push(this.markerWayPoint);
    //                 this.wayCount++;
    //             }
    //         }
    //     }

    //     this.wayPointInputLength = this.waypoints.length;
    //     let originPoint = this.wayMarkerOptions.length + 1;
    //     this.markerOptions.destination.icon = this.iconUrl + '' + originPoint + '.png';

    //     this.markerOptions = {
    //         origin: {
    //             icon: this.iconUrl + '1.png',
    //             draggable: true,
    //         },
    //         destination: {
    //             icon: this.iconUrl + (this.wayMarkerOptions.length + 1) + '.png',
    //             draggable: true
    //         }
    //     }
    //     this.calculateTripDistanceTime();
    // }
    // addWayPoint() {
    //     let wayPointLength = this.wayPointInput.length;
    //     this.tripWaypoints[wayPointLength] = '';
    //     this.wayPointInput.push(1);
    // }

    getOrderDetails() {
        let vehicleId = this.tripDetails.vehicle_id;
        this.tripDetails.order_id = '';
        if (vehicleId) {
            this.tripService.getOrderByVehicle(vehicleId).subscribe(
                res => {
                    this.message = res.message;
                    if (res.success == true) {
                        this.orderList = res.data;
                    } else {
                        this.orderList = [];
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'getOrder');
                }
            )
        } else {
            this.orderList = []
        }
    }

    // removeWayPoint(index) {
    //     if (this.tripWaypoints.length > 1) {
    //         this.tripWaypoints.splice(index, 1);
    //         this.wayPointInput.splice(index, 1);
    //     } else {
    //         this.tripWaypoints.splice(index, 1);
    //     }
    //     this.changeWayPoint();
    // }

    calculateTripDistanceTime() {
        this.shapeType = 0;
        this.boundries = [];
        this.geofenceDraw = false;
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });

        let _this = this;
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: _this.lat, lng: _this.lng },
            gestureHandling: 'cooperative',
            styles: this.mapStyles,
            scrollwheel: true,
            sensor: false
        });
        this.directionsDisplay.setMap(this.map);
        // this.directionsDisplay = new google.maps.DirectionsRenderer;
        this.tripDetails.trip_distance = 0;
        this.tripDetails.trip_duration = 0;
        let imgUrl = Config.AuthenticationURL + 'public/image/route-icon/';
        console.log(this.placeSelectedItem,'root');
        this.placeSelectedIds = [];
        if(this.placeSelectedItem.length >= 2){
            let placeSelectedLen = this.placeSelectedItem.length;
            let origin = new google.maps.LatLng(this.placeSelectedItem[0].lat, this.placeSelectedItem[0].lng);
            this.setPlaceGeofence(this.placeSelectedItem[0].id);
            this.tripDetails.pickup_point = this.placeSelectedItem[0].id;
            this.placeSelectedIds.push(this.placeSelectedItem[0].id);
            this.setPlaceGeofence(this.placeSelectedItem[placeSelectedLen - 1].id);
            this.tripDetails.drop_point = this.placeSelectedItem[placeSelectedLen - 1].id;
            this.createMarker(origin, this.placeSelectedItem[0].url, this.placeSelectedItem[0].place_name, imgUrl + '1.png');
            let destination = new google.maps.LatLng(this.placeSelectedItem[placeSelectedLen - 1].lat, this.placeSelectedItem[placeSelectedLen - 1].lng);
            this.createMarker(destination, this.placeSelectedItem[placeSelectedLen - 1].url, this.placeSelectedItem[placeSelectedLen - 1].place_name, imgUrl + placeSelectedLen + '.png');
            // let wayPointLen = this.waypts.length;

            let directionsService = new google.maps.DirectionsService();
            
            this.waypts = [];
            this.tripDetails.way_points = []
            if(placeSelectedLen > 2){
                let wayPointCount = 0;
                let wayPointLen = placeSelectedLen - 2;
                for (let i = 1; i <= wayPointLen; i++) {
                    let wayPointLat = new google.maps.LatLng(this.placeSelectedItem[i].lat, this.placeSelectedItem[i].lng);
                    this.createMarker(wayPointLat, this.placeSelectedItem[i].url, this.placeSelectedItem[i].place_name, imgUrl + (i + 1) + '.png');
                    wayPointCount++;
                    this.setPlaceGeofence(this.placeSelectedItem[i].id);
                    this.tripDetails.way_points.push(this.placeSelectedItem[i].id);
                    let routeWayPoint = this.placeSelectedItem[i].lat + ',' + this.placeSelectedItem[i].lng;
                    this.waypts.push({
                        location: routeWayPoint,
                        stopover: true
                    });
                    this.placeSelectedIds.push(this.placeSelectedItem[i].id);
                }
            }
            this.placeSelectedIds.push(this.placeSelectedItem[placeSelectedLen - 1].id);
            this.distanceTimeRequest = {
                origin: origin,
                destination: destination,
                waypoints: this.waypts,
                optimizeWaypoints: false,
                travelMode: 'DRIVING',
                provideRouteAlternatives: false
            };
            directionsService.route(this.distanceTimeRequest, function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    _this.directionsDisplay.setDirections(response);
                    _this.routeDistance = 0;
                    _this.routeETA = 0;
                    var route = response.routes[0];
                    for (var i = 0; i < route.legs.length; i++) {
                        _this.routeDistance += Number(route.legs[i]['distance']['value']);

                        _this.routeETA += Number(route.legs[i]['duration']['value']);


                        var routeSegment = i + 1;

                    }
                    _this.routeDistanceKm = (_this.routeDistance / 1000).toFixed(1) + ' km';
                    _this.routeETAMin = (_this.routeETA / 60).toFixed(0);
                    _this.tripDetails.trip_distance = (_this.routeDistance).toFixed(1);;
                    _this.tripDetails.trip_duration = _this.routeETAMin;
                    _this.timeFormating(_this.routeETAMin);
                }
                else {
                    _this.tripETATime = '0 m';
                    _this.routeDistanceKm = '0 km';
                    _this.routeDistance = 0;
                    _this.routeETA = 0;
                }
            });
            this.geofencingDrawing(this.map);
        }
        // if (typeof this.origin.lat != 'undefined' && typeof this.origin.lng != 'undefined' && typeof this.destination.lat != 'undefined' && typeof this.destination.lng != 'undefined') {
        //     let origin = new google.maps.LatLng(this.origin.lat, this.origin.lng);
        //     this.setPlaceGeofence(this.origin.id);
        //     this.setPlaceGeofence(this.destination.id);
        //     this.createMarker(origin, this.origin.url, this.origin.name, imgUrl + '1.png');
        //     let destination = new google.maps.LatLng(this.destination.lat, this.destination.lng);
        //     let wayPointLen = this.waypts.length;
        //     this.createMarker(destination, this.destination.url, this.destination.name, imgUrl + (wayPointLen + 2) + '.png');
        //     var directionsService = new google.maps.DirectionsService();
        //     this.distanceTimeRequest = {
        //         origin: origin,
        //         destination: destination,
        //         waypoints: this.waypts,
        //         optimizeWaypoints: false,
        //         travelMode: 'DRIVING',
        //         provideRouteAlternatives: false
        //     };
        //     let wayPointCount = 0;
        //     for (let i = 0; i < this.waypoints.length; i++) {
        //         let wayPointLat = new google.maps.LatLng(this.waypoints[i].location.lat, this.waypoints[i].location.lng);
        //         this.createMarker(wayPointLat, this.waypoints[i].location.url, this.waypoints[i].location.name, imgUrl + (wayPointCount + 2) + '.png');
        //         wayPointCount++;
        //         this.setPlaceGeofence(this.waypoints[i].location.id);
        //     }
        //     var _this = this;
        //     directionsService.route(this.distanceTimeRequest, function (response, status) {
        //         if (status == google.maps.DirectionsStatus.OK) {
        //             _this.directionsDisplay.setDirections(response);
        //             _this.routeDistance = 0;
        //             _this.routeETA = 0;
        //             var route = response.routes[0];
        //             for (var i = 0; i < route.legs.length; i++) {
        //                 _this.routeDistance += Number(route.legs[i]['distance']['value']);

        //                 _this.routeETA += Number(route.legs[i]['duration']['value']);


        //                 var routeSegment = i + 1;

        //             }
        //             _this.routeDistanceKm = (_this.routeDistance / 1000).toFixed(1) + ' km';
        //             _this.routeETAMin = (_this.routeETA / 60).toFixed(0);
        //             _this.tripDetails.trip_distance = (_this.routeDistance).toFixed(1);;
        //             _this.tripDetails.trip_duration = _this.routeETAMin;
        //             _this.timeFormating(_this.routeETAMin);
        //         }
        //         else {
        //             _this.tripETATime = '0 m';
        //             _this.routeDistanceKm = '0 km';
        //             _this.routeDistance = 0;
        //             _this.routeETA = 0;
        //         }
        //     });
        //     this.geofencingDrawing(this.map);
        // }
    }

    timeFormating(totalMinutes) {
        let totalTime = Number(totalMinutes);
        this.tripETATime = '';
        if (totalTime < 60) {
            this.tripETATime = totalTime + ' m';
        } else {
            if (totalTime > 1440) {
                let days = totalTime / 1440;
                days = parseInt(days.toString());
                if (days > 0) {
                    this.tripETATime = days + ' d ';
                }

                let hours = totalTime / 60;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.tripETATime = this.tripETATime + '' + hours + ' h ';
                }

                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.tripETATime = this.tripETATime + '' + minute + ' m';
                }

            } else {

                let hours = totalTime / 60;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.tripETATime = hours + ' h ';
                }
                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.tripETATime = this.tripETATime + '' + minute + ' m';
                }
            }
        }
    }

    calculateTotalAmount() {
        let tripFare = this.tripDetails.trip_fare;
        this.tripDetails.trip_total = '';
        if (typeof this.tripDetails.trip_fare == 'undefined') {
            tripFare = 0;
        }

        let tripTax = this.tripDetails.trip_tax;
        if (typeof this.tripDetails.trip_tax == 'undefined') {
            tripTax = 0;
        }

        let tripDiscount = this.tripDetails.trip_discount;
        if (typeof this.tripDetails.trip_discount == 'undefined') {
            tripDiscount = 0;
        }
        this.tripDetails.trip_total = Number(tripFare) + Number(tripTax) - Number(tripDiscount);
    }

    openAddPlaceModal(template) {
        let places = document.getElementById(template);
        if (places.className === "hidden") {
            places.className = "hidden active";
        }
    }
    closeAddPlaceModal(template3) {
        let places3 = document.getElementById(template3);
        if (places3.className === "hidden active") {
            places3.className = "hidden";
        }

    }
    addPlace(template1) {
        this.placeLocationRequired = '';
        this.placeNameRequired = '';
        this.placeDetails.radius = "10";
        this.placeDetails.company_id = this.userDetails.company_id;
        this.placeDetails.createdBy = this.userDetails.id;
        if (this.placeDetails.address == '') {
            this.placeLocationRequired = 'Location is required';
            return false;
        }
        if (this.placeDetails.place_name == '') {
            this.placeNameRequired = 'Place name is required';
            return false;
        }
        this.placeService.addPlace(this.placeDetails).subscribe(
            res => {

                if (res.success == true) {

                    let places1 = document.getElementById(template1);
                    if (places1.className === "hidden active") {
                        places1.className = "hidden";
                    }

                    this._commonService.showSuccessMessage(res.message);
                    this.getPlaceDetails();
                    this.placeDetails.place_name = '';
                    this.placeDetails.address = '';
                } else {
                    this._commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'addPlace');
            }
        )

    }

    createMarker(latlng, placeUrl, name, icon) {
        var content = '<div>' + name + '</div>';//'<div style="background: url('+imgUrl+') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">'+name+'</b><p>'+description+'</p>';
        var title = name;
        var _this = this;
        var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 150
        });
        var marker = new google.maps.Marker({
            position: latlng,
            icon: icon,
            map: _this.map,
            shape: { coords: [17, 17, 18], type: 'circle' }
        });
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(_this.map, marker);
        });

        this.markers.push(marker);
    }

    geofencingDrawing(map1) {
        this.map = map1;
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.POLYGON]
            }
        });
        this.drawingManager.setMap(map1);
        this.boundries = [];
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.circleRadius = event.overlay.getRadius(); // in meters
                this.circleLat = event.overlay.getCenter().lat();
                this.circleLng = event.overlay.getCenter().lng();
                this.shapeType = Constants.GeofenceType.Circle;
                this.geofenceDraw = true;
            }
            else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
                var bounds = event.overlay.getBounds();
                this.rectangleBounds = {
                    north: bounds.getNorthEast().lat(),
                    south: bounds.getSouthWest().lat(),
                    east: bounds.getNorthEast().lng(),
                    west: bounds.getSouthWest().lng()
                };
                this.shapeType = Constants.GeofenceType.Rectangle;
                this.geofenceDraw = true;
            }
            else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                var polygonPath = event.overlay.getPath().getArray();
                this.polygonPaths = [];
                polygonPath.forEach(item => {
                    var path = {
                        lat: item.lat(),
                        lng: item.lng()
                    };
                    this.polygonPaths.push(path);
                });
                this.shapeType = Constants.GeofenceType.Polygone;
                this.geofenceDraw = true;
            }
            this.boundries = [];
            if (this.shapeType === Constants.GeofenceType.Polygone) {
                this.boundries = this.polygonPaths;
            }
            else if (this.shapeType === Constants.GeofenceType.Rectangle) {
                this.boundries = this.rectangleBounds;
            }
            else if (this.shapeType === Constants.GeofenceType.Circle) {
                this.boundries = {
                    radius: this.circleRadius,
                    lat: this.circleLat,
                    lng: this.circleLng
                };
            }
            this.drawingManager.setDrawingMode(null);
            this.drawingManager.setOptions({
                drawingControl: false
            });

        });
    }

    resetGeoference() {
        this.boundries = [];
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        let map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: 22.7664707, lng: 75.8824308 }
        });
        this.geofencingDrawing(map);
        this.directionsDisplay.setMap(map);
        this.calculateTripDistanceTime();
        this.drawGeofencing = [];
        this.drawGeofencingType = 0;
        this.geofenceDraw = false;
    }
    

    onItemSelectvehiclename(item: any) {
        this.workorderSelectedItems = [];
        this.tripDetails.vehicle_id = item.id;
        this.getOrderDetails();
    }
    onOpenvehiclename(event: any) {
        this.vehicleList = this.vehicleList.map((item) => {
            return {
                id: item.id,
                itemName: item.itemName
            }
        });
    }

    onOpenworkorder(event: any) {
        this.orderList = this.orderList.map((item) => {
            return {
                id: item.id,
                itemName: item.itemName
            }
        });
    }

    placeLocationAddress(event) {
        let geocoder = new google.maps.Geocoder();
        var _this = this;
        _this.placeSelectedBtn = true;
        setTimeout(function () {
            let address = $('#locationAddress').val().toString();
            _this.checkPlaceAddress = '';
            if (address.length > 4) {

                geocoder.geocode({ 'address': address }, function (results, status) {
                    _this.checkPlaceAddress = status;
                    if (_this.checkPlaceAddress != 'OK') {
                        _this.placeDetails.address = '';
                        _this._commonService.showCustomError('Please select valid address');
                    } else {
                        _this.placeSelectedBtn = false;
                        $('input[name="place_name"]').click();
                    }
                });
            }

            if (address.length < 5) {
                _this.placeDetails.address = '';
                _this._commonService.showCustomError('Please select valid address');
            }
        }, 500)
    }

    dateDifferenceInMinutes(startDate, endDate){
        this.startDateTime = new Date(startDate);
        this.endDateTime = new Date(endDate);
        let diff = this.endDateTime - this.startDateTime;
        return (diff / 1000)/60;
    }

    changeStartDate(){
        let timeDiff = this.dateDifferenceInMinutes(this.dateTime, this.tripDetails.required_at);
        this.minDateOnTrip = timeDiff;
        if(timeDiff < 0){
           this.tripDetails.required_at = this.dateTime;
        }
        console.log(this.minDateOnTrip)
    }

    setPlaceGeofence(placeId){
        let place = this.placeList.find(s => s.id == placeId);
        let myArray = ["#262525"];
        let randomItem = myArray[Math.floor(Math.random()*myArray.length)];
        if (typeof place != 'undefined') { 
            let marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(place.lat, place.lng),
                title: place.place_name
            });
            let circle = new google.maps.Circle({
                map: this.map,
                radius: place.geo_fence_radius,    // 10 miles in metres
                fillColor: randomItem,
                strokeColor: randomItem
            });
            circle.bindTo('center', marker, 'position');
        }
    }

    selectionChanged(item: any){

        this.placeSelectedItem = item.value;
        this.availableSelectedIds = [];
        for(let i = 0; i< this.placeSelectedItem.length; i++){
            this.availableSelectedIds.push(this.placeSelectedItem[i].id);
        }
      //  this.locationDragable();
        this.calculateTripDistanceTime();
    }

    locationDragable() {
        var _this = this;
        $("#sortable").sortable({
            placeholder: "ui-state-highlight",
            handle: ".dragdrop-handle",
            opacity: 0.1,
            cursor: "move",
            stop: function (event, ui) {
                let placeSelectedTemp = [];
                $('.route_input').each(function () {
                    let placeId = $(this).attr('id');
                    let place = _this.placeList.find(s => s.id == placeId);
                    placeSelectedTemp.push(place);
                });
                _this.placeSelectedItem = placeSelectedTemp;
                _this.calculateTripDistanceTime();
            },
            update: function (event, ui) {
                $('.sort-order-value').each(function (index, value) {
                    if (0 == index) {
                        $(value).parent().find('.delete-handle').hide();
                    } else {
                        $(value).parent().find('.delete-handle').show();
                    }
                    $(value).val(index + 1);
                });
            }
        });
        $("#sortable").disableSelection();
    }

    removeSelectedPlace(placeId){
        console.log(this.availableSelectedIds)
        if(this.placeSelectedItem.length <= 2){
            this._commonService.showCustomError('Minimum 2 point is required for route');
            return false;
        }
        let index = this.availableSelectedIds.indexOf(placeId);
        console.log(index)
        setTimeout(function(){
            $('.ngx-dropdown-button').click();
        },50);
        setTimeout(function(){
            $('.selected-items li').eq(index).click();
            $('body').click()
        },100);
    }

    setMenu() {
        this.allowModule.forEach((obj) => {
            if(obj == 16) {
                this.checklistModule = true;                    
            }
            if(obj == 13) {
                this.workOrderModule = true;                    
            }
        });
    }
}    
