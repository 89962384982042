import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-vehicle-ignition-report',
  templateUrl: './vehicle-ignition-report.component.html',
  styleUrls: ['./vehicle-ignition-report.component.css']
})
export class VehicleIgnitionReportComponent implements OnInit {
	isDemoTenant:boolean;
  	loading:boolean;
	selected: any;
	reportDetails: any = [];
  	// VehicleSelectedItems = [];
  	// listSettings = {};
	start_date;
	end_date;
	alwaysShowCalendars: boolean;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  	totalItem: any;
  	perPage: any = 10;
  	pageNo: any = 1;
  	// reportDetails: any = [];
  	searchDetails: any = {};
  	page: number = 2;
  	searchTempVal: any = '';
  	message:string = 'Currently, there is no data available.';
	// ranges: any = {
	//   	'Today': [moment(), moment()],
	//   	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	//   	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	//   	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	//   	'This Month': [moment().startOf('month'), moment().endOf('month')],
	//   	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	// }
	// dateTime = new Date();
	dateSelected:any;
  	constructor(
		private commonService:CommonService, 
		private customErroHandler:CustomErrorHandler,
		public reportService: ReportService,
    	private translate: TranslateService
  	) { }

  	ngOnInit() {
  		this.loading = false;
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
		this.getignitionReport(1);
	    // this.listSettings = {
	    //   	singleSelection: true, 
	    //   	text:"Select Vehicle",
	    //   	enableSearchFilter: true,
	    //   	classes:"myclass custom-class"
	    // }
	    // this.getVehicle();
  	}

	// getVehicle() {
	//     this.commonService.getAssignVehicle().subscribe(res => {
	//       	if (res.success == true) {
	//         	this.vehicleList = res.data;
	//       	} else {
	//        	 	this.commonService.showErrorMessage(res);
	//       	}
	//    	}, error => {
	//     	this.customErroHandler.handleHttpError(error, 'addInspection');
	//     });
 //  	}

  	getignitionReport(currentPage, Export:any = ''){
  		this.loading =true;
		let fileName = "Vehicle Ignition Report";
		// if(this.start_date != undefined && this.end_date != undefined){
		// 	fileName = this.VehicleSelectedItems[0].itemName + " - Detailed Report From "+this.start_date+" To "+this.end_date;
		// }
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: fileName
		};
		
		this.page = currentPage;
		this.pageNo = currentPage;
		let tempDate = this.dateSelected;
		tempDate = tempDate == undefined ? '' :moment(tempDate).format('YYYY-MM-DD');
		let queryParams = {
			row_per_page: this.perPage,
			page_no:this.page,
			date: tempDate,
      		export:Export,
      		search_by: this.searchDetails.search_by,
  		 	start_date: this.start_date == undefined ? '' : this.start_date,
			end_date: this.end_date == undefined ? '' : this.end_date,
		}
		this.reportService.getIgnitionReport(queryParams).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
		          	if(Export == 'yes'){
		            	new Angular2Csv(res.data, fileName, options);
		          	}
	          		else{
			            this.totalItem = res.data.count;
			            this.reportDetails = res.data.rows;
	          		}
				} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	        	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
  	}

  	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getignitionReport(this.pageNo);
		}
	}

 	changedDate(e:any){
		this.start_date = e.startDate.format('YYYY-MM-DD');
    	this.end_date = e.endDate.format('YYYY-MM-DD')
    	this.getignitionReport(1);  
  	}

  // 	changedDate(e:any){
		// this.start_date = e.startDate.format('YYYY-MM-DD');
  //   	this.end_date = e.endDate.format('YYYY-MM-DD');
  //   	this.getdetailedReport(1,'no');
  //   }

  // 	onInspectionSelect(e:any){
  //   	this.getdetailedReport(1,'no');
  // 	}

  	// onDateSelect(){
	  // 	if(this.VehicleSelectedItems.length){
		 //  	this.getdetailedReport(1,'no');
	  // 	}
  	// }

  	// getYearSlab() {
	  // 	var d = new Date();
	  // 	var s = d.getFullYear();
	  // 	var e:any = s - 80;
	  // 	e = e + ':' + s;
	  // 	return e;
  	// }
}
