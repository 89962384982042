import { Component, OnInit ,ElementRef, NgZone,ViewChild} from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { LocationMappingService } from '../../../services/location-mapp/location-mapp.service';
import { FormControl } from '@angular/forms';
 //import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import { GeofenceType } from 'src/app/common/enums';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// declare var google: any;

@Component({
  	selector: 'app-add-location-mapping',
  	templateUrl: './add-location-mapping.component.html',
  	styleUrls: ['./add-location-mapping.component.css']
})
export class AddLocationMappingComponent implements OnInit {
	geofenceRadiusSelectedItems = [];
	geofenceRadiusSettings = {};
	geofenceCategorySettings = {};
	loading:boolean;
	placeDetails: any = {};
    currentpage : any;
 	public lat: number;
  	public lng: number;
  	public searchControl: FormControl;
  	public zoom: number;
  	public origin: {}
	public destination: {}
	public circle:any= [];
	public locationValidation: any; 
	public userInfo: any;
	public editPlaceId : any= {};
	public geofenceList: any = [];
	drawingManager: any;
	public renderOptions = {
	    draggable: true,
	}
	mapStyles: any;
	map: any;

	@ViewChild("search")
  	public searchElementRef: ElementRef;
  	public checkPlaceAddress: any;
  	public currentLat: any;
  	public currentLong: any;
  	geofenceRadius : any;
  	drawGeofencingType: any;
  	geofenceDraw: boolean = false;

  	polygonPaths: any[] = [];
    rectangleBounds: any;
    circleRadius: any;
    circleLat: string;
    circleLng: string;
    shapeType: number;
    allOverlays: any = [];
    geofenceCategorySelectedItems: any = [];

  	constructor(
  		private router: Router,
    	private mapsAPILoader: MapsAPILoader,
    	private ngZone: NgZone,
    	public LocationMappingService: LocationMappingService,
		private customErrorHandler:CustomErrorHandler,
		private commonService:CommonService,
		private translate: TranslateService,
		private broadcaster: Broadcaster
	) {
  		this.userInfo = localStorage.getItem('userInfo');
  		if(this.userInfo){
			  this.userInfo = JSON.parse(this.userInfo);
			  this.currentpage = "Add";
  		}
  	}

  	ngOnInit() {
  		this.editPlaceId = localStorage.getItem('editPlaceId');
        if(this.editPlaceId){
		  this.currentpage = "Update";
        }
  		this.getGeofenceRadius();
  		if (navigator.geolocation) {
  			navigator.geolocation.getCurrentPosition((position) => {
  				this.showTrackingPosition(position);
	      	});
	    }
		this.loadAutoComple();
  		this.mapStyles = Constants.mapStyles;
		setTimeout(()=>{

			if(this.lat == undefined || this.lat == null){
				this.lat = 22.7196;
				this.lng = 75.8577;
			}
			this.map = new google.maps.Map(document.getElementById('map'), {
				zoom: 16,
				center: { lat: this.lat, lng: this.lng },
				gestureHandling: 'cooperative',
				styles: this.mapStyles,
				streetViewControl: false
			});
			this.geofenceRadius = Constants.GeofenceRadius;
			this.drawGeofencingType = 0;
			
  		},500)
  		
  		// gestureHandling: 'greedy'
		// this.commonService.stickyHeader();
        this.translate.get('selectors').subscribe((res) => {
			this.geofenceRadiusSettings = {
				singleSelection: true,
				text: res.SelectGeofenceRadius,
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				enableSearchFilter: true,
				classes: "singleSelection"
			};
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
				this.geofenceRadiusSettings = {
					singleSelection: true,
					text: res.SelectGeofenceRadius,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true,
					classes: "singleSelection"
				};
            });
        }); 


        this.translate.get('selectors').subscribe((res) => {
			this.geofenceCategorySettings = {
				singleSelection: true,
				text: res.SelectGeofenceRadius,
				selectAllText: 'Select All',
				unSelectAllText: 'UnSelect All',
				enableSearchFilter: true,
				classes: "singleSelection"
			};
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
				this.geofenceCategorySettings = {
					singleSelection: true,
					text: res.SelectGeofenceRadius,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true,
					classes: "singleSelection"
				};
            });
        }); 
	    
	}

	loadAutoComple(){
		this.geofenceRadius = Constants.GeofenceRadius;
        this.zoom = 16;
	    
	    this.lat = Number(this.userInfo.lat);
        this.lng = Number(this.userInfo.lng);

	    this.placeDetails.radius = '';
	    this.placeDetails.address = '';
	    this.placeDetails.lat = '';
		this.placeDetails.lng = '';
	    //create search FormControl
	    this.searchControl = new FormControl();

	    //load Places Autocomplete
	    this.mapsAPILoader.load().then(() => {
	    	let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
	        	
	      	});
	      	autocomplete.addListener("place_changed", () => {
	      		this.ngZone.run(() => {
	          		this.checkAddress();
		          	let place: google.maps.places.PlaceResult = autocomplete.getPlace();
		          	//console.log(place);
		          	//verify result
		          	if (place.geometry === undefined || place.geometry === null) {
		            	return;
		          	}

		          	//set latitude, longitude and zoom
		          	this.lat = place.geometry.location.lat();
		          	this.lng = place.geometry.location.lng();
		          	this.zoom = 16;
		          	this.placeDetails.lat = this.lat;
 					this.placeDetails.lng = this.lng;
		          	this.map = new google.maps.Map(document.getElementById('map'), {
			            zoom: 16,
			            center: {lat:  this.lat, lng: this.lng},
			            gestureHandling: 'cooperative',
			            styles: this.mapStyles,
			            streetViewControl: false
			        });

		          	let radius = Number(this.placeDetails.radius);
				    this.circle = [
				      	{lat: this.lat, lng: this.lng, radius: radius, color: 'red'}
				    ];
				    this.placeDetails.address = place.formatted_address;
		        	this.placeDetails.place_image_url = place.url;
		        	this.placeDetails.place_name = place.name;
		        	this.placeDetails.latitude = this.lat;
		        	this.placeDetails.longitude = this.lng;
		        	this.locationValidation = '';
		        	
		        	if (typeof this.lat != 'undefined' && typeof this.lng != 'undefined') {
						var _this = this;
						if (typeof this.geofenceRadiusSelectedItems[0].itemName != 'undefined') {

							var circle = new google.maps.Circle({
								map: _this.map,
								radius: _this.geofenceRadiusSelectedItems[0].itemName,    // 10 miles in metres
								fillColor: '#262525',
								center: { lat: _this.lat, lng: _this.lng },
							});
						}
					}
		        	// this.drawingGeofenceIcon();
		        });
	      	});
	    });
	}

	// drawingGeofenceIcon(){
	// 	this.drawingManager = new google.maps.drawing.DrawingManager({
 //            drawingControl: true,
 //            drawingControlOptions: {
 //                position: google.maps.ControlPosition.TOP_CENTER,
 //                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.POLYGON]
 //            }
 //        });
 //        this.drawingManager.setMap(this.map);
 //        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
 //            this.allOverlays.push(event);
 //            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
 //                this.circleRadius = event.overlay.getRadius(); // in meters
 //                this.circleLat = event.overlay.getCenter().lat();
 //                this.circleLng = event.overlay.getCenter().lng();
 //                this.shapeType = GeofenceType.Circle;
 //                this.geofenceDraw = true;
 //            }
 //            else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
 //                var bounds = event.overlay.getBounds();
 //                this.rectangleBounds = {
 //                    north: bounds.getNorthEast().lat(),
 //                    south: bounds.getSouthWest().lat(),
 //                    east: bounds.getNorthEast().lng(),
 //                    west: bounds.getSouthWest().lng()
 //                };
 //                this.shapeType = GeofenceType.Rectangle;
 //                this.geofenceDraw = true;
 //            }
 //            else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
 //                var polygonPath = event.overlay.getPath().getArray();
 //                this.polygonPaths = [];
 //                polygonPath.forEach(item => {
 //                    var path = {
 //                        lat: item.lat(),
 //                        lng: item.lng()
 //                    };
 //                    this.polygonPaths.push(path);
 //                });
 //                this.shapeType = GeofenceType.Polygone;
 //                this.geofenceDraw = true;
 //            }

 //            this.drawingManager.setDrawingMode(null);
 //            this.drawingManager.setOptions({
 //                drawingControl: false
 //            });
 //        });
	// }

	// resetGeoference() {
        
 //        this.drawGeofencingType = 0;
 //        this.geofenceDraw = false;
 //        for (var i = 0; i < this.allOverlays.length; i++) {
	// 	    this.allOverlays[i].overlay.setMap(null);
	// 	}
	// 	this.allOverlays = [];
 //        this.drawingManager.setOptions({
 //            drawingControl: true
 //        });
   
 //    }

   	removeSpecialChar(event : any)
    {   
       var k;  
       k = event.charCode;  
       return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 0 || k == 32 || (k >= 48 && k <= 57)); 
    }

 	addLocationMapping(){
		this.checkAddress()
		.then((res)=>{
			if(res){
			this.loading =true;
			this.locationValidation = '';
			if(typeof this.placeDetails.lat == 'undefined' || typeof this.placeDetails.lng == 'undefined' || this.placeDetails.lat == '' || this.placeDetails.lng == ''){
				this.locationValidation = 'Select valid location';
				this.loading = false;
				return false;
			}
			if(this.placeDetails.radius == ''){
				this.loading = false;
				return false;
			}
			if(this.placeDetails.address == ''){
				this.loading = false;
				this.locationValidation = 'Location is required';
				return false;
			}
			this.placeDetails.company_id = this.userInfo.company_id;
			this.placeDetails.createdBy = this.userInfo.id;
			if(this.editPlaceId){
				this.LocationMappingService.updatePlace(this.placeDetails).subscribe(
					res => {
						this.loading =false; 
						if (res.success == true) {
							this.commonService.showSuccessMessage(res.message);
							this.router.navigate(['./location-mapping/location-mapping-list']);
						} else {
							this.commonService.showErrorMessage(res);
						}
					},
					error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'addPlace');
					}
				)
			}else{

				this.LocationMappingService.addPlace(this.placeDetails).subscribe(
					res => {
						this.loading =false; 
						if (res.success == true) {
							this.commonService.showSuccessMessage(res.message);
							this.router.navigate(['./location-mapping/location-mapping-list']);
						} else {
							this.commonService.showErrorMessage(res);
						}
					},
					error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'addPlace');
					}
				)
			}
		}
	 })
	 .catch((err)=>{
		this.loading = false;
	 })
	}

	getPlaceDetails(placeId){
        this.LocationMappingService.getSinglePlace(placeId).subscribe(res => {
            if (res.success == true) {
                this.placeDetails = res.data;
                this.zoom = 16;
                this.lat = Number(this.placeDetails.lat);
				this.lng = Number(this.placeDetails.lng);
                this.placeDetails.radius = Number(this.placeDetails.geo_fence_radius);
				this.setGeofence();
				this.updatePlaceDetailOnMap();
            } else {
                this.commonService.showErrorMessage(res);
            }
        },
        error => {
                this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
            }
        )
        return event;
    }
    
    updatePlaceDetailOnMap(){
    	this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 16,
            center: {lat:  this.lat, lng: this.lng},
            gestureHandling: 'cooperative',
            styles: this.mapStyles,
            streetViewControl: false
        });
		if (typeof this.geofenceRadiusSelectedItems[0].itemName != 'undefined') {

			var circle = new google.maps.Circle({
				map: this.map,
				radius: this.geofenceRadiusSelectedItems[0].itemName,    // 10 miles in metres
				fillColor: '#262525',
				center: { lat: this.lat, lng: this.lng },
			});
		}
    }


    getGeofenceRadius(){
        this.LocationMappingService.getGeofenceRadius().subscribe(res => {
            if (res.success == true) {
            	//this.geofenceList = res.data;
				this.geofenceList = [
                              {"id":1,"itemName":"School"},
                              {"id":2,"itemName":"Collage"},
                              {"id":3,"itemName":"Hospital"},
                              {"id":4,"itemName":"Restaurants"},
                              {"id":5,"itemName":"Mandir"},
                              {"id":6,"itemName":"Stadium"}
                            ];  
				if(this.geofenceList.length > 0){
					this.geofenceRadiusSelectedItems = [];
					this.geofenceRadiusSelectedItems.push(this.geofenceList[0]);
					this.placeDetails.radius = this.geofenceList[0]['itemName'];
				}
				if(this.editPlaceId){
					this.getPlaceDetails(this.editPlaceId);
				}               
            } else {
               	this.commonService.showErrorMessage(res);
            }
        },
        error => {
            this.customErrorHandler.handleHttpError(error, 'getGeofenceRadius');
        })
        return event;
    }

	checkAddress():Promise<Boolean>{
		return new Promise((resolve,reject)=>{
			let geocoder = new google.maps.Geocoder();
			let address = this.placeDetails.address;
			var _this = this;
			_this.checkPlaceAddress = '';
			geocoder.geocode( { 'address': address}, function(results, status) {
				_this.checkPlaceAddress = status;
				if(_this.checkPlaceAddress != 'OK') {
					reject(false);
				}
				else{
					resolve(true);
				}
			});
 		
		})
	}
	
	setGeofence(){
		let Geofence = this.geofenceList.find(s => s.radius == this.placeDetails.radius);
		if(Geofence != undefined){
			this.geofenceRadiusSelectedItems = [];
			this.geofenceRadiusSelectedItems.push(Geofence);
		}
	}
	onItemGeofenceradius(item: any) {
		this.placeDetails.radius = item.itemName;
		// this.changeRadius();
	}
	
	onOpenGeofenceradius(event: any) {
	    this.geofenceList = this.geofenceList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
	}

	showTrackingPosition(position) {
	    this.currentLat = position.coords.latitude;
	    this.currentLong = position.coords.longitude;
	    if(this.lat == null && this.currentLat != null){
        	this.lat = this.currentLat;
        	this.lng = this.currentLong;
        }
	}

	placeLocationAddress(event) {
        let geocoder = new google.maps.Geocoder();
        var _this = this;
        // _this.placeSelectedBtn = true;
        setTimeout(function () {
            let address = $('#locationAddress').val().toString();
	        _this.checkPlaceAddress = '';
            if (address.length > 4) {
                geocoder.geocode({ 'address': address }, function (results, status) {
                    _this.checkPlaceAddress = status;
                    if (_this.checkPlaceAddress != 'OK') {
                        _this.placeDetails.address = '';
                        _this.commonService.showCustomError('Please select valid address');
                    } else {           
                        // _this.placeSelectedBtn = false;
                        $('input[name="address"]').click();
                    }
                });
            }
            if (address.length < 5) {
                _this.placeDetails.address = '';
                _this.commonService.showCustomError('Please select valid address');
            }
        }, 500)
    }

    placeLocationLatlng(event) {
        var geocoder;
      	geocoder = new google.maps.Geocoder();

        var _this = this;
        // _this.placeSelectedBtn = true;
        setTimeout(function () {
			let latitude =  $('#locationLatitude').val();
            let longitude = $('#locationLongitude').val();
            let latitude1 = parseFloat(latitude.toString());
            let longitude1 = parseFloat(longitude.toString());

			var latlng = new google.maps.LatLng(latitude1, longitude1);

			var _this = this;
	        _this.checkPlaceAddress = '';
	      	if(latitude && longitude){
		      	geocoder.geocode( { 'latLng': latlng}, function(results, status) {
				console.log(results);
				if (results != null ) {
					var add= results[0].formatted_address ;
	                var value=add.split(",");
	                var count=value.length;
					var city = value[count-3];
					
					$("#locationAddress").val(add);
					$("#locationName").val(city);

			        _this.checkPlaceAddress = status;
			        if(_this.checkPlaceAddress != 'OK') {
			        	//_this.placeDetails.latlng = '';
			          _this.commonService.showCustomError('Please select valid latlng');
			        }
			        else{
			          $('input[name="latitude"]').click();
			        }
			    }
		      });
	  		}
            
        }, 500)
    }

	ngOnDestroy() {
		localStorage.removeItem('editPlaceId');   	
	}
}
