import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import * as moment from 'moment';
import { Subscription, interval } from "rxjs";

@Component({
  selector: 'app-vehicle-current-location',
  templateUrl: './vehicle-current-location.component.html',
  styleUrls: ['./vehicle-current-location.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class VehicleCurrentLocationComponent implements OnInit {
  loading:boolean;
  searchTracking:any = {}
  vehicleData: any = [];
  public counter: any;
  public subscription: any;
  objCurrentPosition: any = '';
  perPage: any;
  pageNo: any;
  vehicle_status :any = {};
  vehicleIdArray : any = [];

  constructor(
    private eref: ElementRef,
    public liveTrackingService: LiveTrackingService, 
    private customErrorHandler:CustomErrorHandler, 
    private commonService:CommonService, 
  ) { }

  ngOnInit() {
    this.searchTracking.group_by = '';
    this.getLiveDriverDetails();
    this.counter = interval(50000);
    // this.subscription = this.counter.subscribe((x) => {
    //   this.getLiveDriverDetails();
    // })
    this.subscription = setInterval(()=> {
         this.getLiveDriverDetails();
    }, 5000);
  }

  getLiveDriverDetails(){
    this.loading = true;
    this.liveTrackingService.getLiveTrackingDrivers(this.pageNo ='' ,this.perPage='',this.searchTracking,this.vehicle_status='',this.vehicleIdArray = '').subscribe(res => {
      if (res.success) {
        let vehicleData = res.data;
        this.loading = false;
        // this.getLatLongAddress(vehicleData, (coords) => {
        //     vehicleData.forEach((ele, idx) => { 
        //       ele.date_time = moment(ele.date_time).format('D MMM YYYY h:mm a');
        //       ele.location = coords[idx];
        //     });
        //     this.vehicleData = vehicleData;
        // });
        this.vehicleData = vehicleData;
        let _this = this;
        this.vehicleData.forEach((ele, idx) => { 
            ele.date_time = moment(ele.date_time).format('D MMM YYYY h:mm a');
            _this.getAddress(Number(ele.lat), Number(ele.lng), ele.registration_no);
        });
      } else {
        this.loading = false;
        this.commonService.showErrorMessage(res);
      }      
    }, error => {
        this.customErrorHandler.handleHttpError(error, 'getLiveDriverDetails');
        this.loading = false;
    })
  }

  getAddress(lat, lng, registrationNo) {
    
      this.objCurrentPosition = '';
      var latlng = new google.maps.LatLng(lat, lng);
      var geocoder = geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng },  (results, status ) => {
          console.log(registrationNo, status)
          if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                let temp = this.vehicleData.find(s => s.registration_no == registrationNo);
                if(temp != undefined){
                  let shiftIdx = this.vehicleData.indexOf(temp);
                  this.vehicleData[shiftIdx].location = results[1].formatted_address;

                   // temp.location = results[1].formatted_address;
                }
                // console.log(registrationNo, results[1].formatted_address)
                // this.vehicleData[index].location =  results[1].formatted_address;
                 
              }
          }
      });
  } 

  getLatLongAddress(addresses, callback) {
    let coords = [];
    for(let i = 0; i < addresses.length; i++) {
        let currAddress = new google.maps.LatLng(addresses[i].lat, addresses[i].lng);
        let geocoder = new google.maps.Geocoder();
        if (geocoder) {
            geocoder.geocode({'location': currAddress}, (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                  coords.push(results[0].formatted_address.replace('Unnamed Road,',''));
                  if(coords.length == addresses.length) {
                      if( typeof callback == 'function' ) {
                          callback(coords);
                          this.loading = false;
                      }
                  }
              } else {
                  throw('No results found: ' + status);
              }
          });
        }
     }
  }

  ngOnDestroy(){
    clearInterval(this.subscription);
  }
  // onClick() {
  //   this.loading = false;
  // }
}
