import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class PartsAndFuleService {
    constructor(private _httpHelper: HttpHelper) {
    }

    getFuelDetail(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-fuel', request);
        }
        catch (e) {
        }
    }

    getPartsDetail(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-part', request);
        }
        catch (e) {
        }
    }

    setPartsStatus(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'set-part-request', request);
        }
        catch (e) {
        }
    }
    
}
