import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class TripService {

    constructor(private _httpHelper: HttpHelper) {
    }

    addTrip(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-trip', request)
        }
        catch (e) {
        }
    }

    getTrip(pageNo, rowsPerPage,companyId,searchDetails){
    	try {
            let parameter = '';

            if(typeof searchDetails.status != 'undefined'){
                parameter += '&status='+searchDetails.status;
            }
             if(typeof searchDetails.trip_type != 'undefined'){
                parameter += '&trip_type='+searchDetails.trip_type;
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+companyId+'&start_date='+searchDetails.start_date+'&end_date='+searchDetails.end_date+'&search_by='+searchDetails.search_by+'&vehicle_id='+searchDetails.vehicle+'&driver_id='+searchDetails.driver+''+parameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-trip'+requestParameter)
        }
        catch (e) {
        }
    }    

    assignTrip(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'assign-trip', request)
        }
        catch (e) {
        }
    }

    cancelTrip(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'cancel-trip', request)
        }
        catch (e) {
        }
    }

    assignOrder(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'assign-order', request)
        }
        catch (e) {
        }
    } 

    getCompletedTrip(request){
        try {
            // let requestParameter = '?driver_id='+request.driver_id+'&date='+request.date;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'driver-trip-details', request)
        }
        catch (e) {
        }
    }

    getOrderByVehicle(vehicleId, tripId = null){
        try {
            let requestParameter = '';
            if(tripId)
            {
                requestParameter = '?vehicle_id='+vehicleId+'&trip_id='+tripId;
            }
            else{
                requestParameter = '?vehicle_id='+vehicleId;
            }
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'order-by-vehicle'+requestParameter)
        }
        catch (e) {
        }
    }

    getShiftByVehicle(request) {
        try {
            // let requestParameter = '?driver_id='+request.driver_id+'&date='+request.date;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-shift-by-vehicle', request)
        }
        catch (e) {
        }
    }
    getDriverConductorByVehicle(request) {
        try {
            // let requestParameter = '?driver_id='+request.driver_id+'&date='+request.date;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-by-shift', request)
        }
        catch (e) {
        }
    }
    addRoute(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-route', request)
        }
        catch (e) {
        }
    }

    getRoute(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-route', request)
        }
        catch (e) {
        }
    }

    getSingleRoute(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-route-by-id', request)
        }
        catch (e) {
        }
    }

    updateRoute(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'update-route', request)
        }
        catch (e) {
        }
    }

    deleteRoute(request){
        try {
           return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-route', request);
       }
       catch (e) {
       }
    }
}
