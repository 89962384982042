import { Component, OnInit ,ElementRef, NgZone,ViewChild} from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { PlaceService } from '../../services/place/place.service';
import { FormControl } from '@angular/forms';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { PlayBackService } from 'src/app/services/play-back/play-back.service';
import { Constants } from 'src/app/constants/constants';
import { UserService } from 'src/app/services/user/user.service';
import { TripService } from '../../services/trip/trip.service';
import { VehicleService } from '../../services/vehicle/vehicle.service';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import * as moment from 'moment';
import { Config } from './../../config/config';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { Socket } from 'ngx-socket-io';
declare var google: any;
declare var socket : any;

@Component({
    selector: 'app-play-back',
    templateUrl: './play-back.component.html',
    styleUrls: ['./play-back.component.css']
})
export class PlayBackComponent implements OnInit {
	selectdriverSelectedItems: any = [];
	selectdriverSettings:any = {};
    selectcompletedtripSelectedItems = [];
	selectcompletedtripSettings = {};
    showPlayBar:boolean =false;
    placeDetails: any = {};
    countries: any[];  
    private location;

    public lat: number;
    public lng: number;
    public searchControl: FormControl;
    public zoom: number;
    public origin: {}
    public destination: {}
    public circle:any= [];
    public locationRequired: any; 
    public userInfo: any;

    public trackingDetails: any = {};
    public trackingData : any = [];
    public waypoints: any = [
    {
        location: { lat: 22.7336896, lng: 75.8879226 },
        stopover: true,
    },
    {
        location: { lat: 22.8558764, lng: 75.988558 },
        stopover: true,
    }]
    public renderOptions = {
        draggable: true,
    }
    public change(event: any) {
        this.waypoints = event.request.waypoints;
    }

    // @ViewChild("search")
    // public searchElementRef: ElementRef;

    //  items: Observable<any[]>;
  
    
    private startTime:any;
    private endTime:any;
    private selectDate: any;
    private tooltipContent: any;
    private infowindow: any;
    private timeArray = [];
    private position;
    private marker;
    private route;
    public modeArrayStatus = [];
    public stoppageArray   = [];
    public userRunningSpeed = [];
    msg: any;
    errormsg: any;
    infoWindow: any;
    markers: any;
    newMarkerArray: any;
    speedArray: any;
    speed: any;
    interVal : any;
    lastPosn: any;
    map: any;
    count: any; 
    play: any;
    pause: any;
    notblank: any;
    notblankprospect: any;
    lineCoordinates: any;
    trackData: any[];
    tripId: any;
    trackingTripDetails: any = {};
    message: any;
    wayPointlength : any;
    mapStyles: any;
    playDriverId: any;
    playVehicleId: any;
    playbackFilter: any = {};
    driverList: any = [];
    driverSearchDetails: any = {}
    playbackErrorDate: any;
    playbackErrorDriver: any;
    tripList: any = [];
    currentMonth: any;
    currentDate: any;
    trackingDriverDetails: any = [];
    initializeMapOptions: any = {}
    movingMarker: any;
    workorderSettings = {};
    trackingTripDetailsPickup: any;
    trackingTripDetailsDrop: any;
    trackingTripDetailsVehicleName : any;
    trackingTripDetailsDriverName : any;
    trackingTripDetailsRegistrationNumber : any;
    trackingTripDetailsContactNumber: any;
    trackingTripDetailsCurrentStatus: any;
    trackingTripDetailsEndDistance: number = 0;
    trackingTripDetailsStartDistance: number = 0;
    trackingTripDetailsEndDuration : any;
    trackingTripDetailsStartDuration : any;
    trackingTripDetailsMaxSpeed : any;
    trackingTripDetailsAvg: any;
    markerCount: number = 0;
    startActualTime: any;
    endActualTime: any;
    tempSpeed: number = 0;
    previousDateTime: any = '';
    maxSpeedLimit: number = 60;
    startAddress: any = '';
    endAddress: any = '';
    routeTime: any = '';
    paybackCount: number = 0;
    trackBy: any = 'Vehicle';
    vehicleList: any = [];
    vehicleSelectItems: any  = [];
    vehicleSettings:any = {}
    vehicleId: any = '';
    playbackErrorVehicle: any = '';
    moveMarkerTime: number = 50;
    smoothCount: number = 0;
    smoothRunning: boolean = true;
    fuelConsumption: any = '0 L';

    constructor(
        private router: Router,
        public tripService: TripService,
        public userService:UserService,
        public playBackService: PlayBackService,
        private ngZone: NgZone,
        public _placeService: PlaceService, 
        private customErrorHandler:CustomErrorHandler, 
        private commonService:CommonService, 
        db: AngularFireDatabase,
        private vehicleService: VehicleService,
        public liveTrackingService: LiveTrackingService,
        private translate: TranslateService,
        private broadcaster: Broadcaster,
        private socket: Socket,
    ) {
        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }

        this.tripId = localStorage.getItem('playBackTrackingId');
        this.playVehicleId = localStorage.getItem('playbackVehicleId');
        if(this.tripId == null){
            this.tripId = '';
        }
        if(this.playVehicleId == null || this.playVehicleId == ''){
            this.playVehicleId = '';
        }

        this.translate.get('selectors').subscribe((res) => {
            setTimeout(() => {
                this.selectdriverSettings = {
                    singleSelection: true,
                    text: res.SelectDriver,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection width-setting"
                };
                this.vehicleSettings = {
                    singleSelection: true,
                    text: res.SelectVehicle,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection width-setting"
                };
                this.selectcompletedtripSettings = {
                    singleSelection: true,
                    text: res.SelectCompletedTrip,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection width-setting"
                };
            });
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
                setTimeout(() => {
                    this.selectdriverSettings = {
                        singleSelection: true,
                        text: res.SelectDriver,
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        enableSearchFilter: true,
                        classes: "singleSelection width-setting"
                    };
                    this.vehicleSettings = {
                        singleSelection: true,
                        text: res.SelectVehicle,
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        enableSearchFilter: true,
                        classes: "singleSelection width-setting"
                    };
                    this.selectcompletedtripSettings = {
                        singleSelection: true,
                        text: res.SelectCompletedTrip,
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        enableSearchFilter: true,
                        classes: "singleSelection width-setting"
                    };
                });
            });
        }); 
    }

    ngOnInit() {
        this.socket.removeListener('vehicle_tracking'); 
        this.lat = Number(this.userInfo.lat);
        this.lng = Number(this.userInfo.lng);
        this.playbackDataBinding();
        this.workorderSettings = {
            singleSelection: false,
            text: "Select work order",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true
        };
       
        this.playbackFilter.driver_id = '';
        this.movingMarker = Config.AuthenticationURL +''+ Constants.TruckMovingVehicleIcon; //'assets/image/Truck/truck1.png';
        
        this.zoom = 10;
        
        this.placeDetails.radius = 500;
        let cuurentDate = new Date();
        
        let currentYear = cuurentDate.getFullYear();
        let month = cuurentDate.getMonth() + 1;
        if(month < 10){
            this.currentMonth = '0'+month;
        }else{
            this.currentMonth = month;
        }
        let date = cuurentDate.getDate();
        let currentDate = '';
        if(date < 10){
            this.currentDate = '0'+date;
        }else{
            this.currentDate = date;
        }

        let completeTripDate = localStorage.getItem('playBackCompleteTripDate');
        if(completeTripDate != undefined || completeTripDate != null){
            this.playbackFilter.date = new Date(completeTripDate);
            // this.changeRouteDetails();
        }else{
            this.playbackFilter.date = currentYear+'-'+ this.currentMonth+'-'+this.currentDate;
        }
        this.mapStyles = Constants.mapStyles;

        
        
       
        
        // this.getTrackingDetails();
        // this.getTrackingData();

        this.searchControl = new FormControl();
        
        this.startTime = '00:00';
        this.endTime = '23:59'
        this.msg             = false;
        this.errormsg       = '';
        // this.clat            = 23.258467;
        // this.clng            = 77.431245;
        this.markers         = [];
        this.newMarkerArray  = [];
        this.speedArray      = [1000,900,800,700,600,500,400,300,200,100];
        this.speed           = "";
        this.interVal        = "";
        this.lastPosn        = "";
        this.map;
        this.count           = 0;
        this.play = true;
        this.pause = false;
        this.notblank = false; 

        this.playbackFilter.trip_id = this.tripId;
       
        var this1 = this;
        setTimeout(() => {
            // this.getDriverDetails();
            this.getVehicleDetails();
            var mapOptions = {
                center: new google.maps.LatLng(this1.lat, this1.lng),
                zoom: 15,
                travelMode: google.maps.TravelMode.DRIVING,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: this.mapStyles,
                scrollwheel: true,
                sensor:false,
                disableDefaultUI: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                }
            };
            
            this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
            
        },1000)
        this.playbackFilter.vehicle_id = '';          
    }

    getTrackingData(){
        this.markerCount = 0;
        this.previousDateTime = '';
        this.trackingDetails.trip_id = this.tripId;
        this.playBackService.getTackingData(this.trackingDetails).subscribe(
            res => {
                this.paybackCount = 0;
                if (res.success == true) {
                     this.trackingData = res.data;
                } else {
                     this.commonService.showErrorMessage(res);
                }
                var mapOptions = {
                    zoom: 15,
                    center: new google.maps.LatLng(this.lat, this.lng),
                    travelMode: google.maps.TravelMode.DRIVING,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: this.mapStyles,
                    scrollwheel: true,
                    disableDefaultUI: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_RIGHT
                    }
                };
                var bounds = new google.maps.LatLngBounds();
                this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
                if (res.data.length > 0) 
                {


                    this.notblank = true;
                    this.trackData = res.data;
                    this.lineCoordinates = [];
                    this.timeArray = [];
                    this.userRunningSpeed = [];
                    
                    var myLatLng = [];
                    for (let i = 0;i< this.trackData.length;i++) 
                    {
                        this.trackData[i].time = moment(this.trackData[i]['date_time']).format('h:mm a');
                        this.stoppageArray.push(this.trackData[i]);
                        let d_lat = Number(this.trackData[i].lat);
                        let d_lng = Number(this.trackData[i].lng);
                        this.lineCoordinates.push(new google.maps.LatLng(d_lat, d_lng));
                        var boundLatLng = new google.maps.LatLng(d_lat, d_lng);                       
                        if(this.previousDateTime != ''){
                            this.setStopageAndSpeedmarker(this.previousDateTime, this.trackData[i]['date_time'], d_lat, d_lng, this.trackData[i]['speed'],this.trackData[i]['is_aggressive_acceleration'],this.trackData[i]['is_excessive_speed'],this.trackData[i]['is_hard_braking'],this.trackData[i]['is_phone_usage']);
                        }
                        this.previousDateTime = this.trackData[i]['date_time'];
                        bounds.extend(boundLatLng);
                    }
                    
                    var markerstart = new google.maps.Marker({
                        map: this.map,
                        position: this.lineCoordinates[0],
                        icon: "assets/image/30_s.png"
                    });

                    this.getLatLongAddress(this.lineCoordinates[0],'start',markerstart);

                    this.position = this.lineCoordinates[0];
                    this.map.setCenter(markerstart.getPosition());

                    var markerend = new google.maps.Marker({
                        map: this.map,
                        position: this.lineCoordinates[this.lineCoordinates.length - 1],
                        icon: "assets/image/30_e.png"
                    });
                    this.getLatLongAddress(this.lineCoordinates[this.lineCoordinates.length - 1],'end', markerend);
                    markerend.getPosition();

                    var line = new google.maps.Polyline({
                        path: this.lineCoordinates,
                        icons: [{
                            icon: {
                                strokeColor: '#0000ff',
                                strokeWeight: 2,
                                fillColor: '#0000ff',
                                fillOpacity: 1
                            },
                            offset: '100%',
                            repeat: '250px'
                        }],
                        map: this.map,
                        strokeColor: "#4998f6",//"#ff0000",
                        strokeOpacity: 1,
                        strokeWeight: 3
                    });

                    line.setMap(this.map);
                    this.speed = (<HTMLInputElement>document.getElementById('dd_range')).value;
                    this.map.fitBounds(bounds);
                } 
            },
            error => {
            this.customErrorHandler.handleHttpError(error, 'getPlace');
            }
        )
    }

    changeTime(){
        this.notblank = false;
        this.notblankprospect = false;
        this.lineCoordinates = [];
    }

    moveMarker() 
    {
        if(this.play == true){
            return false;
        }
        if( typeof this.marker !== 'undefined' && this.marker ){
            this.marker.setMap(null);
        }
        var icon = {};
        
        this.marker = new google.maps.Marker({
            map: this.map,
            icon: this.movingMarker//"assets/images/30_W.png"
        });
        
        
        this.infowindow = new google.maps.InfoWindow();
        this.infowindow.open(this.map, this.marker);
        $(".gm-style-iw").next("div").hide();
        let previousLat = 0;
        let previousLng = 0;
        let currentLat;
        let currentLng;
        this.interVal = setInterval(() => {
            
           
            let content = '<b>Mode: </b>'+this.stoppageArray[this.count].mode+'<br><b>Time:</b> '+this.stoppageArray[this.count].time;
            this.infowindow.setContent(content);

            this.markers  = this.newMarkerArray;
            var point     = this.lineCoordinates[this.count];
            this.lastPosn = point;
            var result    = this.lineCoordinates[this.count];
            // this.marker.setDuration(this.speedArray[this.speed]);

            if(previousLat != 0 && previousLng != 0 && this.smoothRunning == true)
            {
                
                this.smoothCount = 0;
                currentLat = Number(this.stoppageArray[this.count].lat);
                currentLng = Number(this.stoppageArray[this.count].lng);

                var deltaLat = (currentLat - previousLat)/this.moveMarkerTime;
                var deltaLng = (currentLng - previousLng)/this.moveMarkerTime;
                this.moveMarkerSmooth(previousLat, previousLng, deltaLat, deltaLng);
                setTimeout(()=>{
                    this.route.getPath().push(point);
                },500)
            }
            else{
                this.route.getPath().push(point);

                this.marker.setPosition(point);
            } 
            previousLat = Number(this.stoppageArray[this.count].lat);
            previousLng = Number(this.stoppageArray[this.count].lng);
            this.smoothRunning = true;
            // setTimeout( ()=> {
            //     this.route.getPath().push(point);
            //     this.marker.setPosition(point);
            // },500)
            
            
            if ((!this.map.getBounds().contains(this.marker.getPosition()))) { //Note the double &  
                this.map.setCenter(this.marker.getPosition());  
            }
            if (this.count == this.lineCoordinates.length - 1) {
                this.count = 0;
                this.markerCount = 0;
                clearInterval(this.interVal);
                this.play = true;
                this.pause = false;
            } else {
                this.count++;
            }
        }, this.speedArray[this.speed]);

    }

    moveMarkerSmooth(previousLat , previousLng, tempLat, tempLng){
        console.log(this.smoothRunning)
        if(this.smoothRunning == false){
            return false;
        }
        previousLat += tempLat;

        previousLng += tempLng;
        var latlng = new google.maps.LatLng(previousLat, previousLng);
        // this.route.getPath().push(latlng);
        this.marker.setPosition(latlng);
        
        var temp = this.moveMarkerTime - 4;
        if(this.smoothCount < temp){
            this.smoothCount++;
            console.log('move',previousLat ,previousLng)
            setTimeout(() => {
                this.moveMarkerSmooth(previousLat, previousLng, tempLat, tempLng);
            },10);
        }    
    }

    setInitializeData(){
        this.play = true;
        this.pause = false;
        this.smoothRunning = false;
        // this.route.setPath([]);
        if( typeof this.route !== 'undefined' && this.route){
            this.route.setPath([]);
        }
        this.lineCoordinates = []
        this.stoppageArray = []
        clearInterval(this.interVal);
        if( typeof this.marker !== 'undefined' && this.marker ){
            this.marker.setMap(null);
        }
    }

    addMarker(marker) {
       
        this.clearMarkers(this.markers);
        this.newMarkerArray.push(marker);
        return marker;
    }

    clearMarkers(data) {
        for (var i = 0; i < data.length; i++) {
            this.markers[i].setMap(null);
        }
    }

    doPause() 
    {
        this.play = true;
        this.pause = false;
        clearInterval(this.interVal);
    }

    doPlay() {
        if( typeof this.route !== 'undefined' && this.route && this.paybackCount == 0){
            this.route.setPath([]);
        }
        this.paybackCount++;
        if(this.markerCount == 0){
            if(this.route){
                this.route.setOptions({strokeColor:'#4998f6'});
            }
            this.count = 0;
            this.route = new google.maps.Polyline({
                path: [],
                geodesic : true,
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                editable: false,
                map:this.map,
                zIndex: 9999
            });
        }
        this.markerCount++;
        this.play = false;
        this.pause = true;
        
        if (this.interVal != "") 
        {
            clearInterval(this.interVal);
            this.moveMarker();
        } 
        else 
        {
            this.moveMarker();
        }
    }

    doStop() 
    {
        this.marker.setMap(null);
        this.route.setPath([]);
        this.play = true;
        this.pause = false;
        if (this.interVal != "") 
        {
            this.count = 0;
            clearInterval(this.interVal);
            this.interVal = "";
            this.marker = new google.maps.Marker({
                map: this.map,
                position: this.lineCoordinates[0],
                icon: "assets/image/30_W.png"
            });
            this.addMarker(this.marker);
            this.map.setCenter(this.lineCoordinates[0]);
        }
    }

    changeRange(value: number) 
    {
        this.speed = value;
        if (this.interVal != "") 
        {
            clearInterval(this.interVal);
            this.moveMarker();
        }   
    }
   
    getTrackingDetails(){
        if(this.tripId){
            this.playBackService.getTrackingDetails(this.tripId).subscribe(
                res => {
                    this.message = res.message;
                    if (res.success == true) {
                        this.trackingTripDetails = res.data;
                        this.trackingTripDetailsPickup = this.trackingTripDetails.pickup;
                        this.trackingTripDetailsDrop = this.trackingTripDetails.drop;
                        this.trackingTripDetailsVehicleName = this.trackingTripDetails.vehicle_name;
                        this.trackingTripDetailsDriverName = this.trackingTripDetails.driver_name;
                        this.trackingTripDetailsRegistrationNumber = this.trackingTripDetails.registration_number;
                        this.trackingTripDetailsContactNumber = this.trackingTripDetails.contact_number;
                        this.trackingTripDetailsCurrentStatus = this.trackingTripDetails.current_status;

                        this.trackingTripDetailsStartDistance = this.trackingTripDetails.start_distance;
                        this.trackingTripDetailsEndDistance = this.trackingTripDetails.end_distance;
                        this.trackingTripDetailsEndDuration = this.trackingTripDetails.end_duration;
                        this.trackingTripDetailsStartDuration = this.trackingTripDetails.start_duration;
                        this.fuelConsumption = this.trackingTripDetails.fuel_consumption;
                        this.trackingTripDetailsMaxSpeed = this.trackingTripDetails.max_speed;
                        this.trackingTripDetailsAvg = this.trackingTripDetails.avg;

                        this.trackingTripDetails.time_difference = this.trackingTripDetails.end_duration - this.trackingTripDetails.start_duration;
                        this.trackingTripDetails.distance_difference = this.trackingTripDetails.end_distance - this.trackingTripDetails.start_distance;
                        this.wayPointlength = this.trackingTripDetails.wayPoints;
                        
                        var FillingStationData = res.data.geofences;
                        var lat  = Number(FillingStationData.boundries[0].lat);
                        var lng = Number(FillingStationData.boundries[0].lng)
                        var antennasCircle = new google.maps.Circle({
                          strokeColor: "#FF0000",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#FF0000",
                          fillOpacity: 0.35,
                          map: this.map,
                          center: {
                            lat: lat,
                            lng: lng
                          },
                          radius: Number(FillingStationData.circle_radius)
                        });
                        this.map.fitBounds(antennasCircle.getBounds());
                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(lat,lng),
                            icon: 'test.png',
                            label: { color: '#0000FF', fontWeight: 'bold', fontSize: '10px', text: FillingStationData.geofence_title },
                            optimized: false,
                            visible: true
                        });
                        marker.setMap(this.map); 

                        
                        this.movingMarker = Config.AuthenticationURL +''+ Constants.CarMovingVehicleIcon;
                        // this.movingMarker = Constants.CAR_RUNNING_ICON;
                        if(typeof this.trackingTripDetails.vehicle_type != 'undefined'){
                            if(this.trackingTripDetails.vehicle_type == 'Truck'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.TruckMovingVehicleIcon;
                                // this.movingMarker = Constants.TRUCK_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Bus'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.BusMovingVehicleIcon;
                                // this.movingMarker = Constants.BUS_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Car'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.CarMovingVehicleIcon;
                                // this.movingMarker = Constants.TRUCK_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Tanker'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.TankerMovingVehicleIcon;
                                // this.movingMarker = Constants.TANKER_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Motorcycle'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.BikeMovingVehicleIcon;
                                // this.movingMarker = Constants.BIKE_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Loader'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.LoaderMovingVehicleIcon;
                                // this.movingMarker = Constants.TRUCK_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Mower'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.MowerMovingVehicleIcon;
                                // this.movingMarker = Constants.TRUCK_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Trailer'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.TrailerMovingVehicleIcon;
                                // this.movingMarker = Constants.TRUCK_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Van'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.VanMovingVehicleIcon;
                                // this.movingMarker = Constants.CAR_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Fire-Truck'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.VanMovingVehicleIcon;
                                // this.movingMarker = Constants.CAR_RUNNING_ICON;
                            }
                            else if(this.trackingTripDetails.vehicle_type == 'Crane'){
                                this.movingMarker = Config.AuthenticationURL +''+ Constants.VanMovingVehicleIcon;
                                // this.movingMarker = Constants.CAR_RUNNING_ICON;
                            }
                        }
                    } 
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'getTrackingDetails');
                }
            )
        }
    }

    getDriverDetails(){
        this.driverSearchDetails.company_id = this.userInfo.company_id;
        this.userService.getDriver(0,0, this.driverSearchDetails).subscribe(
            res => {
                if (res.success == true) {
                    this.driverList = res.data.rows;
                    this.trackingDriverDetails = [];
                    for(let i = 0;i< this.driverList.length;i++){
                        let driverId = this.driverList[i]['id'];
                        this.trackingDriverDetails[driverId] = {}
                        this.trackingDriverDetails[driverId].vehicle_name = this.driverList[i]['vehicle_name'];
                        this.trackingDriverDetails[driverId].driver_name = this.driverList[i]['full_name'];
                        this.trackingDriverDetails[driverId].registration_number = this.driverList[i]['registration_number'];
                        this.trackingDriverDetails[driverId].contact_number = this.driverList[i]['mobile'];
                        this.trackingDriverDetails[driverId].pickup = '-';
                        this.trackingDriverDetails[driverId].drop = '-';
                        this.trackingDriverDetails[driverId].end_distance = '0';
                        this.trackingDriverDetails[driverId].start_distance = '0';
                        this.trackingDriverDetails[driverId].end_duration = '0';
                        this.trackingDriverDetails[driverId].start_duration = '0';

                        this.trackingDriverDetails[driverId].max_speed = '0';
                        this.trackingDriverDetails[driverId].avg = '0';
                        this.trackingDriverDetails[driverId].current_status = 'NA';
                        this.trackingDriverDetails[driverId].start_duration = '0';
                        this.trackingDriverDetails[driverId].start_duration = '0';
                        this.trackingDriverDetails[driverId].start_duration = '0';
                    }
                    // this.playbackFilter.vehicle_id = this.playVehicleId;
                    // if(this.playVehicleId){
                    //     this.selectdriverSelectedItems = [];
                    //     let tempVehicle = this.vehicleList.find(s => s.id == this.playVehicleId);
                    //     if (tempVehicle) {
                    //         if (typeof tempVehicle != 'undefined' || tempVehicle != '') {
                    //             this.vehicleSelectItems.push(tempVehicle);
                    //             this.changeRouteDetails();
                    //             this.changeDriverDetails();
                    //         }
                    //     }
                    // }
                }else{
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'driverList');
            }
        )
    }

    changeRouteDetails(){
        this.tripList = [];
        this.selectcompletedtripSelectedItems = [];
        this.showPlayBar =false;
        var mapOptions = {
            zoom: 15,
            center: new google.maps.LatLng(this.lat, this.lng),
            travelMode: google.maps.TravelMode.DRIVING,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.mapStyles,
            scrollwheel: true,
            disableDefaultUI: false
        };
        
        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
                
        this.playbackErrorDate = '';
        this.playbackFilter.trip_id = '';
        this.playbackErrorDriver = '';
        this.playbackErrorVehicle = '';
        if(this.playbackFilter.date == '' || this.playbackFilter.driver_id == '' || this.playbackFilter.vehicle_id == ''){
            if(this.playbackFilter.date == ''){
                this.playbackErrorDate = 'Date is required';
                return false;
            }
            if(this.playbackFilter.driver_id == '' && this.trackBy == 'Driver'){
                this.playbackErrorDriver = 'Driver is required';
                return false;
            }

            if(this.playbackFilter.vehicle_id == '' && this.trackBy == 'Vehicle'){
                this.playbackErrorVehicle = 'Vehicle is required';
                return false;
            }
        }

        if(this.trackBy == 'Vehicle')
        {
            this.playbackFilter.driver_id = '';
        }

        if(this.trackBy == 'Driver')
        {
            this.playbackFilter.vehicle_id = '';
        }

        this.playbackFilter.date = moment(this.playbackFilter.date).format('YYYY-MM-DD');
        this.tripService.getCompletedTrip(this.playbackFilter).subscribe(
            res => {
                  if (res.success == true) {
                    this.tripList = res.data;
                    // this.playbackFilter.driver_id = this.playDriverId;
                    if(this.tripId){
                        this.selectcompletedtripSelectedItems = [];
                        let tempTrip = this.tripList.find(s => s.id == this.tripId);
                        if (tempTrip) {
                            if (typeof tempTrip != 'undefined' || tempTrip != '') {
                                this.selectcompletedtripSelectedItems.push(tempTrip);
                                this.playbackFilter.trip_id = this.tripId;
                                this.showPlayBar = true;  
                                this.changeTripRoute();
                            }
                        }
                    }else if(this.tripList.length > 0){
                        this.selectcompletedtripSelectedItems.push(this.tripList[0]);
                        this.playbackFilter.trip_id = this.tripList[0].id;
                        this.showPlayBar = true;  
                        this.changeTripRoute();
                    }
                  }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'driverList');
            }
        )
    }

    changeTripRoute(){
        if(this.playbackFilter.trip_id == ''){
            this.commonService.showCustomError("Trip is required");
            return false;
        }
        this.tripId = this.playbackFilter.trip_id;
        this.getTrackingData();
        this.getTrackingDetails();
    }

    changeDriverDetails(){
        let driverId = this.playbackFilter.driver_id;
        this.trackingTripDetails = this.trackingDriverDetails[driverId];
        
        this.trackingTripDetailsPickup = this.trackingTripDetails.pickup;
        this.trackingTripDetailsDrop = this.trackingTripDetails.drop;
        this.trackingTripDetailsVehicleName = this.trackingTripDetails.vehicle_name;
        this.trackingTripDetailsDriverName = this.trackingTripDetails.driver_name;
        this.trackingTripDetailsRegistrationNumber = this.trackingTripDetails.registration_number;
        this.trackingTripDetailsContactNumber = this.trackingTripDetails.contact_number;
        this.trackingTripDetailsCurrentStatus = this.trackingTripDetails.current_status;
        this.trackingTripDetailsStartDistance = this.trackingTripDetails.start_distance;
        this.trackingTripDetailsEndDistance = this.trackingTripDetails.end_distance;

        this.trackingTripDetailsEndDuration = this.trackingTripDetails.end_duration;
        this.trackingTripDetailsStartDuration = this.trackingTripDetails.start_duration;
        this.fuelConsumption = this.trackingTripDetails.fuel_consumption;
        this.trackingTripDetailsMaxSpeed = this.trackingTripDetails.max_speed;
        this.trackingTripDetailsAvg = this.trackingTripDetails.avg;

        this.trackingTripDetails.time_difference = this.trackingTripDetails.end_duration - this.trackingTripDetails.start_duration;
        this.trackingTripDetails.distance_difference = this.trackingTripDetails.end_distance - this.trackingTripDetails.start_distance;       
    }

    playbackDataBinding(){
        this.trackingTripDetailsVehicleName = 'NA';
        this.trackingTripDetailsDriverName = 'NA';
        this.trackingTripDetailsRegistrationNumber = 'NA';
        this.trackingTripDetailsContactNumber = 'NA';
        this.trackingTripDetailsPickup = '-';
        this.trackingTripDetailsDrop = '-';
        this.trackingTripDetailsCurrentStatus = 'NA';

        this.trackingTripDetailsEndDistance = 0;
        this.trackingTripDetailsStartDistance = 0;

        this.trackingTripDetailsEndDuration = '0';
        this.trackingTripDetailsStartDuration = '0';
        this.trackingTripDetailsAvg = '0';
        this.trackingTripDetailsMaxSpeed = '0';

        this.trackingTripDetails.time_difference = '0';
        this.trackingTripDetails.distance_difference = '0';
        this.trackingTripDetails.wayPoints = [];
        this.fuelConsumption = '0 L';
    }

    onItemSelectselectdriver(item: any) {
        this.showPlayBar =false;
        this.tripId = '';
        this.playbackFilter.driver_id = item.id;  
        this.selectcompletedtripSelectedItems = [];     
        this.setInitializeData();
        this.changeRouteDetails();
        this.changeDriverDetails();
        this.doStop();
    }
    onOpenselectdriver(event: any) {
        this.driverList = this.driverList.map((item) => {
          return {
            id: item.id,
            itemName: item.itemName
          }
        });
    }
    onItemSelectcompletedtrip(item: any) {      
        this.showPlayBar = true;  
        this.playbackFilter.trip_id = item.id;
        this.setInitializeData();
        this.changeTripRoute();
        this.doStop();
    }
    onOpenselectcompletedtrip(event: any) {
        this.tripList = this.tripList.map((item) => {
          return {
            id: item.id,
            itemName: item.itemName
          }
        });
    }

    ngOnDestroy() {
        localStorage.removeItem('playbackVehicleId');
        localStorage.removeItem('playBackTrackingId');
        localStorage.removeItem('playBackCompleteTripDate');
    }

    setStopageAndSpeedmarker(startDate, endDate, lat, lng, speed, accelation, excessiveSpeed, hardBreaking,phoneUses){
        this.startActualTime = new Date(startDate);
        this.endActualTime = new Date(endDate);

        let diff = this.endActualTime - this.startActualTime;

        diff = (diff / 1000)/60;
        diff = Math.round(diff);
        
        if(diff > 4)
        {
            let stopageIcon = '';
            if(diff < 10){
                stopageIcon = "assets/image/map-images/stop_5_9.png";
            }else if(diff < 15){
                stopageIcon = "assets/image/map-images/stop_10_14.png";
            }else{
                stopageIcon = "assets/image/map-images/stop_15.png";
            }   
            this.timeFormating(diff);
            let stopInfowindow = new google.maps.InfoWindow();
            let stoppageTooltipContent = 'Stoppage - '+this.routeTime+'<br><b>Time:</b> '+moment(startDate).format('h:mm A')+ ' - ' +moment(endDate).format('h:mm A');
            var stoppageMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon : stopageIcon
            });
            stoppageMarker.setMap(this.map);


            google.maps.event.addListener(stoppageMarker, 'click', (function (stoppageMarker, stoppageTooltipContent, stopInfowindow) {
              return function () {
                stopInfowindow.setContent(stoppageTooltipContent);
                stopInfowindow.open(this.map, stoppageMarker);
              };
            })(stoppageMarker, stoppageTooltipContent, stopInfowindow));
        }

        if(accelation == 1){
            let speedIcon = "assets/image/map-images/accelation.png";
            let accelarationInfoWindow = new google.maps.InfoWindow();
            let speedMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon : speedIcon
            });
            speedMarker.setMap(this.map);
            let accelerationToolTipContent = '<p style="text-align: center;"><b>Aggressive Acceleration</b><br>'+speed+' Kph<br><i class="fa fa-arrow-circle-up"></i><br>'+this.tempSpeed+' Kph<br></p>';
            google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, accelerationToolTipContent, accelarationInfoWindow) {
              return function () {
                accelarationInfoWindow.setContent(accelerationToolTipContent);
                accelarationInfoWindow.open(this.map, speedMarker);
              };
            })(speedMarker, accelerationToolTipContent, accelarationInfoWindow));
        }
        if(excessiveSpeed == 1){
            let speedIcon = "assets/image/map-images/speed.png";
            let excessiveInfowindow = new google.maps.InfoWindow();
            let speedMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon : speedIcon
            });
            speedMarker.setMap(this.map);

            let excessiveSpeedToolTipContent =  '<p style="text-align: center;"><b>Excessive Speed</b><br>'+speed+' Kph<br><i class="fa fa-arrow-circle-up"></i><br>'+this.tempSpeed+' Kph<br></p>';
            google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, excessiveSpeedToolTipContent, excessiveInfowindow) {
              return function () {
                excessiveInfowindow.setContent(excessiveSpeedToolTipContent);
                excessiveInfowindow.open(this.map, speedMarker);
              };
            })(speedMarker, excessiveSpeedToolTipContent, excessiveInfowindow));
        }
        if(hardBreaking == 1){
            let speedIcon = "assets/image/map-images/break.png";
            let hardBreakingInfowindow = new google.maps.InfoWindow();
            let speedMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon : speedIcon
            });
            speedMarker.setMap(this.map);

            let hardBreakingToolTipContent = '<p style="text-align: center;"><b>Hard Breaking</b><br>'+speed+' Kph<br><i class="fa fa-arrow-circle-up"></i><br>'+this.tempSpeed+' Kph<br></p>';;;
            google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, hardBreakingToolTipContent, hardBreakingInfowindow) {
              return function () {
                hardBreakingInfowindow.setContent(hardBreakingToolTipContent);
                hardBreakingInfowindow.open(this.map, speedMarker);
              };
            })(speedMarker, hardBreakingToolTipContent, hardBreakingInfowindow));
        }
        if(phoneUses != null && phoneUses != '' && phoneUses != 0){
            let speedIcon = "assets/image/map-images/phone.png";
            let phoneUsesInfowindow = new google.maps.InfoWindow();
            let speedMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: this.map,
                icon : speedIcon
            });
            speedMarker.setMap(this.map);

            let phoneUsesInfoWindow = '<p style="text-align: center;">Phone Uses <br><br>'+phoneUses+'</p>';
            google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, phoneUsesInfoWindow, phoneUsesInfowindow) {
              return function () {
                phoneUsesInfowindow.setContent(phoneUsesInfoWindow);
                phoneUsesInfowindow.open(this.map, speedMarker);
              };
            })(speedMarker, phoneUsesInfoWindow, phoneUsesInfowindow));
        }
        this.tempSpeed = speed;
    }

    timeFormating(totalMinutes){
        let totalTime = Number(totalMinutes);
        this.routeTime = '';
        if(totalTime < 60){
          this.routeTime = totalTime + ' Min.';
        }else{
            if(totalTime > 1440){
                let days = totalTime/1440;
                days = parseInt(days.toString());
                if(days > 0){
                    this.routeTime = days+' Day ';
                }

                let hours = totalTime/60%24;
                hours = parseInt(hours.toString());
                if(hours > 0){
                    this.routeTime = this.routeTime+ ''+ hours+' H ';
                }

                let minute = (totalTime%60);
                minute = parseInt(minute.toString());
                if(minute > 0){
                    this.routeTime = this.routeTime+ ''+ minute+ ' Min.';
                }

            }else{

                let hours = totalTime/60;
                hours = parseInt(hours.toString());
                if(hours > 0){
                    this.routeTime = hours+' H ';
                }
                let minute = (totalTime%60);
                minute = parseInt(minute.toString());
                if(minute > 0){
                    this.routeTime = this.routeTime+ ''+ minute+ ' Min.';
                }
            }
        }
    }

    getLatLongAddress(latlng, start, marker){
        // var geocoder = new google.maps.Geocoder; 


        let lat = latlng.lat();
        var lng = latlng.lng();
        fetch("https://nominatim.openstreetmap.org/search.php?q="+lat+","+lng+"&polygon_geojson=1&format=json")
            .then(response => response.json())
            .then(j => {
              // this.startAddress = j[0].display_name;
        

        // geocoder.geocode({'location': latlng}, function(results, status) {
        //     if (status === 'OK') {
        //         if (results[0]) {
                    if(start == 'start'){
                        let phoneUsesInfowindow = new google.maps.InfoWindow();
                        this.startAddress = j[0].display_name;
                        // this.startAddress = results[0].formatted_address;
                        let speedInfowindow = new google.maps.InfoWindow({maxWidth: 200});
                        let markerContent = this.startAddress;
                        google.maps.event.addListener(marker, 'click', (function (marker, markerContent, speedInfowindow) {
                          return function () {
                            speedInfowindow.setContent(markerContent);
                            speedInfowindow.open(this.map, marker);
                          };
                        })(marker, markerContent, speedInfowindow));
                    }
                    if(start == 'end'){
                        this.endAddress = j[0].display_name;
                        // this.endAddress = results[0].formatted_address;
                        let speedInfowindow = new google.maps.InfoWindow({maxWidth: 200});
                        let markerContent = this.endAddress;
                        google.maps.event.addListener(marker, 'click', (function (marker, markerContent, speedInfowindow) {
                          return function () {
                            speedInfowindow.setContent(markerContent);
                            speedInfowindow.open(this.map, marker);
                          };
                        })(marker, markerContent, speedInfowindow));
                    }
        //         }
        //     }
        // });
        })
    }

    changeTrackingDate(){
        this.tripId = '';
        this.setInitializeData();
        this.changeRouteDetails();
    }

    getVehicleDetails(){
        this.playBackService.getTrackingVehicleDetails().subscribe(
            res => {
                if (res.success == true) {
                    this.vehicleList = res.data;
                    this.vehicleList.forEach((ele) => {
                        ele.itemName = ele.registration_number 
                    });
                    // if(this.playVehicleId)
                    // {
                    //     this.tripId = '';
                    //     this.playbackFilter.driver_id = '';
                    //     this.playbackFilter.vehicle_id = this.vehicleId;

                    //     let tempVehicle = this.vehicleList.find(s => s.id == this.vehicleId);
                    //     if (tempVehicle) {
                    //         this.trackingTripDetailsRegistrationNumber = tempVehicle.registration_number;
                    //         this.trackingTripDetailsVehicleName = tempVehicle.vehicle_name;
                    //         this.changeRouteDetails();
                    //     }
                    // }
                     this.playbackFilter.vehicle_id = this.playVehicleId;
                    if(this.playVehicleId){
                        this.selectdriverSelectedItems = [];
                        let tempVehicle = this.vehicleList.find(s => s.id == this.playVehicleId);
                        if (tempVehicle) {
                            if (typeof tempVehicle != 'undefined' || tempVehicle != '') {
                                this.vehicleSelectItems.push(tempVehicle);
                                this.changeRouteDetails();
                                this.changeDriverDetails();
                            }
                        }
                    }
                }else{
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'driverList');
            }
        )
    }

    changeTrackBy(trackBy){
        this.showPlayBar = false;  
        this.selectdriverSelectedItems = [];
        this.vehicleSelectItems = [];
        this.trackingTripDetailsVehicleName = 'NA';
        this.trackingTripDetailsDriverName = 'NA';
        this.trackingTripDetailsRegistrationNumber = 'NA';
        this.trackingTripDetailsContactNumber = 'NA';
        this.trackingTripDetailsPickup = '-';
        this.trackingTripDetails = [];
        this.trackingTripDetailsDrop = '-';
        this.trackingTripDetailsCurrentStatus = 'NA';
        this.trackingTripDetailsEndDistance = 0;
        this.trackingTripDetailsStartDistance = 0;
        this.trackingTripDetailsEndDuration = 0;
        this.trackingTripDetailsStartDuration = 0;
        this.fuelConsumption = '0 L';
        this.trackingTripDetailsEndDuration = 0;
        this.trackingTripDetailsMaxSpeed = 0;
        this.trackingTripDetailsAvg = 0;
        this.tripList = [];
        this.selectcompletedtripSelectedItems = [];
        this.setInitializeData();
        let mapOptions = {
            zoom: 15,
            center: new google.maps.LatLng(this.lat, this.lng),
            travelMode: google.maps.TravelMode.DRIVING,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.mapStyles,
            scrollwheel: true,
            disableDefaultUI: false
        };
        
        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
    }

    onItemSelectVehicle(item: any){
        this.vehicleId = item.id;
        this.tripId = '';
        this.playbackFilter.driver_id = '';
        this.playbackFilter.vehicle_id = this.vehicleId;
        this.setInitializeData();
        let tempVehicle = this.vehicleList.find(s => s.id == this.vehicleId);
        if (tempVehicle) {
            this.trackingTripDetailsRegistrationNumber = tempVehicle.registration_number;
            this.trackingTripDetailsVehicleName = tempVehicle.vehicle_name;
            this.changeRouteDetails();
        }

    }
    toggleBars(){
        $('.content_wrap').toggleClass('hide_bars');
    }
}
