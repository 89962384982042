import { RoleService } from './../../../services/role/role.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RolelistComponent implements OnInit {
  loading: boolean;
  public isDemoTenant: boolean;
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  searchDetails: any = {};
  totalItem: any;
  currentpage: any;
  rolelist: any = {};
  deleteDetails: any = {};
  searchTempVal: any;
  message: any = '';
  deleteMsg: any;
	deleteLabel: any;
  cancelLabel: any;
  EditLabel:string;
  constructor(
    private roleService: RoleService, 
    private router: Router, 
    private customErrorHandler: CustomErrorHandler, 
    private _commonService: CommonService,
    private translate: TranslateService,
    private broadcaster: Broadcaster
  ) {
    this.currentpage = "Add";
    this.translate.get('DeleteOption').subscribe((res) => {
      setTimeout(() => {
        this.deleteMsg = res.DeleteAlert;
        this.deleteLabel = res.Delete;
        this.cancelLabel = res.Cancel;
        this.EditLabel = res.Edit;
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('DeleteOption').subscribe((res) => {
          setTimeout(() => {
              this.deleteMsg = res.DeleteAlert;
              this.deleteLabel = res.Delete;
              this.cancelLabel = res.Cancel;
              this.EditLabel = res.Edit;
          });
      });
    });
  }

  ngOnInit() {
    this.searchTempVal = "";
    this.loading = false;
    this.searchDetails.search_by = '';
    this.searchDetails.current_status = '';
    this.totalItem = 0;
    this.getRolelist(this.pageNo);
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
  }

  getRolelist(currentPage) {
    this.loading = true;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.roleService.getRolelist(this.pageNo, this.perPage, this.searchDetails).subscribe(
      res => {
        this.loading = false;
        this.message = res.message;
        if (res.success == true) {
          this.totalItem = res.data.count;
          this.rolelist = res.data.rows;
        } else {
          this._commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getRolelist');
      }
    )
    return event;
  }

  // popup functions end
  searchByFilter() {
    if (this.searchTempVal != this.searchDetails.search_by) {
      this.searchTempVal = this.searchDetails.search_by;
      this.pageNo = 1;
      this.getRolelist(this.pageNo);
    }
  }

  editRolelistById(RolelistById) {
    localStorage.setItem('editRoleId', RolelistById);
    this.router.navigate(['./role-management/role']);
  }

  deleteRolelist(rolelistId) {
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton:true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        this.deleteDetails.rolelistId = rolelistId;
        this.roleService.deleteRole(rolelistId).subscribe(
          res => {
            if (res.success == true) {
              this.pageNo = 1;
              this._commonService.showSuccessMessage(res.message);
              this.getRolelist(this.pageNo);
            } else {
              this._commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'createRole');
          }
        )
      }
    });
  }
}
