import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user/user.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Config } from 'src/app/config/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {

  public isDemoTenant:boolean;
  searchDetails: any = {};
  loading: boolean;
	perPage: any = 10;
	pageNo: any = 1;
	page: number = 2;
	message:string;
  totalItem: any;
  studentList:any = [];
  selectedStudent:any = {

  }
	modalRef: BsModalRef;
	imageBase:string;
  constructor(
		private modalService: BsModalService, 
		private translate: TranslateService,
		private commonService:CommonService, 
		private router: Router, 
		private customErrorHandler:CustomErrorHandler,
		public userService: UserService
  ) { }

  ngOnInit() {
    this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    this.getStudentList(1);
    this.imageBase= Config.AuthenticationURL;
  }
	getStudentList(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {
      row_per_page:this.perPage,
      page:this.page
    };
		this.userService.getStudentList(requestParameter).subscribe(
	        res => {
						this.loading =false; 
						this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
              this.studentList = res.data.rows;
              console.log(this.studentList);
	          } else {
				this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	      )
  }
  viewStudent(template,student){
    this.selectedStudent = student;
		this.modalRef = this.modalService.show(template);
  }
  editStudent(Id){
	  localStorage.setItem('editStudentId', Id);
	  this.router.navigate(['./student-management/create-student']);
  }

}
