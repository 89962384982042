import { CommonService } from './../../services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { Request } from '@angular/http';
import { LoginService } from './../../services/login/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private loginService: LoginService, 
    private router: Router,
    private customErrorHandler: CustomErrorHandler, 
    private commonService: CommonService
  ) { }

  passwordDetails:any ={};
  ngOnInit() {
  }

  /*
    This function is used for change user password
  */
  changePassword() {
    if(this.passwordDetails.new_password != this.passwordDetails.confirm_password){
      let requestparam = {
        message: 'Your password and confirmation password do not match.'
      }
      this.commonService.showErrorMessage(requestparam);
      return false;
    }
    if(this.passwordDetails.current_password != this.passwordDetails.new_password){
      this.loginService.changePassword(this.passwordDetails.current_password,this.passwordDetails.new_password, this.passwordDetails.confirm_password).subscribe(
        res => {
          if (res.success == true) {
            this.passwordDetails={};
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(['/login']);
            this.commonService.logout(); 
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'changePassword');
        }
      )
    }
  }
}
