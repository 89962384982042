import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { DueSerService } from '../../../services/due-service/dueService.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/constants/constants';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-service-due-list',
  templateUrl: './service-due-list.component.html',
  styleUrls: ['./service-due-list.component.css']
})
export class ServiceDueListComponent implements OnInit {

  public isDemoTenant:boolean;
  loading:Boolean=false;
  serviceList:any = [];
  tempSearch;
  searchTextValue = '';
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  noDataMsg = '';
  start_date;
  end_date;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  deleteMsg: any;
	deleteLabel: any;
	cancelLabel: any;
  EditLabel:string;
  ViewLabel:string;

  constructor(
    private router: Router,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public dueService: DueSerService,
    private translate: TranslateService,
    private broadcaster: Broadcaster 
  ) { 
    this.translate.get('DeleteOption').subscribe((res) => {
      setTimeout(() => {
        this.deleteMsg = res.DeleteAlert;
        this.deleteLabel = res.Delete;
        this.cancelLabel = res.Cancel;
        this.EditLabel = res.Edit;
        this.ViewLabel = res.ViewDetails;
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('DeleteOption').subscribe((res) => {
          setTimeout(() => {
            this.deleteMsg = res.DeleteAlert;
            this.deleteLabel = res.Delete;
            this.cancelLabel = res.Cancel;
            this.EditLabel = res.Edit;
            this.ViewLabel = res.ViewDetails;
          });
        });
    });
  }

  ngOnInit() {
    this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    this.getServiceData(1);
  }

  editService(value) {
    this.router.navigate(['/service-due-management/create-due-service'], { queryParams: { id: value.parent_id } });    
  }

  searchByFilter(event){
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getServiceData(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  getServiceData(currentPage) {
    this.loading = true; 
    this.pageNo = currentPage;
    this.page = currentPage;
    let queryParams = {
      row_per_page: this.perPage,
      page_no:this.page,
      search: this.searchTextValue,
      start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY'),
      end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY')
    }
    this.dueService.getService(queryParams).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.serviceList = res.data.rows;
        this.serviceList.map(element => {
          element.service_date = element.service_date == 'NA' || element.service_date =='' || element.service_date == null ? '--' : moment(element.service_date).format('DD-MM-YYYY');
          element.poc_date = element.poc_date == 'NA' || element.poc_date =='' || element.poc_date == null ? '--' : moment(element.poc_date).format('DD-MM-YYYY');
        });
        this.noDataMsg = res.message;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  onSelect(item: any) {
    this.getServiceData(1);
  }

  onDeSelect(item: any) {
    this.getServiceData(1);
  }

  deleteService(val){     
    let id = val.parent_id		
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton:true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        let rawData = {
          service_id: id
        }
        this.loading = true;
        this.dueService.deleteService(rawData).subscribe(
           res => {
              if (res.success == true) {
                 this.commonService.showSuccessMessage(res.message);
                 this.getServiceData(1);
              } else {
                  this.commonService.showErrorMessage(res);
              }
              this.loading =false; 
           },
           error => {
              this.customErrorHandler.handleHttpError(error, 'getVehicle');
              this.loading =false; 
           }
        )
      }
    });   
  }

  viewService(value) {
    this.router.navigate(['/service-due-management/view-service'], { queryParams: { id: value.parent_id } });
  }
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getServiceData(1);
	}
}
