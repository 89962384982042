import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-daily-fuel-consumption',
  templateUrl: './daily-fuel-consumption.component.html',
  styleUrls: ['./daily-fuel-consumption.component.css']
})
export class DailyFuelConsumptionComponent implements OnInit {
	public isDemoTenant:boolean;
  	loading:boolean = false;
	selected: any;
	vehicleList: any = [];
  	VehicleSelectedItems = [];
  	listSettings = {};
	start_date;
	end_date;
  	totalItem: any;
  	perPage: any = 10;
  	pageNo: any = 1;
  	ReportDetails: any = [];
  	page: number = 2;
  	message:string = 'Currently, there is no data available.';
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
	dateTime = new Date();
	dateSelected:any;
  	constructor(
		private commonService:CommonService, 
		private customErroHandler:CustomErrorHandler,
		public reportService: ReportService,
    	private translate: TranslateService
  	) { }

  	ngOnInit() {
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    	this.listSettings = {
						    	singleSelection: true, 
						      	text:"Select Vehicle",
						      	enableSearchFilter: true,
						      	classes:"myclass custom-class"
    						}
    	this.getVehicle();
  	}

	getVehicle() {
    	this.commonService.getAssignVehicle().subscribe(res => {
	      	if (res.success == true) {
	        	this.vehicleList = res.data;
	      	} else {
	        	this.commonService.showErrorMessage(res);
	      	}
    	}, error => {
      		this.customErroHandler.handleHttpError(error, 'addInspection');
    	});
  	}
  	
  	getIdleReport(currentPage,Export:any){
		let fileName = "Idle Report " + this.VehicleSelectedItems[0].itemName;
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "Idle Report - " + this.VehicleSelectedItems[0].itemName
		  };
		//this.loading =true;
		this.page = currentPage;
		let tempDate = this.dateSelected;
		tempDate = tempDate == undefined ? '' :moment(tempDate).format('YYYY-MM-DD');
		console.log('out');
		let queryParams = {
			vehicle_id: this.VehicleSelectedItems[0].id ? this.VehicleSelectedItems[0].id:'',
			row_per_page: this.perPage,
			page_no:this.page,
			date: tempDate,
			export:Export
		}
		this.reportService.getIdleReport(queryParams).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
					if(Export == 'yes'){
						new Angular2Csv(res.data, fileName, options);
					}
					else{
						this.totalItem = res.data.count;
						this.ReportDetails = res.data.rows;
					}
				} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	        	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
  	}

  	changedDate(e:any){
		this.start_date = e.startDate.format('YYYY-MM-DD');
    	this.end_date = e.endDate.format('YYYY-MM-DD');
    	console.log('here');
    	this.getIdleReport(1,'no');
  	}
  	onInspectionSelect(e:any){
    	this.getIdleReport(1,'no');
  	}
	onDateSelect(){
		if(this.VehicleSelectedItems.length){
			this.getIdleReport(1,'no');
		}
	}
	
	getYearSlab() {
		var d = new Date();
		var s = d.getFullYear();
		var e:any = s - 80;
		e = e + ':' + s;
		return e;
	}

}
