import { OnInit, Component } from "@angular/core";
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { PlaceService } from "src/app/services/place/place.service";
import { CustomErrorHandler } from "src/app/errorhandler/custom-errorhandler";
import { CommonService } from "src/app/services/common/common.service";
import { TranslateService } from "@ngx-translate/core";
import { Broadcaster } from "src/app/services/common/broadcaster";
import { FormControl } from "@angular/forms";
import { VehicleService } from "src/app/services/vehicle/vehicle.service";
import { PaymentService } from "src/app/services/payment/payment.service";
declare var Razorpay: any; 

declare let paypal: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  loading: boolean;
  payPalConfig?: IPayPalConfig;
  showSuccess: boolean = false;
  public searchControl: FormControl;
  geofenceRadiusSettings = {};
  subscriptionList: any = [];
  vehicleId: any;
  amount: any;
  vehicle: any;
  perPage: any = 10;
  pageNo: any = 1;
  vehicleDetails: any = [];
  singleDetails: any = [];
  page: number = 2;
  searchDetails: any = {};
  message: string;
  totalItem: any;
  VehicleSelectedItems = [];
  SubscriptionSelectedItems :any = [];
  VehicleSettings = {};
  SubscriptionSettings = {};
  vehicles: any = [];
  subscriptionId: any = '14';
  subscriptions: any = [];
  constructor(private router: Router,
    private http: HttpClient,
    public placeService: PlaceService,
    private customErrorHandler: CustomErrorHandler,
    private commonService: CommonService,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
    public vehicleService: VehicleService,
    private paymentService: PaymentService) { }

  ngOnInit() {
    // this.initConfig();
    // this.translate.get('selectors').subscribe((res) => {
    //   this.geofenceRadiusSettings = {
    //     singleSelection: true,
    //     text: res.SelectGeofenceRadius,
    //     selectAllText: 'Select All',
    //     unSelectAllText: 'UnSelect All',
    //     enableSearchFilter: true,
    //     classes: "singleSelection"
    //   };
    // });

    this.translate.get('selectors').subscribe((res) => {
      this.VehicleSettings = {
        singleSelection: true,
        text: res.SelectVehicle,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "singleSelection",
        disabled: true
      };
      this.SubscriptionSettings = {
        singleSelection: true,
        text: res.SelectSubscriptionType,
        selectAllText: 'Select All', 
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "singleSelection"
      };
    });

    this.getVehicleDetails(this.pageNo);
    this.getSubscriptionList();
  }

  onItemSelectVehicle(item: any) {
    this.vehicleId = item.id;
  }

  payNow(amount) { 
    const options: any = {
      // key: 'rzp_test_PUY69YkU4DGKDR',
      key : 'rzp_live_V7jjYtiB1Orl1r',
      amount: (parseInt(amount))*100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description
      // image: './assets/logo.png', // company logo or product image
      // order_id: '123456', // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {

      options.response = response;
      
      this.payPaypalPayment(response)
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });

  var rzp1 = new Razorpay(options);

 rzp1.open();

} 



  payPaypalPayment(data) {

    // Server API calling
    this.loading = true;
    data.vehicle_id = this.vehicleId;
    data.subscription_id = this.subscriptionId;
    data.id = data.razorpay_payment_id
    data.status = 'COMPLETED'

    this.paymentService.subscriptionPayment(data).subscribe(
      res => {
        this.loading = false;
        this.message = res.message;
        if (res.success == true) {
          alert("Payment done!")
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'subscriptionPayment');
      }
    )
  }

  onVehicleChange(event) {

  }

  getVehicleDetails(currentPage) {
    this.loading = true;
    this.pageNo = currentPage;
    this.page = currentPage;
    let requestParameter = {};
    this.vehicleService.getVehicle(this.pageNo, this.perPage, this.searchDetails).subscribe(
      res => {
        this.loading = false;
        this.message = res.message;
        if (res.success == true) {
          this.totalItem = res.data.count;
          this.vehicleDetails = res.data.rows;
          this.setVehiclecategory();
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getVehicle');
      }
    )
  }

  setVehiclecategory(){
    var id = localStorage.getItem("vehicleId");
    let vehicle = this.vehicleDetails.find(s=>s.id == id);
    this.vehicleId = vehicle.id
    if(vehicle!= undefined){
      this.VehicleSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
      this.VehicleSelectedItems.push(vehicle);
    }
  }

 
  onOpenVehicle(event: any) {
    this.vehicles = this.vehicles.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }

  onItemSelectSubscription(item: any) {
    this.subscriptionId = item.id;
    this.amount = item.cost;
  }
  onOpenSubscription(event: any) {
    this.vehicles = this.vehicles.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }

  onSubscriptionChange(event) {
  }

  getSubscriptionList() {
    // Server API calling
    this.loading = true;
    let request = {
      page: 1,
      per_page: 50,
      search: "",
      export: ""
    };

    this.paymentService.getSubscriptionList(request).subscribe(
      res => {
        this.loading = false;
        this.message = res.message;
        if (res.result == true) {
          this.subscriptionList = res.data
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getSubscriptionList');
      }
    )
  }
}
