import { Component, OnInit } from '@angular/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report/report.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import * as moment from 'moment';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-vehicle-engine-operating-time',
  templateUrl: './vehicle-engine-operating-time.component.html',
  styleUrls: ['./vehicle-engine-operating-time.component.css']
})
export class VehicleEngineOperatingTimeComponent implements OnInit {

  	loading:boolean;
    isDemoTenant:boolean;
    message:string;
    totalItem: any;
    perPage: any = 10;
    pageNo: any = 1;
    vehicleDetails: any = [];
    page: number = 1;
    vehicleList: any;
    VehicleSelectedItems = [];
    VehicleSettings = {};
    vehicleId:any = '';
    selected: any;
    start_date = moment().format('YYYY-MM-DD');
    end_date = moment().format('YYYY-MM-DD');
    alwaysShowCalendars: boolean;
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
   }

   constructor(
        public reportService: ReportService,
        private commonService:CommonService, 
        private customErroHandler:CustomErrorHandler,
        private translate: TranslateService,
        private broadcaster: Broadcaster
    ) { }

    ngOnInit() {
        this.totalItem = 0;
        this.translate.get('selectors').subscribe((res) => {
            this.VehicleSettings = {
               singleSelection: true,
               text: res.SelectVehicle,
               enableSearchFilter: true,
               classes: "singleSelection"
            };
        });
        
        this.getVehicle();
        this.getVehicleDetails(this.pageNo);
    }

    getVehicleDetails(currentPage) {
        this.loading =true;
        this.page = currentPage;
        let queryParams = {
          vehicle_id: this.vehicleId,
          row_per_page: this.perPage,
          page_no:this.page
        }
        this.reportService.getVehicleEngineOperatingReport(queryParams).subscribe(res => {
            this.loading =false; 
            this.message = res.message;
            if (res.success == true) {
                this.totalItem = res.data.count;
                this.vehicleDetails = res.data.rows;
                this.vehicleDetails.forEach(ele => {
                    ele.date_time = moment(ele.date_time).format('D MMM YYYY h:mm a');
                });
            
            } else {
                this.commonService.showErrorMessage(res);
            }
        }, error => {
            this.loading =false; 
            this.customErroHandler.handleHttpError(error, 'getVehicleEngineOperatingReport');
        });
    }

    getVehicle() {
        this.commonService.getAssignVehicle().subscribe(res => {
            if (res.success == true) {
                this.vehicleList = res.data;
            } else {
                this.commonService.showErrorMessage(res);
            }
        }, error => {
            this.customErroHandler.handleHttpError(error, 'vehicle list');
        });
    }
  
    onVehicleSelect(item: any) {
        this.vehicleId = item.id;
        this.getVehicleDetails(1);
    }

    changedDate(item: any){
        this.start_date = item.startDate.format('YYYY-MM-DD');
        this.end_date = item.endDate.format('YYYY-MM-DD')
        if (this.vehicleId) {
          this.getVehicleDetails(1);
        } else {
          this.commonService.showCustomError('Please select vehicle');
        }    
    }
  
    exportReport(){
        this.loading = true;
        let fileName = "Vehicle Stoppage Report";
        if(this.start_date != undefined && this.end_date != undefined){
          fileName = "Vehicle Stoppage Report From "+this.start_date+" To "+this.end_date;
        }
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            useBom: true,
            title: "A/C misuse duration report"
        };
        let queryParams = {
            vehicle_id: this.vehicleId,
            row_per_page: this.perPage,
            page_no:this.page,
            start_date: this.start_date,
            end_date: this.end_date,
            export: 'yes'
        }
    
        this.reportService.getVehicleEngineOperatingReport(queryParams).subscribe(res => {
        this.loading =false; 
            new Angular2Csv(res.data, fileName, options);
        }, error => {
            this.loading =false; 
            this.customErroHandler.handleHttpError(error, 'exportUserReport');
        });
    }  
}
