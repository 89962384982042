import { Component, OnInit } from '@angular/core';
import { PartsAndFuleService } from '../../services/parts-fule/parts-fule.service';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from '../../services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/constants/constants';
import { Config } from '../../config/config';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-part-management',
  templateUrl: './part-management.component.html',
  styleUrls: ['./part-management.component.css']
})
export class PartManagementComponent implements OnInit {

  isDemoTenant: boolean;
	modalRef: BsModalRef;
	loading:Boolean=false;
	fuleList:any = [];
	partsList:any = [];
	tempSearch;
	searchTextValue = '';
	perPage: any = 10;
	pageNo: any = 1;
	page: number = 2;
	totalItem: any;
	noDataMsg = '';
	tabName = 'Parts';
	rejectComment;
	rejectData:any = {};
	serverUrl = Config.AuthenticationURL;
	fuelImage;
	partImage;  
	start_date;
  	end_date;
	selected: any;
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
	ApproveLabel: any;
	CancelLabel: any;
	ApprovedMsg: any;
	constructor(
		public partsAndFuleService: PartsAndFuleService,
		private router: Router,
		private customErrorHandler:CustomErrorHandler,
		public commonService: CommonService,
		private modalService: BsModalService,
		private translate: TranslateService,
    	private broadcaster: Broadcaster
	) { 
		this.translate.get('Approve').subscribe((res) => {
			setTimeout(() => {
				this.ApproveLabel = res;
			});
		});
		this.translate.get('Cancel').subscribe((res) => {
			setTimeout(() => {
				this.CancelLabel = res;
			});
		});
		this.translate.get('ApprovedMsg').subscribe((res) => {
			setTimeout(() => {
				this.ApprovedMsg = res;
			});
		});
		this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('Approve').subscribe((res) => {
				setTimeout(() => {
					this.ApproveLabel = res;
				});
			});
			this.translate.get('Cancel').subscribe((res) => {
				setTimeout(() => {
					this.CancelLabel = res;
				});
			});
			this.translate.get('ApprovedMsg').subscribe((res) => {
				setTimeout(() => {
					this.ApprovedMsg = res;
				});
			});
		});
	}

  	ngOnInit() {
		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
		this.getTabListData(1);
	}

	getTabListData(val) {
			this.getPartsList(val);
	}

	searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getTabListData(1);
		}
		this.tempSearch = this.searchTextValue;
	}

	onSelect(item: any) {
		this.getTabListData(1);
	}

	onDeSelect(item: any) {
		this.getTabListData(1);
	}

	/*
		This function is used for get part listing according to pagination
	*/
	getPartsList(currentPage) {
		this.pageNo = currentPage;
		this.loading = true; 
		this.page = currentPage;
		let queryParams = {
			row_per_page: this.perPage,
      		page_no:this.page,
			search: this.searchTextValue,
			start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY'),
      		end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY')
		}
		this.partsAndFuleService.getPartsDetail(queryParams).subscribe(res => {
			this.loading =false; 
			if (res.success == true) {
				this.totalItem = res.data.count;
				this.partsList = res.data.rows;
				this.noDataMsg = res.message;
			} else {
				this.commonService.showErrorMessage(res);
			}
		}, error => {
			this.loading =false; 
			this.customErrorHandler.handleHttpError(error, 'addInspection');
		});
	}

	openModal(template, data) {
		if (this.tabName == 'Fuel') {
			if (data.image == null) {
				return false;
			}
			this.fuelImage = this.serverUrl + data.image;
		} else {
			this.partImage = this.serverUrl + data.image;
		}
		this.modalRef = this.modalService.show(template);
	}

	closeModal(template) {
		this.modalRef.hide();
		this.fuelImage = '';
		this.partImage = '';
	}

	/*
		This function is used for Approve parts details
	*/
	approveParts(val, status){		
		let rawData = {
			part_id: val.id,
			status: status,
			comment: ''
		}
		swal({
		  type: 'info',
		  text: this.ApprovedMsg,
		  confirmButtonText: this.ApproveLabel,
		  showCancelButton:true,
		  cancelButtonText: this.CancelLabel
		}).then((result) => {
		  if (result.value) {
			this.loading = true;
			this.partsAndFuleService.setPartsStatus(rawData).subscribe(
			   res => {
				  if (res.success == true) {
					 this.commonService.showSuccessMessage(res.message);
					 this.getPartsList(1);
				  } else {
					  this.commonService.showErrorMessage(res);
				  }
				  this.loading =false; 
			   },
			   error => {
				  this.customErrorHandler.handleHttpError(error, 'getVehicle');
				  this.loading =false; 
			   }
			)
		  }
		});   
	}

	rejectPartModal(template, data, status) {		
		this.modalRef = this.modalService.show(template);
		this.rejectData = {
			part_id: data.id,
			status: status
		}
	}

	/*
		This function is used for reject part status
	*/
	onReject() {
		this.loading = true;
		this.rejectData.comment = this.rejectComment
		this.partsAndFuleService.setPartsStatus(this.rejectData).subscribe(
			res => {
				if (res.success == true) {
					this.commonService.showSuccessMessage(res.message);
					this.getPartsList(1);
					this.modalRef.hide();
					this.rejectComment = '';
				} else {
					this.commonService.showErrorMessage(res);
				}
				this.loading =false; 
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getVehicle');
				this.loading =false; 
			}
		)
	}

	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getTabListData(1);
	}
}
