import { Component, OnInit , NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import { Config } from './../../config/config';
import { VehicleService } from '../../services/vehicle/vehicle.service';
import { Constants } from 'src/app/constants/constants';
declare var $: any;
declare var google: any;
import * as moment from 'moment';
declare var map: any;
import { Socket } from 'ngx-socket-io';
declare var OverlappingMarkerSpiderfier: any;
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-live-location-tracking',
  templateUrl: './live-location-tracking.component.html',
  styleUrls: ['./live-location-tracking.component.css']
})
export class LiveLocationTrackingComponent implements OnInit {

	vehiclegroupSelectedItems = [];
	vehiclegroupSettings = {};
	location : any;
	userInfo: any;
	tripId: any;
	zoom: any;
	lat: any;
	lng: any;
	driverLocation: any = [];
	liveTrackingDrivers: any;
	serverUrl: any;
	driverInfo: any;
	liveDriverDetails : any;
	profileUrl: any;
	profileScalable: any;
    driverLocationNotLogin: any = [];
    liveTrackingDriverIDs: any =[];
    groupRequest: any = {}
    groupDetails: any= [];
    message: any;
    searchTracking: any = {};
    runningDriver: number = 0;
    stopDriver: number = 0;
    idleDriver: number = 0;
    inactiveDriver: number = 0;
    noDataDriver: number = 0;
    mapStyles: any;
    liveDriverIds: any;
    allDriverDetails: any = [];
    startCount: number = 0;
    liveTrackingAllData : any = [];
    checkLiveTrackingAllData: boolean = false;
    driverNotAvailableMsg: any = '';
    runningIds: any = [];
    trackingStatusRunning: boolean = true;
    trackingStatusStop: boolean = true;
    trackingStatusIdle: boolean = true;
    trackingStatusInactive: boolean = true;
    trackingStatusNoData: boolean = true;
    pageLoadCount : number = 0;
    liveDriverAPICount: number = 0;
    directionsDisplay: any;
    directionsService: any;
    map: any;
    markers = [];
    newMarkerArray: any = [];
    count: number = 0;
    oms: any;
  	infowindow: any;
    driverCountInfo: number = 1;
    previousRecords: any = [];
    loading: boolean = false;
    baseIconPath =  'assets/image/vehicles-icon/'
    // testCount:any = 1;
    public counter: any;
    public subscription: Subscription;
    perPage: any;
    pageNo: any;
    vehicle_status :any = {};
    vehicleIdArray : any = [];

  	constructor(
        private router: Router,
        public liveTrackingService: LiveTrackingService, 
        private customErrorHandler:CustomErrorHandler, 
        private commonService:CommonService, 
        private vehicleService: VehicleService,
        private socket: Socket,
        private translate: TranslateService,
        private broadcaster: Broadcaster
    ) { 
  		// this.userInfo = localStorage.getItem('userInfo');
        // if(this.userInfo){
        //     this.userInfo = JSON.parse(this.userInfo);
        // }
        this.stopDriver = 0;
        this.inactiveDriver = 0;
        this.runningDriver = 0;
        this.idleDriver = 0;
        this.noDataDriver = 0;
        this.trackingStatusRunning = true;
        this.trackingStatusStop = true;
        this.trackingStatusIdle = true;
        this.trackingStatusInactive = true;
        this.trackingStatusNoData = true;

        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
        this.translate.get('selectors').subscribe((res) => {
            setTimeout(() => {
                this.vehiclegroupSettings = {
                    singleSelection: true,
                    text: res.SelectVehicle,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection"
                };
            });
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
                setTimeout(() => {
                    this.vehiclegroupSettings = {
                        singleSelection: true,
                        text: res.SelectVehicle,
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        enableSearchFilter: true,
                        classes: "singleSelection"
                    };
                });
            });
        }); 
  	}

	ngOnInit() {
        this.mapStyles = Constants.mapStyles;
        this.mapStyles = Constants.mapStyles;
        localStorage.removeItem('playbackVehicleId');
        localStorage.removeItem('playbackDriverId');	
        this.socket.removeListener('vehicle_tracking'); 

  		this.zoom = 14;
	    this.lat = Number(this.userInfo.lat);
	    this.lng = Number(this.userInfo.lng);
        var this1 = this;

     	setTimeout( () => {
            this.directionsService = new google.maps.DirectionsService;
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 14,
                center: { lat: this.lat, lng: this.lng },
                scrollwheel: true,
                styles: this.mapStyles,
                sensor: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                },
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                },
            });
         	this.directionsDisplay.setMap(this.map);
            this.infowindow = new google.maps.InfoWindow();
            
            this.searchTracking.group_by = '';
            this.serverUrl = Config.AuthenticationURL;
            this.oms = new OverlappingMarkerSpiderfier(this.map, {
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: true,
                spiralFootSeparation: 40,
                spiralLengthStart: 15,
                spiralLengthFactor: 10,
                legWeight: 0,
                keepSpiderfied: true,
                nudgeStackedMarkers: false
            });

            // this.previousStorageRecors();
            this.getLiveDriverDetails('initalize');
            this.initializeScoket();
        }, 1000);

        // this.counter = interval(30000);
        // this.subscription = this.counter.subscribe((x) => {
        //     this.getLiveDriverDetails();
        // })
    }


    /**
        This function is used for get live driver details
    **/
    getLiveDriverDetails(status = null){
        this.loading = true;
        this.driverNotAvailableMsg = '';
    	this.liveTrackingService.getLiveTrackingDrivers(this.pageNo ='' ,this.perPage='',this.searchTracking,this.vehicle_status='',this.vehicleIdArray = '').subscribe(res => {
            this.checkLiveTrackingAllData = true;
            this.liveTrackingAllData = res.data;
            this.liveTrackingDrivers = res.data;
            if (res.success == true) {
                if(status == 'initalize'){
                    this.mapInitialize();
                    this.clearMarkers();
                    
                    this.trackingStatusRunning = false;
                    this.trackingStatusStop = false;
                    this.trackingStatusIdle = false;
                    this.trackingStatusInactive = false;
                    this.trackingStatusNoData = false;
                }
                localStorage.setItem('liveDriverDetails', JSON.stringify(res));
                if(res.data.length == 0){
                    this.driverNotAvailableMsg = res.message;
                }           
                this.stopDriver = res.stop;
                this.inactiveDriver = res.inactive;
                this.runningDriver = res.running;
                this.idleDriver = res.ideal;
                this.noDataDriver = res.nodata;

                this.driverCountInfo++;
                
                var bounds = new google.maps.LatLngBounds();   
                for(let i = 0;i < this.liveTrackingAllData.length;i++){
                	let imageUrl = '';
                	if(this.liveTrackingAllData[i].photo)
                	{
                		imageUrl = Config.AuthenticationURL +''+ this.liveTrackingAllData[i].photo;
                	}
                	let mapStatus = 'Stop';
                    if(this.liveTrackingAllData[i]['status'])
                    {
                        mapStatus = this.liveTrackingAllData[i]['status'];
                    }
                	let lastUpdate = this.liveTrackingAllData[i]['created_at'];
                	if(lastUpdate){
                        lastUpdate = moment(lastUpdate).format('D MMM YYYY h:mm a');
                    }
                    let contactNo = this.liveTrackingAllData[i]['mobile'];
                    let vehicleType = this.liveTrackingAllData[i].vehicle_type;
                    let vehicleBattery = this.liveTrackingAllData[i].vehicle_battery;
                    let deviceBattery = this.liveTrackingAllData[i].device_battery;
                    let speed = this.liveTrackingAllData[i].speed;
                    // cons
                    var boundLatLng = '';
                    if(this.liveTrackingAllData[i].lat == '' || this.liveTrackingAllData[i].lat == null || this.liveTrackingAllData[i].lng == '' || this.liveTrackingAllData[i].lng == null){
                        boundLatLng = new google.maps.LatLng(this.lat, this.lng);
                    }else{
                        boundLatLng = new google.maps.LatLng(Number(this.liveTrackingAllData[i].lat), Number(this.liveTrackingAllData[i].lng));
                    }
                    this.addMarker(this.liveTrackingAllData[i].driver_name, Number(this.liveTrackingAllData[i].lat), Number(this.liveTrackingAllData[i].lng), imageUrl, lastUpdate, this.liveTrackingAllData[i]['id'], mapStatus, contactNo, vehicleType, vehicleBattery, deviceBattery, speed, this.liveTrackingAllData[i].registration_no);
                    bounds.extend(boundLatLng);
                }
                if (this.liveTrackingAllData.length > 0) {
                    this.map.fitBounds(bounds);
                }
              } else {
                   this.commonService.showErrorMessage(res);
              }
              this.loading = false;
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
            }
        )
  	}

  	liveTrackingFilter(){
        this.runningIds = [];
        this.driverLocation = [];
        this.searchTracking.status = '';
        this.stopDriver = 0;
        this.inactiveDriver = 0;
        this.runningDriver = 0;
        this.idleDriver = 0;
        this.noDataDriver = 0;
        this.trackingStatusRunning = true;
        this.trackingStatusStop = true;
        this.trackingStatusIdle = true;
        this.trackingStatusInactive = true;
        this.trackingStatusNoData = true;
        this.getLiveDriverDetails();
    }

    liveTrackingByStatus(status){
        this.mapInitialize();
        this.clearMarkers();
        
        this.trackingStatusRunning = false;
        this.trackingStatusStop = false;
        this.trackingStatusIdle = false;
        this.trackingStatusInactive = false;
        this.trackingStatusNoData = false;
        this.searchTracking.status = status;
        
        this.getLiveDriverDetails();
    }

    searchByFilter(){
        this.getLiveDriverDetails();
    }

    // playbackTracking(driverId){
    //     localStorage.setItem('playbackDriverId', driverId);
    //     this.router.navigate(['./play-back']);
    // }

	// onItemSelectvehiclegroup(item: any) {
    //     this.mapInitialize();
    //     this.clearMarkers();
    //     this.searchTracking.vehicle_id = item.id;
    //     this.liveTrackingFilter();
    // }

    addMarker(name, lat, long, profile_pic, last_update, vehicleId, status, contact, vehicleType, vehicleBattery, deviceBattery, speed, registrationNumber) {
        var this1 = this;
       	if (contact == 'undefined') {
	      contact = '';
	    }
        if(lat == '' || lat == null || long == '' || long == null){
            lat = this.lat;
            long = this.lng;
        }
        var vehicleImage = Config.AuthenticationURL + "public/image/tracking/Car/stop.png";
        if(vehicleType == null || vehicleType == undefined){
            vehicleType = 'Car';
        }
        if(vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'){
        }else{
            vehicleType = 'Car';
        }
        if(status == 'Stop' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/stop.png";
            // vehicleImage = this.baseIconPath + vehicleType +"/stop.png";
            
        }
        if(status == 'Running' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/running.png";
            // vehicleImage = this.baseIconPath + vehicleType +"/running.png";
        }
        if(status == 'Idle' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/idle.png";
            // vehicleImage = this.baseIconPath + vehicleType +"/idle.png";
        }
        if(status == 'Inactive' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/inactive.png";
            // vehicleImage = this.baseIconPath + vehicleType +"/inactive.png";
        }
        if(status == 'No Data' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane'))
        {
            vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/nodata.png";
            // vehicleImage = this.baseIconPath + vehicleType +"/nodata.png";
        }
        if (typeof last_update == 'undefined') {
	      last_update = '';
	    }
	    if (typeof status == 'undefined') {
	      status = '...';
	    }
	    if (typeof contact == 'undefined') {
            contact = '...';
        }
        if (typeof speed == 'undefined') {
            speed = '...';
        }         
        if (typeof vehicleBattery == 'undefined') {
            vehicleBattery = '...';
        } else {
            vehicleBattery = vehicleBattery+' V';
        }
        if (typeof deviceBattery == 'undefined') {
            deviceBattery = '...';
        } else {
            deviceBattery = deviceBattery+' V';
        }
        var icon = {
	      	url: vehicleImage,
	      	scaledSize: new google.maps.Size(30, 50), // scaled size
	      	origin: new google.maps.Point(0, 0), // origin
	      	anchor: new google.maps.Point(0, 0), // anchor
	      	shape: { coords: [17, 17, 18], type: 'circle' }
	    };
        var latlngset = new google.maps.LatLng(lat, long);
	    var this1 = this;
	    var marker = new google.maps.Marker({
	      	map: this1.map,
	      	position: latlngset,
	      	icon: icon,
            vehicle_id: vehicleId,
            registration_no : registrationNumber,
            vehicle_type : vehicleType,
            vehicle_battery : vehicleBattery,
            anchor: new google.maps.Point(lat, long)
        });   
        this.newMarkerArray.push(marker);
        if (this.count != 1) {
	      	this.map.setCenter(marker.getPosition())
	    }
        if(profile_pic == null || profile_pic == '')
        {
            profile_pic = 'assets/image/default_employee.png';
            
        }

        let setStatus = status;
        if(status == 'Stop'){
            setStatus = 'Parked';
        }
	    var content = "<div class='infoTooltip' style='min-width: 280px;'><div>";
	    content += "<div style='width: 50%;float:left;font-size:14px;'><span>Status</span><br><p style='font-weight: bold;'>" + setStatus + "</p></div>";
	    content += "<div style='width: 50%;float:left;font-size:14px;'>";
	    if (speed) {
	      	content += "<span>Speed</span><br><p style='font-weight: bold; margin-bottom:0px'>" + speed + " Km/hr </p>";
	    }
        contact += "</div>";
        
        content += "</div><div style='clear:both'></div>";
        content += "<div>";
	    content += "<div style='width: 50%;float:left;font-size:14px;'><span>Vehicle Battery</span><br><p style='font-weight: bold; margin-bottom:0px'>" + vehicleBattery + "</p></div>";
	    content += "<div style='width: 50%;float:left;font-size:14px;'>";
        content += "<span>Device Battery</span><br><p style='font-weight: bold; margin-bottom:0px'>" + deviceBattery + "</p>";
        contact += "</div>";                
        content += "</div><div style='clear:both'></div>";
        content += "<div style='margin-top:5px'>";
	    content += "<div style='width: 50%;;font-size:14px;float:left;'><span>Last Seen</span><br><p style='font-weight: bold;'>" + last_update + "</p></div>";
        content += "<div style='width: 50%;;font-size:14px;float:left;'><span>Registration No.</span><br><p style='font-weight: bold;'>" + registrationNumber + "</p></div>";
	    content += "</div><div style='clear:both'></div>";
        if(status != 'Inactive'){
	        content += "<br><div style='width: 100%;text-align:center;'><a id='playbackTracking' class='btn btn-default'>Playback</a></div>";
        }
	    content += "</div>";
        
        var infowindow = this.infowindow;
	    var router = this.router;
        
	    google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
	      	return function () {
	        	infowindow.close();
	       		infowindow.setContent(content);
	       		infowindow.open(this1.map, marker);
                setTimeout(function(){
                    document.getElementById("playbackTracking").addEventListener("click", function () {
    	          		localStorage.setItem('playbackVehicleId', vehicleId);
                        router.navigate(['./play-back']);
                    });
                },200)
	      	};
	    })(marker, content, infowindow));

        this.oms.addMarker(marker);
        
    }
    
  	initializeScoket(){
        let vehicleId = localStorage.getItem('defaultVehicleId');
        var userData = {user_id :'01', vehicle_id : '',company_id: this.userInfo.company_id};
        this.socket.emit('ready for data', userData);
        this.socketInitialize();
    }  
    socketInitialize() {
        var this1 = this;
        this.socket.on('update', function (data) {
            console.log(data, 'socket')
            this1.changeMarkerPosition(data);
        });
    }

    changeMarkerPosition(data){
        this.removeSpecificMarker(data);
    }

    removeSpecificMarker(data) {
        let vehicleId = data.vehicle_id;
        let driverName = data.driver_name;
        let lat = data.lat;
        let lng = data.lng;
        let mapStatus = data.mode;
        let contactNo = data.driver_mobile;
        let lastUpdate = data.date_time;
        let vehicleType = data.vehicle_type;
        let vehicleBattery = data.vehicle_battery;
        let deviceBattery = data.device_battery;
        let registrationNo = data.registration_number;
        let speed = data.speed;
        if(lastUpdate){
            lastUpdate = moment(lastUpdate).format('D MMM YYYY h:mm a');
        }
        let imageUrl = data.driver_image; 
        let findVehicle = true;
        let markerCount = 0;
        for (let i = 0; i < this.newMarkerArray.length; i++) {
            markerCount++;
            if ((vehicleId == this.newMarkerArray[i].vehicle_id) && (lat != this.newMarkerArray[i].position.lng())) {
                this.newMarkerArray[i].setMap(null);
                this.newMarkerArray.splice(i,1);
                
                this.addMarker(driverName, lat, lng, imageUrl, lastUpdate, vehicleId, mapStatus, contactNo, vehicleType, vehicleBattery, deviceBattery, speed, registrationNo);
                let status = $('#live-driver-'+ vehicleId).text();
                if (status != mapStatus && vehicleType != null) {
                    this.setRealTimeLocation(data);
                }
               break;
            }
            if(markerCount == this.newMarkerArray.length){
                this.addMarker(driverName, lat, lng, imageUrl, lastUpdate, vehicleId, mapStatus, contactNo, vehicleType, vehicleBattery, deviceBattery, speed, registrationNo);
                let status = $('#live-driver-'+ vehicleId).text();
                if (status != mapStatus && vehicleType != null) {
                    this.setRealTimeLocation(data);
                }
            }

        }
    }

    clearMarkers() {
        for (let i = 0; i < this.newMarkerArray.length; i++) {
            this.newMarkerArray[i].setMap(null);
            this.newMarkerArray.splice(i,1);
        }
        this.newMarkerArray = [];
    }

    mapInitialize(){
        // var this = this;
        this.lat = Number(this.userInfo.lat);
        this.lng = Number(this.userInfo.lng);
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 14,
            center: { lat: this.lat, lng: this.lng },
            scrollwheel: true,
            styles: this.mapStyles,
            sensor: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                position: google.maps.ControlPosition.BOTTOM_RIGHT
            },
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_RIGHT
            },
        });
        this.directionsDisplay.setMap(this.map);
        this.oms = new OverlappingMarkerSpiderfier(this.map, {
            markersWontMove: true,
            markersWontHide: true,
            basicFormatEvents: true,
            spiralFootSeparation: 40,
            spiralLengthStart: 15,
            spiralLengthFactor: 10,
            legWeight: 0,
            keepSpiderfied: true,
            nudgeStackedMarkers: false
        });
    }

    
    toggleBars(){
        $('.content_wrap').toggleClass('hide_bars');
    }

    previousStorageRecors(){
        let records = localStorage.getItem('liveDriverDetails');
        this.previousRecords = JSON.parse(records);
        if(this.previousRecords != undefined && this.previousRecords){
            
            this.liveTrackingAllData = this.previousRecords.data;
            this.liveTrackingDrivers = this.previousRecords.data;
            if(this.driverCountInfo == 1){
                
                this.groupDetails = this.previousRecords.data;
                this.groupDetails.unshift({
                    'id': '',
                    'itemName': 'All'
                });
            }
            this.stopDriver = this.previousRecords.stop;
            this.inactiveDriver = this.previousRecords.inactive;
            this.runningDriver = this.previousRecords.running;
            this.idleDriver = this.previousRecords.ideal;
            this.noDataDriver = this.previousRecords.nodata;

            this.driverCountInfo++;
            for(let i = 0;i < this.liveTrackingAllData.length;i++){
                if(this.liveTrackingAllData[i].id){
                    let imageUrl = '';
                    if(this.liveTrackingAllData[i].photo)
                    {
                        imageUrl = Config.AuthenticationURL +''+ this.liveTrackingAllData[i].photo;
                    }
                    let mapStatus = 'Stop';
                    if(this.liveTrackingAllData[i]['status'])
                    {
                        mapStatus = this.liveTrackingAllData[i]['status'];
                    }
                    let lastUpdate = this.liveTrackingAllData[i]['created_at'];
                    if(lastUpdate){
                        lastUpdate = moment(lastUpdate).format('D MMM YYYY h:mm a');
                    }
                    let contactNo = this.liveTrackingAllData[i]['mobile'];
                    let vehicleType = this.liveTrackingAllData[i].vehicle_type;
                    let vehicleBattery = this.liveTrackingAllData[i].vehicle_battery;
                    let deviceBattery = this.liveTrackingAllData[i].device_battery;
                    let speed = this.liveTrackingAllData[i].speed;
                    let registrationNo = this.liveTrackingAllData[i].registration_no;
                    this.addMarker(this.liveTrackingAllData[i].driver_name, Number(this.liveTrackingAllData[i].lat), Number(this.liveTrackingAllData[i].lng), imageUrl, lastUpdate, this.liveTrackingAllData[i]['id'], mapStatus, contactNo, vehicleType, vehicleBattery, deviceBattery, speed, registrationNo);
                }
            }
        }
    }

    setRealTimeLocation(data:any) {
        this.liveTrackingAllData.forEach((ele, idx) => {
            if (ele.id == data.vehicle_id) {
                ele.status = data.mode;
            }
        });
        this.runningDriver =  this.liveTrackingAllData.filter((item) => item.status == 'Running').length;
        this.stopDriver =  this.liveTrackingAllData.filter((item) => item.status == 'Stop').length;
        this.idleDriver =  this.liveTrackingAllData.filter((item) => item.status == 'Idle').length;
        this.noDataDriver =  this.liveTrackingAllData.filter((item) => item.status == 'No Data').length;
    } 

    ngOnDestroy() {
        this.socket.removeListener('update');
        localStorage.removeItem('liveDriverDetails');     
    }
}



