import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

@Injectable()
export class DashboardService {
    constructor(private _httpHelper: HttpHelper) {
    }
    getDashboardData() {
        try {           
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-dashboard-detail')
        }
        catch (e) {
        }
    }
}
