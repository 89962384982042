import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { PlaceService } from '../../../services/place/place.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.css']
})
export class CreateStudentComponent implements OnInit {
	loading:boolean;
	perPage: any = 10;
	pageNo: any = 1;
	placeDetails: any = [];
	page;
	message:string;
	totalItem: any;
  studentDetails: any= {};
	searchDetails: any = {};
  classSettings;
  SelectedClass = [
  ];
  ClassList = [
    {id:'nursery',itemName:'nursery'},
    {id:'L.K.G.',itemName:'L.K.G.'},
    {id:'U.K.G.',itemName:'U.K.G.'},
    {id:'1st',itemName:'1st'},
    {id:'2nd',itemName:'2nd'},
    {id:'3rd',itemName:'3rd'},
    {id:'4th',itemName:'4th'},
    {id:'5th',itemName:'5th'},
    {id:'6th',itemName:'6th'},
    {id:'7th',itemName:'7th'},
    {id:'8th',itemName:'8th'},
    {id:'9th',itemName:'9th'},
    {id:'10th',itemName:'10th'},
    {id:'11th',itemName:'11th'},
    {id:'12th',itemName:'12th'}
  ];
  pickupSettings;
  Selectedpickup = [
  ];
  pickupList = [
  ];
  
  dropSettings;
  Selecteddrop = [
  ];
  dropList = [
  ]
  // busSettings;
  // Selectedbus;
  // busList = [
  //   {id:'1',itemName:'1'},
  //   {id:'2',itemName:'2'}
  // ];
  student_Photo:string;
  studentPhoto_url;
  studentID:string;
  imageBase:string;
  picktouch:boolean = false;
  droptouch:boolean = false;
  constructor(
		public placeService: PlaceService,
    private router: Router, 
		private customErrorHandler: CustomErrorHandler,
		private commonService: CommonService,
    public userService: UserService, 
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.imageBase = Config.AuthenticationURL;
    this.studentID = localStorage.getItem('editStudentId');
    this.commonService.stickyHeader();
    this.classSettings = {
      singleSelection: true,
      text: 'Select Class',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.pickupSettings = {
      singleSelection: true,
      text: 'Select Pickup Point',
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    
    this.dropSettings = {
      singleSelection: true,
      text: 'Select Drop Point',
      enableSearchFilter: true,
      classes: "singleSelection"
    }
    // this.busSettings = {
    //   singleSelection: true,
    //   text: 'Select Bus',
    //   enableSearchFilter: true,
    //   classes: "singleSelection"
    // };
    this.getPlaceDetails();
  }
  ngOnDestroy(){
    localStorage.removeItem('editStudentId');
  }
  getStudentById(){
    this.loading =true; 
    let data = {
      id:this.studentID
    }
    this.userService.getStudentById(data).subscribe(
      res => {
        this.loading =false; 
           if (res.success == true) {
            this.studentDetails = res.data;
            this.pickupList.forEach(element => {
              if(element.id == res.data.pickup_point){
                this.Selectedpickup.push({id:element.id,itemName:element.itemName});
              }
              if(element.id == res.data.drop_point){
                this.Selecteddrop.push({id:element.id,itemName:element.itemName});
              }
            });
            this.ClassList.forEach(element => {
              if(element.id == res.data.standard){
                this.SelectedClass.push({id:element.id,itemName:element.itemName});
              }
            });
            this.student_Photo = this.imageBase + this.studentDetails.image;

          } else {
            this.commonService.showErrorMessage(res);
          }
      },
      error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'updatestudent');
      }
  )
  }
  addStudent(){

    this.loading =true; 
    let data = this.studentDetails;
    data.pickup_point = this.Selectedpickup[0].id;
    data.drop_point = this.Selecteddrop[0].id;
    if(this.studentPhoto_url){
      data.photo = this.studentPhoto_url;
    }
    if(this.studentDetails.image){
      data.photo = this.studentDetails.image;
    }
    if(this.studentID){
      data.id = this.studentID;
    }
    data.standard = this.SelectedClass[0].id;
    // {
    //   student_name:"Ankur Tiwari",
    //   parent_name:"Prakash Tiwari",
    //   mobile:"9826423503",
    //   address:"658/18 meghdoot nagar, indore",
    //   scholar_nubmer:"0821CS101017",
    //   pickup_point:"d4e97fc0-fbf2-47b9-9c3e-1ae0590ebe64",
    //   drop_point:"aa16a03e-2de4-401f-86cb-0a10d7e57ce4"
    //   }
    this.userService.addStudent(data).subscribe(
      res => {
        this.loading =false; 
           if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
             this.router.navigate(['./student-management/student-list']);
          } else {
            this.commonService.showErrorMessage(res);
          }
      },
      error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'updatestudent');
      }
  )
  }
	numberValidation(event: any) {

        const pattern = /[0-9\+\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        //    console.log(event.keyCode);
        if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
    }
    getPlaceDetails() {
      this.loading =true;
      this.pageNo = '';
      this.page = '';
      let requestParameter = {}
      this.placeService.getPlace(this.pageNo, this.perPage, this.searchDetails).subscribe(
        res => {
          this.loading =false; 
          this.message = res.message; 
          if (res.success == true) {
            if(this.studentID){
              this.getStudentById();
            }
            this.totalItem = res.data.count;
            this.pickupList = res.data.rows;
            this.dropList = res.data.rows;
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.loading =false; 
          this.customErrorHandler.handleHttpError(error, 'getPlace');
        }
      )
    }
    
  	changeListener($event): void {
      let file:File = $event.target.files[0];
      let myReader:FileReader = new FileReader();
      let imgName = $event.target.files[0].name;
      let imageextensionarray=  imgName.split('.').pop();
      if (imageextensionarray === 'jpg'|| imageextensionarray=== 'png' || imageextensionarray==='jpeg'|| imageextensionarray=== 'bmp') {
      myReader.onloadend = (e) => {
          // this.driverDetails.photo = myReader.result;
              this.studentPhoto_url = myReader.result;
              $('.driver-img').attr('src', this.studentPhoto_url);
      }
    }
    
    else {
      $event.target.files.name = '';
      this.studentPhoto_url = '';
    }
    
      myReader.readAsDataURL(file);
  }
  onpickSelect(e){
    this.picktouch = true;
  }
  OnpickDeSelect(e){
    this.picktouch = true;
  }
  ondropSelect(e){
    this.droptouch = true;
  }
  OndropDeSelect(e){
    this.droptouch = true;
  }
}
