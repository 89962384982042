import { HttpHelper } from './../../helpers/http.helper';
import { Injectable } from "@angular/core";
import { Config } from 'src/app/config/config';

@Injectable()
export class RoleService {
    description: any;
    constructor(private httpHelper:HttpHelper){
    }
    getRolelist(pageNo,rowsPerPage,searchDetails) {
        try {      
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&menuItems='+searchDetails.menuItems+'&search_by='+searchDetails.search_by;      
             return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-user-role' + requestParameter)
         }
         catch (e) {
         }
     }
   
     getRolelistById(RolelistById) {
        try {
             var request = {
               "role_type_id": RolelistById
             };
             return this.httpHelper.post(Config.AuthenticationBaseURL + 'get-single-role', request)
         }
         catch (e) {
         }
     }
   
     deleteRole(role_id: string) {
        try {
             var request = {
               "role_id": role_id
             };
             return this.httpHelper.delete(Config.AuthenticationBaseURL + 'delete-role', request)
         }
         catch (e) {
              
         }
     }
   
   createRole(request ) {
        try {           
             return this.httpHelper.post(Config.AuthenticationBaseURL + 'create-role', request)
         }
         catch (e) {
         }
     }
    
     editRole(request) {
        try {            
             return this.httpHelper.put(Config.AuthenticationBaseURL + 'update-role', request)
         }
         catch (e) {
         }
     }

     getMenuModules() {
        try {
             return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-all-module')
         }
         catch (e) {
         }
     }

     getRole(pageNo,  searchDetails){
        try {
            let requestParameter = '?page_no='+pageNo+'&company_id='+searchDetails.company_id+'&search_by='+searchDetails.search_by;
            return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-user-role'+requestParameter)
        }
        catch (e) {
        }
    }
    getSingleRole(roleId){
        try {
            let requestParameter = '?role_id='+roleId;
            return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-single-role'+requestParameter)
        }
        catch (e) {
        }
    }     
}