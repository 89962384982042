import { CommonService } from './../../services/common/common.service';
import { LoginService } from './../../services/login/login.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import {Observable} from 'rxjs/Observable';
import { RouterModule, Router } from '@angular/router';
import { Config } from 'src/app/config/config';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';
import { NotificationService } from 'src/app/services/notification/notification.service';
// import TimeAgo from 'javascript-time-ago'
// Load locale-specific relative date/time formatting rules.
// import en from 'javascript-time-ago/locale/en'
import * as moment from 'moment';
// Add locale-specific relative date/time formatting rules.
// TimeAgo.locale(en)
import { Socket } from 'ngx-socket-io';

// Create relative date/time formatter.
// const timeAgo = new TimeAgo('en-US')
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public isDemoTenant:boolean;
    imageBase:string;
    notifications:any =[];   
    userInfo:any;
    clicker:number = 0;
	message:string;
    usersChild2:any;
    usersChild: any;
    usersChild3: any;
    lat:any=22.763905;
    lng:any=75.882733;
    allModule = [];
    allowModule = [];
    dashboardModule: boolean = false;
    liveTrackingModule: boolean = false;
    geoLiveTrackingModule: boolean = false;
    realTimeLocation: boolean = false;
    playBackModule: boolean = false;
    groupModule: boolean = false;
    vehicleListModule: boolean = false;
    vehicleAssignmentModule: boolean = false;
    placesModule: boolean = false;
    tripListModule: boolean = false;
    checklistModule: boolean = false;
    driverModule: boolean = false;
    employeeModule: boolean = false;
    roleModule: boolean = false;
    workOrderModule: boolean = false;
    inspectionListModule: boolean = false;
    inspectionAssignmentModule: boolean = false;
    serviceListModule: boolean = false;
    partsFuelModule: boolean = false;
    partsModule: boolean = false;
    FuelModule: boolean = false;
    reportModule: boolean = false;
    leaveModule: boolean = false;
    expenseModule: boolean = false;
    groupGeofenceModule: boolean = false;
    nearByVehicle: boolean = false;    
    manageAlertModule: boolean = false;
    // createstudentModule: boolean = false;       
    // assignedstudentModule: boolean = false;
    companySettingModule: boolean = false;
    unreadNotificationCount:any = 0;
    languageList: any;
    selectedLanguage = [];
    languageSettings = {};
    language = '';
    isParentApp = false;
    isGps = false;
    modalRef: BsModalRef;
    settingData:any = {};
    socketConnection: boolean = true;
    placesModuleNew: boolean = false;
    contractorModule: boolean = false;

    constructor(
        private router: Router,
        private loginService: LoginService, 
        private commonService:CommonService, 
        private customErrorHandler:CustomErrorHandler,
        private notificationService:NotificationService,
        private socket: Socket,
        private translate: TranslateService,
        private broadcaster: Broadcaster,
        private modalService: BsModalService
    ) { }

    ngOnInit() {
        if (localStorage.getItem('language') == null) {
            localStorage.setItem('language', 'en');
        }
        
        this.language = localStorage.getItem('language');
        this.translate.setDefaultLang(this.language);
        this.languageList = [{
            itemName: 'English',
            id: 'en',
            image: './assets/image/flag/us.jpg'
        }, {
            itemName: 'Hindi',
            id: 'hi',
            image: './assets/image/flag/india.jpg'
        }]
        this.languageSettings = {
            singleSelection: true,
            text: "Select Language",
            classes: "singleSelection"
        };

        this.selectedLanguage = [];					
        let langTemp = this.languageList.find(s => s.id == this.language);
        if (typeof langTemp != 'undefined') {
            this.selectedLanguage.push(langTemp);
        }
        
        Observable.fromEvent(document, 'click').delay(100).do(() => {}).subscribe((event) => {
            if(this.clicker == 0){
                let closeElems = document.getElementsByClassName('dropMenu_wrap');
                for (var i = 0; i < closeElems.length; i++) {
                    closeElems[i].classList.add('hideDropDown');
                    closeElems[i].classList.remove('displayDropDown');
                }​
            }              
        });
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log(this.userInfo);
        this.isGps = JSON.parse(localStorage.getItem('isGps'));
        // this.isParentApp = this.userInfo.is_parent == 1 ? true : false;
        this.isParentApp = false;
        this.imageBase= Config.FILEURL;
        this.allModule = this.userInfo.all_modules;
        this.allowModule = JSON.parse(this.userInfo.access_module.allow_modules);
        
        this.getNotifications();
        this.setMenu();

        setTimeout(() => {
            this.initializeScoket();
        },1500);
       
        $('body').on("click","#m_aside_header_topbar_mobile_toggle",function() {
            $("body").toggleClass("m-topbar--on")
        });
        $('body').on("click","#m_aside_header_menu_mobile_toggle",function() {
            $("#m_header_menu").toggleClass("m-aside-header-menu-mobile--on")
        });
        $('body').on("click","#m_aside_header_menu_mobile_close_btn,.has_no_child",function() {
            $("#m_header_menu").removeClass("m-aside-header-menu-mobile--on")
        });
        
        
    	this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    }

    toggleMenu(e:Element){
        this.clicker = 1;
        let targetE:string = e.getAttribute("data-target");
        if(targetE ==='parentMenu'){
            if(e.classList.contains('hideDropDown')){
                e.classList.remove('hideDropDown');
                e.classList.add('displayDropDown');
            }
            else{
                e.classList.add('hideDropDown');
                e.classList.remove('displayDropDown');
            }
        }
        else{
            let elementT = document.getElementById(targetE),
                closeElems = document.getElementsByClassName('dropMenu_wrap');
            for (var i = 0; i < closeElems.length; i++) {
                closeElems[i].classList.add('hideDropDown');
                closeElems[i].classList.remove('displayDropDown');
            }​
            if(elementT.classList.contains('hideDropDown')){
                elementT.classList.remove('hideDropDown');
                elementT.classList.add('displayDropDown');
            }
            else{
                elementT.classList.add('hideDropDown');
                elementT.classList.remove('displayDropDown');
            }
        }
        setTimeout(()=>{
            this.clicker = 0;
       }, 500);
    }
    closeParent(e:Element){
            e.classList.add('hideDropDown');
            e.classList.remove('displayDropDown');
    }
    
    logout() {
        this.commonService.logout();       
    }

    editProfile(employeeId){
        localStorage.setItem('editEmployeeId', employeeId);
        this.router.navigate(['./user-management/employee']);
    }

    getNotifications(readMessage = null){
        let request = { read: false}
        if(readMessage != null){
            request = { read: true}
        }
        this.notificationService.getNotifications(request).subscribe(
            res => {
                this.message = res.message;
                if (res.success == true) {
                       this.notifications = res.data;
                       this.notifications.forEach(item => {
                        item.created_at = moment(item.created_at).fromNow()
                        // item.created_at = timeAgo.format(new Date(item.created_at));
                    });
                    this.unreadNotificationCount = res.unread_count;
                    this.unreadNotificationCount > 99 ? this.unreadNotificationCount = '99+': this.unreadNotificationCount;
                }
                else {
                    this.notifications = [];
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'logout');
            });
    }

    setMenu() {
        console.log(this.allowModule)
        this.allowModule.forEach((obj) => {
            if(obj == 1) {
                this.dashboardModule = true;
            }
            if(obj == 3) {
                this.liveTrackingModule = true;             
            }
            if (obj == 28) {
                this.realTimeLocation = true;
            }
            if(obj == 4) {
                this.playBackModule = true;                    
            }
            if(obj == 6) {
                this.groupModule = true;                    
            }
            if(obj == 7) {
                this.vehicleListModule = true;                    
            }
            if(obj == 14) {
                this.vehicleAssignmentModule = true;                    
            }
            if(obj == 8) {
                this.placesModule = true;                    
            }
            if(obj == 15) {
                this.tripListModule = true;                    
            }
            if(obj == 16) {
                this.checklistModule = true;                    
            }
            if(obj == 11) {
                this.driverModule = true;                    
            }
            if(obj == 12) {
                this.employeeModule = true;                    
            }
            if(obj == 17) {
                this.roleModule = true;                    
            }
            if(obj == 13) {
                this.workOrderModule = true;                    
            }
            if(obj == 19) {
                this.inspectionListModule = true;                    
            }
            if(obj == 20) {
                this.inspectionAssignmentModule = true;                    
            }
            if(obj == 22) {
                this.serviceListModule = true;                    
            }            
            if(obj == 24) {
                this.reportModule = true;                    
            }
            if(obj == 26) {
                this.leaveModule = true;                    
            }
            if(obj == 27) {
                this.expenseModule = true;                    
            }
            if(obj == 23) {
                this.FuelModule = true;                    
            }
            if(obj == 29) {
                this.partsModule = true;                    
            }
            if(obj == 30) {
                this.companySettingModule = true;                    
            } 
            if(obj == 31) {
                this.manageAlertModule = true;                    
            }
            if(obj == 33) {
                this.geoLiveTrackingModule = true;             
            }
            if(obj == 34) {
                this.contractorModule = true;     
                localStorage.setItem('contractorModule', '1');        
            }else{
                localStorage.setItem('contractorModule', '0');   
            }
            
        });
                this.placesModuleNew = true;
    }

    initializeScoket(){            
        this.socket.on('connected', (data) => {
            console.log('socket connect');
            if(this.socketConnection == false){
                let routeString = this.router.url;
                
                // if(routeString.includes("live-location-tracking")){
                //     this.router.navigate(['./report-management/report-dashboard']);
                //     setTimeout(()=>{
                //         this.router.navigate(['live-location-tracking']);
                //     },200);
                // }
                // if(routeString.includes("live-tracking")){
                //     this.router.navigate(['./report-management/report-dashboard']);
                //     setTimeout(()=>{
                //         let dashboardUrl:any = localStorage.getItem('dashboardUrl');
                //         this.router.navigate([dashboardUrl]);
                //         //this.router.navigate(['live-location-tracking']);
                //     },200);
                // }
                // console.log(this.router.url);
                // window.location.reload();
            }
            this.socketConnection = true;
        });
        this.socket.on('disconnect', ()=> {
            //console.log('socket disconnect');
            this.socketConnection = false;
        });
        let userData = {user_id: this.userInfo.id, company_id: this.userInfo.company_id};
        this.socket.emit('ready for notification', userData);
        this.socketInitialize();
    }

    socketInitialize() {
        var this1 = this;
        this.socket.on('get notification', function (data) {
            this1.setNewNotification(data);
        });
    }
    setNewNotification(data){
       // console.log('set notification')
        let notification = {}
        // data.created_at = timeAgo.format(new Date(data.created_at));
        this.notifications.unshift(data);
        this.unreadNotificationCount++;
    }

    switchLanguage(language, type) {
        this.selectedLanguage = [];					
        let langTemp = this.languageList.find(s => s.id == language.id);
        if (typeof langTemp != 'undefined') {
            this.selectedLanguage.push(langTemp);
        }
        localStorage.setItem('language', language.id);
        this.broadcaster.broadcast('langEvent', 'languageChange');
        if (type == 'select') {
            window.location.reload();
        }
        this.translate.use(language.id);
    }

    openModal(template) {
        this.getSettings();
        this.modalRef = this.modalService.show(template);
    }

    closeModal(template) {
        this.modalRef.hide();
    }

    getSettings() {
        this.commonService.getCompanySetting().subscribe(res => {
            if (res.success) {
                this.settingData = res.data;
            }
        }, err => {
            this.customErrorHandler.handleHttpError(err, 'getSettings');
        })
    }
    saveSettings() {
        let reqData = {
            is_hard_braking: this.settingData.is_hard_braking ? 1 : 0,
            is_aggressive_acceleration: this.settingData.is_aggressive_acceleration ? 1 : 0,
            is_excessive_speed: this.settingData.is_excessive_speed ? 1 : 0,
            is_stoppage: this.settingData.is_stoppage ? 1 : 0,
        }
        this.commonService.addCompanySetting(reqData).subscribe(res => {
            if (res.success) {
                this.modalRef.hide();
                this.commonService.showSuccessMessage(res.message);
                this.getSettings();
            }
        }, err => {
            this.customErrorHandler.handleHttpError(err, 'getSettings');
        });
    }
}
