import { Constants } from 'src/app/constants/constants';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { PlaceService } from '../../../services/place/place.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
	selector: 'app-places-list',
	templateUrl: './places-list.component.html',
	styleUrls: ['./places-list.component.css']
})
export class PlacesListComponent implements OnInit {
	loading:boolean;
	public isDemoTenant:boolean;
	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	placeDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	deleteDetails: any = {};
	searchTempVal: any;
	deleteMsg: any;
    deleteLabel: any;
	cancelLabel: any;
	editLabel:string;

	constructor(private router: Router,
		private http: HttpClient,
		public placeService: PlaceService,
		private customErrorHandler: CustomErrorHandler,
		private commonService: CommonService,
		private translate: TranslateService,
    	private broadcaster: Broadcaster
	) { 
		this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
                this.deleteMsg = res.DeleteAlert;
                this.deleteLabel = res.Delete;
                this.cancelLabel = res.Cancel;
				this.editLabel = res.Edit;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
                setTimeout(() => {
                    this.deleteMsg = res.DeleteAlert;
                    this.deleteLabel = res.Delete;
                    this.cancelLabel = res.Cancel;
					this.editLabel = res.Edit;
                });
            });
		});
	}

	ngOnInit() {
		this.searchTempVal = '';
		this.loading = false;
		this.totalItem = 0;
		this.searchDetails.search_by = '';
		this.getPlaceDetails(this.pageNo);
		localStorage.removeItem('editPlaceId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
	}

	getPlaceDetails(currentPage) {
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {}
		this.placeService.getPlace(this.pageNo, this.perPage, this.searchDetails).subscribe(
			res => {
				this.loading =false; 
				this.message = res.message; 
				if (res.success == true) {
					this.totalItem = res.data.count;
					this.placeDetails = res.data.rows;
				} else {
					this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getPlace');
			}
		)
	}

	searchByFilter() {
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getPlaceDetails(this.pageNo);
		}
	}

	editPlace(placeId) {
		localStorage.setItem('editPlaceId', placeId);
		this.router.navigate(['./places/place']);
	}

	deletePlace(placeId) {
		swal({
			type: 'error',
			text: this.deleteMsg,
			confirmButtonText: this.deleteLabel,
			showCancelButton:true,
			cancelButtonText: this.cancelLabel
		}).then((result) => {
			if (result.value) {
				this.deleteDetails.place_id = placeId;
				this.placeService.deletePlace(this.deleteDetails).subscribe(
					res => {
						if (res.success == true) {
							this.pageNo = 1;
							this.commonService.showSuccessMessage(res.message);
							this.getPlaceDetails(this.pageNo);
						} else {
							this.commonService.showErrorMessage(res);
						}
					},
					error => {
						this.customErrorHandler.handleHttpError(error, 'deleteGroup');
					}
				)
			}
		}
		);
	}

	addPlace() {
		this.router.navigate(['./places/place']);
	}
}
