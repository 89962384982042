import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class UserService {
 

    constructor(private _httpHelper: HttpHelper) {
    }

    addDriver(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-driver', request)
        }
        catch (e) {
        }
    }

    updateDriver(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-driver', request)
        }
        catch (e) {
        }
    }

    getDriver(pageNo, rowsPerPage,searchDetails = null){
        try {
            let searchParameter = '';
            if(searchDetails){

                if(typeof searchDetails.status != 'undefined'){
                    searchParameter += '&status='+searchDetails.status;
                }

                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search_by='+searchDetails.search_by;
                }
                if(typeof searchDetails.company_id != 'undefined'){
                    searchParameter += '&company_id='+searchDetails.company_id;
                }
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver'+requestParameter)
        }
        catch (e) {
        }
    }

    getDriverList(){
        try {   
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
           
            let requestParameter = '?company_id='+userInfo.company_id;         
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'all-driver-list'+requestParameter)
        }
        catch (e) {
        }
    }
    addStudent(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'add-student', request)
        }
        catch (e) {
        }

    }
    getStudentById(requestParameter){
        try {          
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-student-by-id',requestParameter)
        }
        catch (e) {
        }
    }
    getStudentList(requestParameter){
        try {          
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-student',requestParameter)
        }
        catch (e) {
        }
    }
    getConductorList(){
        try {   
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
           
            let requestParameter = '?company_id='+userInfo.company_id;         
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-contductor-list'+requestParameter)
        }
        catch (e) {
        }
    }
    getUserList(){
        try {   
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));           
            let requestParameter = '?company_id='+userInfo.company_id;         
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'all-user-list'+requestParameter)
        }
        catch (e) {
        }
    }

    getUserRole(companyId){
        try {
           
            let requestParameter = '?company_id='+companyId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'user-role'+requestParameter)
        }
        catch (e) {
        }
    }

    getTimeZone() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'timezone')
        }
        catch (e) {
        }
    }

    addEmployee(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-user', request)
        }
        catch (e) {
        }
    }

    updateEmployee(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-user', request)
        }
        catch (e) {
        }
    }

    getEmployee(pageNo, rowsPerPage, searchDetails){
        try {
            console.log(searchDetails.company_id)
            let parameter = '';
            if(typeof searchDetails.company_id != 'undefined'){
                parameter += '&company_id='+searchDetails.company_id;
            }
            if(typeof searchDetails.status != 'undefined'){
                parameter += '&status='+searchDetails.status;
            }
            if(typeof searchDetails.search_by != 'undefined'){
                parameter += '&search_by='+searchDetails.search_by;
            }
          
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+parameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-user'+requestParameter)
        }
        catch (e) {
        }
    }

    getSingleEmployee(employeeId){
        try {
            let requestParameter = '?id='+employeeId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-user'+requestParameter)
        }
        catch (e) {
        }
    }

    getSingleDriver(driverId){
        try {
            let requestParameter = '?id='+driverId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-driver'+requestParameter)
        }
        catch (e) {
        }
    }

    getFreeDriver(pageNo, rowsPerPage, searchDetails){
        try {
            console.log(searchDetails.company_id)
            let parameter = '';
            if(typeof searchDetails.company_id != 'undefined'){
                parameter += '&company_id='+searchDetails.company_id;
            }
            if(typeof searchDetails.trip_id != 'undefined'){
                parameter += '&trip_id='+searchDetails.trip_id;
            }
            if(typeof searchDetails.assigned_by != 'undefined'){
                parameter += '&assigned_by='+searchDetails.assigned_by;
            }
            
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+parameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'free-driver'+requestParameter)
        }
        catch (e) {
        }
    }

    deleteEmployee(request){
         try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-user', request)
        }
        catch (e) {
        }
    }

    deleteDriver(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-driver', request)
        }
        catch (e) {
        }
    }

    getVehicleList(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-list')
        }
        catch (e) {
        }
    }
}
