import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { ReportService } from 'src/app/services/report/report.service';
import * as moment from 'moment';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.css']
})
export class VehicleSummaryComponent implements OnInit {
  loading:boolean;
	public isDemoTenant:boolean;
	message:string;
  totalItem: any; 
	perPage: any = 10;
	pageNo: any = 1;
	vehicleDetails: any = [];
  page: number = 1;
  //vehicleList: any;
  VehicleSelectedItems = [];
  VehicleSettings = {};
  vehicleId:any = [];
  selected: any;
  start_date = moment().format('YYYY-MM-DD');
	end_date = moment().format('YYYY-MM-DD');
	alwaysShowCalendars: boolean;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  vehicleList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(
    public reportService: ReportService,
		private commonService:CommonService, 
		private customErroHandler:CustomErrorHandler,
		private translate: TranslateService,
		private broadcaster: Broadcaster
  ) { }
   
  ngOnInit() {
	   	/*this.dropdownList = [
          {"id":1,"itemName":"India"},
          {"id":2,"itemName":"Singapore"},
          {"id":3,"itemName":"Australia"},
          {"id":4,"itemName":"Canada"},
          {"id":5,"itemName":"South Korea"},
          {"id":6,"itemName":"Germany"},
          {"id":7,"itemName":"France"},
          {"id":8,"itemName":"Russia"},
          {"id":9,"itemName":"Italy"},
          {"id":10,"itemName":"Sweden"}
        ];
      this.selectedItems = [
          {"id":2,"itemName":"Singapore"},
          {"id":3,"itemName":"Australia"},
          {"id":4,"itemName":"Canada"},
          {"id":5,"itemName":"South Korea"}
      ];*/
      this.dropdownSettings = { 
            singleSelection: false, 
            text:"Select Vehicle(s)",
            selectAllText:'Select All',
            unSelectAllText:'UnSelect All',
            enableSearchFilter: true,
            classes:"myclass custom-class"
          };            
   

    this.totalItem = 0;
			this.translate.get('selectors').subscribe((res) => {
				this.VehicleSettings = {
					 singleSelection: true,
					 text: res.SelectVehicle,
					 enableSearchFilter: true,
					 classes: "singleSelection"
				};
		 });
		 this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('selectors').subscribe((res) => {
          this.VehicleSettings = {
            singleSelection: true,
            text: res.SelectVehicle,
            enableSearchFilter: true,
            classes: "singleSelection"
          };
        });
     }); 
     this.getVehicle();
     this.getVehicleDetails(this.pageNo);
  }

  onSelectAll(items: any){
    for( var i = 0; i < items.length; i++){ 
      this.vehicleId.push(items[i]['id']);
    }
    this.getVehicleDetails(this.pageNo);
    console.log(this.vehicleId);
  }
  onDeSelectAll(items: any){
      console.log(items);
      this.vehicleId = [];
      this.getVehicleDetails(this.pageNo);
  }

  getVehicleDetails(currentPage) {
    this.loading =true;
		this.page = currentPage;
    console.log(this.vehicleId);
		let queryParams = {
			vehicle_id: JSON.stringify(this.vehicleId),
			row_per_page: this.perPage,
      page_no:this.page,
      start_date: this.start_date,
      end_date: this.end_date
		}
		this.reportService.getVehicleSammary(queryParams).subscribe(res => {
      this.loading =false; 
      this.message = res.message;
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.vehicleDetails = res.data.rows;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
        this.loading =false; 
        this.customErroHandler.handleHttpError(error, 'getVehicleDetails');
    });
  }

  getVehicle() {
    this.commonService.getAssignVehicle().subscribe(res => {
      if (res.success == true) {
        this.vehicleList = res.data;
        //this.dropdownList = res.data;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErroHandler.handleHttpError(error, 'addInspection');
    });
  }
  
  onVehicleSelect(item: any) {
		this.vehicleId.push(item.id);
    this.getVehicleDetails(1);
  }

  onVehicleDeselect(item: any) {
    console.log(item.id);
    for( var i = 0; i < this.vehicleId.length; i++){ 
    
        if ( this.vehicleId[i] === item.id) { 
    
            this.vehicleId.splice(i, 1); 
        }
    
    }
    //this.vehicleId.splice(item.id);
    this.getVehicleDetails(1);
  }

  changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
    this.end_date = item.endDate.format('YYYY-MM-DD')
    if (this.vehicleId) {
      this.getVehicleDetails(1);
    } else {
      this.commonService.showCustomError('Please select vehicle');
    }    
  }
  
  exportReport(){
    this.loading = true; 
		let fileName = "Vehicle Summary Report";
		if(this.start_date != undefined && this.end_date != undefined){
			fileName = "Vehicle Summary Report From "+this.start_date+" To "+this.end_date;
		}
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "Vehicle Summary Report"
		  };
		  let queryParams = {
				vehicle_id: JSON.stringify(this.vehicleId),
        row_per_page: this.perPage,
        page_no:this.page,
        start_date: this.start_date,
        end_date: this.end_date,
				export: 'yes'
			}
		
		  this.reportService.getVehicleSammary(queryParams).subscribe(res => {
			  this.loading =false; 
			  new Angular2Csv(res.data, fileName, options);
		  }, error => {
				this.loading =false; 
				this.customErroHandler.handleHttpError(error, 'exportUserReport');
		  });
	}	

}
