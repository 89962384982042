import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  	loading:boolean;
	public isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	employeeDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	userInfo: any;
	deleteDetails: any = {};
	serverUrl: any;
	searchTempVal: any;

  	constructor(private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.searchTempVal = '';
  		this.searchDetails.search_by = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.company_id = this.userInfo.company_id;
  		this.getUserReport(this.pageNo);
  		localStorage.removeItem('editEmployeeId');
  		this.serverUrl = Config.FILEURL;
			this.searchDetails.status = '';
			this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getUserReport(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {};
		this.searchDetails.export = '';
		this.reportService.getUser(this.pageNo, this.perPage, this.searchDetails).subscribe(
	        res => {
						this.loading =false; 
						this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
	          	this.employeeDetails = res.data.rows;
	          } else {
							this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getEmployee');
	        }
	      )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getUserReport(this.pageNo);
		}
	}

	exportUserReport(){
		this.loading =true; 
		let requestParameter = {};
		this.searchDetails.export = true;
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "User Report"
		  };
		
		  this.reportService.getUser(0, 0, this.searchDetails).subscribe(res => {
			this.loading =false; 
			new Angular2Csv(res.data, 'User Report', options);
		  }, error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'exportUserReport');
		  });
	}
}
