import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-driver-attendance',
  templateUrl: './driver-attendance.component.html',
  styleUrls: ['./driver-attendance.component.css']
})
export class DriverAttendanceComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	attendanceDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	userInfo: any;
	deleteDetails: any= {};
	serverUrl: any;
	searchTempVal: any;
	attendance_date : Date = new Date();
	end_date;
	noDataMsg;
	modalRef: BsModalRef;
	modalRefImage: BsModalRef;
	attendanceInfo: any = [];
	currentDate: Date = new Date();
	authenticationUrl: any = Config.AuthenticationURL;
	image_url = ''

  	constructor(private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler,private modalService: BsModalService) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
		this.searchTempVal = '';
		this.searchDetails.search_by = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.getAttendanceDetails(this.pageNo);
  		this.serverUrl = Config.AuthenticationURL;		
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getAttendanceDetails(currentPage){
		if(this.attendance_date == undefined){
			this.attendance_date = new Date();
		}
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {
			page_no: this.pageNo,
			row_per_page: this.perPage,
			search: this.searchDetails.search_by,
			date: this.attendance_date == undefined ? '' : moment(this.attendance_date).format('YYYY-MM-DD'),
		};
		this.reportService.getAttendanceDetails(requestParameter).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
		          	this.totalItem = res.data.count;
					this.attendanceDetails = res.data.rows;
					this.noDataMsg = res.message;
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getAttendanceDetails(this.pageNo);
		}
	}

    exportReport(){
    	var options = {
	      fieldSeparator: ',',
	      quoteStrings: '"',
	      decimalseparator: '.',
	      showLabels: true,
	      showTitle: true,
	      useBom: true,
	      title: "Driver Attendance Report " + moment(this.attendance_date).format('YYYY-MM-DD')

	    };
    	let requestParameter = {
			page_no: 0,
			row_per_page: 0,
			search: this.searchDetails.search_by,
			date: this.attendance_date == undefined ? '' : moment(this.attendance_date).format('YYYY-MM-DD'),
			export: 'yes'
		};
    	this.reportService.getAttendanceDetails(requestParameter).subscribe(
	        res => {
	        	 new Angular2Csv(res.data, 'Driver Attendance Report', options);
			},
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getAttendanceDetails');
	        }
	    )
	}

	openAttendanceDetails(attendanceInfo, template){
		this.modalRef = this.modalService.show(template);
		this.attendanceInfo  = attendanceInfo;
    }	

		

	onSelect(item: any) {
		this.getAttendanceDetails(1);
	}

	onDeSelect(item: any) {
		this.getAttendanceDetails(1);
	}
	
	openModal(template, image) {
		this.image_url = this.serverUrl + image;
		this.modalRefImage = this.modalService.show(template);
	}

	closeModal(template) {
		this.modalRefImage.hide();
		this.image_url = '';
	}

	closeAttachment(template){
		this.modalRef.hide();
		// modalRef.hide()
	}

}
 