import { Component, OnInit ,ElementRef, NgZone,ViewChild} from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { CompanySettingsService } from './../../services/company-settings/companySettingsService.service';
import { FormControl } from '@angular/forms';
// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import { GeofenceType } from 'src/app/common/enums';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-manage-alert',
  templateUrl: './manage-alert.component.html',
  styleUrls: ['./manage-alert.component.css']
})
export class ManageAlertComponent implements OnInit {

  	loading:boolean;
	manageAlerts: any = {};
    userInfo: any;
	manageAlertId : any= '';
	message: any = '';
	
  	constructor(
  		private router: Router,
    	private mapsAPILoader: MapsAPILoader,
    	private ngZone: NgZone,
    	public companySettingService: CompanySettingsService,
		private customErrorHandler:CustomErrorHandler,
		private commonService:CommonService,
		private translate: TranslateService
	) {
  		this.userInfo = localStorage.getItem('userInfo');
  		if(this.userInfo){
			  this.userInfo = JSON.parse(this.userInfo);
  		}
  	}

  	ngOnInit() {
  		this.getManageAlert();
  	}

  	getManageAlert(){
  		this.companySettingService.getUserAlertSetting().subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
		          	this.manageAlerts = res.data;
		          	this.manageAlerts.ac_alert = this.manageAlerts.ac_alert == 1 ? true : false;
		          	this.manageAlerts.geo_alert = this.manageAlerts.geo_alert == 1 ? true : false;
		          	this.manageAlerts.speed_alert = this.manageAlerts.speed_alert == 1 ? true : false;
		          	this.manageAlerts.tempering_alert = this.manageAlerts.tempering_alert == 1 ? true : false;
		          	this.manageAlerts.vehicle_idle_alert = this.manageAlerts.vehicle_idle_alert == 1 ? true : false;
		       	} else {
		    		this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	         	this.customErrorHandler.handleHttpError(error, 'getManageAlert');
	        }
	    )
  	}

  	updateManageAlert(){
  		if(this.manageAlerts.speed_limit <= 0){
  			return false;
  		}
  		let requestParam = {
  			ac_alert : this.manageAlerts.ac_alert == true ? 1 : 0,
  			geo_alert : this.manageAlerts.geo_alert == true ? 1 : 0,
  			speed_alert : this.manageAlerts.speed_alert == true ? 1 : 0,
  			tempering_alert : this.manageAlerts.tempering_alert == true ? 1 : 0,
  			vehicle_idle_alert : this.manageAlerts.vehicle_idle_alert == true ? 1 : 0,
  			user_id : this.manageAlerts.user_id,
  			id : this.manageAlerts.id,
  			speed_limit : this.manageAlerts.speed_limit
  		}
  		this.companySettingService.updateManageAlert(requestParam).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
		          	this.manageAlerts = res.data;
		          	this.manageAlerts.ac_alert = this.manageAlerts.ac_alert == 1 ? true : false;
		          	this.manageAlerts.geo_alert = this.manageAlerts.geo_alert == 1 ? true : false;
		          	this.manageAlerts.speed_alert = this.manageAlerts.speed_alert == 1 ? true : false;
		          	this.manageAlerts.tempering_alert = this.manageAlerts.tempering_alert == 1 ? true : false;
		          	this.manageAlerts.vehicle_idle_alert = this.manageAlerts.vehicle_idle_alert == 1 ? true : false;
		       		this.commonService.showSuccessMessage(res.message);
		       	} else {
		    		this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	         	this.customErrorHandler.handleHttpError(error, 'getManageAlert');
	        }
	    )
  	}
}
