import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PlayBackService {

  	constructor(private _httpHelper: HttpHelper) {
    }

    getTackingData(request){
    	try {
            let requestParameter = '?trip_id='+request.trip_id;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'tracking'+requestParameter)
        }
        catch (e) {
        }
    }

    getTrackingDetails(tripId){
        try {
            let requestParameter = '?trip_id='+tripId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'tracking-details'+requestParameter)
        }
        catch (e) {
        }
    }

    getTrackingVehicleDetails(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-registration')
        }
        catch (e) {
        }
    }
}
