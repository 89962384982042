import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { DueSerService } from '../../../services/due-service/dueService.service';
import { CommonService } from '../../../services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../../../constants/constants';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-create-due-service',
  templateUrl: './create-due-service.component.html',
  styleUrls: ['./create-due-service.component.css']
})
export class CreateDueServiceComponent implements OnInit {
  vehicleList: any;
  VehicleSelectedItems = [];
  VehicleSettings = {};
  durationList: any;
  serviceSelectedItems = [];
  durationSettings = {};
  loading:Boolean=false;
  minDate:Date = new Date();
  serviceData:any = {};
  pocSelectedItems = [];
  pageLabel;
  selectedServiceId;
  isEditMode: boolean;
  errorMessage = '';
  editServiceData:any = {};

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public dueService: DueSerService,
    private translate: TranslateService,
    private broadcaster: Broadcaster  
  ) {
    this.translate.get('selectors').subscribe((res) => {
      setTimeout(() => {
        this.VehicleSettings = {
          singleSelection: false,
          text: res.SelectVehicle,
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          classes: ""
        };
        this.durationSettings = {
          singleSelection: true,
          text: res.SelectDuration,
          classes: "singleSelection"
        };
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('selectors').subscribe((res) => {
            setTimeout(() => {
              this.VehicleSettings = {
                singleSelection: false,
                text: res.SelectVehicle,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: ""
              };
              this.durationSettings = {
                singleSelection: true,
                text: res.SelectDuration,
                classes: "singleSelection"
              };
            });
        });
    });
  }

  ngOnInit() {
      this.pageLabel = 'Create'
      this.selectedServiceId = this.activatedRoute.snapshot.queryParamMap.get('id');
      if (this.selectedServiceId) {
        this.isEditMode = true;
        this.pageLabel = 'Edit'
        this.getServiceDetail(this.selectedServiceId);
      } else {
        this.isEditMode = false;
    }
    this.getReminders();
    this.getVehicle();
  }

  addService(data){
    this.errorMessage = '';
    if (data.service_date == undefined && data.poc_date == undefined ) {
      this.errorMessage = 'Service or PUC value should not be blank.';
      return false;
    }
    if (data.service_estimated_cost == undefined && data.poc_estimated_cost == undefined ) {
      this.errorMessage = 'Service or PUC value should not be blank.';
      return false;
    }
    if (this.serviceSelectedItems.length == 0 && this.pocSelectedItems.length == 0 ) { 
      this.errorMessage = 'Service or PUC value should not be blank.';
      return false;
    }
    
    this.loading = true;
    if (this.pocSelectedItems.length > 0) {
      data.poc_reminder = this.pocSelectedItems[0].id;
    } 
    if (this.serviceSelectedItems.length > 0) {
      data.service_reminder = this.serviceSelectedItems[0].id;
    }
    
    data.vehicles = this.VehicleSelectedItems;
    if (this.isEditMode) {
      this.updateService(data);
    } else {
      this.dueService.addService(data).subscribe(res => {
        if (res.success == true) {
          this.loading =false; 
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(['/service-due-management/service-due-list']);
        } else {
          this.commonService.showErrorMessage(res);
          this.loading = false; 
        }
      }, error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'addInspection');
      });
    }
  }

  updateService(data) {
    data.service_id = data.parent_id;
    this.dueService.updateService(data).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(['/service-due-management/service-due-list']);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getReminders() {
    this.commonService.getReminder().subscribe(res => {
      if (res.success == true) {
        this.durationList = res.data;
        let serviceDurationId = this.serviceData.service_reminder;
        if (this.serviceData.service_reminder != '') {
          if (this.isEditMode && serviceDurationId != undefined && this.durationList.length > 0) {
            this.serviceSelectedItems = [];					
            let durationTemp = this.durationList.find(s => s.id == serviceDurationId);
            if (typeof durationTemp != 'undefined') {
              this.serviceSelectedItems.push(durationTemp);
            }
          }
        }
				if (this.serviceData.poc_reminder != '') {
          let pocDurationId = this.serviceData.poc_reminder;
          if (this.isEditMode && pocDurationId != undefined && this.durationList.length > 0) {
            this.pocSelectedItems = [];					
            let durationTemp = this.durationList.find(s => s.id == pocDurationId);
            if (typeof durationTemp != 'undefined') {
              this.pocSelectedItems.push(durationTemp);
            }
          }
        }
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }
  getVehicle() {
    this.commonService.getAssignVehicle().subscribe(res => {
      if (res.success == true) {
        this.vehicleList = res.data;
        let vehicleId:any = this.serviceData.vehicles;
				if (this.isEditMode && vehicleId != undefined && this.vehicleList.length > 0) {
          this.VehicleSelectedItems = [];
          for (let i = 0; i < vehicleId.length; i++) {					
            let vehicleTemp = this.vehicleList.find(s => s.id == vehicleId[i].vehicle_id);
            if (typeof vehicleTemp != 'undefined') {
              this.VehicleSelectedItems.push(vehicleTemp);
            }
          }
				}
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getServiceDetail(id) {
    this.loading = true;
    let queryParam = {
      service_id: id
    }
    this.dueService.getSingleService(queryParam).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.serviceData = res.data;
        let copy = JSON.parse(JSON.stringify(res.data));
        this.editServiceData = copy;
        console.log(this.editServiceData);
        this.serviceData.service_estimated_cost = res.data.estimated_cost;
        
        if (this.serviceData.poc_date == '' || this.serviceData.poc_date == null) {
          this.serviceData.poc_date = '';
          this.editServiceData.poc_date = '';
        } else {
          this.serviceData.poc_date = new Date(this.serviceData.poc_date);
          this.editServiceData.poc_date = new Date(this.serviceData.poc_date);
        }

        if (this.serviceData.service_date == '' || this.serviceData.service_date == null) {
          this.serviceData.service_date = '';
          this.editServiceData.service_date = '';
        } else {
          this.serviceData.service_date = new Date(this.serviceData.service_date);
          this.editServiceData.service_date = new Date(this.serviceData.service_date);
        }
        
        this.getReminders();
        this.getVehicle();
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }
  getYearSlab() {
    var d = new Date();
    var s = d.getFullYear();
    var e:any = s + 50;
    e = s + ':' + e;
    return e;
  }
}
