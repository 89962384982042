import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
// import { request } from 'http';

declare var $: any;


@Injectable()
export class VehicleService {
    userDetails: any = {}
    companyId: any = {}
    constructor(private _httpHelper: HttpHelper) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
        this.companyId = this.userDetails.company_id;
    }

    addVehicle(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-vehicle', request)
        }
        catch (e) {
        }
    }

    updateVehicle(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-vehicle', request)
        }
        catch (e) {
        }
    }

    getVehicle(pageNo, rowsPerPage, searchDetails = null){
    	try {
            let searchParameter = '';
            if(searchDetails){
                if(typeof searchDetails.current_status != 'undefined'){
                    searchParameter += '&current_status='+searchDetails.current_status;
                }
                if(typeof searchDetails.contractorId != 'undefined'){
                    searchParameter += '&contractorId='+searchDetails.contractorId;
                }
                if(typeof searchDetails.vehileStatus != 'undefined'){
                    searchParameter += '&vehileStatus='+searchDetails.vehileStatus;
                }
                if(typeof searchDetails.assignment_status != 'undefined'){
                    searchParameter += '&assignment_status='+searchDetails.assignment_status;
                }
                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search_by='+searchDetails.search_by;
                }
            }
            console.log(searchParameter,'searchParametersearchParameter');
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.companyId+ ''+ searchParameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle'+requestParameter)
        }
        catch (e) {
        }
    }

    getVehicleModal(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-model')
        }
        catch (e) {
        }
    }

    getownership(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'ownership-type')
        }
        catch (e) {
        }
    }
    getVehicleBodyType(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-body-type')
        }
        catch (e) {
        }
    }

    addGroup(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-group', request)
        }
        catch (e) {
        }
    }

    updateGroup(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-group', request)
        }
        catch (e) {
        }
    }

    getGroup(pageNo, rowsPerPage, searchDetails){
        try {
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+searchDetails.company_id+'&search_by='+searchDetails.search_by;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-group'+requestParameter)
        }
        catch (e) {
        }
    }

    getVehicleCategory(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-category')
        }
        catch (e) {
        }
    }
    
    assignVehicle(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'set-assign-vehicle', request)
        }
        catch (e) {
        }
    }

    updateAssignVehicle(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-assign-vehicle', request)
        }
        catch (e) {
        }
    }

    getSingleVehicle(vehicleId, companyId){
        try {
            let requestParameter = '?vehicle_id='+vehicleId+'&company_id='+companyId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-vehicle'+requestParameter)
        }
        catch (e) {
        }
    }

    getSingleAssignmentVehicle(vehicleId){
        try {
            let requestParameter = {
                vehicle_id: vehicleId
            }
            
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-details',requestParameter)
        }
        catch (e) {
        }
    }

    deleteVehicle(request){
         try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-vehicle', request)
        }
        catch (e) {
        }
    }

    deleteGroup(request){
         try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-group', request)
        }
        catch (e) {
        }
    }

    getSingleGroup(groupId){
        try {
            let requestParameter = '?group_id='+groupId;//+'&company_id='+companyId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'single-group-details'+requestParameter)
        }
        catch (e) {
        }
    }

    getAssignVehicle(pageNo, rowsPerPage, searchDetails){
        try {
            if(searchDetails.end_date == null){
                searchDetails.end_date = '';
            }
            if(searchDetails.start_date == null){
                searchDetails.start_date = '';
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&search_by='+searchDetails.search_by+'&start_date='+searchDetails.start_date+'&end_date='+searchDetails.end_date;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-assignment-details'+requestParameter)
        }
        catch (e) {
        }
    }

    getShiftAssignVehicle(pageNo, rowsPerPage, searchDetails){
        try {
            if(searchDetails.end_date == null){
                searchDetails.end_date = '';
            }
            if(searchDetails.start_date == null){
                searchDetails.start_date = '';
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&search_by='+searchDetails.search_by+'&start_date='+searchDetails.start_date+'&end_date='+searchDetails.end_date;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-assign-shift-vehicle'+requestParameter)
        }
        catch (e) {
        }
    }

    deleteVehicleAssignment(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-vehicle-assignment', request)
        }
        catch (e) {
        }
    }

    checkGps(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'get-imei-detail', request)
        }
        catch (e) {
        }
    }

    assignVehicleWithShift(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'assign-shift-to-vehicle', request)
        }
        catch (e) {
        }
    }

    getSingleShiftAssign(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-shift-vehicle', request)
        }
        catch (e) {
        }
    }

    updateVehicleShift(request){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'update-vehicle-shift', request)
        }
        catch (e) {
        }
    }

    deleteVehicleWithShift(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-vehicle-shift', request)
        }
        catch (e) {
        }
    }

    getDeviceType(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-device-type')
        }
        catch (e) {
        }
    }

    getNearByVehicle(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-nearest-vehicles', request)
        }
        catch (e) {
        }
    }
}
