import { LiveTrackingComponent } from './modules/live-tracking/live-tracking.component';
//import { RolelistComponent } from './modules/role-management/role-list/role-list.component';
import { CompanySettingsComponent } from './modules/company-settings/company-settings.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { AuthGuard } from './auth.guard';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { SignupComponent } from 'src/app/modules/signup/signup.component';
import { ResetPasswordComponent } from 'src/app/modules/reset-password/reset-password.component';
import { VehicleManagementComponent } from 'src/app/modules/vehicle-management/vehicle-management.component';
import { VehicleListComponent } from 'src/app/modules/vehicle-management/vehicle-list/vehicle-list.component';
import { AddVehicleComponent } from 'src/app/modules/vehicle-management/add-vehicle/add-vehicle.component';
import { PlacesComponent } from 'src/app/modules/places/places.component';
import { AddPlacesComponent } from 'src/app/modules/places/add-places/add-places.component';
import { PlacesListComponent } from 'src/app/modules/places/places-list/places-list.component';
import { VehicleGroupListComponent } from 'src/app/modules/vehicle-management/vehicle-group-list/vehicle-group-list.component';
import { TripsComponent } from 'src/app/modules/trips/trips.component';
import { AddTripComponent } from 'src/app/modules/trips/add-trip/add-trip.component';
import { TripListComponent } from 'src/app/modules/trips/trip-list/trip-list.component';
import { TripMapComponent } from 'src/app/modules/trips/trip-map/trip-map.component';
import { WorkOrdersComponent } from 'src/app/modules/work-orders/work-orders.component';
import { AddOrderComponent } from 'src/app/modules/work-orders/add-order/add-order.component';
import { OrderListComponent } from 'src/app/modules/work-orders/order-list/order-list.component';
import { UserManagementComponent } from 'src/app/modules/user-management/user-management.component';
import { AddDriverComponent } from 'src/app/modules/user-management/add-driver/add-driver.component';
import { DriverListComponent } from 'src/app/modules/user-management/driver-list/driver-list.component';
import { EmployeeListComponent } from 'src/app/modules/user-management/employee-list/employee-list.component';
import { AddEmployeeComponent } from 'src/app/modules/user-management/add-employee/add-employee.component';
import { PlayBackComponent } from 'src/app/modules/play-back/play-back.component';
import { RoleComponent } from 'src/app/modules/role-management/role/role.component';
import { RoleManagementComponent } from 'src/app/modules/role-management/role-management.component';
import { VisitorComponent } from 'src/app/modules/visitor/visitor.component';
import { ShiftsComponent } from './modules/shifts/shifts.component';
import { AddShiftComponent } from './modules/shifts/add-shift/add-shift.component';
import { ShiftListComponent } from './modules/shifts/shift-list/shift-list.component';
import { RolelistComponent } from './modules/role-management/role-list/role-list.component';
import { ReportManagementComponent } from 'src/app/modules/report-management/report-management.component';
import { UserComponent } from 'src/app/modules/report-management/user/user.component';
import { VehicleComponent } from 'src/app/modules/report-management/vehicle/vehicle.component';
import { TripComponent } from 'src/app/modules/report-management/trip/trip.component';
import { DriverComponent } from 'src/app/modules/report-management/driver/driver.component';
// import { VehicleAssignmentComponent } from 'src/app/modules/report-management/vehicle-assignment/vehicle-assignment.component';
import { DriverPerformanceComponent } from 'src/app/modules/report-management/driver-performance/driver-performance.component';
import { ReportDashboardComponent } from './modules/report-management/report-dashboard/report-dashboard.component';
import { MessageComponent } from './modules/message/message.component';
import { VehicleAssignmentComponent } from 'src/app/modules/vehicle-management/vehicle-assignment/vehicle-assignment.component';
import { InspectionComponent } from './modules/inspection/inspection.component';
import { InspectionListComponent } from './modules/inspection/inspection-list/inspection-list.component';
import { AddInspectionComponent } from './modules/inspection/add-inspection/add-inspection.component';
import { InspectionAssignmentComponent } from './modules/inspection/inspection-assignment/inspection-assignment.component';
import { ServiceDueManagementComponent } from './modules/service-due-management/service-due-management.component';
import { ServiceDueListComponent } from './modules/service-due-management/service-due-list/service-due-list.component';
import { CreateDueServiceComponent } from './modules/service-due-management/create-due-service/create-due-service.component';
import { IssueListComponent } from './modules/issue-list/issue-list.component';
import { FuelManagementComponent } from './modules/fuel-management/fuel-management.component';
import { RouteManagementComponent } from './modules/route-management/route-management.component';
import { RouteListComponent } from './modules/route-management/route-list/route-list.component';
import { CreateRouteComponent } from './modules/route-management/create-route/create-route.component';
import { ExpenseManagementComponent } from './modules/expense-management/expense-management.component';
import { ExpenseListComponent } from './modules/expense-management/expense-list/expense-list.component';
import { ViewInspectionComponent } from './modules/inspection/view-inspection/view-inspection.component';
import { ViewServiceComponent } from './modules/service-due-management/view-service/view-service.component';
import { LeaveManagementComponent } from './modules/leave-management/leave-management.component';
import { ChecklistComponent } from './modules/checklist/checklist.component';
// import { VehicleInspectionReportComponent } from './modules/report-management/vehicle-inspection-report/vehicle-inspection-report.component';
// import { VehicleWiseServiceComponent } from './modules/report-management/vehicle-wise-service/vehicle-wise-service.component';
import { DriverWiseServiceComponent } from './modules/report-management/driver-wise-service/driver-wise-service.component';
import { DriverWiseFuelComponent } from './modules/report-management/driver-wise-fuel/driver-wise-fuel.component';
import { VehicleWiseFuelComponent } from './modules/report-management/vehicle-wise-fuel/vehicle-wise-fuel.component';
import { VehicleWiseExpenseComponent } from './modules/report-management/vehicle-wise-expense/vehicle-wise-expense.component';
import { DriverWiseExpenseComponent } from './modules/report-management/driver-wise-expense/driver-wise-expense.component';
import { DriverWisePartsComponent } from './modules/report-management/driver-wise-parts/driver-wise-parts.component';
// import { VehicleWisePartsComponent } from './modules/report-management/vehicle-wise-parts/vehicle-wise-parts.component';
import { LeaveComponent } from './modules/report-management/leave/leave.component';
import { LiveLocationTrackingComponent } from './modules/live-location-tracking/live-location-tracking.component';
import { DriverAttendanceComponent } from './modules/report-management/driver-attendance/driver-attendance.component';
import { PartManagementComponent } from './modules/part-management/part-management.component';
import { StudentManagementComponent } from './modules/student-management/student-management.component';
import { StudentListComponent } from './modules/student-management/student-list/student-list.component';
import { CreateStudentComponent } from './modules/student-management/create-student/create-student.component';
import { AssignVehicleComponent } from './modules/vehicle-management/assign-vehicle/assign-vehicle.component';
import { VehicleDetailedReportComponent } from 'src/app/modules/report-management/vehicle-detailed-report/vehicle-detailed-report.component';
import { VehicleIdleReportComponent } from 'src/app/modules/report-management/vehicle-idle-report/vehicle-idle-report.component';
import { VehicleCurrentPositionComponent } from './modules/report-management/vehicle-current-position/vehicle-current-position.component';
import { VehicleCurrentLocationComponent } from './modules/vehicle-current-location/vehicle-current-location.component';
import { VehicleSummaryComponent } from './modules/report-management/vehicle-summary/vehicle-summary.component';
import { VehicleStoppageComponent } from './modules/report-management/vehicle-stoppage/vehicle-stoppage.component';
import { IgnitionSummaryComponent } from './modules/report-management/ignition-summary/ignition-summary.component';
import { GroupsComponent } from './modules/groups/groups.component';
import { AddGroupComponent } from './modules/groups/add-group/add-group.component';
import { GroupListComponent } from './modules/groups/group-list/group-list.component';
import { GeofenceComponent } from './modules/groups/geofence/geofence.component';
import { GeofenceListComponent } from './modules/groups/geofence-list/geofence-list.component';
import { NearByVehicleComponent } from 'src/app/modules/vehicle-management/near-by-vehicle/near-by-vehicle.component';
import { VehicleEngineOperatingTimeComponent } from 'src/app/modules/report-management/vehicle-engine-operating-time/vehicle-engine-operating-time.component';
import { VehicleSpeedIgnitionGraphComponent } from 'src/app/modules/report-management/vehicle-speed-ignition-graph/vehicle-speed-ignition-graph.component';
// import { VehicleAcMisuseComponent } from 'src/app/modules/report-management/vehicle-ac-misuse/vehicle-ac-misuse.component';
import { ManageAlertComponent } from 'src/app/modules/manage-alert/manage-alert.component';
// import { DailyFuelConsumptionComponent } from './modules/report-management/daily-fuel-consumption/daily-fuel-consumption.component';
// import { AverageFuelConsumptionComponent } from './modules/report-management/average-fuel-consumption/average-fuel-consumption.component';
import { PaymentComponent } from './modules/payment/payment.component';
import { PaymentService } from './services/payment/payment.service';
import { KendoExampleComponent } from './modules/kendo-example/kendo-example.component';
import { LiveTrackingDataComponent } from './modules/live-tracking-data/live-tracking.component';
import { GeoLiveTrackingComponent } from './modules/geo-live-tracking/geo-live-tracking.component';
import { VehicleIgnitionReportComponent } from 'src/app/modules/report-management/vehicle-ignition-report/vehicle-ignition-report.component';

import { LocationMappingComponent } from 'src/app/modules/location-mapping/location-mapping.component';
import { AddLocationMappingComponent } from 'src/app/modules/location-mapping/add-location-mapping/add-location-mapping.component';
import { LocationMappingListComponent } from 'src/app/modules/location-mapping/location-mapping-list/location-mapping-list.component';

// import { ContractorComponent } from 'src/app/modules/contractor/contractor.component';
// import { AddContractorComponent } from 'src/app/modules/contractor/add-contractor/add-contractor.component';
// import { ContractorListComponent } from 'src/app/modules/contractor/contractor-list/contractor-list.component';
// import { ContractorService } from './services/contractor/contractor.service';

const routes: Routes = [
   	{ path: 'login',component: LoginComponent},
  	{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'signup',component: SignupComponent},
    { path: 'visitor',component: VisitorComponent},
    { path: 'reset-password', component: ResetPasswordComponent},
      { path: 'reset-password/:email', component: ResetPasswordComponent},
      { path: 'change-password', component: ChangePasswordComponent},
      { path: 'company-settings', component: CompanySettingsComponent, canActivate: [AuthGuard]},  
      { path: 'dashboard',component: DashboardComponent, canActivate: [AuthGuard]},
  	{ 
   		path: 'vehicle-management',
      	component: VehicleManagementComponent, //canActivate: [AuthGuard],
      	children: [
        	{path: '', redirectTo: 'vehicle-list', pathMatch: 'full'},
        	{ path: 'vehicle-list', component: VehicleListComponent , canActivate: [AuthGuard]},
        	{ path: 'vehicle', component: AddVehicleComponent , canActivate: [AuthGuard]},
          { path: 'group-list', component: VehicleGroupListComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-assignment', component: VehicleAssignmentComponent , canActivate: [AuthGuard]},
          { path: 'assign-vehicle', component: AssignVehicleComponent, canActivate :[AuthGuard]},
          { path: 'near-by-vehicle', component: NearByVehicleComponent, canActivate :[AuthGuard]},
          { path: 'payment', component: PaymentComponent, canActivate :[AuthGuard]},
       	]
  	},
    { 
       path: 'places',
        component: PlacesComponent, canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'places-list', pathMatch: 'full'},
            {path: 'places-list', component: PlacesListComponent },
            {path: 'place', component: AddPlacesComponent },
        ]
    },

    { 
       path: 'location-mapping',
        component: LocationMappingComponent, canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'location-mapping-list', pathMatch: 'full'},
            {path: 'location-mapping-list', component: LocationMappingListComponent },
            {path: 'location-mapping', component: AddLocationMappingComponent },
        ]
    },
    { 
       path: 'trips',
        component: TripsComponent, canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'trip-list', pathMatch: 'full'},
            {path: 'trip-list', component: TripListComponent, canActivate: [AuthGuard]},
            {path: 'trip-map', component: TripMapComponent, canActivate: [AuthGuard] },
            {path: 'add-trip', component: AddTripComponent, canActivate: [AuthGuard] },
        ]
    },
    { 
       path: 'groups',
        component: GroupsComponent, canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'group-list', pathMatch: 'full'},
            {path: 'group-list', component: GroupListComponent, canActivate: [AuthGuard]},
            {path: 'group', component: AddGroupComponent, canActivate: [AuthGuard] },
            {path: 'geofence-list', component: GeofenceListComponent, canActivate: [AuthGuard]},
            {path: 'geofence', component: GeofenceComponent, canActivate: [AuthGuard] }
        ]
    },
    { 
      path: 'route',
       component: RouteManagementComponent,
       children: [
           {path: '', redirectTo: 'route-list', pathMatch: 'full'},
           {path: 'route-list', component: RouteListComponent},
           {path: 'add-route', component: CreateRouteComponent },
       ]
    },
    { 
       path: 'work-order',
        component: WorkOrdersComponent, canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: 'order-list', pathMatch: 'full'},
            {path: 'order-list', component: OrderListComponent , canActivate: [AuthGuard]},
            {path: 'order', component: AddOrderComponent , canActivate: [AuthGuard]},
        ]
    },
    { 
        path: 'user-management',
        component: UserManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'employee-list', pathMatch: 'full'},
          { path: 'driver', component: AddDriverComponent , canActivate: [AuthGuard]},
          { path: 'driver-list', component: DriverListComponent , canActivate: [AuthGuard]},
          { path: 'employee-list', component: EmployeeListComponent , canActivate: [AuthGuard]},
          { path: 'employee', component: AddEmployeeComponent , canActivate: [AuthGuard]},
         ]
    },
    { 
        path: 'play-back', component: PlayBackComponent, canActivate: [AuthGuard]
    },
    { 
        path: 'live-tracking', component: LiveTrackingComponent, canActivate: [AuthGuard]
    },
    { 
        path: 'geo-live-tracking', component: GeoLiveTrackingComponent, canActivate: [AuthGuard]
    },
    { 
      path: 'live-tracking-data/:id', component: LiveTrackingDataComponent
  },
    {
      path: 'live-location-tracking', component: LiveLocationTrackingComponent, canActivate: [AuthGuard]
    },
    {
      path: 'current-location', component: VehicleCurrentLocationComponent, canActivate: [AuthGuard]
    },
    { 
        path: 'role-management',
        component: RoleManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'role-list', pathMatch: 'full'},
          { path: 'role-list', component: RolelistComponent , canActivate: [AuthGuard]},
          { path: 'role', component: RoleComponent , canActivate: [AuthGuard]}
         ]
    },
    { 
        path: 'shift-management',
        component: ShiftsComponent, 
        children: [
            {path: '', redirectTo: 'shift-list', pathMatch: 'full'},
          { path: 'shift-list', component: ShiftListComponent },
          { path: 'add-shift', component: AddShiftComponent }
         ]
    },
    { 
        path: 'report-management',
        component: ReportManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'report-dashboard', pathMatch: 'full'},
          { path: 'report-dashboard', component: ReportDashboardComponent , canActivate: [AuthGuard]},
          { path: 'driver', component: DriverComponent , canActivate: [AuthGuard]},
          { path: 'trip', component: TripComponent , canActivate: [AuthGuard]},
          { path: 'vehicle', component: VehicleComponent , canActivate: [AuthGuard]},
          { path: 'user', component: UserComponent , canActivate: [AuthGuard]},
          { path: 'driver-performance', component: DriverPerformanceComponent , canActivate: [AuthGuard]},
          // { path: 'vehicle-inspection', component: VehicleInspectionReportComponent , canActivate: [AuthGuard]},
          // { path: 'vehicle-wise-service', component: VehicleWiseServiceComponent , canActivate: [AuthGuard]},
          { path: 'driver-wise-service', component: DriverWiseServiceComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-wise-fuel', component: VehicleWiseFuelComponent , canActivate: [AuthGuard]},
          { path: 'driver-wise-fuel', component: DriverWiseFuelComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-wise-expense', component: VehicleWiseExpenseComponent , canActivate: [AuthGuard]},
          { path: 'driver-wise-expense', component: DriverWiseExpenseComponent , canActivate: [AuthGuard]},
          // { path: 'vehicle-wise-parts', component: VehicleWisePartsComponent , canActivate: [AuthGuard]},
          { path: 'driver-wise-parts', component: DriverWisePartsComponent , canActivate: [AuthGuard]},
          { path: 'leave', component: LeaveComponent , canActivate: [AuthGuard]},
          { path: 'driver-attendance', component: DriverAttendanceComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-detailed-report', component: VehicleDetailedReportComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-idle-report', component: VehicleIdleReportComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-current-position', component: VehicleCurrentPositionComponent , canActivate: [AuthGuard]},
          { path: 'vehicle-summary', component: VehicleSummaryComponent, canActivate: [AuthGuard]},
          { path: 'vehicle-stoppage', component: VehicleStoppageComponent, canActivate: [AuthGuard]},
          { path: 'vehicle-ignition-summary', component: IgnitionSummaryComponent, canActivate: [AuthGuard]},

          { path: 'vehicle-engine-operating-time', component: VehicleEngineOperatingTimeComponent, canActivate: [AuthGuard]},
          { path: 'vehicle-speed-ignition-graph', component: VehicleSpeedIgnitionGraphComponent, canActivate: [AuthGuard]},
          // { path: 'vehicle-ac-misuse', component: VehicleAcMisuseComponent, canActivate: [AuthGuard]},
          // { path: 'daily-fuel-consumption', component: DailyFuelConsumptionComponent, canActivate: [AuthGuard]},
          // { path: 'average-fuel-consumption', component: AverageFuelConsumptionComponent, canActivate: [AuthGuard]}
          { path: 'vehicle-ignition-report', component: VehicleIgnitionReportComponent , canActivate: [AuthGuard]},
        ]
    },
    { 
        path: 'inspection-management',
        component: InspectionComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'inspection-list', pathMatch: 'full'},
          { path: 'inspection-list', component: InspectionListComponent , canActivate: [AuthGuard]},
          { path: 'add-inspection', component: AddInspectionComponent , canActivate: [AuthGuard]},
          { path: 'inspection-assignment-list', component: InspectionAssignmentComponent, canActivate: [AuthGuard]},
          { path: 'view-inspection-list', component: ViewInspectionComponent}
        ]
    },
    { 
        path: 'service-due-management',
        component: ServiceDueManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'service-due-list', pathMatch: 'full'},
          { path: 'service-due-list', component: ServiceDueListComponent , canActivate: [AuthGuard]},
          { path: 'create-due-service', component: CreateDueServiceComponent , canActivate: [AuthGuard]},
          { path: 'view-service', component: ViewServiceComponent , canActivate: [AuthGuard]}
        ]
    },
    { 
        path: 'student-management',
        component: StudentManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'student-list', pathMatch: 'full'},
          { path: 'student-list', component: StudentListComponent , canActivate: [AuthGuard]},
          { path: 'create-student', component: CreateStudentComponent , canActivate: [AuthGuard]}
        ]
    },
    { 
        path: 'route-management',
        component: RouteManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'route-list', pathMatch: 'full'},
          { path: 'route-list', component: RouteListComponent , canActivate: [AuthGuard]},
          { path: 'create-route', component: CreateRouteComponent , canActivate: [AuthGuard]}
        ]
    },
    {
        path:'issue-list',
        component:IssueListComponent,
        canActivate: [AuthGuard]
    },
    {
        path:'parts-management',
        component:PartManagementComponent,
        canActivate: [AuthGuard]
    },
    {
      path:'fuel-management',
      component:FuelManagementComponent,
      canActivate: [AuthGuard]
    },
    {
        path:'leave-management',
        component:LeaveManagementComponent,
        canActivate: [AuthGuard]
    },
    {
        path:'expense-management',
        component: ExpenseManagementComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'expense-list', pathMatch: 'full'},
          { path: 'expense-list', component: ExpenseListComponent , canActivate: [AuthGuard]}
        ]
    },
    {
        path:'message',
        component:MessageComponent,
        canActivate: [AuthGuard]
    },
    { 
      path:'checklist',
      component:ChecklistComponent,
      canActivate: [AuthGuard]
    },
    { 
      path:'manage-alert',
      component:ManageAlertComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'kendo-test',
      component : KendoExampleComponent,
      canActivate: [AuthGuard]
    },
    // { 
    //    path: 'contractor',
    //     component: ContractorComponent, canActivate: [AuthGuard],
    //     children: [
    //         {path: '', redirectTo: 'contractor-list', pathMatch: 'full'},
    //         {path: 'contractor-list', component: ContractorListComponent },
    //         {path: 'add-contractor', component: AddContractorComponent },
    //     ]
    // },

    // { 
    //     path: 'contractor',
    //     component: ContractorComponent, canActivate: [AuthGuard],
    //     children: [
    //       {path: '', redirectTo: 'contractor-list', pathMatch: 'full'},
    //       { path: 'contractor-list', component: ContractorListComponent , canActivate: [AuthGuard]},
    //       { path: 'add-contractor', component: AddContractorComponent , canActivate: [AuthGuard]}
    //      ]
    // },
    // {
    //   path:'payment',
    //   component: PaymentComponent,
    //   canActivate: [AuthGuard]
    // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
