import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class LeaveService {
    constructor(private _httpHelper: HttpHelper) {
    }

    getLeaveDetail(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-leave', request);
        }
        catch (e) {
        }
    }

    setLeaveStatus(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'approve-leave', request);
        }
        catch (e) {
        }
    }
    
}
