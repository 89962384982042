import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-management',
  templateUrl: './route-management.component.html',
  styleUrls: ['./route-management.component.css']
})
export class RouteManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
