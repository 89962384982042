import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/services/login/login.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';

@Injectable()
export class CommonService {
    constructor(private _httpHelper: HttpHelper, private loginService: LoginService, private customErrorHandler: CustomErrorHandler,
    ) {}
    getCity() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'city');
        }
        catch (e) {
        }
    }
    getCountry() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'country');
        }
        catch (e) {
        }
    }
    getVehicle(){
        try {
            let requestParameter = '?list=vehicle';    
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-status-list'+requestParameter);
        }
        catch (e) {
        }
    }
    getVehicleGroup(){
        try {
            let requestParameter =  {
                page_no : 0,
                row_per_page: 0
            }//'?list=vehicle-group';    
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-group', requestParameter);
        }
        catch (e) {
        }
    }
    getPlace(){
        try {
            let requestParameter = '?list=place';    
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-status-list'+requestParameter);
        }
        catch (e) {
        }
    }
    getOrderStatus(){
        try {
            let requestParameter = '?list=work-order-status';    
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-status-list'+requestParameter);        }
        catch (e) {
        }
    }
    getReminder() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-reminder');
        }
        catch (e) {
        }
    }
    getAssignVehicle() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-assign-vehicle');
        }
        catch (e) {
        }
    }
    
    getAssignVehicleShift() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-assign-vehicle-list');
        }
        catch (e) {
        }
    }  
    showErrorMessage(error) {
        if (error.session_expired === true || error.message === 'Invalid Token') {
            swal({
                type: 'error',
                customClass: 'swal-wide swal-error-custom-class',
                text: error.message,
            }).then((result) => {
                if (result.value) {
                    this.logout();
                }
            })
        }
        else {
            if (error.message == '') {
                swal({
                    type: 'error',
                    customClass: 'swal-wide swal-error-custom-class',
                    text: 'Something went wrong.',
                });
            }
            else {
                swal({
                    type: 'error',
                    customClass: 'swal-wide swal-error-custom-class',
                    text: error.message,
                });
            }
        }
    }
    showSuccessMessage(message: string) {
        swal({
            position: 'top-end',
           // type: 'success',
            background: '#d7f2c9',
            title: message,
            backdrop: false,
            customClass: 'swal-wide swal-custom-class',
            showConfirmButton: false,
            showCloseButton:true,
            timer: 2000
        });
    }
    showUnhandledErrorMessage() {
        console.log('unhandled error occured');
        // swal({
        //     type: 'error',
        //     title: 'Oops...',
        //     text: Constants.ErrorMessage
        // });
    }
    showCustomError(message) {
        swal({
            type: 'error',
            customClass: 'swal-wide swal-error-custom-class',
            text: message,
        });
    }
    logout() {
        this.loginService.logOut().subscribe(
            res => {
                if (res.success == true) {
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    this.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'logout');
            });
    }
    getUserInfo() {
        try {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let requestParameter = '?user_id='+userInfo.id;    
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-user-details' + requestParameter);
        }
        catch (e) {
        }
    }
    stickyHeader(){
        // When the user scrolls the page, execute myFunction 
        window.onscroll = function() {myFunction()};                
        // Get the header
        var header = document.getElementById("main_portlet");
        
        // Get the offset position of the navbar
        var sticky = header.offsetTop;
        
        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
            if (window.pageYOffset > sticky) {
            header.classList.add("m-portlet--sticky");
            } else {
            header.classList.remove("m-portlet--sticky");
            }
        }
    }

    getCompanySetting() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-company-setting');
        }
        catch (e) {
        }
    }

    addCompanySetting(request) {
        return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-company-setting', request);
    }
}
