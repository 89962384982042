import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class ChecklistService {
    constructor(private _httpHelper: HttpHelper) {
    }
    getCheckList(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-checklist', request);
        }
        catch (e) {
        }
    }

    addCheckList(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-checklist', request);
        }
        catch (e) {
        }
    }

    updateCheckList(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-checklist', request);
        }
        catch (e) {
        }
    }

    deleteCheckList(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-checklist', request);
        }
        catch (e) {
        }
    }

    getSingleCheckList(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-checklist', request);
        }
        catch (e) {
        }
    }

    getChecklistItem(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-checklist-list', request);
        }
        catch (e) {
        }
    }
}
