import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/services/report/report.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { Angular2Csv } from 'angular2-csv';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-vehicle-inspection-report',
  templateUrl: './vehicle-inspection-report.component.html',
  styleUrls: ['./vehicle-inspection-report.component.css']
})
export class VehicleInspectionReportComponent implements OnInit {
  isDemoTenant: boolean;
	loading:Boolean=false;
	inspectionReportList:any = [];
	tempSearch;
	searchTextValue = '';
	perPage: any = 10;
	pageNo: any = 1;
	page: number = 1;
	totalItem: any;
  noDataMsg = '';
  start_date;
  end_date;
  inspectionList: any;
  inspectionSelectedItems = [];
  inspectionSettings = {};
  inpectionId = '';
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  

  constructor(
    private router: Router,  
    public reportService: ReportService,
    private commonService:CommonService,
    private customErroHandler:CustomErrorHandler,
		private translate: TranslateService,
		private broadcaster: Broadcaster
  ) { }

  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.getInspection();
    this.getInspectionReport(1);
    this.translate.get('selectors').subscribe((res) => {
      this.inspectionSettings = {
        singleSelection: true,
        text: res.SelectInspection,
        enableSearchFilter: true,
        classes: "singleSelection"
      };
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('selectors').subscribe((res) => {
          this.inspectionSettings = {
            singleSelection: true,
            text: res.SelectInspection,
            enableSearchFilter: true,
            classes: "singleSelection"
          };
        });
    }); 
  }

  searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getInspectionReport(1);
		}
		this.tempSearch = this.searchTextValue;
  }
  
  getInspectionReport(currentPage) {
    // console.log(this.inspectionSelectedItems.length);
		this.loading = true; 
		this.page = currentPage;
		let queryParams = {
			row_per_page: this.perPage,
			page_no:this.page,
      search: this.searchTextValue,
      start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D MMM YYYY'),
      end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D MMM YYYY'),
      inspection_id: this.inspectionSelectedItems.length > 0 ? this.inspectionSelectedItems[0].id : ''
    }
		this.reportService.getVehicleInspectionReport(queryParams).subscribe(res => {
			this.loading = false; 
			if (res.success == true) {
				this.totalItem = res.data.count;
        this.inspectionReportList = res.data.rows;
				this.noDataMsg = res.message;
			} else {
				this.commonService.showErrorMessage(res);
			}
		}, error => {
			this.loading =false; 
			this.customErroHandler.handleHttpError(error, 'addInspection');
		});
  }

  getInspection() {
    this.reportService.inspectionLists().subscribe(res => {
      if (res.success == true) {
        this.inspectionList = res.data;        
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErroHandler.handleHttpError(error, 'getInspection');
    });
  }

  onInspectionSelect(item: any) {
    this.getInspectionReport(1);
  }

  onInspectionDeSelect(item: any) {
    this.getInspectionReport(1);
  }

  exportInspectionReport(){
    this.loading = true; 
    let queryParams = {
			row_per_page: 0,
			page_no:0,
      search: this.searchTextValue,
      start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D MMM YYYY'),
      end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D MMM YYYY'),
      inspection_id: this.inspectionSelectedItems.length > 0 ? this.inspectionSelectedItems[0].id : '',
      export: 'yes'
    }    

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Vehicle Inspection"
    };
  
    this.reportService.getVehicleInspectionReport(queryParams).subscribe(res => {
      this.loading =false; 
      new Angular2Csv(res.data, 'Vehicle Inspection List', options);
    }, error => {
          this.loading =false; 
          this.customErroHandler.handleHttpError(error, 'exportInspectionReport');
    });
	}

	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getInspectionReport(1);
	}
}
