import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { UserService } from 'src/app/services/user/user.service';
import { ShiftService } from 'src/app/services/shift/shift.service';

@Component({
  selector: 'app-assign-vehicle',
  templateUrl: './assign-vehicle.component.html',
  styleUrls: ['./assign-vehicle.component.css']
})
export class AssignVehicleComponent implements OnInit {
  loading:Boolean=false;
  assignData:any = {};
  pageLabel;
  userInfo:any;
  vehicleList:any = [];
  vehicleNameSettings = {};
  vehiclenameSelectedItems = [];
  shiftList:any = [];
  shiftSettings = {};
  shiftSelectedItems = [];
  driverList: any = [];
  driverSelectedItems = [];
  driverSettings = {};
  driverReguest: any = {}
  conductorList = [];
  conductorSelectedItems = [];
  conductorSettings = {};
  selectedAssignId;
  isEditMode: boolean;
  constructor(
    public userService: UserService,
    private customErrorHandler:CustomErrorHandler,
    public vehicleService: VehicleService,
    private commonService:CommonService,
    private router: Router,
    public shiftService: ShiftService,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userInfo = localStorage.getItem('userInfo');
    if (this.userInfo) {
        this.userInfo = JSON.parse(this.userInfo);
    }
    this.assignData.assignShifts = [{}];
    this.vehicleNameSettings = {
      singleSelection: true,
      text: 'Select Vehicle',
      enableSearchFilter: true,
      classes: "singleSelection"
    }
    this.shiftSettings = {
      singleSelection: true,
      text: 'Select Shift',
      enableSearchFilter: true,
      classes: "singleSelection"
    }
    this.driverSettings = {
      singleSelection: true,
      text: 'Select Driver',
      enableSearchFilter: true,
      classes: "singleSelection"
    }
    this.conductorSettings = {
      singleSelection: true,
      text: 'Select Conductor',
      enableSearchFilter: true,
      classes: "singleSelection"
    }
    
    this.pageLabel = 'Add';
    this.driverReguest.company_id = this.userInfo.company_id;
    this.selectedAssignId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedAssignId) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getSignleAssignVehicle(this.selectedAssignId);
    } else {
      this.isEditMode = false;
      this.getVehicleDetails();
      this.getAllShift();
      this.getDriverConductorList();
    }
  }

  getVehicleDetails(){
    this.vehicleService.getVehicle(0, 0).subscribe(res => {
      if (res.success == true) {
          this.vehicleList = res.data.rows;
          let vehicleId = this.assignData.vehicle_id;
          if (this.selectedAssignId && vehicleId != undefined && this.vehicleList.length > 0) {
            this.vehiclenameSelectedItems = [];					
            let vehicleTemp = this.vehicleList.find(s => s.id == vehicleId);
            if (typeof vehicleTemp != 'undefined') {
              this.vehiclenameSelectedItems.push(vehicleTemp);
            }
          }
      } else {
            this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getVehicle');
    });
  }

  getAllShift(){
    this.shiftService.getAllShift().subscribe(res => {
      if (res.success == true) {
        this.shiftList = res.data;

        if (this.selectedAssignId && this.assignData.assignShifts.length > 0 && this.vehicleList.length > 0) {
          this.shiftSelectedItems = [];	
          this.assignData.assignShifts.forEach((ele, idx) => {				
            let shiftTemp = this.shiftList.find(s => s.id == ele.shift_id);
            if (typeof shiftTemp != 'undefined') {
              let tempArr =  [];
              tempArr.push(shiftTemp);
              this.shiftSelectedItems[idx] = tempArr;
            }
          });
        }
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getAllShift');
    });
  }

  getDriverConductorList(){
    this.shiftService.getDriverConductorList().subscribe(res => {
      if (res.success == true) {
          // this.driverList = res.data.rows; 
          res.data.forEach(element => {
            if (element.is_conductor==0) {
              let obj:any = {};
              obj.id = element.id;
              obj.itemName = element.name;
              this.driverList.push(obj);

              if (this.selectedAssignId && this.assignData.assignShifts.length > 0 && this.driverList.length > 0) {
                this.driverSelectedItems = [];	
                this.assignData.assignShifts.forEach((ele, idx) => {				
                  let driverTemp = this.driverList.find(s => s.id == ele.driver_id);
                  if (typeof driverTemp != 'undefined') {
                    let tempArr =  [];
                    tempArr.push(driverTemp);
                    this.driverSelectedItems[idx] = tempArr;
                  }
                });
              }
            } else {
              let obj:any = {};
              obj.id = element.id;
              obj.itemName = element.name;
              this.conductorList.push(obj);
              if (this.selectedAssignId && this.assignData.assignShifts.length > 0 && this.conductorList.length > 0) {
                this.conductorSelectedItems = [];	
                this.assignData.assignShifts.forEach((ele, idx) => {
                  let conduTemp = this.conductorList.find(s => s.id == ele.conductor_id);
                  if (typeof conduTemp != 'undefined') {
                    let tempArr =  [];
                    tempArr.push(conduTemp);
                    this.conductorSelectedItems[idx] = tempArr;
                  }  
                });
              }
            }
          });
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getDriver');
    });
  }

  assignVehicle(data) {
    let arr = [];
    data.assignShifts.forEach((ele, idx) => {
      let obj:any = {};
      obj.shift_id = this.shiftSelectedItems[idx][0].id;
      obj.driver_id = this.driverSelectedItems[idx][0].id;
      obj.conductor_id = this.conductorSelectedItems[idx][0].id;
      arr.push(obj);       
    });

    let checkShiftDup = this.checkDuplicateInObject('shift_id', arr);
    if (checkShiftDup) {
      this.commonService.showCustomError('You cannot assign the same shift twice.');
      return false;
    }
    data.assignShifts = arr;

    if (this.isEditMode) {
      this.updateAssignVehicle(data)
    } else {
      this.loading = true;
      this.vehicleService.assignVehicleWithShift(data).subscribe(res => {
        if (res.success) {
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(['/vehicle-management/vehicle-assignment']);
        } else {
          this.commonService.showErrorMessage(res);
        }
        this.loading = false;
      }, err => {
        this.customErrorHandler.handleHttpError(err, 'assignVehicle');
      })
    }
    
  }
  

  onOpenvehiclename(event: any) {
    this.vehicleList = this.vehicleList.map((item) => {
        return {
            id: item.id,
            itemName: item.itemName
        }
    });
  }

  onItemSelectvehiclename(item: any) {
    this.assignData.vehicle_id = item.id;
  }

  shiftSelect(item: any) {
    // let shiftItem = this.shiftList.find(s => s.id == item.id);
    // let shiftIdx = this.shiftList.indexOf(shiftItem);
    // this.shiftList.splice(shiftIdx, 1);
  }

  shiftDeSelect(item: any) {
    // this.shiftList.push(item);
  }

  addMore() {
    if (this.assignData.assignShifts.length == this.shiftList.length) {
      return false;
    }
    let obj = {};
    this.assignData.assignShifts.push(obj);
  }

  removeItem(index, obj) {
    this.assignData.assignShifts.splice(index, 1);
    // let cloned = this.assignData.assignShifts.map(x => Object.assign({}, x));
    // this.assignData.assignShifts = cloned;
    let shiftItem = this.shiftSelectedItems.find(s => s.id == obj.id);
    let shiftIdx = this.shiftSelectedItems.indexOf(shiftItem);
    this.shiftSelectedItems.splice(shiftIdx, 1);
    let driverItem = this.driverSelectedItems.find(s => s.id == obj.id);
    let driverIdx = this.driverSelectedItems.indexOf(driverItem);
    this.driverSelectedItems.splice(driverIdx, 1);
    let conductorItem = this.conductorSelectedItems.find(s => s.id == obj.id);
    let conductorIdx = this.conductorSelectedItems.indexOf(conductorItem);
    this.conductorSelectedItems.splice(conductorIdx, 1);
  }

  onItemSelectDriver(item: any){
    console.log(item,'driver');
  }

  getSignleAssignVehicle(id){
    this.loading =true; 
    let queryParam = {
      parent_id: id
    }
    this.vehicleService.getSingleShiftAssign(queryParam).subscribe(res => {
      if (res.success == true) {
        this.assignData = res.data;
        this.getVehicleDetails();
        setTimeout(() => {
          this.getAllShift();
          this.getDriverConductorList();
          this.loading =false; 
        }, 500);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  updateAssignVehicle(data) {
    data.parent_id = this.selectedAssignId;
    this.vehicleService.updateVehicleShift(data).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(['/vehicle-management/vehicle-assignment']);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'update');
    });
  }

  checkDuplicateInObject(propertyName, inputArray) {
    let seenDuplicate = false,
        checkObj = {};
  
    inputArray.map(function(item) {
      let itemPropertyName = item[propertyName];    
      if (itemPropertyName in checkObj) {
        checkObj[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      }
      else {
        checkObj[itemPropertyName] = item;
        delete item.duplicate;
      }
    });  
    return seenDuplicate;
  }

}
