import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LiveTrackingService {

    userInfo :any;
    constructor(private _httpHelper: HttpHelper) {
        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }

    }

    getLiveTrackingDrivers(pageNo, rowsPerPage,searchTracking,vehicle_status,vehicleIdArray){
        try {
            if(vehicle_status =='Parked'){
                vehicle_status = 'Stop';
            }
            if(vehicle_status =='Non Reporting'){
                vehicle_status = 'No Data';
            }
            console.log(vehicle_status,'vehicle_statusvehicle_statusvehicle_status');
            let searchBy = '';
            if(typeof searchTracking.group_by != 'undefined'){
                searchBy += '&group_by='+searchTracking.group_by;
            }
            if(typeof searchTracking.vehicle_id != 'undefined'){
                searchBy += '&vehicle_id='+searchTracking.vehicle_id;
            }
            if(typeof searchTracking.status != 'undefined'){
                searchBy += '&status='+searchTracking.status;
            }
            if(typeof searchTracking.search_by != 'undefined'){
                searchBy += '&search_by='+searchTracking.search_by;
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&vehicle_status='+vehicle_status+'&vehicleIdArray='+vehicleIdArray+'&company_id='+this.userInfo.company_id+''+searchBy;
            return this._httpHelper.get(Config.AuthenticationBaseURLv2 + 'live-driver-details'+requestParameter)
        }
        catch (e) {
        }
    }

    getCurrentTrip(requestParameter){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-current-trip', requestParameter)
        }
        catch (e) {
        }
    }

    getCurrentTripWithoutLogin(requestParameter){
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'get-current-vehicle_data', requestParameter)
        }
        catch (e) {
        }
    }
}
