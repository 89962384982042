import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
	loading:boolean;
	public isDemoTenant:boolean;
	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	employeeDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	userInfo: any;
	deleteDetails: any = {};
	serverUrl: any;
	loginUserId: any;
	searchTempVal: any;
	currentTime: any = new Date();
	deleteMsg: any;
	deleteLabel: any;
	cancelLabel: any;
	EditLabel:string;

  	constructor(
			private router: Router, 
			private commonService:CommonService, 
			private http: HttpClient, 
			public userService: UserService,
			private customErrorHandler:CustomErrorHandler,
			private translate: TranslateService,
			private broadcaster: Broadcaster
		) {
			this.userInfo = localStorage.getItem('userInfo');
			if(this.userInfo){
					this.userInfo = JSON.parse(this.userInfo);
			}
			this.translate.get('DeleteOption').subscribe((res) => {
				setTimeout(() => {
					this.deleteMsg = res.DeleteAlert;
					this.deleteLabel = res.Delete;
					this.cancelLabel = res.Cancel;
					this.EditLabel = res.Edit;
				});
			});
			this.broadcaster.on<string>('langEvent').subscribe(message => {
				this.translate.get('DeleteOption').subscribe((res) => {
						setTimeout(() => {
								this.deleteMsg = res.DeleteAlert;
								this.deleteLabel = res.Delete;
								this.cancelLabel = res.Cancel;
								this.EditLabel = res.Edit;
						});
				});
			});
  	}

  	ngOnInit() {
  		this.searchDetails.search_by = '';
		this.searchTempVal = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.company_id = this.userInfo.company_id;
  		this.loginUserId = this.userInfo.id;
  		this.getEmployeeDetails(this.pageNo);
  		localStorage.removeItem('editEmployeeId');
  		this.serverUrl = Config.FILEURL;
		this.searchDetails.status = '';
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getEmployeeDetails(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {};
		this.userService.getEmployee(this.pageNo, this.perPage, this.searchDetails).subscribe(
	        res => {
						this.loading =false; 
						this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
	          	this.employeeDetails = res.data.rows;
	          } else {
							this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getEmployee');
	        }
	      )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getEmployeeDetails(this.pageNo);
		}
	}

	editEmployee(employeeId){
		localStorage.setItem('editEmployeeId', employeeId);
		this.router.navigate(['./user-management/employee']);
	}

	deleteEmployee(employeeId){		
		this.deleteDetails.user_id = employeeId;
		swal({
					type: 'error',
					text: this.deleteMsg,
					confirmButtonText: this.deleteLabel,
					showCancelButton:true,
					cancelButtonText: this.cancelLabel
        }).then((result) => {
            if (result.value) {
		    	this.userService.deleteEmployee(this.deleteDetails).subscribe(
			        res => {
			          if (res.success == true) {
			          	this.pageNo = 1;
			            this.commonService.showSuccessMessage(res.message);
			          	this.getEmployeeDetails(this.pageNo);


			          } else {
			            this.commonService.showErrorMessage(res);
			          }
			        },
			        error => {
			          this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
			        }
			    )
		    }
        })   
    }

}
