import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class InspectionService {
    constructor(private _httpHelper: HttpHelper) {
    }

    addInspection(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-inspection', request);
        }
        catch (e) {
        }
    }

    updateInspection(request){
    	try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-inspection', request);
        }
        catch (e) {
        }
    }

    getInspection(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-inspection', request);
        }
        catch (e) {
        }
    }

    getSingleInspection(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-inspection', request);
        }
        catch (e) {
        }
    }

    deleteInspection(request){
        try {
           return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-inspection', request);
       }
       catch (e) {
       }
    }

    inspectionLists(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-inspection-list');
        }
        catch (e) {
        }
    }

    addAssignInspection(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-assign-check-list', request);
        }
        catch (e) {
        }
    }

    updateAssignInspection(request){
    	try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'edit-inspection-assign', request);
        }
        catch (e) {
        }
    }
    getAssignInspection(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-assign-check-list', request);
        }
        catch (e) {
        }
    }
    getSingleAssignInspection(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-assignment', request);
        }
        catch (e) {
        }
    }

    deleteAssignInspection(request){
        try {
           return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-assignment', request);
       }
       catch (e) {
       }
    }
    viewInspection(request){
    	try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-inspection-result', request);
        }
        catch (e) {
        }
    }
}
