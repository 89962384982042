import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { InspectionService } from '../../../services/inspection/inspection.service';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Constants } from 'src/app/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.css']
})
export class InspectionListComponent implements OnInit {

  public isDemoTenant:boolean;
  loading:Boolean=false;
  inspectionList:any = [];
  tempSearch;
  searchTextValue = '';
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  noDataMsg;
  deleteMsg: any;
	deleteLabel: any;
  cancelLabel: any;
  EditLabel:string;
  constructor(
    private router: Router,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public inspectionService: InspectionService,
    private translate: TranslateService,
    private broadcaster: Broadcaster
  ) { 
    this.translate.get('DeleteOption').subscribe((res) => {
      setTimeout(() => {
        this.deleteMsg = res.DeleteAlert;
        this.deleteLabel = res.Delete;
        this.cancelLabel = res.Cancel;
        this.EditLabel = res.Edit;
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('DeleteOption').subscribe((res) => {
          setTimeout(() => {
              this.deleteMsg = res.DeleteAlert;
              this.deleteLabel = res.Delete;
              this.cancelLabel = res.Cancel;
              this.EditLabel = res.Edit;
          });
      });
    });
  }

  ngOnInit() {
    this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    this.getInspectionData(1);
  }

  editInspection(value) {
    this.router.navigate(['/inspection-management/add-inspection'], { queryParams: { id: value.id } });
  }

  

  searchByFilter(event){
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getInspectionData(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  getInspectionData(currentPage) {
    this.pageNo = currentPage;
    this.loading = true; 
    this.page = currentPage;
    let queryParams = {
      row_per_page: this.perPage,
      page_no:this.page,
      search: this.searchTextValue
    }
    // let itemName:any = [];
    this.inspectionService.getInspection(queryParams).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.inspectionList = res.data.rows;
        this.inspectionList.map((obj) => { 
          let itemObj = [];
          obj.inspection_item.forEach(element => {
            itemObj.push(element.item_name);
          });
          obj.inspection_item = itemObj.toString();
        })
        this.noDataMsg = res.message;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  deleteInspection(val){     
    let id = val.id		
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton:true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        let rawData = {
          inspection_id: id
        }
        this.loading = true;
        this.inspectionService.deleteInspection(rawData).subscribe(
           res => {
              if (res.success == true) {
                 this.commonService.showSuccessMessage(res.message);
                 this.getInspectionData(1);
              } else {
                  this.commonService.showErrorMessage(res);
              }
              this.loading =false; 
           },
           error => {
              this.customErrorHandler.handleHttpError(error, 'getVehicle');
              this.loading =false; 
           }
        )
      }
    });   
  }
}
