import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { WorkOrderService } from '../../../services/work-order/work-order.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user/user.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
	loading:boolean;
	public isDemoTenant:boolean;
	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	orderDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	modalRef: BsModalRef;
	assignDetails: any = {};
	userInfo: any = {};
	driverRequestParameter: any = {};
	driverList: any = [];
	deleteDetails: any = {};
	searchTempVal: any;
	deleteMsg: any;
	deleteLabel: any;
	cancelLabel: any;
	EditLabel:string;
	orderStatusList: any = [];

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private http: HttpClient, 
		public _workOrderService: WorkOrderService,
		private customErrorHandler:CustomErrorHandler,
		public _commonService: CommonService,
		public userService: UserService,
		private translate: TranslateService,
	private broadcaster: Broadcaster
	) {
			this.userInfo = localStorage.getItem('userInfo');
			if(this.userInfo){
					this.userInfo = JSON.parse(this.userInfo);
			}
			this.translate.get('DeleteOption').subscribe((res) => {
				setTimeout(() => {
					this.deleteMsg = res.DeleteAlert;
					this.deleteLabel = res.Delete;
					this.cancelLabel = res.Cancel;
					this.EditLabel = res.Edit;
				});
			});
			this.broadcaster.on<string>('langEvent').subscribe(message => {
				this.translate.get('DeleteOption').subscribe((res) => {
						setTimeout(() => {
								this.deleteMsg = res.DeleteAlert;
								this.deleteLabel = res.Delete;
								this.cancelLabel = res.Cancel;
								this.EditLabel = res.Edit;
						});
				});
			});
	}

  	ngOnInit() {
		this.searchTempVal = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.getOrderDetails(this.pageNo);
  		this.getOrderStatusList();
  		localStorage.removeItem('editOrderId');
  		this.orderDetails.status = '';
		this.searchDetails.status = '';
		this.searchDetails.search_by = '';
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getOrderDetails(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		this._workOrderService.getOrder(this.pageNo, this.perPage, this.searchDetails).subscribe(
	        res => {
						this.loading =false; 
						this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
	          	this.orderDetails = res.data.rows;
	          } else {
	           	this.totalItem = 0;
							 this.orderDetails = [];
							 this._commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'getOrder'); 
	        }
	      )
	}

	editOrder(orderId){
    	localStorage.setItem('editOrderId', orderId);
    	this.router.navigate(['./work-order/order']);
    }

    deleteOrder(orderId){		
        swal({
            type: 'error',
            text: this.deleteMsg,
						confirmButtonText: this.deleteLabel,
						showCancelButton:true,
						cancelButtonText: this.cancelLabel
        }).then((result) => {
            if (result.value) {
		    	this.deleteDetails.order_id = orderId;
		    	this._workOrderService.deleteOrder(this.deleteDetails).subscribe(
			        res => {
			          if (res.success == true) {
			          	this.pageNo = 1;
			            this._commonService.showSuccessMessage(res.message);
			          	this.getOrderDetails(this.pageNo);
			          } else {
			            this._commonService.showErrorMessage(res);
			          }
			        },
			        error => {
			          this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
			        }
			    )
		    }
        })   
    }

  	searchByFilter(search = null) {
    	if(search == 'title'){
	      	if(this.searchTempVal != this.searchDetails.search_by){
		        this.searchTempVal = this.searchDetails.search_by;
		        this.pageNo = 1;
		        this.getOrderDetails(this.pageNo);
	      	}
    	}else{
      		this.pageNo = 1;
      		this.getOrderDetails(this.pageNo);
    	}    
  	}


	getOrderStatusList(){
  		this._commonService.getOrderStatus().subscribe(
    		res => {
      			if (res.success == true) {
        			this.orderStatusList = res.data; 
        			this.orderStatusList.unshift({
                        'id': '',
                        'itemName': 'All'
                    });
      			}
		    },
		    error => {
		      this.customErrorHandler.handleHttpError(error, 'getUser');
		    }
  		)
	}
}
