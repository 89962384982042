import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { TripService } from '../../../services/trip/trip.service';
import { CommonService } from 'src/app/services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from 'src/app/services/user/user.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { WorkOrderService } from '../../../services/work-order/work-order.service';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { ChecklistService } from '../../../services/checklist/checklist.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-trip-list',
  templateUrl: './trip-list.component.html',
  styleUrls: ['./trip-list.component.css']
})
export class TripListComponent implements OnInit {
	loading:boolean;
	isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	tripDetails: any = [];
	page: number = 2;
	userDetails: any;
	searchDetails: any ={};
	driverList: any = [];
	assignDetails: any = {};
	modalRef: BsModalRef;
	userInfo: any;
	cancelDetails: any = {};
	driverRequestParameter: any = {};
	driverListDetails: any = [];
	orderAssignDriverList: any = [];
	orderList: any = [];
	calculateDistance: any;
	calculateTime: any;
	calculateAmount: any;
	driverDetails:any = [];
	vehicleDetails:any = [];
	searchTempVal: any;
	tripType: any;
	workorderSelectedItems = [];
	workorderSettings = {};
	tripErrorMessage: any = '';
	checklistDetails: any = {};
	assignOrderOnTrip: any = [];
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
	AssignWorkOrder:string;
	deleteLabel:string;
	showAddTrip: number = 0;

  	constructor(private modalService: BsModalService, private router: Router, private http: HttpClient, public tripService: TripService,public workOrderService : WorkOrderService,private commonService:CommonService, private userService:UserService, private customErroHandler:CustomErrorHandler, public vehicleService: VehicleService, public checklistService: ChecklistService,
		private translate: TranslateService,
		private broadcaster: Broadcaster) {
  		this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);

        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
		this.translate.get('DeleteOption').subscribe((res) => {
			  setTimeout(() => {
				this.AssignWorkOrder = res.AssignWorkOrder;
				this.deleteLabel = res.Delete;
			  });
		  });
		  this.broadcaster.on<string>('langEvent').subscribe(message => {
			  this.translate.get('DeleteOption').subscribe((res) => {
				  setTimeout(() => {
					this.AssignWorkOrder = res.AssignWorkOrder;
                    this.deleteLabel = res.Delete;
				  });
			  });
		  });
  	}

  	ngOnInit() {
  		this.searchDetails.search_by = 'complete';
  		this.workorderSettings = {
            singleSelection: false,
            text: "Select work order",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true
        };

  		this.tripType = localStorage.getItem('isGps') == 'true' ? 'auto' : 'assigned';
		this.searchTempVal = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.start_date = '';
  		this.searchDetails.end_date = '';
  		this.searchDetails.search_by = '';
  		this.searchDetails.status = '';
  		this.searchDetails.driver = '';
  		this.searchDetails.vehicle = '';
  		this.getTripDetails(this.pageNo);
		// this.getOrderDetails();
		this.getsearchParam();
  		this.assignDetails.order_id = '';
  		localStorage.removeItem('playBackTrackingId');
  		localStorage.removeItem('playbackVehicleId');
  		this.calculateDistance = Constants.CalculateDistance;
  		this.calculateTime = Constants.CalculateTime;
		this.calculateAmount = Constants.CalculateAmount;
		
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getTripDetails(currentPage){
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {};
		this.searchDetails.trip_type = this.tripType;
		this.tripDetails = [];
		this.totalItem = 0;
		console.log(this.searchDetails,'search')
		if(this.searchDetails.start_date != undefined && this.searchDetails.start_date != '' && this.searchDetails.start_date != null)
		{
			this.searchDetails.start_date = moment(this.searchDetails.start_date).format('YYYY-MM-DD');
		}
		else{
			this.searchDetails.start_date = '';
		}

		if(this.searchDetails.end_date != undefined && this.searchDetails.end_date != '' && this.searchDetails.end_date != null)
		{
			this.searchDetails.end_date = moment(this.searchDetails.end_date).format('YYYY-MM-DD');
		}
		else{
			this.searchDetails.end_date = '';
		}

		this.tripService.getTrip(this.pageNo, this.perPage,this.userDetails.company_id,this.searchDetails).subscribe(
	        res => {
				this.loading =false; 
				this.tripErrorMessage = res.message;
	          	if (res.success == true) {
		          	this.totalItem = res.data.count;
		          	this.tripDetails = res.data.rows;
		          	this.showAddTrip = res.add_trip;
	          	} else {
		    		this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	         	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
	}
	getsearchParam(){
		let pageNo = 1,rowsPerPage = 100,searchDetails: any ={};
		searchDetails.company_id = this.userInfo.company_id;
		this.userService.getDriver(pageNo, rowsPerPage,searchDetails).subscribe(
			res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
					this.driverDetails = res.data.rows;
				} else {
					this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading =false; 
				this.customErroHandler.handleHttpError(error, 'getDriver');
			}
		)
		
		this.vehicleService.getVehicle(pageNo, rowsPerPage, this.searchDetails).subscribe(
			res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
					this.vehicleDetails = res.data.rows;
					this.vehicleDetails.forEach((item, index) => {
						if(item.vehicle_name.length > 50) item.vehicle_name = item.vehicle_name.substring(0,50);
					  });
				} else {
				this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading =false; 
				this.customErroHandler.handleHttpError(error, 'getVehicle');
			}
		)
	}

	searchByFilter(search = null){
		if(search == 'title'){
			if(this.searchTempVal != this.searchDetails.search_by){
				this.searchTempVal = this.searchDetails.search_by;
				this.pageNo = 1;
				this.getTripDetails(this.pageNo);
			}
		}else{
			this.pageNo = 1;
			this.getTripDetails(this.pageNo);
		}

	}

	openTripAssignModal(template, tripId) {
		this.assignDetails = {}
        this.assignDetails.trip_id = tripId;
        this.assignDetails.company_id = this.userInfo.company_id;
        this.assignDetails.assigned_by = this.userInfo.id;
        this.assignDetails.driver_id = '';
        this.getFreeDriverDetails();
        this.modalRef = this.modalService.show(template);
    }

    assignTrip(){
    	this.tripService.assignTrip(this.assignDetails).subscribe(
	        res => {
	          if (res.success == true) {
	          	this.getTripDetails(this.pageNo);
	            this.commonService.showSuccessMessage(res.message);
	          } else {
	            this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
	          this.customErroHandler.handleHttpError(error, 'assignTrip');          
	        }
	    )
	    this.modalRef.hide();

    }

    getFreeDriverDetails(){
        this.userService.getFreeDriver(0,0, this.assignDetails).subscribe(
            res => {
              	if (res.success == true) {
                	this.driverList = res.data;
                	console.log(this.driverListDetails,'driver')
              	}
            },
            error => {
              this.customErroHandler.handleHttpError(error, 'driverList');
            }
        )
    }

    cancelTrip(tripId){
		swal({
			type: 'error',
			text: Constants.CancelMsg,
			confirmButtonText: 'Yes',
			showCancelButton:true,
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
		    	this.cancelDetails.trip_id = tripId;
		    	this.cancelDetails.cancelled_by = this.userInfo.id;
		    	this.tripService.cancelTrip(this.cancelDetails).subscribe(
			        res => {
			          	if (res.success == true) {
			           		this.pageNo = 1;
				            this.commonService.showSuccessMessage(res.message);
							this.getTripDetails(this.pageNo);
				        } else {
			            	this.commonService.showErrorMessage(res);
			          	}
			        },
			        error => {
			          this.customErroHandler.handleHttpError(error, 'cancelTrip');          
			        }
				)
			}
		})
	}

	openOrderAssignModal(template, tripId, vehicleId) {
		this.getOrderDetails(vehicleId, tripId);
		this.workorderSelectedItems = []
		this.assignDetails = {}
        this.modalRef = this.modalService.show(template);
        this.assignDetails.trip_id = tripId;
        this.assignDetails.company_id = this.userInfo.company_id;
        this.assignDetails.assigneBy = this.userInfo.id;
    }

    assignOrder(){
    	this.assignDetails.order_id = [];
    	for(let i = 0;i < this.workorderSelectedItems.length;i++){
            this.assignDetails.order_id.push(this.workorderSelectedItems[i].id);
        }
        if(this.assignDetails.order_id.length <= 0){
        	return false;
        }
    	this.tripService.assignOrder(this.assignDetails).subscribe(
	        res => {
	          if (res.success == true) {
	          	this.getTripDetails(this.pageNo);
	            this.commonService.showSuccessMessage(res.message);
	          } else {
	            this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
	           this.customErroHandler.handleHttpError(error, 'assignOrder');             
	        }
	    )
	    this.modalRef.hide();
	    
    }

    getOrderDetails(vehicleId, tripId){
    	this.assignOrderOnTrip = [];
      	if (vehicleId) {
            this.tripService.getOrderByVehicle(vehicleId, tripId).subscribe(
                res => {
                    this.message = res.message;
                    if (res.success == true) {
                        this.orderList = res.data;
                        this.assignOrderOnTrip = res.assign_order;
                    } else {

                        this.orderList = [];
                        this.commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErroHandler.handleHttpError(error, 'getOrder');
                }
            )
        } else {
            this.orderList = []
        }
	}

	playBackTracking(tripid, vehicleId, completeDate){
		localStorage.setItem('playBackTrackingId', tripid);
		localStorage.setItem('playbackVehicleId', vehicleId);
		localStorage.setItem('playBackCompleteTripDate', completeDate)
		this.router.navigate(['./play-back']);
	}

	liveTracking(tripid){
		localStorage.setItem('liveTrackingId', tripid);
		this.router.navigate(['./live-tracking']);
	}

	searchTripType(type){
		this.tripType = type;
		this.pageNo = 1;
		this.getTripDetails(this.pageNo);
	}

	// onItemSelectworkorder(item: any){

	// }

	openChecklistModal(template, checklistId, tripId){
		this.workorderSelectedItems = []
		this.checklistDetails = {};
		
        this.modalRef = this.modalService.show(template);
        // this.assignDetails.trip_id = tripId;
        let requestParameter = {
        	checklist_id: checklistId,
        	trip_id: tripId
        }
        this.checklistService.getSingleCheckList(requestParameter).subscribe(
	        res => {
				if (res.success == true) {
		          	this.checklistDetails = res.data;
	          	} else {
		    		this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	         	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
       
	}
	changedDate(item: any){
		this.searchDetails.start_date = item.startDate.format('YYYY-MM-DD');
		this.searchDetails.end_date = item.endDate.format('YYYY-MM-DD')
		this.getTripDetails(1);
	}
}
