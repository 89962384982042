import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Config } from 'src/app/config/config';
import { UserService } from '../../../services/user/user.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { Router } from '@angular/router';

@Component({
   selector: 'app-vehicle-assignment',
   templateUrl: './vehicle-assignment.component.html',
   styleUrls: ['./vehicle-assignment.component.css']
})
export class VehicleAssignmentComponent implements OnInit {

   isDemoTenant:boolean;
	modalRef: BsModalRef;
   dateTime:Date;
   required_at:any;
   value: Date;
   userInfo:any;
   imageBase:string;
   driverList: any = [];
   message: any;
   vehicleList: any = [];
   assignDetails: any = {};
   vehicleNameSettings = {};
   vehiclenameSelectedItems = [];
   driverSelectedItems = [];
   driverSettings = {};
   driverReguest: any = {}
   vehicleAssignmentList: any = [];
   totalItem: any;
   perPage: any = 10;
   pageNo: any = 1;
   searchDetails: any ={};
   loading: boolean;
   searchTempVal: any;
   page: number = 1;
   updateAssignVehicle:any;
   assignmentType: any;
   deleteAssignDetails:any = {};
   dateTimeEnd: any ;
   endDateTime: any;
   startDateTime: any;
   timeDiffMsg: any = '';
   vehicleAssignmentDisabled: boolean = false;
   start_date;
	end_date;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
   }
   deleteMsg: any;
   deleteLabel: any;
   cancelLabel: any;
   editLabel:string;
   conductorList = [];
   conductorSelectedItems = [];
   conductorSettings = {};
   isParentApp;
   constructor(
      private modalService: BsModalService,
      public userService: UserService,
      private customErrorHandler:CustomErrorHandler,
      public vehicleService: VehicleService,
      private commonService:CommonService,
      private translate: TranslateService,
      private broadcaster: Broadcaster,
      private router: Router,
   ) { 
      this.userInfo = localStorage.getItem('userInfo');
      if(this.userInfo){
         this.userInfo = JSON.parse(this.userInfo);
         // this.isParentApp = this.userInfo.is_parent == 1 ? true : false;
         this.isParentApp = false;
      }
      this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
                this.deleteMsg = res.DeleteAlert;
                this.deleteLabel = res.Delete;
                this.cancelLabel = res.Cancel;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
                setTimeout(() => {
                    this.deleteMsg = res.DeleteAlert;
                    this.deleteLabel = res.Delete;
                    this.cancelLabel = res.Cancel;
                });
            });
		});
   }
   ngOnInit() {
      this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
      this.assignmentType = 'Add';
      this.searchTempVal = '';
      this.loading = false;
      this.totalItem = 0;
      this.searchDetails.start_date = '';
      this.searchDetails.end_date = '';
      this.searchDetails.search_by = '';
      this.assignDetails.message = '';
      this.driverReguest.company_id = this.userInfo.company_id;
      this.assignDetails.notification = false;


      this.dateTime = new Date();
      this.dateTimeEnd = new Date (this.dateTime);
      this.dateTimeEnd.setMinutes ( this.dateTime.getMinutes() + 30 );
      this.dateTimeEnd = new Date(this.dateTimeEnd);
      this.userInfo=JSON.parse(localStorage.getItem('userInfo'));
      this.imageBase= Config.AuthenticationURL;
      this.getVehicleDetails();
      this.getDriverList();
      // this.getConductorList();
      this.getAssignDetails(this.pageNo);



      this.translate.get('selectors').subscribe((res) => {
         this.vehicleNameSettings = {
            singleSelection: true,
            text: res.SelectVehicle,
            enableSearchFilter: true,
            classes: "singleSelection"
         };
         this.driverSettings = {
            singleSelection: true,
            text: res.SelectDriver,
            enableSearchFilter: true,
            classes: "singleSelection"
         };
         this.conductorSettings = {
            singleSelection: true,
            text: res.SelectDriver,
            enableSearchFilter: true,
            classes: "singleSelection"
         };
      });

      this.broadcaster.on<string>('langEvent').subscribe(message => {
          this.translate.get('selectors').subscribe((res) => {
            this.vehicleNameSettings = {
               singleSelection: true,
               text: res.SelectVehicle,
               enableSearchFilter: true,
               classes: "singleSelection"
            };
            this.driverSettings = {
               singleSelection: true,
               text: res.SelectDriver,
               enableSearchFilter: true,
               classes: "singleSelection"
            }
            this.conductorSettings = {
               singleSelection: true,
               text: res.SelectDriver,
               enableSearchFilter: true,
               classes: "singleSelection"
            };
          });
      }); 
      
		this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
            this.deleteLabel = res.Delete;
				this.editLabel = res.Edit;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
               setTimeout(() => {
                  this.deleteLabel = res.Delete;
                  this.editLabel = res.Edit;
               });
            });
		});
   }
  
   openVehicleAssignModal(template, assignId = null) {
      this.assignDetails = {};
      this.assignDetails.notification = false;
      this.updateAssignVehicle = assignId;
      this.assignmentType = 'Add';
      if(assignId){
         this.assignmentType = 'Edit';
         this.getSingleAssignDetails(assignId);
      }else{
         this.vehiclenameSelectedItems = [];
         this.driverSelectedItems = [];
         this.assignDetails.vehicle_id = '';
         this.assignDetails.driver_id = '';
         this.assignDetails.unassignAt = '';
         this.assignDetails.assignAt = '';
         this.assignDetails.message = '';
      }
      this.modalRef = this.modalService.show(template);
   }

   onEdit(assignId) {
      this.router.navigate(['/vehicle-management/assign-vehicle'], { queryParams: { id: assignId } });
   }

   // getConductorList(){
   //    this.conductorList = [];
   //    this.userService.getConductorList().subscribe(
   //       res => {
   //          if (res.success == true) {
   //             res.data.forEach(element => {
   //                let obj:any = {}
   //                obj.id = element.id
   //                obj.itemName = element.name;
   //                this.conductorList.push(obj);
   //             });
   //          }
   //       },
   //       error => {
   //          this.customErrorHandler.handleHttpError(error, 'getDriver');
   //       }
   //    )
   // }

   getDriverList(){
      this.userService.getDriver(0,0,this.driverReguest).subscribe(
         res => {
            if (res.success == true) {
               // this.driverList = res.data.rows; 
               res.data.rows.forEach(element => {
                  if (element.is_conductor==0) {
                     this.driverList.push(element);
                  }
               });
            }
         },
         error => {
            this.customErrorHandler.handleHttpError(error, 'getDriver');
         }
      )
   }

   getVehicleDetails(){
      this.vehicleService.getVehicle(0, 0).subscribe(
         res => {
            this.message = res.message;
            if (res.success == true) {
                this.vehicleList = res.data.rows;
            } else {
                  this.commonService.showErrorMessage(res);
            }
         },
         error => {
            this.customErrorHandler.handleHttpError(error, 'getVehicle');
         }
      )
   }
   onItemSelectvehiclename(item: any) {
      console.log(item, 'vehicle name');
      this.assignDetails.vehicle_id = item.id;
      // this.getDriverByVehicle(item.id);
        // this.tripDetails.vehicle_id = item.itemName;
   }
   
   onItemSelectDriver(item: any){
      this.assignDetails.driver_id = item.id;
      console.log(item,'driver');
   }
   onOpenvehiclename(event: any) {
        this.vehicleList = this.vehicleList.map((item) => {
            return {
                id: item.id,
                itemName: item.itemName
            }
        });
   }

   assignVehicle(){
      this.loading = true;
      this.vehicleAssignmentDisabled = true;
      if(this.updateAssignVehicle == '' || this.updateAssignVehicle == null){
         this.assignDetails.createdBy = this.userInfo.id;
         this.vehicleService.assignVehicle(this.assignDetails).subscribe(
            res => {
               this.vehicleAssignmentDisabled = false;
               this.loading = false;
               if (res.success == true) {
                     this.loading = true;
                     this.commonService.showSuccessMessage(res.message);
                     this.pageNo = 1;
                     this.getAssignDetails(this.pageNo);
                     this.modalRef.hide();
               } else {
                  this.commonService.showErrorMessage(res);
               }
            },
            error => {
               this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
            }
         )
         
      }else{
         this.assignDetails.createdBy = this.userInfo.id;
         this.vehicleService.updateAssignVehicle(this.assignDetails).subscribe(
            res => {
               this.vehicleAssignmentDisabled = false;
               this.loading = false;
               if (res.success == true) {
                  this.pageNo = 1;
                  this.getAssignDetails(this.pageNo);
                  this.commonService.showSuccessMessage(res.message);
                  this.modalRef.hide();
               } else {
                  this.commonService.showErrorMessage(res);
               }
            },
            error => {
               this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
            }
         )
         
      }
   }

   getAssignDetails(currentPage){
      this.loading =true;
      this.pageNo = currentPage;
      this.page = currentPage;
      this.searchDetails.start_date = this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY');
      this.searchDetails.end_date= this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY');

      if (this.isParentApp) {
         this.vehicleService.getShiftAssignVehicle(this.pageNo, this.perPage, this.searchDetails).subscribe(res => {
            this.message = res.message;
            if (res.success == true) {
               this.loading = false;
               this.totalItem = res.data.count;
               this.vehicleAssignmentList = res.data.rows;
            } else {
               this.commonService.showErrorMessage(res);
               this.loading = false;
            }
         }, error => {
            this.customErrorHandler.handleHttpError(error, 'getVehicle');
            this.loading = false;
         });
      } else {
         this.vehicleService.getAssignVehicle(this.pageNo, this.perPage, this.searchDetails).subscribe(res => {
            this.message = res.message;
            if (res.success == true) {
               this.loading = false;
               this.totalItem = res.data.count;
               this.vehicleAssignmentList = res.data.rows;
            } else {
               this.commonService.showErrorMessage(res);
               this.loading = false;
            }
         }, error => {
            this.customErrorHandler.handleHttpError(error, 'getVehicle');
            this.loading = false;
         });
      }
   }

   searchByFilter(search = null){
      if(search == 'title'){
         if(this.searchTempVal != this.searchDetails.search_by){
            this.searchTempVal = this.searchDetails.search_by;
            this.pageNo = 1;
            this.getAssignDetails(this.pageNo);
         }
      }else{
         this.pageNo = 1;
         this.getAssignDetails(this.pageNo);
      }
   }

   getSingleAssignDetails(assignId){
      this.vehicleService.getSingleAssignmentVehicle(assignId).subscribe(
         res => {
            this.message = res.message;
            if (res.success == true) {
               this.assignDetails = res.data.vehicleAssignment;
               this.setAssignVehicle();
               this.setAssignDriver();
               this.assignDetails.assignedAt = new Date(this.assignDetails.assignedAt);//this.datePipe.transform(this.assignDetails.assignedAt, 'yyyy-dd-MM H:mm');
               this.assignDetails.unassignAt = new Date(this.assignDetails.unassignAt);
            } else {
               this.commonService.showErrorMessage(res);
            }
         },
         error => {
            this.customErrorHandler.handleHttpError(error, 'getVehicle');
         }
      )
   }

   setAssignVehicle(){
      let vehicle = this.vehicleList.find(s => s.id == this.assignDetails.vehicle_id);
      if(vehicle != undefined){
         this.vehiclenameSelectedItems = [];// [{ "id": "107", "itemName": "India" }];//[];
         this.vehiclenameSelectedItems.push(vehicle);
      }
   }

   setAssignDriver(){
      let driver = this.driverList.find(s => s.id == this.assignDetails.driver_id);
      if(driver != undefined){
         this.driverSelectedItems = [];
         this.driverSelectedItems.push(driver);
      }
   }

   deleteVehicleAssignment(assignId){
      swal({
         type: 'error',
         text: this.deleteMsg,
			confirmButtonText: this.deleteLabel,
			showCancelButton:true,
			cancelButtonText: this.cancelLabel
      }).then((result) => {
         if (result.value) {
            if (this.isParentApp) {
               this.deleteAssignDetails.parent_id = assignId;
               this.vehicleService.deleteVehicleWithShift(this.deleteAssignDetails).subscribe(res => {
                  this.message = res.message;
                  if (res.success == true) {
                     this.pageNo = 1;
                     this.commonService.showSuccessMessage(res.message);
                     this.getAssignDetails(this.pageNo);
                  } else {
                        this.commonService.showErrorMessage(res);
                  }
               }, error => {
                  this.customErrorHandler.handleHttpError(error, 'getVehicle');
               });
            } else {
               this.deleteAssignDetails.id = assignId;
               this.vehicleService.deleteVehicleAssignment(this.deleteAssignDetails).subscribe(res => {
                  this.message = res.message;
                  if (res.success == true) {
                     this.pageNo = 1;
                     this.commonService.showSuccessMessage(res.message);
                     this.getAssignDetails(this.pageNo);
                  } else {
                        this.commonService.showErrorMessage(res);
                  }
               }, error => {
                  this.customErrorHandler.handleHttpError(error, 'getVehicle');
               });
            }
         }
      });
   }
   checkDate(){
      alert(this.assignDetails.assignedAt);
   }
   selectStartDate(){
      let timeDiff = this.dateDifferenceInMinutes(this.assignDetails.assignedAt, this.assignDetails.unassignAt);
      if(timeDiff == NaN){
         timeDiff = this.dateDifferenceInMinutes(this.assignDetails.assignedAt, this.dateTimeEnd)
      }
      if(timeDiff < 30){
         this.dateTimeEnd = new Date (this.assignDetails.assignedAt);
         this.dateTimeEnd.setMinutes ( this.assignDetails.assignedAt.getMinutes() + 30 );
         this.dateTimeEnd = new Date(this.dateTimeEnd);
         this.assignDetails.unassignAt = this.dateTimeEnd;
      }
   }

   dateDifferenceInMinutes(startDate, endDate){
      this.startDateTime = new Date(startDate);
      this.endDateTime = new Date(endDate);
      let diff = this.endDateTime - this.startDateTime;
      return (diff / 1000)/60;
   }

   openCalender(status){
      if(status == 'end'){
         if(this.assignDetails.unassignAt == '' || this.assignDetails.unassignAt == undefined){
            this.assignDetails.unassignAt = this.dateTimeEnd;
         }
      }

      if(status == 'start'){
         if(this.assignDetails.assignedAt == '' || this.assignDetails.assignedAt == undefined){
            this.assignDetails.assignedAt = new Date();
         }
      }
   }

   changeEndDate(){
      if(this.assignDetails.assignedAt && this.assignDetails.unassignAt){
         let timeDiff = this.dateDifferenceInMinutes(this.assignDetails.assignedAt, this.assignDetails.unassignAt);
         if(timeDiff < 0){
            this.assignDetails.unassignAt = '';
         }
      }
   }

   changeStartDate(){
      let timeDiff = this.dateDifferenceInMinutes(this.dateTime, this.assignDetails.assignedAt);
      console.log(timeDiff,'time',this.assignDetails.assignedAt,this.dateTime)
      if(timeDiff < 0){
         this.assignDetails.assignedAt = this.dateTime;
      }
      this.selectStartDate();
   }
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getAssignDetails(1);
	}
}
