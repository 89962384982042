import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { GroupsService } from '../../../services/groups/groups.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import * as moment from 'moment';
// import { ContractorService } from '../../../services/contractor/contractor.service';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.css']
})
export class AddVehicleComponent implements OnInit {
    GroupnameSelectedItems = [];
    GroupnameSettings = {};
    VehiclecategorySelectedItems = [];
    VehiclecategorySettings = {};
    OwnershipSelectedItems = [];
	OwnershipSettings = {};
    loading:boolean;
	vehicleDetails: any= {};
	cityDetails: any = {};
    vehicleModalDetails: any;
    vehicleBodyTypeDetails: any;
    fileToUpload: File  = null;
    url: any;
    userInfo: any;
    groupList: any = [];
    groupListParameter: any = {};
    editVehicleId: any;
    serverUrl: any;
    currentpage : any;
    vehicleCategoryList : any = [];
    ownership: any = [];
    imageextension : any = [];
    currentTime: any = new Date();
    isGps: boolean;
    isGpsInfo;
    chackGpsId: boolean = false;
    validGpsMsg = '';
    deviceItems = [];
    deviceSettings = {};
    // deviceSelectedItems = [];
    geofenceGroupList: any = [];
    geofenceGroupSelectedItems: any = [];
    geofenceGroupSettings: any = {};
    geofenceList: any = [];
    geofenceSelectedItems: any = [];
    geofenceSettings: any = {};
    contractorSettings: any = {};
    contractorList: any =[];
    contractorSelectedItems: any = [];
    contractorModule: boolean = false;
    statusDropdownList = [];
    VehicleStatusSettings = {};
    VehicleStatusList: any = [];
    VehicleSelectedItems: any = [];

 
  	constructor(
        private router: Router, 
        private http: HttpClient, 
        public _vehicleService: VehicleService, 
        public _commonService: CommonService, 
        private customErrorHandler:CustomErrorHandler,
        private translate: TranslateService,
        private broadcaster: Broadcaster,
        public groupsService: GroupsService,
        // public contractorService: ContractorService,
    ) {
        this.userInfo = localStorage.getItem('userInfo');        
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
            this.currentpage = "Add";
        }
    }

  	ngOnInit() {
        this.vehicleDetails.geofence_category = 'Group';
        this.isGpsInfo = localStorage.getItem('isGps');
        let contractorModule1 = localStorage.getItem('contractorModule');
        if(contractorModule1 =='1'){
            this.contractorModule = true;
        }
        this.isGps =  this.isGpsInfo=='true' ? true : false;
        this.serverUrl = Config.FILEURL;
        this.vehicleDetails.vehicle_image_url = '';
        this.imageextension= Constants.imageextensionarray;
        this.loading = false;
  		// this.getCity();
        // this.getVehicleBodyTypes();
        this.getGeofenceGroupList();
        this.getGeofenceList();
        // this.getDeviceType();
        this.getGroupList();
        //this.getContractorList();
        // this.getVehicleCategoryList();
        // this.getownership();
        this.vehicleDetails.city_id = '';
        this.vehicleDetails.ownership = '';
        this.vehicleDetails.status = 'active';
        this.vehicleDetails.vehicle_model_id = '';
        this.vehicleDetails.body_type_id = '';
        this.vehicleDetails.contractor = '';
        this.vehicleDetails.contractor_id = '';
        this.vehicleDetails.group_id = '';
        this.vehicleDetails.vehicle_type_name = '';
        this.editVehicleId = localStorage.getItem('editVehicleId');
        if(this.editVehicleId){
          this.loading = true;
          // this.getVehicleDetails(this.editVehicleId, this.userInfo.company_id);
          this.currentpage = "Update";
        }else{
            this.editVehicleId = '';
        }

        
        this.vehicleDetails.vehicle_type = '';
        this._commonService.stickyHeader();
        
        this.translate.get('selectors').subscribe((res) => {
            this.GroupnameSettings = {
                singleSelection: true,
                text: res.SelectGroupName,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.VehiclecategorySettings = {
                singleSelection: true,
                text: res.SelectVehicleCategory,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.OwnershipSettings = {
                singleSelection: true,
                text: res.SelectOwnership,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.deviceSettings = {
                singleSelection: true,
                text: res.SelectDevice,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };

            this.geofenceGroupSettings = {
                singleSelection: true,
                text: res.SelectGeofenceGroup,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.geofenceSettings = {
                singleSelection: false,
                text: res.SelectGeofence,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.contractorSettings = {
                singleSelection: true,
                text: res.SelectContractor,
                //text: 'Select Contractor',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
            this.VehicleStatusSettings = {
                singleSelection: true,
                //text: 'Select status',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
            this.translate.get('selectors').subscribe((res) => {
              this.GroupnameSettings = {
                singleSelection: true,
                text: res.SelectGroupName,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
              };
              this.VehiclecategorySettings = {
                singleSelection: true,
                text: res.SelectVehicleCategory,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
              };
              this.OwnershipSettings = {
                singleSelection: true,
                text: res.SelectOwnership,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
              };
              this.deviceSettings = {
                singleSelection: true,
                text: res.SelectDevice,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
              };

              this.contractorSettings = {
                singleSelection: true,
                text: res.SelectContractor,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
              };

            });
        });

        this.statusDropdownList = [
          {"id":1,"title":"Status","itemName":"trash"}
        ]; 
  	}

  	getCity(){
  		this._commonService.getCity().subscribe(
            res => {
               this.cityDetails = res.data;
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'getCity');
            }
        )
  	}  

  	getVehicleBodyTypes(){
        this._vehicleService.getVehicleBodyType().subscribe(
            res => {
               this.vehicleBodyTypeDetails = res.data;
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'getVehicleBodyType');
             }
        )
  	}

  	addVehicle(){
        // if (this.chackGpsId) {
        //   let param = {message: 'Please select geofence'}
        //   this._commonService.showErrorMessage(param);
        //     return false;
        // }
        
        this.vehicleDetails.geofence_group_id = '';
        this.vehicleDetails.geofence_ids = [];
        if(this.vehicleDetails.geofence_category == 'Group'){
            this.vehicleDetails.geofence_group_id = this.geofenceGroupSelectedItems[0].id;
        }
        if(this.vehicleDetails.geofence_category == 'Vehicle'){
            for (var i = 0; i < this.geofenceSelectedItems.length; ++i) {
              this.vehicleDetails.geofence_ids.push(this.geofenceSelectedItems[i].id);
            }
        }
        
        if(this.vehicleDetails.insurance_expiry_date){
            this.vehicleDetails.insurance_expiry_date = moment(this.vehicleDetails.insurance_expiry_date).format('YYYY-MM-DD');
        }
        
        if(this.vehicleDetails.service_due_date){
            this.vehicleDetails.service_due_date = moment(this.vehicleDetails.service_due_date).format('YYYY-MM-DD');
        }
        
        if(this.vehicleDetails.last_service_date){
            this.vehicleDetails.last_service_date = moment(this.vehicleDetails.last_service_date).format('YYYY-MM-DD');
        }
        
        this.loading =true;
        if(this.editVehicleId){
            this.vehicleDetails.company_id = this.userInfo.company_id;
            this.vehicleDetails.modified_by = this.userInfo.company_id;
              this._vehicleService.updateVehicle(this.vehicleDetails).subscribe(
                res => {
                  this.loading =false; 
                    localStorage.removeItem('editVehicleId');
                    if (res.success == true) {
                      this._commonService.showSuccessMessage(res.message);
                       this.router.navigate(['./vehicle-management']);
                    } else {
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                  this.loading =false; 
                this.customErrorHandler.handleHttpError(error, 'addVehicle');
                }
            )
        }else{
            this.vehicleDetails.company_id = this.userInfo.company_id;
      		this._vehicleService.addVehicle(this.vehicleDetails).subscribe(
                res => {
                  this.loading =false; 
                  if (res.success == true) {
                    this._commonService.showSuccessMessage(res.message);
                   this.router.navigate(['./vehicle-management']);
                  } else {
                    this._commonService.showErrorMessage(res);
                  }
                },
                error => {
                  this.loading =false; 
                this.customErrorHandler.handleHttpError(error, 'addVehicle');
                }
            )
        }
  	}	

  	removeSpecialChar(event : any){   
       var k;  
       k = event.charCode;  
       return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 0 || k == 32 || (k >= 48 && k <= 57)); 
    }

    checkMobileNumber(event: any) {

        const pattern = /[0-9\+\-\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
        }
    }
    
    changeListener($event): void {
        let eventName = $event.target.name;
        let inputValue = $event.target;
        let file:File = inputValue.files[0];
        let myReader:FileReader = new FileReader();
        let imgName = $event.target.files[0].name;
        let imageextensionarray=  imgName.split('.').pop();
        if (imageextensionarray === 'jpg'|| imageextensionarray=== 'png' || imageextensionarray==='jpeg'|| imageextensionarray=== 'bmp') {
        myReader.onloadend = (e) => {
            if(eventName == 'insurance_image'){
                this.vehicleDetails.insurance_image_url = myReader.result;
                $('.insurance-img').attr('src', this.vehicleDetails.insurance_image_url);
            }
            if(eventName == 'registration_image'){
                this.vehicleDetails.registration_image_url = myReader.result;
                $('.registration-img').attr('src', this.vehicleDetails.registration_image_url);
            }
            if(eventName == 'vehicle_image'){
              this.vehicleDetails.vehicle_image_url = myReader.result;
              $('.vehicle-img').attr('src', this.vehicleDetails.vehicle_image_url);
            }
        }
      }
      else {
				$event.target.files.name = '';
				 $('.insurance-img').val('');
				this.vehicleDetails.insurance_image_url = '';
				$('input[name=insurance_image]').val('');
				$('.registration-img').val('');
				this.vehicleDetails.registration_image_url = '';
				$('input[name=registration_image]').val('');
				$('.vehicle-img').val('');
				this.vehicleDetails.vehicle_image_url = '';
				$('input[name=vehicle_image]').val('');
        swal({
					type: 'error',
					text: Constants.extensionmsg,
		confirmButtonText: 'ok',
			})
			}

        myReader.readAsDataURL(file);
    }


    getGroupList(){
       this._commonService.getVehicleGroup().subscribe(
            res => {
              if (res.success == true) {
                  this.groupList = res.data.rows;
                  this.getVehicleCategoryList();
              } else {
                this._commonService.showErrorMessage(res);
              }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'getGroupList');
            }
          );      
    }
 
    getVehicleCategoryList(){      
      this._vehicleService.getVehicleCategory().subscribe(
          res => {
            if (res.success == true) {
                this.vehicleCategoryList = res.data;
                this.getownership();
                // this.getContractorList();
            } else {
              this._commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'vehicle-category');
          }
        )
    }

  getownership(){
    this._vehicleService.getownership().subscribe(
        res => {
          if (res.success == true) {
              this.ownership = res.data;
              this.editVehicleId = localStorage.getItem('editVehicleId');
              if(this.editVehicleId){
                this.getVehicleDetails(this.editVehicleId, this.userInfo.company_id);
              }
          } else {
            this._commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'ownership-type');
        }
      )
  }

    getVehicleDetails(vehicleId, companyId){
        this.loading = true;
        this._vehicleService.getSingleVehicle(vehicleId, companyId).subscribe(res => {
              if (res.success == true) {
                  this.vehicleDetails = res.data;
                  this.vehicleDetails.insurance_image = this.vehicleDetails.insurance_image_url;
                  this.vehicleDetails.insurance_image_url = '';
                  this.vehicleDetails.registration_image = this.vehicleDetails.registration_image_url;
                  this.vehicleDetails.registration_image_url = '';
                  this.vehicleDetails.vehicle_image = this.vehicleDetails.vehicle_image_url;
                  this.vehicleDetails.vehicle_image_url = '';

                  this.setVehiclecategory();
                  this.setOwnership();
                  this.setGroupname();
                  this.setVehicleStatus();
                  //this.setContractor();
                  // this.setVehicleDeviceType();
                  if(this.vehicleDetails.geofence_category == 'Group'){
                    this.setGeofenceGroup();
                  }else{
                    this.setVehicleGeofence();
                  }
                  this.loading = false;
                } else {
                   this._commonService.showErrorMessage(res);
                   this.loading = false;
              }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
                this.loading = false;
            }
        )
    }

    setGeofenceGroup(){
        let temp = this.geofenceGroupList.find(s=>s.id == this.vehicleDetails.geofence_group_id);
        if(temp!= undefined && this.geofenceGroupSelectedItems.length == 0){
          this.geofenceGroupSelectedItems = [];
          this.geofenceGroupSelectedItems.push(temp);
        }
    }

    setVehicleGeofence(){
        let geofenceIds = this.vehicleDetails.geofences;
        this.geofenceSelectedItems = [];
        for (var i = 0; i < geofenceIds.length; i++) {
          let temp = this.geofenceList.find(s=>s.id == geofenceIds[i].id);
          if(temp!= undefined){
            this.geofenceSelectedItems.push(temp);
          }
        }
    }

    // setVehicleDeviceType(){
    //     let temp = this.deviceItems.find(s=>s.itemName == this.vehicleDetails.device_type);
    //     if(temp!= undefined && this.deviceSelectedItems.length == 0){
    //       this.deviceSelectedItems = [];
    //       this.deviceSelectedItems.push(temp);
    //     }
    // }

    setGroupname(){
      let Groupname = this.groupList.find(s=>s.id == this.vehicleDetails.group_id);
                if(Groupname!= undefined && this.GroupnameSelectedItems.length == 0){
                  this.GroupnameSelectedItems = [];
                  this.GroupnameSelectedItems.push(Groupname);
                }
    }
    setVehiclecategory(){
      let vehicle = this.vehicleCategoryList.find(s=>s.itemName == this.vehicleDetails.vehicle_type_name);
      if(vehicle!= undefined && this.VehiclecategorySelectedItems.length == 0){
        this.VehiclecategorySelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
        this.VehiclecategorySelectedItems.push(vehicle);
      }
    }

    setOwnership(){
      let Ownership = this.ownership.find(s=>s.itemName == this.vehicleDetails.ownership);
                //console.log(this.vehicleDetails,'this.vehicleDetails');
                //console.log(Ownership,'Ownership');
                if(Ownership!= undefined && this.OwnershipSelectedItems.length == 0){
                  this.OwnershipSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
                  this.OwnershipSelectedItems.push(Ownership);
                }
    }

     

    onItemSelectGroupname(item: any) {
	    this.vehicleDetails.group_id = item.id;
	  }
		onOpenGroupname(event: any) {
	    this.groupList = this.groupList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
    }
    onItemSelectVehiclecategory(item: any) {
	    this.vehicleDetails.vehicle_type_name = item.itemName;
	  }
		onOpenVehiclecategory(event: any) {
	    this.vehicleCategoryList = this.vehicleCategoryList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
    }
    onItemSelectOwnership(item: any) {
	    this.vehicleDetails.ownership = item.itemName;
	  }
		onOpenOwnership(event: any) {
	    this.ownership = this.ownership.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
    }

    checkValidGps(val) {
      this.validGpsMsg = '';
      if (val == undefined || val == '') {
        return false;
      } else {
        this.loading = true;
        let data: any = {
          imei : val
        };
        this._vehicleService.checkGps(data).subscribe(res => {
          if (res.success == true) {
            this.chackGpsId = false;
            this.loading = false;
          } else {
            this.validGpsMsg = res.message;
            this.chackGpsId = true;
            this.loading = false;
          }          
        }, error => {
          this.chackGpsId = false;
          this.loading = false;
        });
      }
    }

    ngOnDestroy() {
      localStorage.removeItem('editVehicleId');   	
    }

    // getDeviceType(){      
    //   this._vehicleService.getDeviceType().subscribe(
    //       res => {
    //         if (res.success == true) {
    //             this.deviceItems = res.data;
    //             this.getGroupList();
    //         } else {
    //           this._commonService.showErrorMessage(res);
    //         }
    //       },
    //       error => {
    //         this.customErrorHandler.handleHttpError(error, 'vehicle-category');
    //       }
    //     )
    // }

    onItemSelectDevice(item: any) {
	    this.vehicleDetails.device_type = item.itemName;
	}

    changeGeofenceType(geofenceType){

    }

    getGeofenceGroupList(){
        this.groupsService.getGeofenceGroupItemList().subscribe(res => {
              if (res.success == true) {
                  this.geofenceGroupList = res.data;
                } else {
                   this._commonService.showErrorMessage(res);
              }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getGeofenceGroupList');
                this.loading = false;
            }
        )
    }

    getGeofenceList(){
        this.groupsService.getGeofenceItemList().subscribe(res => {
            if (res.success == true) {
                  this.geofenceList = res.data;
            } else {
                   this._commonService.showErrorMessage(res);
               }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getGeofenceGroupList');
                this.loading = false;
            }
        )
    }

    // getContractorList(){
    //     let param = {
    //         contractors_id: this.userInfo.id
    //     }
    //     this.contractorService.getContractorItemList(param).subscribe(res => {
    //           if (res.success == true) {
    //               this.contractorList = res.data;
    //               this.setContractor();
    //             } else {
    //                this._commonService.showErrorMessage(res);
    //           }
    //         },
    //         error => {
    //             this.customErrorHandler.handleHttpError(error, 'getContractorpList');
    //             this.loading = false;
    //         }
    //     )
    // }

    setContractor(){
        let temp = this.contractorList.find(s=>s.id == this.vehicleDetails.contractor_id);
        if(temp!= undefined && this.contractorSelectedItems.length == 0){
          this.contractorSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
          this.contractorSelectedItems.push(temp);
        }
    }

    onItemSelectContractor(item: any) {
        this.vehicleDetails.contractor = item.id;
      }

    onItemDeSelectContractor(item: any){
        this.vehicleDetails.contractor = '';
        this.vehicleDetails.contractor_id = '';
        //this.contractorSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
        //this.contractorSelectedItems.push('');
        //this.geofenceTypeSelectedItems = [];
        //this.geofenceTypeSelectedItems.push(item);
    }

    onOpenContractor(event: any) {
        this.contractorList = this.contractorList.map((item) => {
          return {
            id: item.id,
            itemName: item.itemName
          }
        });
    }

    onVehicleStatusSelect(item: any) {
        this.vehicleDetails.status = item.itemName;
    }

    onVehicleDeStatusSelect(item: any) {
        //this.vehicleDetails.VehicleStatus = '';
        this.vehicleDetails.status = '';
    }

    setVehicleStatus(){
        //let temp = this.VehicleStatusList.find(s=>s.itemName == this.vehicleDetails.status);
        let temp = {
            "id": "1",
            "itemName": "trash"
        }
        if(this.vehicleDetails.status =='trash'){
            this.VehicleSelectedItems = [];
            this.VehicleSelectedItems.push(temp);
        }
    }

}
