import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TripService } from 'src/app/services/trip/trip.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Constants } from 'src/app/constants/constants';
import { Config } from 'src/app/config/config';
declare var $: any;
declare var google: any;
declare var map: any;
declare var cityCircle: any;

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.css']
})
export class RouteListComponent implements OnInit {
  isDemoTenant: boolean;
  loading: boolean;
  routeList = [];
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  tempSearch;
  searchTextValue = '';
  noDataMsg;
  deleteMsg: any;
  deleteLabel: any;
  cancelLabel: any;
  EditLabel: string;
  modalRef: BsModalRef;

  tripDetails: any = {};
  vehicleList: any = [];
  userDetails: any = {};
  tripWaypoints: any = [];
  wayRouteLength: number = 0;
  placeList: any = [];
  wayPoint: any = {};
  waypoints: any = []
  renderOptions = {
    draggable: true,
    suppressMarkers: false,
  }
  dateTime = new Date();
  wayPointInput: any = [];
  zoom: any;
  lat: any;
  lng: any;
  origin: any = {};
  destination: any = {};
  waypointLocation: any = {};
  wayPointData: any = {}
  message: any;
  wayPointInputLength: number = 0;
  mapStyles: any;
  selectedWayPoint: any = [];
  saveButton: boolean = false;
  distanceTimeRequest: any = {};
  tripETATime: any;
  waypts: any = [];
  routeDistance: any;
  routeDistanceKm: any;
  routeETAMin: any;
  routeETA: any;
  markerOptions: any = {}
  iconUrl: any;
  serverUrl: any;
  markerWayPoint: any = {}

  wayMarkerOptions = []
  wayCount: number = 2;
  placeDetails: any = {}
  placeLocationRequired: any;
  placeNameRequired: any;
  map: any;
  markers = [];
  routePaths: any[] = [];
  routeOrigin: any;
  routeDestination: any;
  directionsDisplay: any;
  directionsService: any;
  mapInitializeCount: number = 0;
  drawingManager: any;
  boundries: any = [];
  circleRadius: any;
  circleLat: string;
  circleLng: string;
  shapeType: number;
  geofenceDraw: boolean = false;
  polygonPaths: any = [];
  rectangleBounds: any = {};
  drawGeofencingType: number;
  drawGeofencing: any;
  checkPlaceAddress: any;
  placeSelectedBtn: boolean = true;
  endDateTime: any;
  startDateTime: any;
  timeDiffMsg: any = '';
  config = {}
  placeSelectedItem = [];
  placeSelectedIds = [];
  availableSelectedIds = [];
  minDateOnTrip: number = 0;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    public tripService: TripService,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
  ) {
    this.translate.get('DeleteOption').subscribe((res) => {
      setTimeout(() => {
        this.deleteMsg = res.DeleteAlert;
        this.deleteLabel = res.Delete;
        this.cancelLabel = res.Cancel;
        this.EditLabel = res.Edit;
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('DeleteOption').subscribe((res) => {
        setTimeout(() => {
          this.deleteMsg = res.DeleteAlert;
          this.deleteLabel = res.Delete;
          this.cancelLabel = res.Cancel;
          this.EditLabel = res.Edit;
        });
      });
    });
  }
  
  ngOnInit() {
    this.userDetails = localStorage.getItem('userInfo');
    this.userDetails = JSON.parse(this.userDetails);
    this.totalItem = 0;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.getRoute(1);
    this.mapStyles = Constants.mapStyles;
    this.tripWaypoints[0] = '';
    this.zoom = 12;
    this.lat = Number(this.userDetails.lat);
    this.lng = Number(this.userDetails.lng);
    this.tripETATime = '0 m';
    this.routeDistanceKm = '0 km';
    this.tripDetails.pickup_point = '';
    this.tripDetails.drop_point = '';
    this.serverUrl = Config.AuthenticationURL;
    this.iconUrl = this.serverUrl + 'public/image/route-icon/';
    this.markerOptions = {
        origin: {
            icon: this.iconUrl + '1.png',
            draggable: true,
        },
        destination: {
            icon: this.iconUrl + '2.png',
            draggable: true
        }
    }
    let mapStyle = this.mapStyles;
    let this1 = this;
    setTimeout(function () {
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: this1.lat, lng: this1.lng },
            scrollwheel: true,
            styles: mapStyle,
            sensor: false
        });
        this.directionsDisplay.setMap(this.map);
    }, 1000);
  }

  getRoute(currentPage) {
    this.loading = true;
    this.pageNo = currentPage;
    this.page = currentPage;
    let queryParams = {
      row_per_page: this.perPage,
      page_no: this.page,
      search: this.searchTextValue,
    }

    this.tripService.getRoute(queryParams).subscribe(res => {
      this.loading = false;
      this.noDataMsg = res.message;
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.routeList = res.data.rows;
        // this.shiftList.forEach(ele => {
        //   ele.shift_start_timing = moment(ele.shift_start_timing).format('hh:mm a');
        //   ele.shift_end_timing = moment(ele.shift_end_timing).format('hh:mm a');
        // })
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(error, 'getRolelist');
    });
  }

  searchByFilter(event) {
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getRoute(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  onEdit(data) {
    this.router.navigate(['/route/add-route'], { queryParams: { id: data.id } });
  }

  deleteRoute(val) {
    let id = val.id
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton: true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        let rawData = {
          id: id
        }
        this.loading = true;
        this.tripService.deleteRoute(rawData).subscribe(res => {
          if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
            this.getRoute(1);
          } else {
            this.commonService.showErrorMessage(res);
          }
          this.loading = false;
        }, error => {
          this.customErrorHandler.handleHttpError(error, 'getVehicle');
          this.loading = false;
        });
      }
    });
  }

  viewRoute(data, template) {
    this.placeSelectedItem = data.way_points;
    this.modalRef = this.modalService.show(template);
    setTimeout(() => {
      this.calculateTripDistanceTime();
    }, 500);
  }

  closeRouteModal() {
    this.placeSelectedItem = [];
    this.modalRef.hide();
  }

  calculateTripDistanceTime() {
    this.shapeType = 0;
    this.boundries = [];
    this.geofenceDraw = false;
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });

    let _this = this;
    console.log(_this.lat);
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8,
      center: { lat: _this.lat, lng: _this.lng },
      gestureHandling: 'cooperative',
      styles: this.mapStyles,
      scrollwheel: true,
      sensor: false
    });
    this.directionsDisplay.setMap(this.map);
    let imgUrl = Config.AuthenticationURL + 'public/image/route-icon/';
    this.placeSelectedIds = [];
    if (this.placeSelectedItem.length >= 2) {
      let placeSelectedLen = this.placeSelectedItem.length;
      let origin = new google.maps.LatLng(this.placeSelectedItem[0].lat, this.placeSelectedItem[0].lng);
      this.setPlaceGeofence(this.placeSelectedItem[0].id);
      this.tripDetails.pickup_point = this.placeSelectedItem[0].id;
      this.placeSelectedIds.push(this.placeSelectedItem[0].id);
      this.setPlaceGeofence(this.placeSelectedItem[placeSelectedLen - 1].id);
      this.tripDetails.drop_point = this.placeSelectedItem[placeSelectedLen - 1].id;
      this.createMarker(origin, this.placeSelectedItem[0].url, this.placeSelectedItem[0].place_name, imgUrl + '1.png');
      let destination = new google.maps.LatLng(this.placeSelectedItem[placeSelectedLen - 1].lat, this.placeSelectedItem[placeSelectedLen - 1].lng);
      this.createMarker(destination, this.placeSelectedItem[placeSelectedLen - 1].url, this.placeSelectedItem[placeSelectedLen - 1].place_name, imgUrl + placeSelectedLen + '.png');
      // let wayPointLen = this.waypts.length;

      let directionsService = new google.maps.DirectionsService();

      this.waypts = [];
      this.tripDetails.way_points = []
      if (placeSelectedLen > 2) {
        let wayPointCount = 0;
        let wayPointLen = placeSelectedLen - 2;
        for (let i = 1; i <= wayPointLen; i++) {
          let wayPointLat = new google.maps.LatLng(this.placeSelectedItem[i].lat, this.placeSelectedItem[i].lng);
          this.createMarker(wayPointLat, this.placeSelectedItem[i].url, this.placeSelectedItem[i].place_name, imgUrl + (i + 1) + '.png');
          wayPointCount++;
          this.setPlaceGeofence(this.placeSelectedItem[i].id);
          this.tripDetails.way_points.push(this.placeSelectedItem[i].id);
          let routeWayPoint = this.placeSelectedItem[i].lat + ',' + this.placeSelectedItem[i].lng;
          this.waypts.push({
            location: routeWayPoint,
            stopover: true
          });
          this.placeSelectedIds.push(this.placeSelectedItem[i].id);
        }
      }
      this.placeSelectedIds.push(this.placeSelectedItem[placeSelectedLen - 1].id);
      this.distanceTimeRequest = {
        origin: origin,
        destination: destination,
        waypoints: this.waypts,
        optimizeWaypoints: false,
        travelMode: 'DRIVING',
        provideRouteAlternatives: false
      };
      directionsService.route(this.distanceTimeRequest, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          _this.directionsDisplay.setDirections(response);
          _this.routeDistance = 0;
          _this.routeETA = 0;
          var route = response.routes[0];
          for (var i = 0; i < route.legs.length; i++) {
            _this.routeDistance += Number(route.legs[i]['distance']['value']);

            _this.routeETA += Number(route.legs[i]['duration']['value']);


            var routeSegment = i + 1;

          }
          _this.routeDistanceKm = (_this.routeDistance / 1000).toFixed(1) + ' km';
          _this.routeETAMin = (_this.routeETA / 60).toFixed(0);
          // _this.tripDetails.trip_distance = (_this.routeDistance).toFixed(1);;
          // _this.tripDetails.trip_duration = _this.routeETAMin;
          _this.timeFormating(_this.routeETAMin);
        }
        else {
          _this.tripETATime = '0 m';
          _this.routeDistanceKm = '0 km';
          _this.routeDistance = 0;
          _this.routeETA = 0;
        }
      });
      // this.geofencingDrawing(this.map);
    }
  }
  setPlaceGeofence(placeId) {
    let place = this.placeList.find(s => s.id == placeId);
    let myArray = ["#262525"];
    let randomItem = myArray[Math.floor(Math.random() * myArray.length)];
    if (typeof place != 'undefined') {
      let marker = new google.maps.Marker({
        map: map,
        position: new google.maps.LatLng(place.lat, place.lng),
        title: place.place_name
      });
      let circle = new google.maps.Circle({
        map: this.map,
        radius: place.geo_fence_radius,    // 10 miles in metres
        fillColor: randomItem,
        strokeColor: randomItem
      });
      circle.bindTo('center', marker, 'position');
    }
  }

  timeFormating(totalMinutes) {
    let totalTime = Number(totalMinutes);
    this.tripETATime = '';
    if (totalTime < 60) {
      this.tripETATime = totalTime + ' m';
    } else {
      if (totalTime > 1440) {
        let days = totalTime / 1440;
        days = parseInt(days.toString());
        if (days > 0) {
          this.tripETATime = days + ' d ';
        }

        let hours = totalTime / 60;
        hours = parseInt(hours.toString());
        if (hours > 0) {
          this.tripETATime = this.tripETATime + '' + hours + ' h ';
        }

        let minute = (totalTime % 60);
        minute = parseInt(minute.toString());
        if (minute > 0) {
          this.tripETATime = this.tripETATime + '' + minute + ' m';
        }

      } else {

        let hours = totalTime / 60;
        hours = parseInt(hours.toString());
        if (hours > 0) {
          this.tripETATime = hours + ' h ';
        }
        let minute = (totalTime % 60);
        minute = parseInt(minute.toString());
        if (minute > 0) {
          this.tripETATime = this.tripETATime + '' + minute + ' m';
        }
      }
    }
  }

  createMarker(latlng, placeUrl, name, icon) {
    var content = '<div>' + name + '</div>';//'<div style="background: url('+imgUrl+') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">'+name+'</b><p>'+description+'</p>';
    var title = name;
    var _this = this;
    var infowindow = new google.maps.InfoWindow({
      content: content,
      maxWidth: 150
    });
    var marker = new google.maps.Marker({
      position: latlng,
      icon: icon,
      map: _this.map,
      shape: { coords: [17, 17, 18], type: 'circle' }
    });
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(_this.map, marker);
    });

    this.markers.push(marker);
  }
  

}
