import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/constants/constants';
import { Config } from '../../config/config';
import { LeaveService } from '../../services/leave-service/leave.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.css']
})
export class LeaveManagementComponent implements OnInit {

  	isDemoTenant: boolean;
	modalRef: BsModalRef;
	loading:Boolean=false;
	leaveList:any = [];
	tempSearch;
	searchTextValue = '';
	perPage: any = 10;
	pageNo: any = 1;
	page: number = 1;
	totalItem: any;
	noDataMsg = '';
	rejectComment;
	rejectData:any = {};
	serverUrl = Config.AuthenticationURL;
	fuelImage;  
	start_date;
  	end_date;
	selected: any;
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	};
	ApproveLabel: any;
	CancelLabel: any;
	ApprovedMsg: any;
	EditLabel:string;
	deleteLabel:string;

  constructor(
    	public leaveService: LeaveService,
		private customErrorHandler:CustomErrorHandler,
		public commonService: CommonService,
		private modalService: BsModalService,
		private translate: TranslateService,
    	private broadcaster: Broadcaster  
  	) {
		this.translate.get('Approve').subscribe((res) => {
			setTimeout(() => {
				this.ApproveLabel = res;
			});
		});
		this.translate.get('Cancel').subscribe((res) => {
			setTimeout(() => {
				this.CancelLabel = res;
			});
		});
		this.translate.get('ApprovedMsg').subscribe((res) => {
			setTimeout(() => {
				this.ApprovedMsg = res;
			});
		});
		this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
				this.EditLabel = res.Edit;
				this.deleteLabel = res.Delete;
			});
		});
		this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('Approve').subscribe((res) => {
				setTimeout(() => {
					this.ApproveLabel = res;
				});
			});
			this.translate.get('Cancel').subscribe((res) => {
				setTimeout(() => {
					this.CancelLabel = res;
				});
			});
			this.translate.get('ApprovedMsg').subscribe((res) => {
				setTimeout(() => {
					this.ApprovedMsg = res;
				});
			});
			this.translate.get('DeleteOption').subscribe((res) => {
				setTimeout(() => {
					this.EditLabel = res.Edit;
					this.deleteLabel = res.Delete;
				});
			});
		});
   }

  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.getLeaveData(1);
  }

	searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getLeaveData(1);
		}
		this.tempSearch = this.searchTextValue;
	}
  
  getLeaveData(currentPage) {
		this.pageNo = currentPage;
		this.loading = true; 
		this.page = currentPage;
		let queryParams = {
			row_per_page: this.perPage,
      		page_no:this.page,
			search: this.searchTextValue,
			start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY'),
      		end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY')
		}
		this.leaveService.getLeaveDetail(queryParams).subscribe(res => {
			this.loading = false; 
			if (res.success == true) {
				this.totalItem = res.data.count;
        		this.leaveList = res.data.rows;
				this.noDataMsg = res.message;
			} else {
				this.commonService.showErrorMessage(res);
			}
		}, error => {
			this.loading =false; 
			this.customErrorHandler.handleHttpError(error, 'addInspection');
		});
  }

	onSelect(item: any) {
		this.getLeaveData(1);
	}

	onDeSelect(item: any) {
		this.getLeaveData(1);
	}
  
  closeModal(template) {
		this.modalRef.hide();
		this.fuelImage = '';
	}

	approveLeave(val, status){		
		let rawData = {
			leave_id: val.id,
			status: status,
			comment: ''
		}
		if (val.flag==false) {
			swal({
				type: 'info',
				text: this.ApprovedMsg,
				confirmButtonText: this.ApproveLabel,
				showCancelButton:true,
				cancelButtonText: this.CancelLabel
			}).then((result) => {
				if (result.value) {
				this.loading = true;
				this.leaveService.setLeaveStatus(rawData).subscribe(
					res => {
						if (res.success == true) {
						this.commonService.showSuccessMessage(res.message);
						this.getLeaveData(1);
						} else {
							this.commonService.showErrorMessage(res);
						}
						this.loading =false; 
					},
					error => {
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
						this.loading =false; 
					}
				)
				}
			});
		}   
	}

	rejectLeaveModal(template, data, status) {
		if (data.flag==false) {
			this.modalRef = this.modalService.show(template);
			this.rejectData = {
				leave_id: data.id,
				status: status
			}
		}				
	}

	onReject() {
		this.loading = true;
		this.rejectData.comment = this.rejectComment
		this.leaveService.setLeaveStatus(this.rejectData).subscribe(
			res => {
				if (res.success == true) {
					this.commonService.showSuccessMessage(res.message);
					this.getLeaveData(1);
					this.modalRef.hide();
					this.rejectComment = '';
				} else {
					this.commonService.showErrorMessage(res);
				}
				this.loading =false; 
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getVehicle');
				this.loading =false; 
			}
		)
	}
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getLeaveData(1);
	}

}