import { Constants } from 'src/app/constants/constants';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { GroupsService } from '../../../services/groups/groups.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-geofence-list',
  templateUrl: './geofence-list.component.html',
  styleUrls: ['./geofence-list.component.css']
})
export class GeofenceListComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	geofenceDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	deleteDetails: any = {};
	searchTempVal: any;
	deleteMsg: any;
    editLabel:string;
    deleteLabel: string;
    cancelLabel: string;

	constructor(private router: Router,
		private http: HttpClient,
		public groupsService: GroupsService,
		private customErrorHandler: CustomErrorHandler,
		private commonService: CommonService,
		private translate: TranslateService,
    	private broadcaster: Broadcaster
	) { 
		this.translate.get('DeleteOption').subscribe((res) => {
	        setTimeout(() => {
	          this.deleteMsg = res.DeleteAlert;
	          this.deleteLabel = res.Delete;
	          this.cancelLabel = res.Cancel;
	        });
	    });
	}

	ngOnInit() {
		this.searchTempVal = '';
		this.loading = false;
		this.totalItem = 0;
		this.searchDetails.search_by = '';
		this.getGeofenceDetails(this.pageNo);
		localStorage.removeItem('editGeofenceId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant') == 'true');
	}

	getGeofenceDetails(currentPage) {
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {
			page_no: this.pageNo, 
			row_per_page : this.perPage, 
			search_by: this.searchDetails.search_by
		}
		this.groupsService.getGeofenceList(requestParameter).subscribe(
			res => {
				this.loading =false; 
				this.message = res.message; 
				if (res.success == true) {
					this.totalItem = res.data.count;
					this.geofenceDetails = res.data.rows;
					this.geofenceDetails.map((obj) => { 
						if(obj.geofence_type == 1){
							obj.geofence_type = 'Circle';
						}else if(obj.geofence_type == 2){
							obj.geofence_type = 'Polygon';
						}
						console.log(obj.geofence_type)
					})
				} else {
					this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getPlace');
			}
		)
	}

	searchByFilter() {
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getGeofenceDetails(this.pageNo);
		}
	}

	editGeofence(geofenceId) {
		localStorage.setItem('editGeofenceId', geofenceId);
		this.router.navigate(['./groups/geofence']);
	}

	deleteGeofence(geofenceId) { 
		swal({
			type: 'error',
			text: this.deleteMsg,
			confirmButtonText: this.deleteLabel,
			showCancelButton:true,
			cancelButtonText: this.cancelLabel
		}).then((result) => {
			if (result.value) {
				this.deleteDetails.id = geofenceId;
				this.groupsService.deleteGeofence(this.deleteDetails).subscribe(
					res => {
						if (res.success == true) {
							this.pageNo = 1;
							this.commonService.showSuccessMessage(res.message);
							this.getGeofenceDetails(this.pageNo);
						} else {
							this.commonService.showErrorMessage(res);
						}
					},
					error => {
						this.customErrorHandler.handleHttpError(error, 'deleteGroup');
					}
				)
			}
		}
		);
	}

	addGeofence() {
		this.router.navigate(['./groups/geofence']);
	}

}
