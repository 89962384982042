import { MessageService } from 'src/app/services/message/message.service';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  driver_message:any=true;
  employee_message:any=false;
  admin_message:any=false;
  
  usersList:any =[];
  messageList:any = [];
  message:string = '';
  serverMessage:string ='';
  constructor(
    private messageService:MessageService, 
    private commonService:CommonService, 
    private customErrorHandler:CustomErrorHandler) {
   }

  ngOnInit() {
    this.getUsers();    
    this.scrollMessageBox();
    document.getElementById('message_scroller').onscroll = function(this) {
      let buttonE = document.getElementsByClassName('scroll_btn')[0];
      // if(this.scrollTop < this.scrollHeight - (this.offsetHeight + 100)){
      //   buttonE.classList.add('showBackToBottom');
      // }
      // else{
      //   buttonE.classList.remove('showBackToBottom');
      // }
    };
  }
  scrollMessageBox(){
    var messageBox = document.getElementById('message_scroller');
    messageBox.scrollTop=messageBox.scrollHeight;
  }

  getUsers(){
    this.messageService.getUserList().subscribe(
      res => {
        this.serverMessage = res.message;
        if (res.success == true) {
          this.usersList = res.data;
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
          this.customErrorHandler.handleHttpError(error, 'doLogin');         
      });
  }
  getMessageList(){

  }
  sendMessage(userId, message){
    this.messageService.sendMessage(userId, message).subscribe(
      res => {
        if (res.success == true) {
          
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
          this.customErrorHandler.handleHttpError(error, 'doLogin');         
      });
  }
}
