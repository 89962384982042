import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { GroupsService } from '../../../services/groups/groups.service';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import { PlaceService } from 'src/app/services/place/place.service';
import { Config } from './../../../config/config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
 
declare var moment: any;
declare var $: any;
declare var google: any;
declare var Autocomplete: any;
declare var map: any;
declare var cityCircle: any;
declare function geofenceTypeSelector(geofenceType): any;

@Component({
  selector: 'app-geofence',
  templateUrl: './geofence.component.html',
  styleUrls: ['./geofence.component.css']
})
export class GeofenceComponent implements OnInit {

    loading: boolean;
    geofenceDetails: any = {};
  
    userDetails: any = {};
    placeList: any = [];
    modalRef: BsModalRef;
    searchControl: FormControl;
    @ViewChild("search")
    searchElementRef: ElementRef;
    
    renderOptions = {
        draggable: true,
        suppressMarkers: false,
    }
    zoom: any;
    lat: any;
    lng: any;
    message: any;
    mapStyles: any;
    saveButton: boolean = false;
    
    iconUrl: any;
    serverUrl: any;
    markerWayPoint: any = {}
    placeDetails: any = {}
    placeLocationRequired: any;
    placeNameRequired: any;
    map: any;
    markers = [];
   
    drawingManager: any;
    shapeType: number;
    geofenceDraw: boolean = false;
    polygonPaths: any = [];
    placeSelectedBtn: boolean = true;
    
    config = {}
    polygonPlaceSelectedItem = [];
    circlePlaceSelectedItem = [];
    placeSelectedIds = [];
    userInfo:any;
    allOverlays: any = [];
    polygon : any;
    setMapPolygon: boolean = false;
    polygonCordinates: any = [];
    editGeofenceId: any = '';
    geofenceTypeList: any = [];
    geofenceTypeSelectedItems: any = [];
    geofenceTypeSettings: any = {};
    placeSettings: any = {};
    geofenceRadiusList: any = [];
    geofenceRadiusSetting: any = {};
    geofenceRadiusSelectedItems : any = [];
    circleGeofance: any;
    setMapCircle: boolean = false;

    geofenceType: any = '';
    boundries: any = [];
    directionsDisplay: any;
    directionsService: any;
    routeDrawRequest: any;
    geofenceMarkers: any = [];
    editPolygonPath: any;
    setEditMapPolygon: any;
    polygonPlaceSettings: any = {};

    constructor(
        private modalService: BsModalService,
        private router: Router,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private http: HttpClient,
        public groupsService: GroupsService,
        public commonService: CommonService,
        public placeService: PlaceService,
        private customErrorHandler: CustomErrorHandler,
		private translate: TranslateService,
		private broadcaster: Broadcaster
    ) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
    }

    ngOnInit() {
        this.getGeofenceRadius();
        this.geofenceTypeList = [{id: 1 , itemName: 'circle'}, {id: 2 , itemName: 'polygon'}]
        this.geofenceTypeSelectedItems = [{id: 1 , itemName: 'circle'}];
        this.translate.get('selectors').subscribe((res) => {
            
            this.placeSettings =  {
                singleSelection: true,
                text: res.SelectPlace,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            }
            this.geofenceTypeSettings = {
                singleSelection: true,
                text: res.SelectGeofenceType,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection req_input"
            }

            this.polygonPlaceSettings = {
                singleSelection: false,
                text: res.SelectPlace,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "req_input"
            }
        })
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        
        this.editGeofenceId = localStorage.getItem('editGeofenceId');
        if(this.editGeofenceId == undefined || this.editGeofenceId == null){
            this.editGeofenceId = '';
        }
        
        this.loading = false;
        this.mapStyles = Constants.mapStyles;
        this.zoom = 15;
        this.lat = Number(this.userDetails.lat);
        this.lng = Number(this.userDetails.lng);
       
        this.commonService.stickyHeader();

        this.getPlaceDetails();
        this.serverUrl = Config.AuthenticationURL;

        this.iconUrl = this.serverUrl + 'public/image/route-icon/';
        this.searchControl = new FormControl();

        let mapStyle = this.mapStyles;
        let this1 = this;
        setTimeout(()=> {
            if(this.map == undefined){
                this.map = new google.maps.Map(document.getElementById('map'), {
                    zoom: this.zoom,
                    center: { lat: this1.lat, lng: this1.lng },
                    scrollwheel: true,
                    styles: mapStyle,
                    sensor: false
                });
                this.openDrawingManagerTool();
            }
        }, 500);
    }

    

    resetGeoference() {
        this.boundries = [];
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.geofenceType = this.geofenceTypeSelectedItems[0].itemName;
        this.openDrawingManagerTool();
        this.directionsDisplay.setMap(this.map);
        this.geofenceDraw = false;
       
        if(this.geofenceType != 'circle' && this.polygonPlaceSelectedItem.length >= 2){
            this.drawPlaceRoutes();
        }
        if(this.geofenceType == 'circle' && this.circlePlaceSelectedItem.length > 0){
            this.setGeofenceMarker(this.circlePlaceSelectedItem[0]);
        }
    }
    
    drawPlaceRoutes(){
        let _this = this;
        this.openDrawingManagerTool();
        let routeLen = this.polygonPlaceSelectedItem.length;
        let wayPointLen = routeLen - 2;
        let waypts = [];
        this.allOverlays = [];
         for (var i=0; i < this.allOverlays.length; i++)
          {
            this.allOverlays[i].overlay.setMap(null);
          }
          this.allOverlays = [];
        for (let i = 1; i <= wayPointLen; i++) {
            let routeWayPoint = this.polygonPlaceSelectedItem[i].lat + ',' + this.polygonPlaceSelectedItem[i].lng;
            waypts.push({
                location: routeWayPoint,
                stopover: true
            });
        }
        this.directionsDisplay = new google.maps.DirectionsRenderer();
        this.directionsDisplay.setMap(this.map);
        let origin = new google.maps.LatLng(this.polygonPlaceSelectedItem[0].lat, this.polygonPlaceSelectedItem[0].lng);
        let destination = new google.maps.LatLng(this.polygonPlaceSelectedItem[routeLen-1].lat, this.polygonPlaceSelectedItem[routeLen-1].lng);
        let directionsService = new google.maps.DirectionsService();
        this.routeDrawRequest = {
                origin: origin,
                destination: destination,
                waypoints: waypts,
                optimizeWaypoints: false,
                travelMode: 'DRIVING',
                provideRouteAlternatives: false
            };
        directionsService.route(this.routeDrawRequest, function (response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                _this.directionsDisplay.setDirections(response);
            }
        });
    }

    getGeofenceRadius(){
        this.placeService.getGeofenceRadius().subscribe(res => {
            if (res.success == true) {
                this.geofenceRadiusList = res.data;  
                if(this.geofenceRadiusList.length > 0){
                    this.geofenceRadiusSelectedItems = [];
                    this.geofenceRadiusSelectedItems.push(this.geofenceRadiusList[0]);
                }           
            } else {
                   this.commonService.showErrorMessage(res);
            }
        },
        error => {
            this.customErrorHandler.handleHttpError(error, 'getGeofenceRadius');
        })
        return event;
    }

    addGeofence() {
        this.geofenceDetails.place_ids = [];
        if(this.geofenceType && this.boundries.length > 0){
            this.geofenceDetails.geofence_boundries = this.boundries;
            if(this.geofenceType == 'circle'){
                if(this.circlePlaceSelectedItem.length > 0){
                    // let placeId = this.circlePlaceSelectedItem[0].id;
                    // this.geofenceDetails.place_ids.placeId;
                    
                    let placeId = this.circlePlaceSelectedItem[0].id;
                    this.geofenceDetails.place_ids.push(placeId);
                    
                    this.geofenceDetails.circle_radius = parseInt(this.geofenceDetails.circle_radius);
                }
                this.geofenceDetails.geofence_type = 1;
                this.geofenceDetails.circle_radius = 10;
            }else{
                this.geofenceDetails.place_ids = this.placeSelectedIds;
                this.geofenceDetails.geofence_type = 2;
            }
        }else{
            let errorParam = {message: "Geofence is required"}
            this.commonService.showErrorMessage(errorParam);
            return false;
        }
       
        if(this.editGeofenceId){
            this.groupsService.updateGeofence(this.geofenceDetails).subscribe(
                res => {
                    this.loading = false;
                    this.saveButton = false;
                    if (res.success == true) {
                        this.commonService.showSuccessMessage(res.message);
                        this.router.navigate(['./groups/geofence-list']);
                    } else {
                        this.commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.loading = false;
                    this.customErrorHandler.handleHttpError(error, 'addGeofence');
                }
            )
        }else{
            this.groupsService.addGeofence(this.geofenceDetails).subscribe(
                res => {
                    this.loading = false;
                    this.saveButton = false;
                    if (res.success == true) {
                        this.commonService.showSuccessMessage(res.message);
                        this.router.navigate(['./groups/geofence-list']);
                    } else {
                        this.commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.loading = false;
                    this.customErrorHandler.handleHttpError(error, 'addGeofence');
                }
            )
        }
    }

    getPlaceDetails() {
        this.placeService.getPlace(0, 0).subscribe(
            res => {
                if (res.success == true) {
                    this.placeList = res.data.rows;
                    if(this.editGeofenceId){
                        this.getSingleGeofenceDetails();
                    }
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getPlace');
            }
        )
    }


    openDrawingManagerTool(){
        var drawingManager;
        this.geofenceType = this.geofenceTypeSelectedItems[0].itemName;      
        // this.boundries = [];
        let drawingModes = [];
        if(this.geofenceTypeSelectedItems[0].itemName == 'circle'){
            drawingModes = [google.maps.drawing.OverlayType.CIRCLE]   
        }else{
            drawingModes = [google.maps.drawing.OverlayType.POLYGON]
        }
        //geofenceTypeSelector(this.geofenceType);
        /*drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: drawingModes
            }
        });*/
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.zoom,
            center: { lat: this.lat, lng: this.lng },
            scrollwheel: true,
            styles: this.mapStyles,
            sensor: false
        });

        if(this.geofenceType =='polygon'){
            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                },
                polygonOptions: {
                    editable: true
                }
            });
        }

        this.drawingManager.setMap(this.map);
        var overlay;
        var _this = this;
        google.maps.event.addListener(this.drawingManager, "overlaycomplete", function(event) {
            
            //this.geofenceDetails.circle_radius = 0;
            this.geofenceType = event.type;
            //this.drawingManager.setDrawingMode(null);
            //this.drawingManager.setOptions({
            //    drawingControl: false
            //});
            this.boundries = [];
            this.geofenceDraw = true;
            //this.clearGeofanceArea()
            if(this.geofenceType == 'circle'){
                this.geofenceDetails.geofence_type = 1;
                let boundry = {
                    lat: event.overlay.getCenter().lat(),
                    lng: event.overlay.getCenter().lng()
                }
                this.boundries.push(boundry);
                this.geofenceDetails.circle_radius = event.overlay.getRadius(); // in meters
               
            }
            if(this.geofenceType != 'circle'){
               
                //this.geofenceDetails.geofence_type = 2;
                let polygonPath = event.overlay.getPath().getArray();
                
                polygonPath.forEach(item => {
                    var path = {
                        lat: item.lat(),
                        lng: item.lng()
                    };
                    this.boundries.push(path);
                });
                _this.boundries = this.boundries;
                this.polygon.setMap(this.map);
                this.setMapPolygon = true;
                for(var i=0; i < this.geofenceMarkers.length; i++){
                    this.geofenceMarkers[i].setMap(null);
                }
                this.geofenceMarkers = [];
            }
            this.drawingManager.setMap(this.map);
        });
    }

    getSingleGeofenceDetails(){
        let requestParam = {
            id: this.editGeofenceId
        }
        this.groupsService.getSingleGeofence(requestParam).subscribe(
            res => {
                this.loading = false;
                if (res.success == true) {
                    this.geofenceDetails = res.data;
                    let temp = this.geofenceTypeList.find(s => s.id == this.geofenceDetails.geofence_type);
                    if(temp != undefined){
                        this.geofenceType = temp.itemName;
                        this.geofenceTypeSelectedItems = [];
                        this.geofenceTypeSelectedItems.push(temp);
                    }
                    this.boundries = this.geofenceDetails.geofence_boundries;
                    this.drawingGeofence();
                } else {
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'addGeofence');
            }
        )
    }

    drawingGeofence() {
        if(this.map == undefined){
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: this.zoom,
                center: { lat: this.lat, lng: this.lng },
                scrollwheel: true,
                styles: this.mapStyles,
                sensor: false
            });
        }
        if(this.geofenceType == 'circle'){
            if(this.geofenceDetails.place_ids.length > 0){
                let placeId = this.geofenceDetails.place_ids[0];
                let temp = this.placeList.find(s => s.id == placeId);
                if(temp != undefined){
                    let circlePlaceInfo = {
                        lat: Number(temp.lat),
                        lng: Number(temp.lng)
                    }
                    this.circlePlaceSelectedItem = [];
                    this.circlePlaceSelectedItem.push(temp);
                    this.setGeofenceMarker(circlePlaceInfo);
                }
            }
            if(this.geofenceDetails.geofence_boundries.length > 0){
                let circleGeofenceInfo = {
                    lat: this.geofenceDetails.geofence_boundries[0].lat,
                    lng: this.geofenceDetails.geofence_boundries[0].lng,
                    radius: this.geofenceDetails.circle_radius
                }
                this.drawGeofanceCircle(circleGeofenceInfo.lat, circleGeofenceInfo.lng, circleGeofenceInfo.radius)
            }
        }else{

            let placeIds = this.geofenceDetails.place_ids;
            if(placeIds.length >= 2){

                this.placeSelectedIds = [];
                this.polygonPlaceSelectedItem = [];
                for(let i = 0; i< placeIds.length; i++){
                    this.placeSelectedIds.push(placeIds[i]);
                    let temp = this.placeList.find(s => s.id == placeIds[i]);
                    if(temp != undefined){
                        this.polygonPlaceSelectedItem.push(temp);
                    }
                }
                if(this.polygonPlaceSelectedItem.length >= 2){
                    this.drawPlaceRoutes();
                }
                setTimeout(()=>{
                    this.locationDragable();
                },1000)
            }


            this.polygonCordinates = this.geofenceDetails.geofence_boundries;
            for (var i = 0; i < this.polygonCordinates.length; ++i) {
                this.polygonCordinates[i].lat = Number(this.polygonCordinates[i].lat);
                this.polygonCordinates[i].lng = Number(this.polygonCordinates[i].lng);
            }
            this.editPolygonPath = new google.maps.Polygon({
                map: this.map,
                paths: this.polygonCordinates,
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#262525',
                fillOpacity: 0.35,
                draggable: true,
                geodesic: true
            });
            this.drawingManager.setDrawingMode(null);
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.editPolygonPath.setMap(this.map);
            this.setEditMapPolygon = true;
            this.geofenceDraw = true;
            this.geofenceType = 'polygon';
            this.setFitBounds(this.polygonCordinates);
        }

    }

    selectPlacesForRouteDraw(item: any){
        this.placeSelectedIds = [];
        for(let i = 0; i< this.polygonPlaceSelectedItem.length; i++){
            this.placeSelectedIds.push(this.polygonPlaceSelectedItem[i].id);
        }
        if(this.polygonPlaceSelectedItem.length >= 2){
            this.drawPlaceRoutes();
        }
        this.locationDragable();
    }

    deSelectPlacesForRouteDraw(item: any){
        this.placeSelectedIds = [];
        for(let i = 0; i< this.polygonPlaceSelectedItem.length; i++){
            this.placeSelectedIds.push(this.polygonPlaceSelectedItem[i].id);
        }
        if(this.polygonPlaceSelectedItem.length >= 2){
            this.drawPlaceRoutes();
        }else{
            this.openDrawingManagerTool();
        }
        this.locationDragable();
    }

    locationDragable() {
        var _this = this;
        $("#sortable").sortable({
            placeholder: "ui-state-highlight",
            handle: ".dragdrop-handle",
            opacity: 0.1,
            cursor: "move",
            stop: function (event, ui) {
                let placeSelectedTemp = [];
                $('.route_input').each(function () {
                    let placeId = $(this).attr('id');
                    let place = _this.placeList.find(s => s.id == placeId);
                    placeSelectedTemp.push(place);
                });
                _this.polygonPlaceSelectedItem = placeSelectedTemp;
                if(_this.polygonPlaceSelectedItem.length >= 2){
                    _this.drawPlaceRoutes();
                }
            },
            update: function (event, ui) {
                $('.sort-order-value').each(function (index, value) {
                    if (0 == index) {
                        $(value).parent().find('.delete-handle').hide();
                    } else {
                        $(value).parent().find('.delete-handle').show();
                    }
                    $(value).val(index + 1);
                });
            }
        });
        $("#sortable").disableSelection();
    }

    setGeofenceMarker(item: any){
        this.lat = Number(item.lat);
        this.lng = Number(item.lng);
        for(var i=0; i < this.geofenceMarkers.length; i++){
            this.geofenceMarkers[i].setMap(null);
        }
        this.geofenceMarkers = [];
        let myLatLng = {lat: this.lat, lng: this.lng};
        let _this = this;
        let marker = new google.maps.Marker({
            position: myLatLng,
            map: _this.map
        });
        this.geofenceMarkers.push(marker);
        this.map.setCenter(marker.getPosition());

        let boundries = []
        boundries.push(myLatLng);
        if(this.boundries.length > 0){
            boundries.push({lat: this.boundries[0].lat, lng : this.boundries[0].lng});
        }
        this.boundries = boundries;
        this.setFitBounds(boundries);
    }

    setFitBounds(boundryArray){
        let bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < boundryArray.length; i++) {
            let myLatLng = new google.maps.LatLng(boundryArray[i].lat, boundryArray[i].lng);
            bounds.extend(myLatLng);
        }
        this.map.fitBounds(bounds);
    }

    drawGeofanceCircle(lat, lng, radius){
        let _this = this;
        this.clearGeofanceArea();
        this.circleGeofance = new google.maps.Circle({
            map: _this.map,
            radius: radius,    // 10 miles in metres
            fillColor: '#262525',
            center: { lat: lat, lng: lng },
        });
        let myLatLng = new google.maps.LatLng(lat, lng);
        let bounds = new google.maps.LatLngBounds();
        bounds.extend(myLatLng);
        this.map.fitBounds(bounds);
        this.map.setZoom(18);
        this.circleGeofance.setMap(this.map);
        this.setMapCircle = true;
        this.boundries = [{ lat: lat, lng: lng }]
        this.drawingManager.setDrawingMode(null);
        this.drawingManager.setOptions({
            drawingControl: false
        });
        this.geofenceDraw = true;
    }

    clearGeofanceArea(){
        if(this.setMapCircle == true){
            this.circleGeofance.setMap(null);
            this.setMapCircle = false;
        }
        if(this.setMapPolygon == true){
            this.polygon.setMap(null);
            this.setMapPolygon = false;
        }
        if(this.setEditMapPolygon == true){
            this.editPolygonPath.setMap(null);
            this.setEditMapPolygon = false;
        }
    }

    onItemSelectGeofenceType(item: any){
        this.resetGeoference();
        this.geofenceType = item.itemName;
        this.openDrawingManagerTool();
    }

    onItemDeSelectGeofenceType(item: any){
        this.geofenceTypeSelectedItems = [];
        this.geofenceTypeSelectedItems.push(item);
    }

    onOnDestroy(){
        localStorage.removeItem('editGeofenceId');
    }

    overlayClickListener(overlay) {
        console.log('overlay');
        console.log(overlay.getPath().getArray());
        google.maps.event.addListener(overlay, "mouseup", function(event){
            console.log(overlay.getPath().getArray());
        });
    }
}
