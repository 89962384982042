import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-vehicle-group-list',
  templateUrl: './vehicle-group-list.component.html',
  styleUrls: ['./vehicle-group-list.component.css']
})
export class VehicleGroupListComponent implements OnInit {
    loading:boolean;
    public isDemoTenant:boolean;
    message:string;
    totalItem: any;
    perPage: any = 10;
    pageNo: any = 1;
    groupDetails: any = [];
    page: number = 1;
    searchDetails: any = {};
    userDetails: any;
    deleteDetails: any = {};
    modalRef: BsModalRef;
    vehicleGroup: any = {};
    userInfo: any;
    currentpage : any;
    editGroupId : any;
    companyDetails: any = [];
    searchTempVal: any;
    deleteMsg: any;
    deleteLabel: any;
    cancelLabel: any;
    editGroupText:string;
    deleteGroupText:string;

    constructor(
        private router: Router, 
        private http: HttpClient, 
        public vehicleService: VehicleService,
        private customErrorHandler:CustomErrorHandler, 
        private _commonService:CommonService,
        private modalService: BsModalService,
        private translate: TranslateService,
    	private broadcaster: Broadcaster
    ) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
        this.userInfo = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(this.userInfo);
        this.currentpage = "Add";
        this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
                this.deleteMsg = res.DeleteAlert;
                this.deleteLabel = res.Delete;
                this.cancelLabel = res.Cancel;
                this.editGroupText=res.Edit;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
                setTimeout(() => {
                    this.deleteMsg = res.DeleteAlert;
                    this.deleteLabel = res.Delete;
                    this.cancelLabel = res.Cancel;
                    this.editGroupText=res.Edit;
                });
            });
		});
    }

    ngOnInit() {
        this.searchTempVal = '';
        this.loading = false;
        this.totalItem = 0;
        this.searchDetails.search_by = '';
        this.getGroupDetails(this.pageNo);
        localStorage.removeItem('editGroupId');

        this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    }

    getGroupDetails(currentPage){
        this.loading =true;
        this.pageNo = currentPage;
        this.page = currentPage;
        let requestParameter = {}
        this.searchDetails.company_id = this.userDetails.company_id;
        this.vehicleService.getGroup(this.pageNo, this.perPage, this.searchDetails).subscribe(
            res => {   
                this.loading =false; 
                this.message = res.message;         
                if (res.success == true) {
                    this.totalItem = res.data.count;
                    this.groupDetails = res.data.rows;
                } else {
                    this._commonService.showErrorMessage(res);
                }
            },
            error => {
                this.loading =false; 
                this.customErrorHandler.handleHttpError(error, 'getGroup');
            }
        )
    }
    // popup functions end
    openOrderAssignModal(groupId,template) {
        if(groupId == '' || groupId == null || typeof groupId == 'undefined'){
            this.editGroupId = '';
        }else{
            this.editGroupId = groupId;
        }
        this.modalRef = this.modalService.show(template);
            
        // this.getCompanyDetails();
        this.vehicleGroup.company_id = '';
        if(groupId){
            this.currentpage = "Update";
            this.getPopupGroupDetails(groupId);
        }
        else{
            delete this.vehicleGroup.group_name;
        }

        this.vehicleGroup.company_id = this.userInfo.company_id;
    }
      
  	// getCompanyDetails(){
  	// 	this._commonService.companyDetails().subscribe(
   //          res => {
   //             this.companyDetails = res.data;
   //          },
   //          error => {
			//     this.customErrorHandler.handleHttpError(error, 'companyDetails');
			// }
   //      )
  	// }
    removeSpecialChar(event : any){   
       var k;  
       k = event.charCode;  //         k = event.keyCode;  (Both can be used)
       return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 0 || k == 32 || (k >= 48 && k <= 57)); 
    }
    getPopupGroupDetails(groupId){
        this.vehicleService.getSingleGroup(groupId).subscribe(res => {
            if (res.success == true) {
                this.vehicleGroup = res.data;
            } else {
                 this._commonService.showErrorMessage(res);
            }
        },
        error => {
              this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
        }
        )
    }
    
    addGroup(){
        if(this.editGroupId){
            this.vehicleGroup.modified_by = this.userInfo.id;
            this.vehicleService.updateGroup(this.vehicleGroup).subscribe(
                res => {
                    if (res.success == true) {
                        this.modalRef.hide();
                        this._commonService.showSuccessMessage(res.message);
                        this.getGroupDetails(this.pageNo);
                    } else {
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'addGroup');
                }
            )
        }else{
            this.vehicleGroup.createdBy = this.userInfo.id;
            this.vehicleService.addGroup(this.vehicleGroup).subscribe(
                res => {
                    if (res.success == true) {
                        this.modalRef.hide();
                        this._commonService.showSuccessMessage(res.message);
                        this.getGroupDetails(1);
                    } else {
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'addGroup');
                }
            )
       }
   }
  // popup functions end
    searchByFilter(){
      if(this.searchTempVal != this.searchDetails.search_by){
        this.searchTempVal = this.searchDetails.search_by;
            this.pageNo = 1;
            this.getGroupDetails(this.pageNo);
        }
    }

    editGroup(groupId){
      this.editGroupId =groupId;
        // localStorage.setItem('editGroupId', groupId);
    }

    deleteGroup(groupId){
      swal({
    			type: 'error',
    			text: this.deleteMsg,
    			confirmButtonText: this.deleteLabel,
    			showCancelButton:true,
    			cancelButtonText: this.cancelLabel
    		}).then((result) => {
    			if (result.value) {
                    this.deleteDetails.id = groupId;
                    this.vehicleService.deleteGroup(this.deleteDetails).subscribe(
                        res => {
                            if (res.success == true) {
                            this.pageNo = 1;
                            this._commonService.showSuccessMessage(res.message);
                            this.getGroupDetails(this.pageNo);

                            } else {
                            this._commonService.showErrorMessage(res);
                            }
                        },
                        error => {
                            this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
                        }
                    )
                }
            })
    }
}
