import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ExpenseService } from '../../../services/expense/expense.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.css']
})
export class ExpenseListComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
	message:string;
	tempSearch;
	searchTextValue = '';
	perPage: any = 10;
	pageNo: any = 1;
	page: number = 2;
	totalItem: any;
	noDataMsg = '';
	expenseDetails: any = [];
	userInfo: any;
	deleteDetails: any= {};
	serverUrl: any;
	searchTempVal: any;
	currentTime: any = new Date();
	start_date;
	end_date;
	modalRef: BsModalRef;
	imageBaseUrl: string = Config.AuthenticationURL;
	images: any = [];
	isImagesModel: boolean;
	galleryNextBtn : any;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
  
	  

  	constructor(
		private router: Router, 
		private commonService:CommonService, 
		private http: HttpClient, 
		public expenseService: ExpenseService,
		private customErrorHandler:CustomErrorHandler,
		private modalService: BsModalService
	) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.getExpenseDetails(this.pageNo);
		this.serverUrl = Config.AuthenticationURL;
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
	}

	searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getExpenseDetails(1);
		}
		this.tempSearch = this.searchTextValue;
	}

	getExpenseDetails(currentPage){
		this.pageNo = currentPage;
		this.loading =true; 
		this.page = currentPage;
		let queryParams = {
			row_per_page: this.perPage,
      		page_no:this.page,
			search: this.searchTextValue,
			start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY'),
      		end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY')
		}
		this.expenseService.getExpenseDetails(queryParams).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
		          	this.totalItem = res.data.count;
		          	this.expenseDetails = res.data.rows;
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
	}

	onSelect(item: any) {
		this.getExpenseDetails(1);
	}

	onDeSelect(item: any) {
		this.getExpenseDetails(1);
	}

	openModal(template, data) {
		this.images = [];
		data.forEach((element, idx) => {
			let imgObj: any = {};
			imgObj.source = this.imageBaseUrl + element;
			imgObj.title = 'Image ' + (idx + 1);
			this.images.push(imgObj);
		});
		setTimeout(() => {
			if (this.images.length == 1) {
				this.galleryNextBtn[0].style.display = 'none';
			} else {
				this.galleryNextBtn[0].style.display = 'block';
			}
		}, 100);
		this.modalRef = this.modalService.show(template);
	}

	closeModal(template) {
		this.images = [];
		this.modalRef.hide();
	}
	onImageChange(event: any) {
		let lastIdx = this.images.length - 1;
		if (event.index == lastIdx) {
			$('.ui-galleria-nav-next').css('display', 'none');
		} else {
			$('.ui-galleria-nav-next').css('display', 'block');
		}
	}
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getExpenseDetails(1);
	}

}