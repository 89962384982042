import { Component, OnInit } from '@angular/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/services/report/report.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import * as moment from 'moment';
import { Angular2Csv } from 'angular2-csv';
var CanvasJS;
@Component({
  selector: 'app-vehicle-speed-ignition-graph',
  templateUrl: './vehicle-speed-ignition-graph.component.html',
  styleUrls: ['./vehicle-speed-ignition-graph.component.css']
})
export class VehicleSpeedIgnitionGraphComponent implements OnInit {

  	loading:boolean;
    isDemoTenant:boolean;
    message:string;
    vehicleDetails: any = [];
    page: number = 1;
    vehicleList: any;
    VehicleSelectedItems = [];
    VehicleSettings = {};
    vehicleId:any = '';
    selected: any;
    date: Date;
    speedGraphType:any;
    speedGraphData:any;
    speedGraphOptions:any;
    ignitionGraphType:any;
    ignitionGraphData:any;
    ignitionGraphOptions:any;

    constructor(
        public reportService: ReportService,
        private commonService:CommonService, 
        private customErroHandler:CustomErrorHandler,
        private translate: TranslateService,
        private broadcaster: Broadcaster
    ) { }

    ngOnInit() {
        this.date = new Date();
        this.translate.get('selectors').subscribe((res) => {
            this.VehicleSettings = {
               singleSelection: true,
               text: res.SelectVehicle,
               enableSearchFilter: true,
               classes: "singleSelection"
            };
        });
        
        this.getVehicle();

        this.speedGraphType = 'line';
        
        this.speedGraphOptions = {
          responsive: true,
          maintainAspectRatio: false
        };

        this.ignitionGraphType = 'bar';
        

        this.ignitionGraphOptions = {
          responsive: true,
          maintainAspectRatio: false
        };
        // window.onload = function () {
        //     var chart = new CanvasJS.Chart("chartContainer", {
        //         animationEnabled: true,
        //         theme: "light2",
        //         title:{
        //             text: "Simple Line Chart"
        //         },
        //         axisY:{
        //             includeZero: false
        //         },
        //         data: [{        
        //             type: "line",       
        //             dataPoints: [
        //                 { y: 450 },
        //                 { y: 414},
        //                 { y: 520, indexLabel: "highest",markerColor: "red", markerType: "triangle" },
        //                 { y: 460 },
        //                 { y: 450 },
        //                 { y: 500 },
        //                 { y: 480 },
        //                 { y: 480 },
        //                 { y: 410 , indexLabel: "lowest",markerColor: "DarkSlateGrey", markerType: "cross" },
        //                 { y: 500 },
        //                 { y: 480 },
        //                 { y: 510 }
        //             ]
        //         }]
        //     });

        //     chart.render();
        // }
    }

    getVehicleDetails() {
        this.loading =true;
        let queryParams = {
            vehicle_id: this.vehicleId,
            start_date: moment(this.date).format('YYYY-MM-DD')
        }
        this.reportService.getVehicleSpeedChartData(queryParams).subscribe(res => {
            this.loading =false; 
            this.message = res.message;
            if (res.success == true) {
                let vehicleSpeedChartData = res.data;
                let speedChartLabel = [];
                let speedChartData = [];
                let speedChartColors = [];
                for(let i = 0; i< vehicleSpeedChartData.length; i++){
                    speedChartLabel.push("");
                    speedChartData.push(vehicleSpeedChartData[i]['avg_speed']);
                    let color = this.getRandomColor();
                    speedChartColors.push(color);
                }
                this.speedGraphData = {
                    labels: speedChartLabel, 
                    datasets: [
                        {
                            label: "Vehicle Speed Chart Graph",
                            data: speedChartData,//[65, 59, 80, 81, 56, 55, 40],
                            // backgroundColor:speedChartColors
                        }
                    ]
                };

            } else {
                this.commonService.showErrorMessage(res);
            }
        }, error => {
            this.loading =false; 
            this.customErroHandler.handleHttpError(error, 'getVehicleSpeedIgnitionGraphReport');
        });

        this.reportService.getVehicleIgnitionChartData(queryParams).subscribe(res => {
            this.loading =false; 
            this.message = res.message;
            if (res.success == true) {
                let ignitionChartInfo = res.data;
                let ignitionChartLabel = [];
                let ignitionChartData = [];
                let ignitionChartColors = [];
                for(let i = 0; i< ignitionChartInfo.length; i++){
                    ignitionChartLabel.push(ignitionChartInfo[i]['date_time']);
                    ignitionChartData.push(ignitionChartInfo[i]['ignition_count']);
                    let color = this.getRandomColor();
                    ignitionChartColors.push(color);
                }

                this.ignitionGraphData = {
                      labels: ignitionChartLabel,
                      datasets: [
                        {
                          label: "Vehicle Ignition Chart Graph",
                          data: ignitionChartData,
                           backgroundColor: ignitionChartColors,
                        }
                      ]
                };
            } else {
                this.commonService.showErrorMessage(res);
            }
        }, error => {
            this.loading =false; 
            this.customErroHandler.handleHttpError(error, 'getVehicleSpeedIgnitionGraphReport');
        });
    }

    getRandomColor() {
         var o = Math.round, r = Math.random, s = 255;
        return 'rgb(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ')';
    }

    getVehicle() {
        this.commonService.getAssignVehicle().subscribe(res => {
            if (res.success == true) {
                this.vehicleList = res.data;
                if(this.vehicleList.length > 0){
                    this.VehicleSelectedItems = [];
                    this.VehicleSelectedItems.push(this.vehicleList[0]);
                    this.vehicleId = this.vehicleList[0]['id'];
                    this.getVehicleDetails();
                }
            } else {
                this.commonService.showErrorMessage(res);
            }
        }, error => {
            this.customErroHandler.handleHttpError(error, 'vehicle list');
        });
    }
  
    onVehicleSelect(item: any) {
        this.vehicleId = item.id;
        this.getVehicleDetails();
    }

    onVehicleDeSelect(item: any){
        this.VehicleSelectedItems.push(item);
    }

    changedDate(item: any){
        if (this.vehicleId) {
          this.getVehicleDetails();
        } else {
          this.commonService.showCustomError('Please select vehicle');
        }    
    }
}
