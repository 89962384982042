import { Injectable } from "@angular/core";
import { Config } from "../../config/config";
@Injectable()
export class ConfigService {
    constructor() {
    }
    public async  load(): Promise<void> {
        if (Config.FleetWebURL == '') {
            this.getUrl();
        }
    }
    private getUrl() {

        Config.FILEURL = 'https://data.basecampfleetpro.com/';
        if (window.location.hostname == 'localhost1' || window.location.hostname == '172.16.12.1801') {
            Config.FleetWebURL = 'http://' + '10.0.22.92' + window.location.pathname;
            Config.AuthenticationBaseURL = Config.FleetWebURL + "live/api/v1/";
            Config.AuthenticationURL = Config.FleetWebURL + "live/";

            Config.AuthenticationBaseURLv2 = Config.FleetWebURL + "live/api/v2/";
        } 
        else if (window.location.hostname == 'fleetpro.basecampscout.com'){           
            Config.FleetWebURL = 'https://' + 'fleetpro.basecampscout.com' + window.location.pathname;
            Config.AuthenticationBaseURL = Config.FleetWebURL + "live/api/v1/";
            Config.AuthenticationURL = Config.FleetWebURL + "live/";

            Config.AuthenticationBaseURLv2 = Config.FleetWebURL + "live/api/v2/";
            
            // Config.FleetWebURL = 'http://' + 'fleetprodev.basecampscout.in' + window.location.pathname;
            // Config.AuthenticationBaseURL = Config.FleetWebURL + "live/api/v1/";
            // Config.AuthenticationURL = Config.FleetWebURL + "live/";
        }
        else {           
            Config.FleetWebURL = 'https://' + 'fleetpro.basecampscout.com' + window.location.pathname;
            Config.AuthenticationBaseURL = Config.FleetWebURL + "live/api/v1/";
            Config.AuthenticationURL = Config.FleetWebURL + "live/";

            Config.AuthenticationBaseURLv2 = Config.FleetWebURL + "live/api/v2/";
            
            // Config.FleetWebURL = 'http://' + 'fleetprodev.basecampscout.in' + window.location.pathname;
            // Config.AuthenticationBaseURL = Config.FleetWebURL + "live/api/v1/";
            // Config.AuthenticationURL = Config.FleetWebURL + "live/";
        }
    }
}
