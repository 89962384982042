import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv';

@Component({
  selector: 'app-vehicle-detailed-report',
  templateUrl: './vehicle-detailed-report.component.html',
  styleUrls: ['./vehicle-detailed-report.component.css']
})
export class VehicleDetailedReportComponent implements OnInit {
	isDemoTenant:boolean;
  loading:boolean;
	selected: any;
	reportDetails: any = [];
  VehicleSelectedItems = [];
  	// listSettings = {};
	start_date;
	end_date;
	alwaysShowCalendars: boolean;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
		vehicleList = [];
		vehicleId:any = [];
  	selectedItems = [];
  	totalItem: any;
  	perPage: any = 10;
  	pageNo: any = 1;
  	// reportDetails: any = [];
  	searchDetails: any = {};
  	page: number = 2;
  	searchTempVal: any = '';
  	message:string = 'Currently, there is no data available.';
		// ranges: any = {
		//   	'Today': [moment(), moment()],
		//   	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		//   	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		//   	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		//   	'This Month': [moment().startOf('month'), moment().endOf('month')],
		//   	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		// }
		// dateTime = new Date();
		dateSelected:any;
		dropdownSettings: any = {};
  	constructor(
		private commonService:CommonService, 
		private customErroHandler:CustomErrorHandler,
		public reportService: ReportService,
    	private translate: TranslateService
  	) { }

  	ngOnInit() {
			this.loading = false;
			this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
	    // this.listSettings = {
	    //   	singleSelection: true, 
	    //   	text:"Select Vehicle",
	    //   	enableSearchFilter: true,
	    //   	classes:"myclass custom-class"
	    // }
	    this.getVehicle();
	    this.getdetailedReport(1);

	    this.translate.get('selectors').subscribe((res) => {
	    this.dropdownSettings = {
	            //singleSelection: true,
	            text: 'Select Vehicle Status',
	            //text: 'Select status',
	            enableSearchFilter: true,
	            //classes: "singleSelection"
            };

    	});


  	}

		getVehicle() {
	    this.commonService.getAssignVehicle().subscribe(res => {
	      if (res.success == true) {
	        this.vehicleList = res.data;
	        //this.dropdownList = res.data;
	      } else {
	        this.commonService.showErrorMessage(res);
	      }
	    }, error => {
	      this.customErroHandler.handleHttpError(error, 'addInspection');
	    });
	  }

  	getdetailedReport(currentPage, Export:any = ''){
  		this.loading =true;
			var fileName = "Vehicle Detailed Report";
		 	if(this.start_date != undefined && this.end_date != undefined){
		 		fileName = "Vehicle Detailed Report-"+this.start_date+" To "+this.end_date;
		 	}
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: fileName
		};
		
		this.page = currentPage;
		let tempDate = this.dateSelected;
		tempDate = tempDate == undefined ? '' :moment(tempDate).format('YYYY-MM-DD');
		let queryParams = {
			row_per_page: this.perPage,
			page_no:this.page,
			date: tempDate,
      export:Export,
      search_by: this.searchDetails.search_by,
  		start_date: this.start_date == undefined ? '' : this.start_date,
			end_date: this.end_date == undefined ? '' : this.end_date,
			vehicle_id: JSON.stringify(this.vehicleId),
		}
		this.reportService.getDetailedReport(queryParams).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
				if (res.success == true) {
		          	if(Export == 'yes'){
		          			options.title = `Vehicle Detailed Report`;
		          			if(this.start_date != undefined && this.end_date != undefined){
									 		options.title = `Date Range: ` +this.start_date+" To "+this.end_date;
									 	}
		          		
		            	new Angular2Csv(res.data, fileName, options);
		          	}
	          		else{
			            this.totalItem = res.data.count;
			            this.reportDetails = res.data.rows;
	          		}
				} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
	        	this.customErroHandler.handleHttpError(error, 'getTrip');
	        }
	    )
  	}

	 	onSelectAll(items: any){
	    for( var i = 0; i < items.length; i++){ 
	      this.vehicleId.push(items[i]['id']);
	    }
	    this.getdetailedReport(this.pageNo);
	    console.log(this.vehicleId);
	  }

	  onDeSelectAll(items: any){
	      console.log(items);
	      this.vehicleId = [];
	      this.getdetailedReport(this.pageNo);
	  }

  	onVehicleSelect(item: any) {
		this.vehicleId.push(item.id);
    this.getdetailedReport(1);
	  }

	  onVehicleDeselect(item: any) {
	    console.log(item.id);
	    for( var i = 0; i < this.vehicleId.length; i++){ 
	    
	        if ( this.vehicleId[i] === item.id) { 
	    
	            this.vehicleId.splice(i, 1); 
	        }
	    
	    }
	    //this.vehicleId.splice(item.id);
	    this.getdetailedReport(1);
	  }

  	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getdetailedReport(this.pageNo);
		}
	}

 	changedDate(e:any){
		this.start_date = e.startDate.format('YYYY-MM-DD');
    	this.end_date = e.endDate.format('YYYY-MM-DD')
    	this.getdetailedReport(1);  
  	}

  // 	changedDate(e:any){
		// this.start_date = e.startDate.format('YYYY-MM-DD');
  //   	this.end_date = e.endDate.format('YYYY-MM-DD');
  //   	this.getdetailedReport(1,'no');
  //   }

  // 	onInspectionSelect(e:any){
  //   	this.getdetailedReport(1,'no');
  // 	}

  	// onDateSelect(){
	  // 	if(this.VehicleSelectedItems.length){
		 //  	this.getdetailedReport(1,'no');
	  // 	}
  	// }

  	// getYearSlab() {
	  // 	var d = new Date();
	  // 	var s = d.getFullYear();
	  // 	var e:any = s - 80;
	  // 	e = e + ':' + s;
	  // 	return e;
  	// }
}
