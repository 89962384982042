import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css']
})
export class ReportDashboardComponent implements OnInit {
  userInfo:any;
  allowModule = [];
  inspectionListModule: boolean = false;
  serviceListModule: boolean = false;
  FuelModule: boolean = false;
  partsModule: boolean = false;
  ignitionModule: boolean = false;
  constructor() { }
  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.allowModule = JSON.parse(this.userInfo.access_module.allow_modules);
    this.setMenu();
  }

  setMenu() {
    this.allowModule.forEach((obj) => {
        if(obj == 19) {
            this.inspectionListModule = true;                    
        }
        if(obj == 22) {
            this.serviceListModule = true;                    
        }  
        if(obj == 23) {
            this.FuelModule = true;                    
        }
        if(obj == 29) {
            this.partsModule = true;                    
        }
        if(this.userInfo.showIgnitionReport == 1) {
          this.ignitionModule = true;
        }
    });
  }
}
