import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class ReportService { 

    userDetails: any = {}
    companyId: any = {}
    constructor(private _httpHelper: HttpHelper) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
        this.companyId = this.userDetails.company_id;
    }

    getTrip(req){
        try {
            // let requestParameter = '';
            // if(searchDetails.export == true){
            //     requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.companyId+'&start_date='+searchDetails.start_date+'&end_date='+searchDetails.end_date+'&search='+searchDetails.search_by+'&export=yes';
            //     // window.open(Config.AuthenticationBaseURL + 'trip-report'+requestParameter,'_blank');
            //     return this._httpHelper.get(Config.AuthenticationBaseURL + 'trip-report'+requestParameter)
            // }else{
            //     requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.companyId+'&start_date='+searchDetails.start_date+'&end_date='+searchDetails.end_date+'&search='+searchDetails.search_by;
            //     return this._httpHelper.get(Config.AuthenticationBaseURL + 'trip-report'+requestParameter)
            // }
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'trip-report', req)
        }
        catch (e) {
        }
    }  
    getDetailedReport(req){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-detail-report', req)
        }
        catch (e) {
        }
    }
    getIgnitionReport(req){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-ignition-report', req)
        }
        catch (e) {
        }
    }  
    getIdleReport(req){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-idel-report', req)
        }
        catch (e) {
        }
    }   
    getPositionReport(req){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-current-position', req)
        }
        catch (e) {
        }
    }   

    getVehicle(pageNo, rowsPerPage, searchDetails = null){

        try {
            let searchParameter = '';
            if(searchDetails){
                if(typeof searchDetails.current_status != 'undefined'){
                    searchParameter += '&current_status='+searchDetails.current_status;
                }
                if(typeof searchDetails.assignment_status != 'undefined'){
                    searchParameter += '&assignment_status='+searchDetails.assignment_status;
                }
                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search_by='+searchDetails.search_by;
                }
            }
            let requestParameter = '';
            if(searchDetails.export == true){
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.companyId+ ''+ searchParameter+'&export=yes';
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-report'+requestParameter)
                // window.open(Config.AuthenticationBaseURL + 'vehicle-report'+requestParameter,'_blank');
            }else{
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.companyId+ ''+ searchParameter;
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'vehicle-report'+requestParameter)
            }
        }
        catch (e) {
        }
    } 

    getUser(pageNo, rowsPerPage, searchDetails){
        try {
            
            let parameter = '';
            parameter += '&company_id='+this.companyId;
            
            if(typeof searchDetails.status != 'undefined'){
                parameter += '&status='+searchDetails.status;
            }
            if(typeof searchDetails.search_by != 'undefined'){
                parameter += '&search='+searchDetails.search_by;
            }
          
            let requestParameter = '';
            if(searchDetails.export == true){
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+parameter+'&export=yes';
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'user-report'+requestParameter);
                // window.open(Config.AuthenticationBaseURL + 'user-report'+requestParameter);
            }else{
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+parameter;
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'user-report'+requestParameter)
            }
        }
        catch (e) {
        }
    }

    getDriver(pageNo, rowsPerPage,searchDetails = null){
        try {
            let searchParameter = '';
            if(searchDetails){

                if(typeof searchDetails.status != 'undefined'){
                    searchParameter += '&status='+searchDetails.status;
                }

                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search='+searchDetails.search_by;
                }
                searchParameter += '&company_id='+this.companyId;
            }
            let requestParameter = '';
            if(searchDetails.export == true){
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter+'&export=yes';
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'driver-report'+requestParameter);
                // window.open(Config.AuthenticationBaseURL + 'driver-report'+requestParameter,'_blank')
            }else{
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter;
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'driver-report'+requestParameter);
            }
        }
        catch (e) {
        }
    }

    getDriverPerformance(pageNo, rowsPerPage,searchDetails = null){
        try {
            let searchParameter = '';
            if(searchDetails){

                if(typeof searchDetails.status != 'undefined'){
                    searchParameter += '&status='+searchDetails.status;
                }

                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search='+searchDetails.search_by;
                }
                if(typeof searchDetails.performance != 'undefined'){
                    searchParameter += '&performance='+searchDetails.performance;
                }
                searchParameter += '&company_id='+this.companyId;
            }
            let requestParameter = '';
            if(searchDetails.export == true){
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter+'&export=yes';
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'driver-performance'+requestParameter);
            }else{
                requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter;
                return this._httpHelper.get(Config.AuthenticationBaseURL + 'driver-performance'+requestParameter);
            }
        }
        catch (e) {
        }
    }

    inspectionLists(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-inspection-list');
        }
        catch (e) {
        }
    }

    getVehicleInspectionReport(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-inspection-report', request);
        }
        catch (e) {
        }
    }

    getVehicleWiseService(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-wise-service', request);
        }
        catch (e) {
        }
    }

    getVehicleWiseFuel(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-wise-fuel', request);
        }
        catch (e) {
        }
    }

    getVehicleWiseExpense(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-wise-expense', request);
        }
        catch (e) {
        }
    }

    getVehicleWisePart(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-wise-part', request);
        }
        catch (e) {
        }
    }

    getDriverWiseService(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-wise-service', request);
        }
        catch (e) {
        }
    }

    getDriverWiseFuel(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-wise-fuel', request);
        }
        catch (e) {
        }
    }

    getDriverWiseExpense(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-wise-expense', request);
        }
        catch (e) {
        }
    }

    getDriverWisePart(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-wise-part', request);
        }
        catch (e) {
        }
    }

    getLeaveDetails(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-leave-details', request);
        }
        catch (e) {
        }
    }
    serviceLists(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-service-list');
        }
        catch (e) {
        }
    }

    getAttendanceDetails(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-driver-attendance-report', request);
        }
        catch (e) {
        }
    }

    getVehicleSammary(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-summary', request);
    }

    getIgnitionSammary(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-trip-ignition-summary', request);
    }

    getVehicleStoppage(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-stoppage-report', request);
    }

    getVehicleAcMisUseReport(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-ac-misuse-report', request);
    }

    getVehicleEngineOperatingReport(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-engine-operating-time-report', request);
    }

    getVehicleSpeedChartData(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-speed-chart-data', request);
    }

    getVehicleIgnitionChartData(request){
        return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-vehicle-ignition-chart-data', request);
    }

     
}
