import { CompanySettingsService } from './../../services/company-settings/companySettingsService.service';
import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/config';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit {
    companySettings:any = {};
    serverUrl:string ;
    industryList:any =[];
    imageextension : any = [];
    loading:Boolean=false;
    userLoginInfo: any;

    constructor(private router: Router, private companySettingsService:CompanySettingsService, private customErrorHandler: CustomErrorHandler, private commonService: CommonService){}
    
    ngOnInit() {
        this.userLoginInfo = localStorage.getItem('userInfo');
        this.userLoginInfo = JSON.parse(this.userLoginInfo);

        this.imageextension= Constants.imageextensionarray;
        this.commonService.stickyHeader();
        this.serverUrl = Config.FILEURL;
        this.getCompanySettings();
        this.companySettings={};
        this.getIndustryList();
    }

    /*
        This function is used for get company setting details
    */
    getCompanySettings(){
        this.loading = true;
        this.companySettingsService.getCompanySettings().subscribe(
        res => {
            if (res.success == true) {
              this.companySettings = res.data;
              this.companySettings.logo_url= this.serverUrl + this.companySettings.logo;
              this.companySettings.logo = '';
              this.loading = false;
            } else {
              this.loading = false;
              this.commonService.showErrorMessage(res);
            }
        },
        error => {
            this.loading = false;
            this.customErrorHandler.handleHttpError(error, 'getCompanySettings');
        })
    }

    /*
        This function is used for update company setting details
    */
    saveCompanySettings(){
        this.loading = true;
        this.companySettingsService.saveCompanySettings(this.companySettings.company_name ,this.companySettings.logo ,this.companySettings.address ,
        this.companySettings.city ,this.companySettings.state ,this.companySettings.zip ,this.companySettings.country ,this.companySettings.contact_number,
        this.companySettings.industry, this.companySettings.trip_start_buffer, this.companySettings.trip_end_buffer, this.companySettings.working_hour).subscribe(
            res => {
                if (res.success == true) {
                    this.commonService.showSuccessMessage(res.message);
                    this.getUserInfo();
                    this.loading = false;
                    $('.company-name-header').text(res.data.company_name);
                    $('.company-logo-header').attr('src',Config.AuthenticationURL+''+ res.data.logo);
                } else {
                    this.commonService.showErrorMessage(res);
                    this.loading = false;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'saveCompanySettings');
            }
        )
    }

    /*
        This function is used for check image validation
    */
    changeListener($event): void {
        let eventName = $event.target.name;
        let inputValue = $event.target;
        let file:File = inputValue.files[0];
        let myReader:FileReader = new FileReader();
        let imgName = $event.target.files[0].name;
        let imageextensionarray=  imgName.split('.').pop();
        if (imageextensionarray === 'jpg'|| imageextensionarray=== 'png' || imageextensionarray==='jpeg'|| imageextensionarray=== 'bmp') {
            myReader.onloadend = (e) => {
                if(eventName == 'logo'){
                    this.companySettings.logo = myReader.result;
                    this.companySettings.logo_url = myReader.result;
                }
            }
        }
        else {
            $event.target.files.name = '';
            $('.vehicle-img').val('');
            this.companySettings.logo = '';
            $('input[name=logo]').val('');
            swal({
                type: 'error',
                text: Constants.extensionmsg,
                confirmButtonText: 'ok',
            })
        }
        myReader.readAsDataURL(file);
    }

    getIndustryList(){
        this.companySettingsService.getIndustryList().subscribe(
            res => {
                if (res.success == true) {
                  this.industryList = res.data;          
                } else {
                  this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getIndustryList');
            }
        )
    }

    getUserInfo(){
        this.commonService.getUserInfo().subscribe(
            res => {
                if (res.success == true) {
                    localStorage.setItem('userInfo', JSON.stringify(res.data));
                    this.router.navigate(["dashboard"]);
                } else {
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getUserInfo');
            }
        )
    }
}
