import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class WorkOrderService {
    userInfo: any;
    constructor(private _httpHelper: HttpHelper) {
        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
    }

    addOrder(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-order', request)
        }
        catch (e) {
        }
    }

    getOrder(pageNo, rowsPerPage, searchDetails = null){
    	try {
            let searchParameter = '';
            if(searchDetails){
                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search_by='+searchDetails.search_by;
                }
                if(typeof searchDetails.status != 'undefined'){
                    searchParameter += '&status='+searchDetails.status;
                }
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+'&company_id='+this.userInfo.company_id+''+searchParameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-order'+requestParameter)
        }
        catch (e) {
        }
    } 

    getSingleOrder(vehicleId, companyId){
        try {
            let requestParameter = '?order_id='+vehicleId+'&company_id='+companyId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-order'+requestParameter)
        }
        catch (e) {
        }
    }

    deleteOrder(request){
         try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-order', request)
        }
        catch (e) {
        }
    }  

    updateOrder(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-order', request)
        }
        catch (e) {
        }
    }

}
