import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { Constants } from 'src/app/constants/constants';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
// import { constants } from 'perf_hooks';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})

export class AddEmployeeComponent implements OnInit {
	roleSelectedItems = [];
	roleSettings = {};
	timezoneSelectedItems = [];
	timezoneSettings = {};
	loading:boolean;
	employeeDetails: any = {};
	userInfo: any;
	roleList: any;
	timeZoneList: any;
	editEmployeeId: any;
	serverUrl: any;
	dateTime = new Date();
	currentpage : any;
	imageextension : any = [];
	countryList: any = [];
	countrySelectedItems: any = [];
	countrySettings:any = {};
	currentTime: any = new Date();

	vehicleList: any = [];
	vehicleSelectedItems: any = [];
	vehicleSettings:any = {};

	isPrimaryUser: number = 0;

	
  	constructor(
		private router: Router, 
		private http: HttpClient, 
		public userService: UserService, 
		public commonService: CommonService, 
		private customErrorHandler:CustomErrorHandler,
		private translate: TranslateService,
    	private broadcaster: Broadcaster  
	) {
        this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
			this.userInfo = JSON.parse(this.userInfo);
			this.currentpage = "Add";
			this.isPrimaryUser = this.userInfo.is_primary;
		}
		this.translate.get('selectors').subscribe((res) => {
			setTimeout(() => {
				this.countrySettings = {
					singleSelection: true,
					text: res.SelectCountry,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true,
					classes: "singleSelection"
				};
				this.roleSettings = {
					singleSelection: true,
					text: res.SelectRole,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true,
					classes: "singleSelection"
				};
				this.timezoneSettings = {
					singleSelection: true,
					text: res.SelectTimeZone,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true,
					classes: "singleSelection"
				};
				this.vehicleSettings = {
					singleSelection: false,
					text: res.SelectVehicle,
					selectAllText: 'Select All',
					unSelectAllText: 'UnSelect All',
					enableSearchFilter: true
				};
			});
		});

		this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('selectors').subscribe((res) => {
				setTimeout(() => {
					this.countrySettings = {
						singleSelection: true,
						text: res.SelectCountry,
						selectAllText: 'Select All',
						unSelectAllText: 'UnSelect All',
						enableSearchFilter: true,
						classes: "singleSelection"
					};
					this.roleSettings = {
						singleSelection: true,
						text: res.SelectRole,
						selectAllText: 'Select All',
						unSelectAllText: 'UnSelect All',
						enableSearchFilter: true,
						classes: "singleSelection"
					};
					this.timezoneSettings = {
						singleSelection: true,
						text: res.SelectTimeZone,
						selectAllText: 'Select All',
						unSelectAllText: 'UnSelect All',
						enableSearchFilter: true,
						classes: "singleSelection"
					};
				});
			});
		});
    }

  	ngOnInit() {
		this.imageextension= Constants.imageextensionarray;		
		this.loading = false;
  		this.employeeDetails.company_id = this.userInfo.company_id;
  		this.employeeDetails.company_name = this.userInfo.company_name;
  		this.employeeDetails.time_zone = '';
  		this.employeeDetails.role_id = '';
  		this.employeeDetails.country = '';
		this.editEmployeeId = '';
		this.getVehicleList();
		this.editEmployeeId = localStorage.getItem('editEmployeeId');
  		if(this.editEmployeeId){
			this.loading = true; 
			this.currentpage = "Update";
  		}
  		this.serverUrl = Config.FILEURL;

		this.commonService.stickyHeader();
		this.dateTime.setDate(this.dateTime.getDate() -1); 
  	}

  	addEmployee(){
		this.loading =true;
		if(this.isPrimaryUser == 1 && (this.userInfo.id != this.editEmployeeId))
		{
			this.employeeDetails.vehicle_ids = [];
			for (var i = 0; i < this.vehicleSelectedItems.length; i++) {
				this.employeeDetails.vehicle_ids.push(this.vehicleSelectedItems[i]['id']);
			}
		}
  		if(this.editEmployeeId){
  			this.employeeDetails.id = this.editEmployeeId;
  			this.userService.updateEmployee(this.employeeDetails).subscribe(
		        res => {
					this.loading =false; 
		           	localStorage.removeItem('editEmployeeId');
		          	if (res.success == true) {
						this.commonService.showSuccessMessage(res.message);
						if(this.editEmployeeId == this.userInfo.id){
							this.getUserInfo();
						}
						else{
						 this.router.navigate(['./user-management/employee-list']);
						}
		          	} else {
		            	this.commonService.showErrorMessage(res);
		          	}
		        },
		        error => {
					this.loading =false; 
		          	this.customErrorHandler.handleHttpError(error, 'updateEmploye');
		        }
		    )
  		}else{
  			this.userService.addEmployee(this.employeeDetails).subscribe(
		        res => {
					this.loading =false; 
		          	if (res.success == true) {
		          		this.commonService.showSuccessMessage(res.message);
		           		this.router.navigate(['./user-management/employee-list']);
		          	} else {
		            	this.commonService.showErrorMessage(res);
		          	}
		        },
		        error => {
					this.loading =false; 
		          	this.customErrorHandler.handleHttpError(error, 'addEmployee');
		        }
		    )
  		}
  	}

  	changeListener($event): void {
        let eventName = $event.target.name;
        let inputValue = $event.target;
        let file:File = inputValue.files[0];
		let myReader:FileReader = new FileReader();
		let imgName = $event.target.files[0].name;
		let imageextensionarray=  imgName.split('.').pop();
		if (imageextensionarray === 'jpg'|| imageextensionarray=== 'png' || imageextensionarray==='jpeg'|| imageextensionarray=== 'bmp') {
			myReader.onloadend = (e) => {
				this.employeeDetails.photo = myReader.result;
				// this.employeeDetails.employee_photo = this.employeeDetails.photo;
				$('.employee-img').attr('src', this.employeeDetails.photo);
			} 
		}
		else {
			$event.target.files.name = '';
			 $('.employee-img').val('');
			this.employeeDetails.photo = '';
			$('input[name=employee_image]').val('');
			swal({
	            type: 'error',
	            text: Constants.imageValidationMsg,
				confirmButtonText: 'ok',
			})
		}
        
        myReader.readAsDataURL(file);
    }

    getUserRole(){
		this.userService.getUserRole(this.userInfo.company_id).subscribe(	       
			res => {
	          	if (res.success == true) {
					this.roleList = res.data;
					this.editEmployeeId = localStorage.getItem('editEmployeeId');
					if(this.editEmployeeId){
						this.getEmployeeDetails(this.editEmployeeId);
					}
	          	} else {
						this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.customErrorHandler.handleHttpError(error, 'getUserRole');
	        }
	    )
	}

	getTimeZone(){
		this.userService.getTimeZone().subscribe(res => {
	        if (res.success == true) {
				this.timeZoneList = res.data;
				this.getUserRole();
	        } else {
				this.commonService.showErrorMessage(res);
	        }
	    },
        error => {
			this.customErrorHandler.handleHttpError(error, 'getTimeZone');
        })
	}

	getCountry(){
		this.commonService.getCountry().subscribe(res => {
	        if (res.success == true) {
				this.countryList = res.data;
				this.getTimeZone();
	        } else {
				this.commonService.showErrorMessage(res);
	        }
	    },
	    error => {
				this.customErrorHandler.handleHttpError(error, 'getCountry');
	        }
	    )
	}

	getEmployeeDetails(employeeId){
		this.loading = true; 
		this.userService.getSingleEmployee(employeeId).subscribe(res => {
	        if (res.success == true) {
	          	this.employeeDetails = res.data;
	          	this.employeeDetails.password = '';
	          	this.employeeDetails.employee_photo = this.employeeDetails.photo;
	          	this.employeeDetails.photo = '';
				this.setCountry();
				this.setTimeZone();
				this.setRole();	
				if(this.isPrimaryUser == 1 && (this.userInfo.id != this.editEmployeeId))
				{
					let veihcleIds = res.data.vehicles;
					this.vehicleSelectedItems = [];
					for (var i = 0; i < veihcleIds.length; i++) {
						let vehicleTemp = this.vehicleList.find(s => s.id == veihcleIds[i]);
						if(vehicleTemp != undefined){
							this.vehicleSelectedItems.push(vehicleTemp);
						}
					}
				}
				this.loading =false; 
	        } else {
				this.loading =false; 
				this.commonService.showErrorMessage(res);
	        }
	    }, error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
		});
	}

	getVehicleList(){
		this.userService.getVehicleList().subscribe(res => {
	        if (res.success == true) {
	          	this.vehicleList = res.data;
	        } else {
				this.commonService.showErrorMessage(res);
	        }
	        this.getCountry();
	    }, error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getVehicleList');
		});
	}

	numberValidation(event: any) {

        const pattern = /[0-9\+\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
          	event.preventDefault();
        }
	}

	setCountry(){
		let selectedCounry = this.countryList.find(s => s.itemName == this.employeeDetails.country);
		if(selectedCounry != undefined && this.countrySelectedItems.length == 0){
			this.countrySelectedItems = [];// [{ "id": "107", "itemName": "India" }];//[];
			this.countrySelectedItems.push(selectedCounry);
		}
	}
	setTimeZone(){
		let timezone = this.timeZoneList.find(s=>s.itemName == this.employeeDetails.time_zone);
		if(timezone!= undefined && this.timezoneSelectedItems.length == 0){
			this.timezoneSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
			this.timezoneSelectedItems.push(timezone);
		}
	}
	setRole(){
		let Role = this.roleList.find(s=>s.id == this.employeeDetails.role_id);
		if(Role!= undefined && this.roleSelectedItems.length == 0){
			this.roleSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
			this.roleSelectedItems.push(Role);
		}
	}

	onItemSelectCounry(item: any) {
    	this.employeeDetails.country = item.itemName;
	}
	onItemSelectTimezone(item: any) {
    	this.employeeDetails.time_zone = item.itemName;
	}
	
	onItemSelectRole(item: any) {
    	this.employeeDetails.role_id = item.id;
  	}
	
	onOpenCountry(event: any) {
    	this.countryList = this.countryList.map((item) => {
      		return {
		        id: item.id,
		        itemName: item.itemName
		    }
    	});
	}

	onOpenRole(event: any) {
	    this.roleList = this.roleList.map((item) => {
	      	return {
		        id: item.id,
		        itemName: item.itemName
		    }
	    });
	}
	onOpenTimezone(event: any) {
	    this.timeZoneList = this.timeZoneList.map((item) => {
	      	return {
		        id: item.id,
		        itemName: item.itemName
		    }
	    });
	}

  	getUserInfo(){
	    this.commonService.getUserInfo().subscribe(
	      	res => {
		        if (res.success == true) {
					let userInfo = JSON.parse(localStorage.getItem('userInfo'));
					res.data.access_module_list = userInfo.access_module_list;
					localStorage.setItem('userInfo', JSON.stringify(res.data));
					let userDetails = JSON.stringify(res.data);
					let dateTime = new Date();
					$('.profile-image').attr('src',Config.AuthenticationURL+''+ res.data['photo']+'?time='+dateTime);					
		         	$('.user-fullname').text(res.data['full_name']);
		         	console.log(Config.AuthenticationURL+''+ res.data['photo']+'?time='+dateTime,'userDetails')
					 $('.user-email').text(res.data['email']);
		         	this.router.navigate(['./dashboard']);
		        } else {
		          this.commonService.showErrorMessage(res);
		        }
	      	},
		    error => {
		        this.customErrorHandler.handleHttpError(error, 'getUserInfo');
		    }
	    )
	}

	ngOnDestroy() {
        localStorage.removeItem('editEmployeeId');   	
    }
}
