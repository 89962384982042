import { HttpHelper } from './../../helpers/http.helper';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/config/config';

@Injectable()
export class NotificationService {
  constructor(private httpHelper: HttpHelper) { }

  getNotifications(requestParameter) {
    try {
    	return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-notification', requestParameter);
    }
    catch (e) {
    }
  }
}
