import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { InspectionService } from '../../../services/inspection/inspection.service';
import { CommonService } from '../../../services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../../../constants/constants';


@Component({
  selector: 'app-add-inspection',
  templateUrl: './add-inspection.component.html',
  styleUrls: ['./add-inspection.component.css']
})
export class AddInspectionComponent implements OnInit {

  vehicleList: any;
  VehicleSelectedItems = [];
  VehicleSettings = {};
  durationList: any;
  durationSelectedItems = [];
  durationSettings = {};
  loading:Boolean=false;
  inspectionDate:Date = new Date();
  minDate:Date = new Date();
  inspectionData: any = {};
  inspection_item:any = [];
  pageLabel;
  selectedInspectionId;
  isEditMode: boolean;
  userData:any = JSON.parse(localStorage.getItem('userInfo'));
  constructor(
    public vehicleService: VehicleService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public inspectionService: InspectionService,
  ) { }

  ngOnInit() {
    this.pageLabel = 'Add'
    this.getReminders();

    this.selectedInspectionId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedInspectionId) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getInspectionDetail(this.selectedInspectionId);
    } else {
      this.isEditMode = false;
    }

    this.VehicleSettings = {
      singleSelection: false,
      text: "Select vehicle",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: ""
    };

    this.durationSettings = {
      singleSelection: true,
      text: "Select Duration",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "singleSelection"
    };

    this.inspectionData.inspection_item = [{
      label: '',
      type: 'textbox'
    }]    
  }

  getReminders() {
    this.commonService.getReminder().subscribe(res => {
      if (res.success == true) {
        this.durationList = res.data;
        let durationId = this.inspectionData.inspection_reminder;
				if (this.selectedInspectionId && durationId != undefined && this.durationList.length > 0) {
					this.durationSelectedItems = [];					
          let durationTemp = this.durationList.find(s => s.id == durationId);
          if (typeof durationTemp != 'undefined') {
            this.durationSelectedItems.push(durationTemp);
          }
				}
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getYearSlab() {
    var d = new Date();
    var s = d.getFullYear();
    var e:any = s + 50;
    e = s + ':' + e;
    return e;
  }

  addInspection(data) {
    this.loading = true; 
    data.user_id = this.userData.id;
    if (this.isEditMode) {
      this.updateInspection(data);
    } else {
      this.inspectionService.addInspection(data).subscribe(res => {
        if (res.success == true) {
          this.loading =false; 
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(['/inspection-management/inspection-list']);
        } else {
          this.commonService.showErrorMessage(res);
          this.loading = false; 
        }
      }, error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'addInspection');
      });
    }
  }

  addItem() {
    let obj = { 
      label: '',
      type: 'radio' 
    }
    this.inspectionData.inspection_item.push(obj);
  }

  removeItem(index, obj) {
    this.inspectionData.inspection_item.splice(index, 1);
    let cloned = this.inspectionData.inspection_item.map(x => Object.assign({}, x));
    this.inspectionData.inspection_item = cloned;
  }

  updateInspection(data) {
    data.inspection_reminder = this.durationSelectedItems[0].id;
    data.inspection_id = data.id;
    this.inspectionService.updateInspection(data).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(['/inspection-management/inspection-list']);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getInspectionDetail(inspectionId) {
    this.loading =true; 
    let queryParam = {
      inspection_id: inspectionId
    }
    this.inspectionService.getSingleInspection(queryParam).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.inspectionData = res.data;
        this.getReminders();
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

}
