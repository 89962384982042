import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { ChecklistService } from '../../services/checklist/checklist.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {

  	loading:boolean;
    isDemoTenant:boolean;
    message:string;
    totalItem: any;
    perPage: any = 10;
    pageNo: any = 1;
    checklistDetails: any = [];
    page: number = 1;
    searchDetails: any = {};
    userDetails: any;
    deleteDetails: any = {};
    modalRef: BsModalRef;
    checklistInfo: any = {};
    userInfo: any;
    currentpage : any;
    editChecklistId : any;
    companyDetails: any = [];
    searchTempVal: any;
    checkListItem: any = [];
    checkListItemCount : any = [];
    deleteMsg: any;
    deleteLabel: any;
    cancelLabel: any;
    EditLabel:string;
    checkItemCount: number = 0;

    constructor(
        private router: Router, 
        private http: HttpClient, 
        public checklistService: ChecklistService,
        private customErrorHandler:CustomErrorHandler, 
        private _commonService:CommonService,
        private modalService: BsModalService,
        private translate: TranslateService,
    	private broadcaster: Broadcaster
    ) {
        this.userDetails = localStorage.getItem('userInfo');
        this.userDetails = JSON.parse(this.userDetails);
        this.userInfo = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(this.userInfo);
        this.currentpage = "Add";
        this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
                this.deleteMsg = res.DeleteAlert;
                this.deleteLabel = res.Delete;
                this.cancelLabel = res.Cancel;
                this.EditLabel = res.Edit;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
                setTimeout(() => {
                    this.deleteMsg = res.DeleteAlert;
                    this.deleteLabel = res.Delete;
                    this.cancelLabel = res.Cancel;;
                    this.EditLabel = res.Edit;
                });
            });
		});
    }

    ngOnInit() {
        this.checkListItemCount.push(0);
    	this.checkListItem.push('');
    	this.checklistInfo.checklist_item = [];
    	this.checklistInfo.checklist_item.push('');
        this.searchTempVal = '';
        this.loading = false;
        this.totalItem = 0;
        this.searchDetails.search_by = '';
        this.getChecklistDetails(this.pageNo);
        localStorage.removeItem('editChecklistId');

        this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    }

    /*
        This function is used for get check list data according to pagination
    */
    getChecklistDetails(currentPage){
        this.loading =true;
        this.pageNo = currentPage;
        this.page = currentPage;
        let requestParameter = {
            page_no: this.pageNo,
            row_per_page: this.perPage,
            search_by: this.searchDetails.search_by
        }
        this.checklistService.getCheckList(requestParameter).subscribe(
            res => {   
                this.loading =false; 
                this.message = res.message;         
                if (res.success == true) {
                    this.totalItem = res.data.count;
                    this.checklistDetails = res.data.rows;
                } else {
                    this._commonService.showErrorMessage(res);
                }
            },
            error => {
                this.loading =false; 
                this.customErrorHandler.handleHttpError(error, 'getGroup');
            }
        )
    }
    
    /*
        This function is used for open check list modal used for add and update check list data
    */
    openCheckListModal(checklistId,template) {
        this.checklistInfo.checklist_item = [];
        this.checklistInfo.checklist_item.push('');

        if(checklistId == '' || checklistId == null || typeof checklistId == 'undefined'){
            this.editChecklistId = '';
        }else{
            this.editChecklistId = checklistId;
        }
        this.modalRef = this.modalService.show(template);
            
        this.checklistInfo.company_id = '';
        if(checklistId){
            this.currentpage = "Update";
            this.getSingleChecklist(checklistId);
        }
        else{
            delete this.checklistInfo.checklist_title;
            this.checkListItemCount = [0];
            this.checkListItem = [];
        }

        this.checklistInfo.company_id = this.userInfo.company_id;
    }
     
    /*
        This function is used for remove special char
    */ 
  	removeSpecialChar(event : any){   
        var k = event.charCode; 
        return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 0 || k == 32 || (k >= 48 && k <= 57)); 
    }

    /*
        This function is used for get single checklist object according to id 
    */
    getSingleChecklist(checklistId){
        this.checklistInfo = [];
    	let requestParameter = {
    		checklist_id: checklistId
    	};

        this.checklistService.getSingleCheckList(requestParameter).subscribe(res => {
            if (res.success == true) {
                this.checklistInfo = res.data;
                this.checkListItem = this.checklistInfo.checklist_item;
                let length = this.checkListItem.length;
                this.checkListItemCount = [];
                for(let i = 0; i < length ; i++){
                    this.checkListItemCount.push(i);
                }
            } else {
                 this._commonService.showErrorMessage(res);
            }
        },
        error => {
              this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
        })
    }
    
    /*
        This function is used for add and update check list details
    */
    addChecklist(){
        this.checkItemCount = 0;
        let checklistData = this.checkListItem.filter(function () { return true });
        let sortedArr = checklistData.slice().sort();
        let itemResults = [];
        for (let i = 0; i < sortedArr.length - 1; i++) {
            if (sortedArr[i + 1] == sortedArr[i]) {
                itemResults.push(sortedArr[i]);
            }
        }

        if(itemResults.length > 0)
        {
            this.checkItemCount = itemResults.length;
            return false;
        }
        
        this.checklistInfo.checklist_item = checklistData;
    	if(this.editChecklistId){
            this.checklistInfo.modified_by = this.userInfo.id;
            this.checklistInfo.checklist_id = this.editChecklistId;
            this.checklistService.updateCheckList(this.checklistInfo).subscribe(
                res => {
                    if (res.success == true) {
                        this.modalRef.hide();
                        this._commonService.showSuccessMessage(res.message);
                        this.getChecklistDetails(this.pageNo);
                    } else {
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'addChecklist');
                }
            )
        }else{
            this.checklistInfo.createdBy = this.userInfo.id;
            this.checklistService.addCheckList(this.checklistInfo).subscribe(
                res => {
                    if (res.success == true) {
                        this.modalRef.hide();
                        this._commonService.showSuccessMessage(res.message);
                        this.getChecklistDetails(1);
                    } else {
                        this._commonService.showErrorMessage(res);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'addChecklist');
                }
            )
       }
    }
    
    /*
        This function is used for search checklist data
    */
    searchByFilter(){
      if(this.searchTempVal != this.searchDetails.search_by){
        this.searchTempVal = this.searchDetails.search_by;
            this.pageNo = 1;
            this.getChecklistDetails(this.pageNo);
        }
    }

    /*
        This function is used for set edit check list id
    */
    editChecklist(checklistId){
      this.editChecklistId = checklistId;
    }

    /*
        This function is used for delete check list item according to check list id
    */
    deleteChecklist(checklistId){
      swal({
    			type: 'error',
    			text: this.deleteMsg,
                confirmButtonText: this.deleteLabel,
                showCancelButton:true,
                cancelButtonText: this.cancelLabel
    		}).then((result) => {
    			if (result.value) {
	          		this.deleteDetails.checklist_id = checklistId;
	          		this.checklistService.deleteCheckList(this.deleteDetails).subscribe(
	              	res => {
		                if (res.success == true) {
		                  this.pageNo = 1;
		                  this._commonService.showSuccessMessage(res.message);
		                  this.getChecklistDetails(this.pageNo);


		                } else {
		                  this._commonService.showErrorMessage(res);
		                }
	              	},
		            error => {
		                this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
		            }
	            )
        	}
      	})
    }

    /*
        This function is used for add new item input 
    */
    addmore() {
	    let length = this.checkListItemCount.length + 1;
        this.checkListItemCount = [];
        for(let i = 0; i < length ; i++){
            this.checkListItemCount.push(i);
            if((length-1) == i){
              this.checkListItem[i] = '';
            }
        }
  	}
  	
    /*
        This function is used for remove specific item input 
    */
  	remove(index) {
    	let length = this.checkListItemCount.length;
        if(length > 1){
            length = length -1;
            this.checkListItemCount = [];
            for(let i = 0; i < length ; i++){
                this.checkListItemCount.push(i);
            }
            this.checkListItem.splice(index, 1);
        }
    }

    checkListItemValue(){
        this.checkItemCount = 0;
        let checklistData = this.checkListItem.filter(function () { return true });
        let sortedArr = checklistData.slice().sort();
        let itemResults = [];
        let blankValue = false;
        for (let i = 0; i < sortedArr.length - 1; i++) {
            if (sortedArr[i + 1] == sortedArr[i]) {
                itemResults.push(sortedArr[i]);
            }
            if(sortedArr[i] == ''){
                blankValue = true;
            }
        }

        if(itemResults.length > 0 && blankValue == false)
        {
            this.checkItemCount = itemResults.length;
            return false;
        }
    }
}
