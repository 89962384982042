import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  getYear: any;
  currentVersion: any = '1.0.0';
  constructor() { }

  ngOnInit() {
  	let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  	if(userInfo.version != undefined){
  		this.currentVersion = userInfo.version;
  	}
    this.getYear = moment().year();
  }

}
