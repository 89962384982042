import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoginService } from '../../services/login/login.service';
import { CommonService } from 'src/app/services/common/common.service';
import { DEFAULT_SCROLL_TIME } from '@angular/cdk/scrolling';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import { Config } from 'src/app/config/config';
declare let $: any;
// declare var TweenMax: any;
// declare var Expo: any;
// declare var Quad: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  show: boolean = false;
  credentials: any = {};
	  modalRef: BsModalRef;
    forgotPasswordDetails : any = {};
    language: any;
    labelup: any = 0;
    signin_btn_txt: string;
    forgot_loader:Boolean = false;
    errorShow:boolean = false;
    error_msg:string;
    registration_number: string = '';
    apkName :any;
      constructor(private modalService: BsModalService,public router: Router, public _loginService: LoginService, 
       private customErrorHandler:CustomErrorHandler, private commonService:CommonService, private liveTrackingService : LiveTrackingService) { }
    ngOnDestroy() {
      document.body.classList.remove("login-page");
    }
  	ngOnInit() {
      this.apkName = Config.FILEURL+'public/Election_Fleet_Pro.apk';
      this.signin_btn_txt = "Sign In";
      document.body.classList.add("login-page");
      this.language = navigator.language;
      let loginchk = localStorage.getItem('isLogin');
      let dashboardUrl = localStorage.getItem('dashboardUrl');
      if(loginchk == '1'){
        this.router.navigate([dashboardUrl]);
      }
      
    }
    onInputFocus(e:any) {
      e.target.parentElement.classList.add("focusWithText");
    }

    onInputBlur(e:any) {
      if(e.target.value == "") {
          e.target.parentElement.classList.remove("focusWithText");
      }
    }
  	login(){ 
      this.errorShow = false;  
      this.signin_btn_txt = "Signing in..";          
        this._loginService.doLogin(this.credentials.email,this.credentials.password, this.language).subscribe(
        res => {
          this.signin_btn_txt = "Sign In";         
          if (res.success == true) {
            let dashboardUrl = "live-tracking";
            let allowModule = JSON.parse(res.data.access_module.allow_modules);
            allowModule.forEach((obj) => {
              if(obj == 34) {
                dashboardUrl = "dashboard";            
              }
            });
            localStorage.setItem('token', res.token);
            localStorage.setItem('isGps', res.data.gps);
            localStorage.setItem('isLogin', '1');
            localStorage.setItem('userInfo', JSON.stringify(res.data));            
            localStorage.setItem('isDemoTenant', 'false');
            localStorage.setItem('googleKey', res.data.googleKey);
            if (localStorage.getItem('language') == null) {
              localStorage.setItem('language', 'en');
            }
            console.log(dashboardUrl)
            localStorage.setItem('dashboardUrl', dashboardUrl);
            window.location.reload();
          } else {
            this.errorShow = true;
            this.error_msg = res.message;
            //this.commonService.showErrorMessage(res);
          }
        },
        error => {
            this.customErrorHandler.handleHttpError(error, 'doLogin');
            this.signin_btn_txt = "Sign In";
        }
      )
  	}

  	openCallLeterModal(template, /*emailId*/) {
      this.forgotPasswordDetails.email='';
        this.modalRef = this.modalService.show(template);
       //  this.callLeterDetails.email = emailId;
    }

    forgotPassword(){
      this.forgot_loader = true;
    	 this._loginService.forgotPassword(this.forgotPasswordDetails).subscribe(
        res => {
	        this.modalRef.hide();
          this.forgot_loader = false;
          if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.modalRef.hide();
          this.forgot_loader = false;
          this.customErrorHandler.handleHttpError(error, 'forgotPassword');          
        }        
      )
    }
    submit(data){
      
    // this.router.navigate(['/live-tracking-data', data]);
    // this.router.navigateByUrl('live-tracking-data')
    this.router.navigate(['live-tracking-data', data])
    }

}
