import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  constructor(
    private httpHelper: HttpHelper
  ) { }

  addShift(request){
    try {
          return this.httpHelper.post(Config.AuthenticationBaseURL + 'add-shift', request);
      }
      catch (e) {
      }
  }

  updateShift(request){
    try {
          return this.httpHelper.put(Config.AuthenticationBaseURL + 'update-shift', request);
      }
      catch (e) {
      }
  }

  getShift(request){
    try {
          return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-shift', request);
      }
      catch (e) {
      }
  }

  getSingleShift(request){
    try {
          return this.httpHelper.post(Config.AuthenticationBaseURL + 'get-shift-by-id', request);
      }
      catch (e) {
      }
  }

  deleteShift(request){
      try {
         return this.httpHelper.delete(Config.AuthenticationBaseURL + 'delete-shift', request);
     }
     catch (e) {
     }
  }

  getAllShift(){
    try {
          return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-shift-list');
      }
      catch (e) {
      }
  }

  getDriverConductorList(){
    try {
          return this.httpHelper.get(Config.AuthenticationBaseURL + 'get-free-driver-list');
      }
      catch (e) {
      }
  }  
}
