import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';

@Component({
  selector: 'app-driver-wise-expense',
  templateUrl: './driver-wise-expense.component.html',
  styleUrls: ['./driver-wise-expense.component.css']
})
export class DriverWiseExpenseComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	driverDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	userInfo: any;
	deleteDetails: any= {};
	serverUrl: any;
	searchTempVal: any;
	start_date;
	end_date;

  	constructor(private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
		this.searchTempVal = '';
		this.searchDetails.search_by = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.company_id = this.userInfo.company_id;
  		this.getDriverDetails(this.pageNo);
  		localStorage.removeItem('editDriverId');
  		this.searchDetails.status = '';
			this.serverUrl = Config.AuthenticationURL;
			
			this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getDriverDetails(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {
			page: this.pageNo,
			per_page: this.perPage,
			search: this.searchDetails.search_by,
			export: ''
		};
		this.reportService.getDriverWiseExpense(requestParameter).subscribe(
	        res => {
						this.loading =false; 
						this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
	          	this.driverDetails = res.data.rows;
	          } else {
				this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getDriverDetails(this.pageNo);
		}
	}

    exportDriverReport(){
    	let requestParameter = {
			page: this.pageNo,
			per_page: this.perPage,
			search: this.searchDetails.search_by,
			export: 'yes'
		};
    	this.reportService.getDriverWiseExpense(requestParameter).subscribe(
	        res => {
				// this.loading =false; 
				// this.message = res.message;
		  //       if (res.success == true) {
		  //         	this.totalItem = res.data.count;
		  //         	this.driverDetails = res.data.rows;
		  //       } else {
				// 	this.commonService.showErrorMessage(res);
		  //       }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
    }


}
