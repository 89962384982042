import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { DueSerService } from '../../../services/due-service/dueService.service';
import { Config } from 'src/app/config/config';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-view-service',
  templateUrl: './view-service.component.html',
  styleUrls: ['./view-service.component.css']
})
export class ViewServiceComponent implements OnInit {

  public isDemoTenant:boolean;
  loading:Boolean=false;
  modalRef: BsModalRef;
  viewServiceList:any = [];
  tempSearch;
  searchTextValue = '';
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  selectedId;
  pendingServiceMsg = 'Service has not been done yet'
  serverUrl = Config.AuthenticationURL;
  imageUrl = '';
  noDataMsg;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public dueSerService: DueSerService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.selectedId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedId) {
      this.getViewService(1);
    }
  }

  getViewService(currentPage) {
    this.loading = true; 
    this.page = currentPage;
    let queryParams = {
      row_per_page: this.perPage,
      page_no:this.page,
      search: this.searchTextValue,
      service_id: this.selectedId
    }
    this.dueSerService.viewService(queryParams).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.viewServiceList = res.data.rows;
        this.noDataMsg = res.message;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  searchByFilter(event){
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getViewService(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  openModal(template, image) {
    this.imageUrl = '';
    this.modalRef = this.modalService.show(template);
    this.imageUrl = this.serverUrl + image;
	}

	closeModal(template) {
    this.modalRef.hide();
    this.imageUrl = '';
	}

}
