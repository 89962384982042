import { Component, OnInit, NgZone} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { LiveTrackingService } from 'src/app/services/live-tracking/live-tracking.service';
import { Config } from './../../config/config';
import { VehicleService } from '../../services/vehicle/vehicle.service';
import { Constants } from 'src/app/constants/constants';
import { Socket } from 'ngx-socket-io';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
declare var socket : any;
declare var google: any;
// import {} from 'googlemaps'


@Component({
  selector: 'app-live-tracking-data',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.css'] 
})

export class LiveTrackingDataComponent implements OnInit {

    zoom: any;
	lat: any;
	lng: any;
	serverUrl: any;
	message: any;
    mapStyles: any;
    directionsService: any;
    directionsDisplay: any;
    map: any;
    vehicleList: any = [];
    vehicleSelectedItems: any = [];
    vehicleSettings: any = {};
    marker: any;
    currentSpeed: number = 0;
    previousSpeed: number = 0;
    speedSetting: boolean = true;
    tempSpeed: number = 0;
    routeTime: any = '';
    currentTripId: any = '';
    markerEnd: any = [];
    bearing: any;
    previousDateTime: any = ''; 
    movingIcon: any = 'assets/image/vehicles-icon/Car/running.png';
    newLineCordinate: any = [];
    tempLat: number = 0;
    tempLng: number = 0;
    vehicleId: any = null;
    startActualTime: any;
    endActualTime: any;
    isLoading:Boolean = false;
    lineCoordinates: any = [];
    timeArray: any = [];
    vehicleType: string = 'Car';
    position: any;
    tripTrackingData: any = [];
    modeArrayStatus: any = [];
    stoppageArray: any = [];
    trackingRequest: any = {};
    lastSeen:any;
    acStatus:any = '0';
    speed:any;
    vehicleBattery:any;
    deviceBattery:any;
    odometerReading: number = 0;
    startAddress: any = '';
    loading: boolean = false;
    moveMarkerTime: number = 50;
    smoothCount: number = 0;
    smoothRunning: boolean = true;
    runningVehicleTrackingCount: number = 0;
    fuelCapacity : any = '0 L';
  	constructor(
        private router: Router,
        public liveTrackingService: LiveTrackingService, 
        private customErrorHandler:CustomErrorHandler, 
        private commonService:CommonService, 
        private socket: Socket,
        private translate: TranslateService,
        private broadcaster: Broadcaster,
        private activated : ActivatedRoute
    )
   {
   
    
    this.translate.get('selectors').subscribe((res) => {
        setTimeout(() => {
            this.vehicleSettings = {
                singleSelection: true,
                text: res.SelectVehicle,
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                enableSearchFilter: true,
                classes: "singleSelection"
            };
        });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('selectors').subscribe((res) => {
            setTimeout(() => {
                this.vehicleSettings = {
                    singleSelection: true,
                    text: res.SelectVehicle,
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    enableSearchFilter: true,
                    classes: "singleSelection"
                };
            });
        });
    }); 
    }

	ngOnInit() {
        console.log('++++++++++++++', this.router.url)
      this.vehicleSettings = {
          singleSelection: true,
          text: "Select vehicle",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          classes: "singleSelection"
      };

        this.zoom = 14;
     
      this.serverUrl = Config.AuthenticationURL;
      this.mapStyles = Constants.mapStyles;

    //   this.lat = Number(this.userInfo.lat);
    //   this.lng = Number(this.userInfo.lng);
      if(this.lat == undefined || this.lat == ''){
          this.lat = 22.752559;
          this.lng = 75.865535;
      }
      this.serverUrl = Config.AuthenticationURL;
      setTimeout( () => {
          this.directionsService = new google.maps.DirectionsService;
          this.directionsDisplay = new google.maps.DirectionsRenderer({
              suppressMarkers: true
          });
          let mapOptions = {
              zoom: 14,
              center: new google.maps.LatLng(this.lat, this.lng),
              travelMode: google.maps.TravelMode.DRIVING,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              styles: this.mapStyles,
              streetViewControl: true,
              disableDefaultUI: false,
              mapTypeControl: true,
              mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
                  position: google.maps.ControlPosition.BOTTOM_RIGHT
              },
              fullscreenControlOptions: {
                  position: google.maps.ControlPosition.BOTTOM_RIGHT
              },
              gestureHandling: 'greedy'
          };
          this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
          this.liveTrackingTrip();
                            this.initializeScoket();
      }, 1000);
  }

//   getLiveVehicleDetails(){
//       this.loading = true;
//       let requestParam = {}
//       this.liveTrackingService.getLiveTrackingDrivers(requestParam).subscribe(res => {
//           if (res.success == true) {
//               this.vehicleList = res.data;
//               if(this.vehicleList.length > 0 && this.vehicleId==null ){
//                   let itemsInd = [];
//                   this.vehicleSelectedItems = [];
//                   this.vehicleSelectedItems.push(this.vehicleList[0]);
//                   this.vehicleId = this.vehicleList[0].id;
//                   this.liveTrackingTrip();
//                   this.initializeScoket();
//               }
//           } else {
//               this.commonService.showErrorMessage(res);
//           }
//           this.loading = false;
//       },
//       error => {
//           this.loading = false;
//               this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
//           }
//       )
//   }

  initializeScoket(){
      this.socket.removeListener('update');
    //   let userData = {user_id : this.userInfo.id,vehicle_id : this.vehicleId, company_id: this.userInfo.company_id};
    //   this.socket.emit('ready for data', userData);
      console.log('socket connection vehicle', this.vehicleId);
      this.socketInitialize();
  }


  socketInitialize(){
      let this1 = this;
      this.socket.on('vehicle_tracking', (data) => {
          console.log('move marker', data.vehicle_id)
          // if(this.runningVehicleTrackingCount == 0){
          //     console.log(data.vehicle_id, 'socket data')
          //     this.router.navigate(['./report-management/report-dashboard']);
          //     setTimeout(()=>{
          //         this.router.navigate(['live-tracking']);
          //     },200);
          // }
          this.moveMarker(data);
      });
  }

  onItemSelectVehicle(item: any){
      this.isLoading = true;
      this.vehicleId = item.id;
      this.socket.removeListener('vehicle_tracking');
      let mapOptions = {
          zoom: 14,
          center: new google.maps.LatLng(22.752559, 75.865535),
          travelMode: google.maps.TravelMode.DRIVING,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: this.mapStyles,
          streetViewControl: true,
          disableDefaultUI: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.VERTICAL_BAR,
              position: google.maps.ControlPosition.BOTTOM_RIGHT
          },
          fullscreenControlOptions: {
              position: google.maps.ControlPosition.BOTTOM_RIGHT
          },
          gestureHandling: 'greedy'
      };
      setTimeout(() => {
          this.isLoading = false;
          this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
          this.liveTrackingTrip();
          this.initializeScoket();
      }, 800);
  }

  moveMarker(data){
    //console.log(data,'data');
      let itemIndex = this.vehicleList.findIndex(item => item.id == data.vehicle_id);
      // if(itemIndex != undefined && itemIndex != null){
      //     this.vehicleList[itemIndex].status = data.status;
      // }
      let oldSpeed = this.previousSpeed;
      if(data.is_aggressive_acceleration == 1 || data.is_excessive_speed == 1 || data.is_hard_braking == 1){
          // console.log(data.speed,'new speed--old speed',this.previousSpeed, data.lat)
      }

      //if(data.trip_id == this.currentTripId && data.vehicle_id == this.vehicleId){
      if(data.trip_id == this.currentTripId){
          this.speedSetting = true;
          for (let i = 0; i < this.markerEnd.length; i++) {
            this.markerEnd[i].setMap(null);
          }
          this.markerEnd = [];
          let currentLat = Number(data.lat);
          let currentLng = Number(data.lng);
          this.bearing = Number(data.bearing);            
          let speed = Number(data.speed);
          
          this.previousSpeed = this.currentSpeed;
          // let oldSpeed = this.previousSpeed;
          this.currentSpeed = speed;            
          let icon = {};
          let accelation = Number(data.is_aggressive_acceleration);
          let excessiveSpeed = Number(data.is_excessive_speed);
          let hardBreaking = Number(data.is_hard_braking);
          let phoneUses = Number(data.is_phone_usage);
          let bounds = new google.maps.LatLngBounds();
          if(this.previousDateTime != ''){
              // let oldSpeed = this.tempSpeed;
              this.setMarker(this.previousDateTime,data.date_time,currentLat,currentLng, speed, accelation, excessiveSpeed, hardBreaking, phoneUses, oldSpeed);
          }
          this.previousDateTime = data.date_time;
          
          if(speed == 0){
              delete icon["rotation"];
          }
          let vehicleType = data.vehicle_type == 'NA' || data.vehicle_type == '' ? this.vehicleType : data.vehicle_type;
          this.movingIcon = this.setMovingIcon(data.status, vehicleType);
          this.marker = new google.maps.Marker({
              map: this.map,
              icon: this.movingIcon,
          });
          this.markerEnd.push(this.marker);
          this.newLineCordinate = [];
          if(this.tempLat != 0 && this.tempLng != 0){
              this.newLineCordinate.push(new google.maps.LatLng(this.tempLat, this.tempLng));
          }
          this.newLineCordinate.push(new google.maps.LatLng(currentLat, currentLng));
          let latlng = new google.maps.LatLng(currentLat, currentLng);
          
          // if(this.tempLat != 0 && this.tempLng != 0){
          //     this.smoothCount = 0;
          //     let deltaLat = (currentLat - this.tempLat)/this.moveMarkerTime;
          //     let deltaLng = (currentLng - this.tempLng)/this.moveMarkerTime;
          //     this.moveMarkerSmooth(this.tempLat, this.tempLng, deltaLat, deltaLng);

          // }else{
          // }
              this.marker.setPosition(latlng);

          // setTimeout(()=>{
          // },800)
          let line = new google.maps.Polyline({
              path: this.newLineCordinate,
              icons: [{
                  icon: {
                      strokeColor: '#0000ff',
                      strokeWeight: 2,
                      fillColor: '#0000ff',
                      fillOpacity: 1
                  },
                  offset: '100%',
                  repeat: '250px'
              }],
              map: this.map,
              strokeColor: "#000000",//"#ff0000",
              strokeOpacity: 1,
              strokeWeight: 3
          });
          line.setMap(this.map);
          this.tempLat = currentLat;
          this.tempLng = currentLng
          
          this.tempSpeed = speed;

          if ((!this.map.getBounds().contains(this.marker.getPosition()))) { //Note the double &  
              this.map.setCenter(this.marker.getPosition());  
          }
          this.lastSeen = moment(data.date_time).format('D MMM YYYY h:mm a');
          this.acStatus = data.ac_status == '0' ? 'Off' : 'On';
          this.fuelCapacity = data.fuel_capacity == undefined ? '0 L': data.fuel_capacity;
          this.speed = data.speed;
          this.vehicleBattery = data.vehicle_battery;
          this.deviceBattery = data.device_battery;
          //this.odometerReading = data.distance;
          let boundLatLng = this.newLineCordinate[this.newLineCordinate.length - 1];
          bounds.extend(boundLatLng);
          this.map.fitBounds(bounds);
          this.getLatLongAddress(latlng)
          this.map.setZoom(14);
      }
      // else {
      //      var mapOptions = {
      //         zoom: 15,
      //         center: new google.maps.LatLng(22.752559, 75.865535),
      //         travelMode: google.maps.TravelMode.DRIVING,
      //         mapTypeId: google.maps.MapTypeId.ROADMAP,
      //         styles: Constants.mapStyles,
      //         streetViewControl: false,
      //         disableDefaultUI: false
      //     };
      //     this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
      //     this.liveTrackingTrip();  
      // }
  }
    
  moveMarkerSmooth(previousLat , previousLng, tempLat, tempLng){
      if(this.smoothRunning == false){
          return false;
      }
      previousLat += tempLat;

      previousLng += tempLng;
      let latlng = new google.maps.LatLng(previousLat, previousLng);
      // this.route.getPath().push(latlng);
      this.marker.setPosition(latlng);
      
      let temp = this.moveMarkerTime - 4;
      if(this.smoothCount < temp){
          this.smoothCount++;
          setTimeout(() => {
              this.moveMarkerSmooth(previousLat, previousLng, tempLat, tempLng);
          },10);
      }    
  }

  setMarker(startDate, endDate, lat, lng, speed, accelation, excessiveSpeed, hardBreaking, phoneUses, oldSpeed){
      this.startActualTime = new Date(startDate);
      this.endActualTime = new Date(endDate);
      let diff = this.endActualTime - this.startActualTime;
      diff = (diff / 1000)/60;
      diff = Math.round(diff);
      //set stoppage point 
      if(accelation == 1 && speed != oldSpeed){
          let speedIcon = "assets/image/map-images/accelation.png";
          let accelarationInfoWindow = new google.maps.InfoWindow();
          let speedMarker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: this.map,
              icon : speedIcon
          });
          speedMarker.setMap(this.map);
          let accelerationToolTipContent = '<p style="text-align: center;"><b>Aggressive Acceleration</b><br>'+speed+' Kph<br><i class="fa fa-arrow-circle-up"></i><br>'+ oldSpeed +' Kph<br></p>';
          google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, accelerationToolTipContent, accelarationInfoWindow) {
            return function () {
              accelarationInfoWindow.setContent(accelerationToolTipContent);
              accelarationInfoWindow.open(this.map, speedMarker);
            };
          })(speedMarker, accelerationToolTipContent, accelarationInfoWindow));
      }
      if(excessiveSpeed == 1 && speed != oldSpeed){
          let speedIcon = "assets/image/map-images/speed.png";
          let excessiveInfowindow = new google.maps.InfoWindow();
          let speedMarker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: this.map,
              icon : speedIcon
          });
          speedMarker.setMap(this.map);

          let excessiveSpeedToolTipContent =  '<p style="text-align: center;"><b>Excessive Speed</b><br>'+speed+' Kph<br><i class="fa fa-arrow-circle-up"></i><br>'+ oldSpeed +' Kph<br></p>';
          google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, excessiveSpeedToolTipContent, excessiveInfowindow) {
            return function () {
              excessiveInfowindow.setContent(excessiveSpeedToolTipContent);
              excessiveInfowindow.open(this.map, speedMarker);
            };
          })(speedMarker, excessiveSpeedToolTipContent, excessiveInfowindow));
      }
      if(hardBreaking == 1 && speed != oldSpeed){
          let speedIcon = "assets/image/map-images/break.png";
          let hardBreakingInfowindow = new google.maps.InfoWindow();
          let speedMarker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: this.map,
              icon : speedIcon
          });
          speedMarker.setMap(this.map);

          let hardBreakingToolTipContent = '<p style="text-align: center;"><b>Hard Breaking</b><br>'+oldSpeed+' Kph<br><i class="fa fa-arrow-circle-down"></i><br>'+ speed +' Kph<br></p>';;;
          google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, hardBreakingToolTipContent, hardBreakingInfowindow) {
            return function () {
              hardBreakingInfowindow.setContent(hardBreakingToolTipContent);
              hardBreakingInfowindow.open(this.map, speedMarker);
            };
          })(speedMarker, hardBreakingToolTipContent, hardBreakingInfowindow));
      }
      //if(phoneUses != null && phoneUses != '' && phoneUses != 0){
      if(phoneUses != null && phoneUses != '' && phoneUses != 0 && !isNaN(phoneUses)){
          let speedIcon = "assets/image/map-images/phone.png";
          let phoneUsesInfowindow = new google.maps.InfoWindow();
          let speedMarker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: this.map,
              icon : speedIcon
          });
          speedMarker.setMap(this.map);

          let phoneUsesInfoWindow = '<p style="text-align: center;">Phone Uses <br><br>'+phoneUses+'</p>';
          google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, phoneUsesInfoWindow, phoneUsesInfowindow) {
            return function () {
              phoneUsesInfowindow.setContent(phoneUsesInfoWindow);
              phoneUsesInfowindow.open(this.map, speedMarker);
            };
          })(speedMarker, phoneUsesInfoWindow, phoneUsesInfowindow));
      }

      if(diff > 4)
      {

          let stopageIcon = '';
          if(diff < 10){
              stopageIcon = "assets/image/map-images/stop_5_9.png";
          }else if(diff < 15){
              stopageIcon = "assets/image/map-images/stop_10_14.png";
          }else{
              stopageIcon = "assets/image/map-images/stop_15.png";
          }   
          this.timeFormating(diff);
          let stopInfowindow = new google.maps.InfoWindow();
          let stoppageTooltipContent = 'Stoppage - '+this.routeTime+' <br><b>Time:</b> '+moment(startDate).format('h:mm A')+ ' - ' +moment(endDate).format('h:mm A');
          let stoppageMarker = new google.maps.Marker({
              position: new google.maps.LatLng(lat, lng),
              map: this.map,
              icon : stopageIcon
          });
          stoppageMarker.setMap(this.map);


          google.maps.event.addListener(stoppageMarker, 'click', (function (stoppageMarker, stoppageTooltipContent, stopInfowindow) {
            return function () {
              stopInfowindow.setContent(stoppageTooltipContent);
              stopInfowindow.open(this.map, stoppageMarker);
            };
          })(stoppageMarker, stoppageTooltipContent, stopInfowindow));
      }
  }

  timeFormating(totalMinutes){
      let totalTime = Number(totalMinutes);
      this.routeTime = '';
      if(totalTime < 60){
        this.routeTime = totalTime + ' Min.';
      }else{
          if(totalTime > 1440){
              let days = totalTime/1440;
              days = parseInt(days.toString());
              if(days > 0){
                  this.routeTime = days+' Day ';
              }

              let hours = totalTime/60%24;
              hours = parseInt(hours.toString());
              if(hours > 0){
                  this.routeTime = this.routeTime+ ''+ hours+' H ';
              }

              let minute = (totalTime%60);
              minute = parseInt(minute.toString());
              if(minute > 0){
                  this.routeTime = this.routeTime+ ''+ minute+ ' Min.';
              }

          }else{

              let hours = totalTime/60;
              hours = parseInt(hours.toString());
              if(hours > 0){
                  this.routeTime = hours+' H ';
              }
              let minute = (totalTime%60);
              minute = parseInt(minute.toString());
              if(minute > 0){
                  this.routeTime = this.routeTime+ ''+ minute+ ' Min.';
              }
          }
      }
  }

  liveTrackingTrip(){
      this.tempLat = 0;
      this.tempLng = 0;
      this.speedSetting = true;
      this.isLoading = true;
      this.lineCoordinates = [];
      this.timeArray = [];
    //   this.trackingRequest.user_id = this.userInfo.id;
      this.trackingRequest.vehicle_id = this.vehicleId;
      this.speed = '';
      this.vehicleBattery = '';
      this.deviceBattery = '';
      this.odometerReading = 0;
      this.startAddress = '';
      this.lastSeen = '';
      this.acStatus = '';
      this.fuelCapacity = '0 L';
      this.runningVehicleTrackingCount = 0;
      console.log(this.trackingRequest.vehicle_id, )
      this.liveTrackingService.getCurrentTripWithoutLogin({registration_number : this.activated.params['value'].id }).subscribe(res => {
          console.log('responseeeeeeeeee', res)
          this.isLoading = false;
          if(res.result == true){
              if(res.data.tracking != undefined && res.data != null && res.data.tracking.length > 0){
                  this.tripTrackingData = res.data.tracking;
                  this.vehicleType = res.data.vehicle_type;
                  let status = res.data.vehicle_status;
                  let trackDataCount = 1;
                  let bounds = new google.maps.LatLngBounds();
                  for (let i of this.tripTrackingData) {
                      this.stoppageArray.push(i);
                      this.timeArray.push(i.time);
                      // this.userRunningSpeed.push(i.speed);
                      if(i.mode == null || $.trim(i.mode) == ''){
                          i.mode = 'Driving';
                      }
                      this.modeArrayStatus.push(i.mode);
                      let d_lat = Number(i.lat);
                      let d_lng = Number(i.lng);
                      if(this.tripTrackingData.length == trackDataCount){
                          this.tempLat = d_lat;
                          this.tempLng = d_lng;
                      }
                      this.lineCoordinates.push(new google.maps.LatLng(d_lat, d_lng));
                      trackDataCount++;
                      this.bearing = Number(i.bearing);
                      this.currentTripId = i.trip_id;                        
                      if(this.previousDateTime != ''){
                          let accelation = Number(i.is_aggressive_acceleration);
                          let excessiveSpeed = Number(i.is_excessive_speed);
                          let hardBreaking = Number(i.is_hard_braking);
                          let phoneUses = Number(i.is_phone_usage);
                          this.setMarker(this.previousDateTime,i.date_time,d_lat,d_lng,i.speed, accelation, excessiveSpeed, hardBreaking, phoneUses, this.tempSpeed);
                      }
                      this.previousDateTime = i.date_time;
                      this.previousSpeed = this.currentSpeed;
                      this.currentSpeed = i.speed;
                      this.tempSpeed = i.speed;
                      this.runningVehicleTrackingCount++;
                  }
                  
                  let itemIndex = this.vehicleList.findIndex(item => item.id == res.data.vehicle_id);
                  if(itemIndex != undefined && itemIndex != null && itemIndex != -1){
                      this.vehicleList[itemIndex].status = status;
                  }
                  // if(this.vehicleType == 'Motorcycle' || this.vehicleType == 'Motorcycle'){
                  //     this.movingIcon = Config.AuthenticationURL + '' + Constants.BikeMovingVehicleIcon;
                  // }else{
                  //     this.movingIcon = Config.AuthenticationURL + '' + Constants.CarMovingVehicleIcon;
                  //     // this.movingIcon = 'assets/image/vehicles-icon/Car/running.png'
                  // }
                  this.movingIcon = this.setMovingIcon(status, this.vehicleType);
                  
                  let markerstart = new google.maps.Marker({
                      map: this.map,
                      position: this.lineCoordinates[0],
                      icon: "assets/image/30_s.png"
                  });
                  
                  this.position = this.lineCoordinates[0];
                  this.map.setCenter(markerstart.getPosition());
                  let lastMarker = new google.maps.Marker({
                      map: this.map,
                      position: this.lineCoordinates[this.lineCoordinates.length - 1],
                      icon: this.movingIcon
                  });
                  lastMarker.getPosition();
                  let boundLatLng = this.lineCoordinates[this.lineCoordinates.length - 1];
                  this.markerEnd.push(lastMarker);
                  let line = new google.maps.Polyline({
                      path: this.lineCoordinates,
                      icons: [{
                          icon: {
                              strokeColor: '#0000ff',
                              strokeWeight: 2,
                              fillColor: '#0000ff',
                              fillOpacity: 1
                          },
                          offset: '100%',
                          repeat: '250px'
                      }],
                      map: this.map,
                      strokeColor: "#000000",//"#ff0000",
                      strokeOpacity: 1,
                      strokeWeight: 3
                  });
                  line.setMap(this.map);
                  this.lastSeen = moment(res.data.date_time).format('D MMM YYYY h:mm a');
                  this.acStatus = res.data.ac_status  == '0' ? 'Off' : 'On';
                  this.fuelCapacity = res.data.fuel_capacity == undefined ? '0 L': res.data.fuel_capacity;
                  this.speed = res.data.speed;
                  this.vehicleBattery = res.data.vehicle_battery;
                  this.deviceBattery = res.data.device_battery;
                  this.odometerReading = Number(res.data.distance);
                   
                  bounds.extend(boundLatLng);
                  this.getLatLongAddress(this.lineCoordinates[this.lineCoordinates.length - 1]);
                  this.map.fitBounds(bounds);
                  this.map.setZoom(14);
              }else{
                  this.vehicleType = 'Car';
                  let item = this.vehicleList.find(item => item.id == this.vehicleId);
                  if(item != undefined && item != null){
                      this.vehicleType = item.vehicle_type;
                  }
                  let vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ this.vehicleType +"/nodata.png";
                  var icon = {
                        url: vehicleImage,
                        scaledSize: new google.maps.Size(30, 50), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                        shape: { coords: [17, 17, 18], type: 'circle' }
                  };
                  var latlngset = new google.maps.LatLng(this.lat, this.lng);
                  var this1 = this;
                  var marker = new google.maps.Marker({
                        map: this1.map,
                        position: latlngset,
                        icon: icon
                  });        
              }  
          }
          else
          {
              this.commonService.showCustomError(res.message);
          }
      },
      error => {
          this.isLoading = false;
          this.customErrorHandler.handleHttpError(error, 'trackingProspectData');
        }
      
      )
  }
  toggleBars(){
      $('.content_wrap').toggleClass('hide_bars');
  }

  ngOnDestroy() {
      this.socket.removeListener('vehicle_tracking');     
  }

  getLatLongAddress(latlng){
      let geocoder = new google.maps.Geocoder; 
      geocoder.geocode({'location': latlng}, (results, status) => {
          if (status === 'OK') {
              if (results[0]) {
                  this.startAddress = results[0].formatted_address.replace('Unnamed Road,','');
              }
          }
      });
  }

  setMovingIcon(status, vehicleType) {
      let vehicleImage = Config.AuthenticationURL + "public/image/tracking/Car/stop.png";
      if (vehicleType == null || vehicleType == undefined) {
          vehicleType = 'Car';
      }
      //console.log(status,'statusstatus');
      //console.log(vehicleType,'vehicleType');
      if (status == 'Stop' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane')){
          vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/stop.png";
      }

      if (status == 'Running' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane')){
          vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/running.png";
      }
      
      if (status == 'Idle' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane')) {
          vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/idle.png";
      }

      if (status == 'Inactive' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane')){
          vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/inactive.png";
      }

      if (status == 'No Data' && (vehicleType == 'Car' || vehicleType == 'Truck' || vehicleType == 'Bus' || vehicleType == 'Motorcycle' || vehicleType == 'Tanker' || vehicleType == 'Loader' || vehicleType == 'Mower' || vehicleType == 'Trailer' || vehicleType == 'Van' || vehicleType == 'Firetruck'  || vehicleType == 'Crane')) {
          vehicleImage = Config.AuthenticationURL + "public/image/tracking/"+ vehicleType +"/nodata.png";
      }
      //console.log(vehicleImage)
      return vehicleImage;
  }


}


