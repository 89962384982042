import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { LoginService } from './../../services/login/login.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.css']
})
export class VisitorComponent implements OnInit {
  signin_btn_txt: string;
  visitorDetails: any = {};
  private language:string;
    constructor(private router:Router, private loginService:LoginService, private commonService:CommonService,
    private customErrorHandler:CustomErrorHandler) { }
    
  	ngOnInit() {
      document.body.classList.add("login-page");
      // document.body.classList.add("login-page");
      this.signin_btn_txt = "Click here to access demo"; 
      let loginchk = localStorage.getItem('isLogin');
      if(loginchk == '1'){
        this.router.navigate(["dashboard"]);
      }
    }    
    
  saveVisitor() {
    this.signin_btn_txt = "Processing..";
    this.loginService.saveVisitorData(this.visitorDetails.name,this.visitorDetails.email, this.visitorDetails.contact_no).subscribe(
      res => {
        if (res.success == true) {        
          this.demoLogin();
        } else {
          this.signin_btn_txt = "Click here to access demo";
          this.commonService.showErrorMessage(res);         }
      },
      error => {
        this.signin_btn_txt = "Click here to access demo";
        this.customErrorHandler.handleHttpError(error, 'demoLogin');      }
    );
  }

  demoLogin() {
    let email = 'demouser@gmail.com';
    let password = 'password';
    this.loginService.doLogin(email,password, this.language).subscribe(
      res => {
        if (res.success == true) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('isLogin', '1');
          localStorage.setItem('userInfo', JSON.stringify(res.data));  
          localStorage.setItem('isDemoTenant', 'true');
          // this.router.navigate(["dashboard"]);
          window.location.href = '';
        } else {          
          this.signin_btn_txt = "Click here to access demo";
          this.commonService.showErrorMessage(res);         
        }
      },
      error => { 
          this.signin_btn_txt = "Click here to access demo";
          this.customErrorHandler.handleHttpError(error, 'demoLogin');
      }
    )
  }

    ngOnDestroy() {
      document.body.classList.remove("login-page");
    }
    onInputFocus(e:any) {
      e.target.parentElement.classList.add("focusWithText");
    }

  onInputBlur(e:any) {
      if(e.target.value == "") {
          e.target.parentElement.classList.remove("focusWithText");
      }
  }
  	

}
