import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.css']
})
export class TripMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
