import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { InspectionService } from '../../../services/inspection/inspection.service';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Constants } from 'src/app/constants/constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-view-inspection',
  templateUrl: './view-inspection.component.html',
  styleUrls: ['./view-inspection.component.css']
})
export class ViewInspectionComponent implements OnInit {
  public isDemoTenant:boolean;
  loading:Boolean=false;
  modalRef: BsModalRef;
  viewInspectionList:any = [];
  tempSearch;
  searchTextValue = '';
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  selectedId;
  pendingInspectionMsg = 'Inspection has not been done yet'
  viewData = [];
  serverUrl = Config.AuthenticationURL;
  signImg = '';

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public inspectionService: InspectionService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private broadcaster: Broadcaster
  ) { 
    this.translate.get('pendingInspectionMsg').subscribe((res) => {
      setTimeout(() => {
        this.pendingInspectionMsg = res
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
        this.translate.get('pendingInspectionMsgy').subscribe((res) => {
          setTimeout(() => {
            this.pendingInspectionMsg = res
          });
        });
    });
  }

  ngOnInit() {
    this.selectedId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedId) {
      this.getViewInspection(1);
    }
  }

  getViewInspection(currentPage) {
    this.pageNo = currentPage;
    this.loading = true; 
    this.page = currentPage;
    let queryParams = {
      per_page: this.perPage,
      page:this.page,
      search: this.searchTextValue,
      inspection_id: this.selectedId
    }
    this.inspectionService.viewInspection(queryParams).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.viewInspectionList = res.data.rows;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  searchByFilter(event){
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getViewInspection(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  openModal(template, data) {
    console.log(data);
    this.viewData = [];
    this.modalRef = this.modalService.show(template);
    this.viewData = data;
	}

	closeModal(template) {
    this.viewData = [];
		this.modalRef.hide();
  }
  
  openSignModal(template, img) {
		this.signImg = this.serverUrl + img;
		this.modalRef = this.modalService.show(template);
	}

	closeSignModal(template) {
    this.signImg = '';
		this.modalRef.hide();
	}

}
