import { Constants } from 'src/app/constants/constants';
import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user/user.service';
import swal from 'sweetalert2';
import { Config } from 'src/app/config/config';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css']
})
export class VehicleListComponent implements OnInit {
	loading:boolean;
	public isDemoTenant:boolean;
  	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	vehicleDetails: any = [];
	singleDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	modalRef: BsModalRef;
	driverRequestParameter: any = {};
	userInfo: any;
	driverList: any = [];
	assignDetails: any = {};
	deleteDetails: any= {};
	assignVehicleDate = new Date();
	searchTempVal: any;
	imageBase:string;
	deleteMsg: any;
    deleteLabel: any;
	cancelLabel: any;
	viewDetail:string;
	editVehicleText:string;
	deleteVehicleText:string;
	isGps:boolean = false;
	vehileStatusList = [];
	vehileStatusSettings: any = {};
  	vehileStatusSelectedItems: any = [];
  	constructor(
		private modalService: BsModalService, 
		public userService: UserService, 
		private router: Router, 
		private commonService:CommonService, 
		private http: HttpClient, 
		public vehicleService: VehicleService,
		private customErrorHandler:CustomErrorHandler,
		private translate: TranslateService,
    	private broadcaster: Broadcaster
	) {
		this.userInfo = localStorage.getItem('userInfo');
		this.isGps = localStorage.getItem('isGps') == 'true' ? true : false;
		// console.log(this.isGps);
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
		}
		this.translate.get('DeleteOption').subscribe((res) => {
			setTimeout(() => {
                this.deleteMsg = res.DeleteAlert;
                this.deleteLabel = res.Delete;
                this.cancelLabel = res.Cancel;
                this.viewDetail = res.ViewDetails;
				this.editVehicleText = res.Edit;
			});
        });
        this.broadcaster.on<string>('langEvent').subscribe(message => {
			this.translate.get('DeleteOption').subscribe((res) => {
                setTimeout(() => {
                    this.deleteMsg = res.DeleteAlert;
                    this.deleteLabel = res.Delete;
                    this.cancelLabel = res.Cancel;
					this.viewDetail = res.ViewDetails;
					this.editVehicleText = res.Edit;
                });
            });
		});
  	}

  	ngOnInit() {
		this.searchTempVal = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.assignment_status = '';
  		this.searchDetails.current_status = '';
			// this.getDriverDetails();
		this.getDriverList();
  		this.getVehicleDetails(this.pageNo);
  		this.assignDetails.driver_id = '';
		localStorage.removeItem('editVehicleId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
        this.imageBase= Config.AuthenticationURL;

        this.vehileStatusList = [
          {"id":1,"title":"VehicleStatus","itemName":"All"},
          {"id":2,"title":"VehicleStatus","itemName":"active"},
          {"id":3,"title":"VehicleStatus","itemName":"inactive"},
          {"id":4,"title":"VehicleStatus","itemName":"trash"},
        ];

        this.translate.get('selectors').subscribe((res) => {
	    this.vehileStatusSettings = {
	            singleSelection: true,
	            text: 'Select Vehicle Status',
	            //text: 'Select status',
	            enableSearchFilter: true,
	            classes: "singleSelection"
            };

    	});
  	}

	getVehicleDetails(currentPage){
		this.loading =true;
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {};
		this.vehicleService.getVehicle(this.pageNo, this.perPage, this.searchDetails).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          	if (res.success == true) {
		          	this.totalItem = res.data.count;
					  this.vehicleDetails = res.data.rows;
					//   console.log(this.vehicleDetails);
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
				this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
        	this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getVehicleDetails(this.pageNo);
    	}
	}

	openVehicleAssignModal(template, vehicleId, driverId, startDate, endDate) {
        this.modalRef = this.modalService.show(template);
        this.assignDetails.vehicle_id = vehicleId;
        this.assignDetails.company_id = this.userInfo.company_id;
        this.assignDetails.assignedBy = this.userInfo.id;
        this.assignDetails.start_date = '';
        this.assignDetails.driver_id = '';
        this.assignDetails.end_date = '';
        if(driverId){
        	this.assignDetails.driver_id = driverId;
        }
        if(startDate){
        	this.assignDetails.start_date = startDate;
        }
        if(endDate){
        	this.assignDetails.end_date = endDate;
        }
	}
	openViewModal(template, vehicleId) {
		this.modalRef = this.modalService.show(template);
		this.vehicleDetails.forEach((element,index) => {
			if(element.id == vehicleId){
				this.singleDetails = this.vehicleDetails[index];
				// console.log(this.singleDetails,'single')
			}
		});
    }

    assignVehicle(){
    	this.vehicleService.assignVehicle(this.assignDetails).subscribe(
	        res => {
	          	if (res.success == true) {
	            	if(typeof res.message != 'undefined'){
	            		this.commonService.showSuccessMessage(res.message);
	            	}else{
	            		this.commonService.showSuccessMessage('Driver assigned successfully');
	            	}
	    			this.getVehicleDetails(this.pageNo);
	          	} else {
	            	this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
	          this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
	        }
	    )
	    this.modalRef.hide();
    }

   
		
	getDriverList(){
		this.driverRequestParameter.company_id = this.userInfo.company_id;
		this.userService.getDriverList().subscribe(
			res => {
				if (res.success == true) {
					this.driverList = res.data; 
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getDriver');
			}
		)
	}

    editVehicle(vehicleId){
    	localStorage.setItem('editVehicleId', vehicleId);
    	this.router.navigate(['./vehicle-management/vehicle']);
    }

    deleteVehicle(vehicleId){		
        swal({
            type: 'error',
            text: this.deleteMsg,
			confirmButtonText: this.deleteLabel,
			showCancelButton:true,
			cancelButtonText: this.cancelLabel
        }).then((result) => {
            if (result.value) {
		    	this.deleteDetails.vehicle_id = vehicleId;
		    	this.vehicleService.deleteVehicle(this.deleteDetails).subscribe(
			        res => {
			          if (res.success == true) {
			          	this.pageNo = 1;
			            this.commonService.showSuccessMessage(res.message);
			          	this.getVehicleDetails(this.pageNo);


			          } else {
			            this.commonService.showErrorMessage(res);
			          }
			        },
			        error => {
			          this.customErrorHandler.handleHttpError(error, 'assignVehicle');          
			        }
			    )
		    }
        })   
	}
	
	renewSubscription(template, vehicleId){
		localStorage.setItem('vehicleId', vehicleId);
    	this.router.navigate(['./vehicle-management/payment']);
	}

	onItemSelectVehileStatus(item: any) {
        this.searchDetails = {
            vehileStatus: item.itemName
        }
        this.getVehicleDetails(this.pageNo);
    }

    onItemDeSelectVehileStatus(item: any){
        this.searchDetails = {
            vehileStatus: ''
        }
        this.getVehicleDetails(this.pageNo);
    }
}
