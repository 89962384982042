import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Config } from '../../config/config';
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'
// TimeAgo.locale(en)
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
// import { ContractorService } from 'src/app/services/contractor/contractor.service';

import { ChartsModule } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';

import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';

// const timeAgo = new TimeAgo('en-US')
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardData: any;
  imageBase:string;
  currentTime: any = new Date();
  type:any;
  data:any;
  options:any;
  newdashboardData:any;
  showDoughnut:boolean;
  perPage: any = 10;
  pageNo: any = 1;
  loading:boolean;
  page: number = 2;
  contractorName: any = {};
  contractorName_search:any = {};
  message:string;
  vehicleDetails: any = [];
  totalItem: any;
  userInfo: any;
  isGps:boolean = false;
  contractorList: any =[];
  contractorSettings: any = {};
  pieChartData:any =[];
  //SingleDataSet:any =[];
  //SingleDataSet_new:any = [];
  running_count: any;
  stop_count: any;
  no_data_count: any;
  idle_count: any;
  //pieChartLabels:string[]
  colors:string[];
  pieChartColors:any[];
  expensesGraphValue = {
    "Part": "",
    "Fuel": "",
    "Others": "",
    "ServicePUC": ""
  }

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels: Label[] = ['Total','Moving'];
  pieChartLabels2: Label[] = ['Total','Parked'];
  pieChartLabels3: Label[] = ['Total','Non Reporting'];
  pieChartLabels4: Label[] = ['Total','Idle'];
  SingleDataSet = [0,0];
  SingleDataSet2 = [0,0];
  SingleDataSet3 = [0,0];
  SingleDataSet4 = [0,0];
  chartColors: any[] = [{ 
    backgroundColor:["#EAEDED","#18cc6c"] 
  }];
  chartColors2: any[] = [{ 
    backgroundColor:["#EAEDED","#ff5a4d"] 
  }];
  chartColors3: any[] = [{ 
    backgroundColor:["#EAEDED","#85929E"] 
  }];
  chartColors4: any[] = [{ 
    backgroundColor:["#EAEDED","#F6BE00"] 
  }];
// chartColors_new = [];
// SingleDataSet_new = [1,1,1,1,1,1,1];
//   pieChartLabelsNew: Label[] = [];    

  //this.pieChartLabels = ['Running','Parked', 'Non Reporting', 'Idle'];
    //this.SingleDataSet = [40, 30,10,20];
    //this.SingleDataSet = [this.running_count, this.stop_count,this.no_data_count,this.idle_count];
    //this.pieChartColors =["#ff9900","#ff9900","#97bbcd","#97bbcd"];
    //this.colors = ["#ff9900","#ff9900","#97bbcd","#97bbcd"];

  pieChartType: ChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];

  vehileStatusList = [];
  vehileStatusSettings: any = {};
  contractorSelectedItems: any = [];
  vehileStatusSelectedItems: any = [];
  
  constructor(
    private dashboardService: DashboardService, 
    private commonService: CommonService, 
    private customErrorHandler: CustomErrorHandler,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
    public router: Router,
    public vehicleService: VehicleService,
    // public contractorService: ContractorService,
    //monkeyPatchChartJsTooltip();
    //monkeyPatchChartJsLegend();
  ) {
    this.userInfo = localStorage.getItem('userInfo');        
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }

    this.isGps = localStorage.getItem('isGps') == 'true' ? true : false;
    this.translate.get('ExpensesGraphValue').subscribe((res) => {
      this.expensesGraphValue.Part = res.Part
      this.expensesGraphValue.Fuel = res.Fuel
      this.expensesGraphValue.Others = res.Others
      this.expensesGraphValue.ServicePUC = res.ServicePUC
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('ExpensesGraphValue').subscribe((res) => {
        this.data.labels[0] = res.Fuel
        this.data.labels[1] = res.ServicePUC
        this.data.labels[2] = res.Part
        this.data.labels[3] = res.Others
      });
    }); 
    //monkeyPatchChartJsTooltip();
    //monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.imageBase= Config.AuthenticationURL;
    this.type = 'doughnut';
    this.showDoughnut = false; 
    let loginchk = localStorage.getItem('isLogin');
    //let contractorModule = localStorage.getItem('contractorModule');
    // if(contractorModule =='1'){
        //   let dashboardUrl:any = localStorage.getItem('dashboardUrl');
        //   this.router.navigate(["dashboard"]);
        // }
    this.getVehicleDetails(this.pageNo);
    // this.getContractorList();
    setTimeout(() => {
      this.getDashboardData(); 
      this.data = {
        labels:[
          this.expensesGraphValue.Fuel,
          this.expensesGraphValue.ServicePUC,
          this.expensesGraphValue.Part,
          this.expensesGraphValue.Others
        ],
        datasets:[
          {
            data:[0,0,0,0],
            backgroundColor:[
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)"
            ]
          }
        ]
      };      
    });      
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      legend:{
        display:false
      }
    };

    this.translate.get('selectors').subscribe((res) => {
      this.contractorSettings = {
          singleSelection: true,
          //text: res.SelectGeofenceGroup,
          text: 'Select Contractor',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          classes: "singleSelection"
      };

      this.vehileStatusSettings = {
            singleSelection: true,
            text: 'Select Vehicle Status',
            //text: 'Select status',
            enableSearchFilter: true,
            classes: "singleSelection"
            };

    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('selectors').subscribe((res) => {
        this.contractorSettings = {
          singleSelection: true,
          text: 'Select Contractor',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          classes: "singleSelection"
        };
      });
    });

    this.vehileStatusList = [
          {"id":1,"title":"VehicleStatus","itemName":"All"},
          {"id":2,"title":"VehicleStatus","itemName":"active"},
          {"id":3,"title":"VehicleStatus","itemName":"inactive"},
          {"id":4,"title":"VehicleStatus","itemName":"trash"},
        ];

  }


  /*
    This function is used for get dashboard details
  */
  getDashboardData(){
    this.dashboardService.getDashboardData().subscribe(
      res => {
        if (res.success == true) {
          this.newdashboardData = res.data;
          this.data.datasets[0].data[0] = this.newdashboardData.graph.fuel_expense_detail;
          this.data.datasets[0].data[1] = this.newdashboardData.graph.service_expense_detail;
          this.data.datasets[0].data[2] = this.newdashboardData.graph.part_expense_detail;
          this.data.datasets[0].data[3] = this.newdashboardData.graph.other_expense_detail;
          if(this.data.datasets[0].data[0] > 0 || this.data.datasets[0].data[1] > 0 || this.data.datasets[0].data[2] > 0 || this.data.datasets[0].data[3] > 0){
            this.showDoughnut = true;
          }
          this.newdashboardData.alerts.forEach(item => {
            // item.created_at = timeAgo.format(new Date(item.created_at));
          });
        }
        else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'logout');
      }
    )
  }


  getVehicleDetails(currentPage){
    this.loading =true;
    this.pageNo = currentPage;
    this.page = currentPage;
    let requestParameter = {};
    this.vehicleService.getVehicle(this.pageNo, this.perPage, this.contractorName).subscribe(
          res => {
        this.loading =false; 
        this.message = res.message;
          if (res.success == true) {
            this.totalItem = res.data.count;

            this.running_count = res.data.running_count;
            this.stop_count = res.data.stop_count;
            this.no_data_count = res.data.no_data_count;
            this.idle_count = res.data.idle_count;

            this.vehicleDetails = res.data.rows;
            this.graph_count();
          } else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'getVehicle');
          }
      )
  }

  graph_count(){
    //this.pieChartLabels = ['Running','Parked', 'Non Reporting', 'Idle'];
    //this.SingleDataSet = [this.running_count, this.stop_count,this.no_data_count,this.idle_count];
    //this.pieChartColors =["#ff9900","#ff9900","#97bbcd","#97bbcd"];
    //this.colors = ["#ff9900","#ff9900","#97bbcd","#97bbcd"];
    //if(this.running_count >=1){
        this.SingleDataSet = [this.totalItem,this.running_count];
    //}
    //if(this.stop_count){
        this.SingleDataSet2 = [this.totalItem,this.stop_count];
    //}
    //if(this.no_data_count){
        this.SingleDataSet3 = [this.totalItem,this.no_data_count];
    //}
    //if(this.idle_count){
        this.SingleDataSet4 = [this.totalItem,this.idle_count];
    //}
    // else{
    //     this.SingleDataSet = [0, 0,0,0];
    // }
    // this.chartColors_new = ["#18cc6c", "#18cc6c", "#F6BE00", "#F6BE00"];
  }

  // getContractorList(){
  //       let param = {
  //           contractors_id: this.userInfo.id
  //       }
  //       this.contractorService.getContractorItemList(param).subscribe(res => {
  //           if (res.success == true) {
  //               this.contractorList = res.data;
  //           } else {
  //              this.commonService.showErrorMessage(res);
  //           }
  //       },
  //       error => {
  //           this.customErrorHandler.handleHttpError(error, 'getContractorpList');
  //           this.loading = false;
  //       }
  //       )
  // }

    // onItemSelectContractor(item: any) {
    //     //this.vehicleDetails.contractorName = item.id;
    //     this.contractorName = {
    //         contractorId: item.id
    //     }
    //     //this.contractorName = item.id;
    //     this.getVehicleDetails(this.pageNo);
    //   }


      onItemDeSelectContractor(item: any){
        this.contractorName = {
            contractorId: ''
        }
        this.getVehicleDetails(this.pageNo);
      }


      searchByFilter(search = null){
        if(this.contractorName_search != this.contractorName.search_by){
            this.contractorName_search = this.contractorName.search_by;
            this.pageNo = 1;
            this.getVehicleDetails(this.pageNo);
          }
      }

    getShortName(fullName) { 
        return fullName.split(' ').map(n => n[0]).join('');
    }


    onItemSelectVehileStatus(item: any) {
      this.contractorName = {
          vehileStatus: item.itemName
      }
        this.getVehicleDetails(this.pageNo);
    }


    onItemDeSelectVehileStatus(item: any){
      this.contractorName = {
          vehileStatus: ''
      }
      this.getVehicleDetails(this.pageNo);
    }


}
