import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { InspectionService } from '../../../services/inspection/inspection.service';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Constants } from 'src/app/constants/constants';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';

@Component({
  selector: 'app-inspection-assignment',
  templateUrl: './inspection-assignment.component.html',
  styleUrls: ['./inspection-assignment.component.css']
})
export class InspectionAssignmentComponent implements OnInit {

  public isDemoTenant:boolean;
  loading:Boolean=false;
  modalRef: BsModalRef;
  orderDate:Date;
  minDate:Date = new Date();
  vehicleList: any;
  VehicleSelectedItems = [];
  VehicleSettings = {};
  inspectionList: any;
  inspectionSelectedItems = [];
  inspectionSettings = {};
  durationList: any;
  durationSelectedItems = [];
  durationSettings = {};
  set_schedule: boolean = false;
  inspectionData:any = {};
  pageLabel;
  selectedInspectionId;
  isEditMode: boolean;
  assignInspectionList:any = [];
  tempSearch;
  searchTextValue = '';
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  noDataMsg;
  start_date;
  end_date;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  deleteMsg: any;
	deleteLabel: any;
	cancelLabel: any;
  EditLabel:string;
  ViewLabel:string;
  constructor(
    private router: Router,
    private modalService: BsModalService,
    public commonService: CommonService,
    public inspectionService: InspectionService,
    private customErrorHandler:CustomErrorHandler,
    private translate: TranslateService,
    private broadcaster: Broadcaster    
  ) { 
      this.translate.get('selectors').subscribe((res) => {
        setTimeout(() => {
          this.VehicleSettings = {
            singleSelection: false,
            text: res.SelectVehicle,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: ""
          };
          this.inspectionSettings = {
            singleSelection: true,
            text: res.SelectInspection,
            enableSearchFilter: true,
            classes: "singleSelection"
          };
          this.durationSettings = {
            singleSelection: true,
            text: res.SelectFrequency,
            classes: "singleSelection"
          };
        });
      });
      this.translate.get('DeleteOption').subscribe((res) => {
        setTimeout(() => {
          this.deleteMsg = res.DeleteAlert;
          this.deleteLabel = res.Delete;
          this.cancelLabel = res.Cancel;
          this.EditLabel = res.Edit;
          this.ViewLabel = res.ViewDetails;
        });
      });
      this.broadcaster.on<string>('langEvent').subscribe(message => {
          this.translate.get('selectors').subscribe((res) => {
              setTimeout(() => {
                this.VehicleSettings = {
                  singleSelection: false,
                  text: res.SelectVehicle,
                  selectAllText: 'Select All',
                  unSelectAllText: 'UnSelect All',
                  enableSearchFilter: true,
                  classes: ""
                };
                this.inspectionSettings = {
                  singleSelection: true,
                  text: res.SelectInspection,
                  enableSearchFilter: true,
                  classes: "singleSelection"
                };
                this.durationSettings = {
                  singleSelection: true,
                  text: res.SelectFrequency,
                  classes: "singleSelection"
                };
              });
          });
          this.translate.get('DeleteOption').subscribe((res) => {
            setTimeout(() => {
              this.deleteMsg = res.DeleteAlert;
              this.deleteLabel = res.Delete;
              this.cancelLabel = res.Cancel;
              this.EditLabel = res.Edit;
              this.ViewLabel = res.ViewDetails;
            });
          });
      });
  }

  ngOnInit() {
    // this.pageLabel = ''
    this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    
    this.getVehicle();
    this.getInspection();
    this.getReminders();
    this.getAssignInspection(1);
  }

  openOrderAssignModal(template) {
    this.modalRef = this.modalService.show(template);
    this.resetForm();  
  }

  closeAssignModal(template) {
    this.isEditMode = false;
    this.modalRef.hide();
    this.resetForm();
  }

  searchByFilter(event){
    this.searchTextValue = event.target.value;
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getAssignInspection(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  getAssignInspection(currentPage) {
    this.pageNo = currentPage;
    this.loading = true; 
    this.page = currentPage;
    let queryParams = {
      row_per_page: this.perPage,
      page_no:this.page,
      search: this.searchTextValue,
      start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D-MM-YYYY'),
      end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D-MM-YYYY')
    }
    this.inspectionService.getAssignInspection(queryParams).subscribe(res => {
      this.loading =false; 
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.assignInspectionList = res.data.rows;
        this.noDataMsg = res.message;
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  onInspectionSelect(item: any) {
    this.getAssignInspection(1);
  }

  onInspectionDeSelect(item: any) {
    this.getAssignInspection(1);
  }

  assignIncpection(data){
    data.inspection_id = this.inspectionSelectedItems[0].id;
    data.inspection_reminder = this.durationSelectedItems[0].id;
    data.vehicles = this.VehicleSelectedItems;
    data.switch_type = data.switch_type ? '1' : '0';
    if (this.isEditMode) {
      this.updateAssignInspection(data)
    } else {
      this.inspectionService.addAssignInspection(data).subscribe(res => {
        if (res.success == true) {
          this.loading =false; 
          this.commonService.showSuccessMessage(res.message);          
          this.getAssignInspection(1);
        } else {
          this.commonService.showErrorMessage(res);
        }
      }, error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'addInspection');
      });
    }    
    this.modalRef.hide();
    this.resetForm();
  }

  resetForm() {
    this.isEditMode = false;
    this.VehicleSelectedItems = [];
    this.inspectionSelectedItems = [];
    this.durationSelectedItems = [];
    this.inspectionData = {};
  }

  getReminders() {
    this.commonService.getReminder().subscribe(res => {
      if (res.success == true) {
        this.durationList = res.data;
        let durationId = this.inspectionData.inspection_reminder;
				if (this.isEditMode && durationId != undefined && this.durationList.length > 0) {
					this.durationSelectedItems = [];					
          let durationTemp = this.durationList.find(s => s.id == durationId);
          if (typeof durationTemp != 'undefined') {
            this.durationSelectedItems.push(durationTemp);
          }
				}
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getInspection() {
    this.inspectionService.inspectionLists().subscribe(res => {
      if (res.success == true) {
        this.inspectionList = res.data;
        let inspectionId = this.inspectionData.inspection_id;
				if (this.isEditMode && inspectionId != undefined && this.inspectionList.length > 0) {
					this.inspectionSelectedItems = [];					
          let inspectionTemp = this.inspectionList.find(s => s.id == inspectionId);
          if (typeof inspectionTemp != 'undefined') {
            this.inspectionSelectedItems.push(inspectionTemp);
          }
				}
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getVehicle() {
    this.commonService.getAssignVehicle().subscribe(res => {
      if (res.success == true) {
        this.vehicleList = res.data;
        let vehicleId:any = this.inspectionData.vehicles;
				if (this.isEditMode && vehicleId != undefined && this.vehicleList.length > 0) {
          this.VehicleSelectedItems = [];
          for (let i = 0; i < vehicleId.length; i++) {					
            let vehicleTemp = this.vehicleList.find(s => s.id == vehicleId[i].vehicle_id);
            if (typeof vehicleTemp != 'undefined') {
              this.VehicleSelectedItems.push(vehicleTemp);
            }
          }
				}
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  editInspection(value, template) {
    this.isEditMode = true;
    this.modalRef = this.modalService.show(template);  
    this.getAssignInspectionDetail(value.parent_id);
  }

  deleteInspection(val){     
    let id = val.parent_id		
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton:true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        let rawData = {
          inspection_id: id
        }
        this.loading = true;
        this.inspectionService.deleteAssignInspection(rawData).subscribe(
           res => {
              if (res.success == true) {
                 this.commonService.showSuccessMessage(res.message);
                 this.getAssignInspection(1);
              } else {
                  this.commonService.showErrorMessage(res);
              }
              this.loading =false; 
           },
           error => {
              this.customErrorHandler.handleHttpError(error, 'getVehicle');
              this.loading =false; 
           }
        )
      }
    });   
  }

  updateAssignInspection(data) {
    this.inspectionService.updateAssignInspection(data).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.commonService.showSuccessMessage(res.message);          
        this.getAssignInspection(1);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  getAssignInspectionDetail(inspectionId) {
    let queryParam = {
      inspection_id: inspectionId
    }
    this.inspectionService.getSingleAssignInspection(queryParam).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.inspectionData = res.data;
        this.inspectionData.date = res.data.assign_date;
        this.getInspection();
        this.getVehicle();
        this.getReminders();
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

  viewInspection(value) {
    this.router.navigate(['/inspection-management/view-inspection-list'], { queryParams: { id: value.parent_id } });
  }
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getAssignInspection(1);
	}
}
