import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { WorkOrderService } from '../../../services/work-order/work-order.service';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from '../../../services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';


@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css']
})
export class AddOrderComponent implements OnInit {
  VehicleSelectedItems = [];
  VehicleSettings = {};
  // assignedtoSelectedItems = [];
  // assignedtoSettings = {};
  statusSelectedItems =[];
  statusSettings = {};
	loading:boolean;
  	orderDetails: any= {};
	  isLogin : any;
    vehicleList: any;
    driverList: any;
    userList: any;
    driverRequestParameter: any = {};
    userRequestParameter: any = {};
    userInfo: any;
    editOrderId: any;
    currentpage : any;        
    statusList:any=[];
  	constructor(
      private router: Router,
      private http: HttpClient, 
      public workOrderService: WorkOrderService,
      public vehicleService: VehicleService,
      public userService: UserService,
      private customErrorHandler:CustomErrorHandler,
      public _commonService: CommonService,
      private translate: TranslateService,
      private broadcaster: Broadcaster
    ) {
        this.isLogin = localStorage.getItem('isLogin');
        if(this.isLogin != 1)
        {
            router.navigate(['./login']);
        }
      this.userInfo = localStorage.getItem('userInfo');
      if(this.userInfo){
          this.userInfo = JSON.parse(this.userInfo);
          this.currentpage = "Add";
      }
      this.translate.get('selectors').subscribe((res) => {
        setTimeout(() => {
          this.VehicleSettings = {
            singleSelection: true,
            text: res.SelectVehicle,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: "singleSelection"
          };
          this.statusSettings = {
            singleSelection: true,
            text: res.SelectStatus,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: "singleSelection"
          };
        });
      });
      this.broadcaster.on<string>('langEvent').subscribe(message => {
          this.translate.get('selectors').subscribe((res) => {
              setTimeout(() => {
                this.VehicleSettings = {
                  singleSelection: true,
                  text: res.SelectVehicle,
                  selectAllText: 'Select All',
                  unSelectAllText: 'UnSelect All',
                  enableSearchFilter: true,
                  classes: "singleSelection"
                };
                this.statusSettings = {
                  singleSelection: true,
                  text: res.SelectStatus,
                  selectAllText: 'Select All',
                  unSelectAllText: 'UnSelect All',
                  enableSearchFilter: true,
                  classes: "singleSelection"
                };
              });
          });
      }); 
    }

  	ngOnInit() {
     
      // this.assignedtoSettings = {
			// 	singleSelection: true,
			// 	text: "Select assigned to",
			// 	selectAllText: 'Select All',
			// 	unSelectAllText: 'UnSelect All',
			// 	enableSearchFilter: true,
			// 	classes: "singleSelection"
      // };
      
      this.loading = false;
        this.orderDetails.status = 'Open';
        this.getVehicle();
        this.getStatusList();

        // this.orderDetails.vehicle = '';
        // this.orderDetails.assigned_to = '';
        this.orderDetails.company_id = this.userInfo.company_id;        
        this.orderDetails.issued_by = this.userInfo.id;

        // this.getDriver();
        // this.getUser();
        this.editOrderId = localStorage.getItem('editOrderId');
        if(this.editOrderId){
          // this.getOrderDetails(this.editOrderId, this.userInfo.company_id);
          this.currentpage = "Update";
        }
        this._commonService.stickyHeader();       
  	}

getStatusList(){
  this._commonService.getOrderStatus().subscribe(
    res => {
      if (res.success == true) {
        this.statusList = res.data; 
        
        this.editOrderId = localStorage.getItem('editOrderId');
        if(this.editOrderId){
          this.getOrderDetails(this.editOrderId, this.userInfo.company_id);
        }
      }
    },
    error => {
      this.customErrorHandler.handleHttpError(error, 'getUser');
    }
  )
}
  	addWorkOrder(){
      this.loading =true;
        if(this.orderDetails.odometer_reading == ''){
            this.orderDetails.odometer_reading = 0;
        }
        if(this.editOrderId){
            this.workOrderService.updateOrder(this.orderDetails).subscribe(
                res => {
                  this.loading =false; 
                        if (res.success == true) {
                        this._commonService.showSuccessMessage(res.message);
                             this.router.navigate(['./work-order/order-list']);
                        } else {
                        this._commonService.showErrorMessage(res);
                        }
                  },
                  error => {
                    this.loading =false; 
                      this.customErrorHandler.handleHttpError(error, 'addOrder'); 
                  }
              )
        }else{
            this.workOrderService.addOrder(this.orderDetails).subscribe(
              res => {
                this.loading =false; 
                	if (res.success == true) {
                    this._commonService.showSuccessMessage(res.message);
                 		this.router.navigate(['./work-order/order-list']);
                	} else {
                    this._commonService.showErrorMessage(res);
                	}
              },
              error => {
                this.loading =false; 
              	this.customErrorHandler.handleHttpError(error, 'addOrder'); 
              }
          )
       }
  	}	

    numberValidation(event: any) {

      const pattern = /[0-9\+\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      //    console.log(event.keyCode);
      if (event.keyCode != 9 && event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
  }

    getVehicle(){
        this._commonService.getAssignVehicle().subscribe(
            res => {
              if (res.success == true) {
                  this.vehicleList = res.data;
              }
          },
          error => {
              this.customErrorHandler.handleHttpError(error, 'getVehicle');
          }
          )
    }

    // getDriver(){
    //     this.driverRequestParameter.company_id = this.userInfo.company_id;
    //     this.userService.getDriver(0,0,this.driverRequestParameter).subscribe(
    //         res => {
    //           if (res.success == true) {
    //             this.driverList = res.data.rows; 
    //           }
    //         },
    //         error => {
    //           this.customErrorHandler.handleHttpError(error, 'getDriver');
    //         }
    //       )
    // }

    // getUser(){
    //     this.userRequestParameter.company_id = this.userInfo.company_id;
    //     this.userService.getUserList().subscribe(
    //         res => {
    //           if (res.success == true) {
    //             this.userList = res.data; 
    //           }
    //         },
    //         error => {
    //           this.customErrorHandler.handleHttpError(error, 'getUser');
    //         }
    //       )
    // }

    getOrderDetails(orderId, companyId){
        this.workOrderService.getSingleOrder(orderId, companyId).subscribe(res => {
              if (res.success == true) {
                  this.orderDetails = res.data;
                  this.setVehicle();
                  // this.setassignedto();
                  this.setStatus();
              } else {
                   this._commonService.showErrorMessage(res);
              }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'getSingleEmployee');
            }
          )
    }

    calculateTotalAmount(){
      let laborCost = this.orderDetails.labor_cost ;
      let orderCost = this.orderDetails.order_cost;
      let tax = this.orderDetails.tax;
      let discount = this.orderDetails.discount;
      let total = this.orderDetails.total;

      if(typeof this.orderDetails.labor_cost == 'undefined'){
        laborCost = 0;
    }
      if(typeof this.orderDetails.order_cost == 'undefined'){
        orderCost = 0;
      }
      if(typeof this.orderDetails.tax == 'undefined'){
        tax = 0;
      }
      if(typeof this.orderDetails.discount == 'undefined'){
        discount = 0;
      }
      if(typeof this.orderDetails.total == 'undefined'){
        total = 0;
      }
    
       this.orderDetails.total = Number(laborCost) + Number(orderCost) + Number(tax) - Number(discount);
    }

    // Vehicle dropdown methods
    setVehicle(){
      let vehicle = this.vehicleList.find(s=>s.id == this.orderDetails.vehicle_id);
                if(vehicle!= undefined && this.VehicleSelectedItems.length == 0){
                  this.VehicleSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
                  this.VehicleSelectedItems.push(vehicle);
                }
    }
    onItemSelectVehicle(item: any) {
	    this.orderDetails.vehicle_id = item.id;
	  }
		onOpenVehicle(event: any) {
	    this.vehicleList = this.vehicleList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
    }
    // Assigned to dropdown methods
    // setassignedto(){
    //   let assignedto = this.vehicleList.find(s=>s.id == this.orderDetails.assigned_to);
    //             if(assignedto!= undefined && this.assignedtoSelectedItems.length == 0){
    //               this.assignedtoSelectedItems = [];//[{ "id": "2", "itemName": "Asia/Dubai" }];//[];
    //               this.assignedtoSelectedItems.push(assignedto);
    //             }
    // }
    // onItemSelectassignedto(item: any) {
	  //   this.orderDetails.assigned_to = item.id;
	  // }
		// onOpenassignedto(event: any) {
	  //   this.driverList = this.driverList.map((item) => {
	  //     return {
	  //       id: item.id,
	  //       itemName: item.itemName
	  //     }
	  //   });
    // }

    // Status dropdown methods
    setStatus(){
      let status = this.statusList.find(s=>s.itemName == this.orderDetails.status);
                if(status!= undefined && this.statusSelectedItems.length == 0){
                  this.statusSelectedItems = [];
                  this.statusSelectedItems.push(status);
                }
    }
    onItemSelectStatus(item: any) {
	    this.orderDetails.status = item.itemName;
	  }
		onOpenStatus(event: any) {
	    this.statusList = this.statusList.map((item) => {
	      return {
	        id: item.id,
	        itemName: item.itemName
	      }
	    });
    }
    ngOnDestroy() {
      localStorage.removeItem('editOrderId');   	
    }
}
