import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class LoginService {

    constructor(private _httpHelper: HttpHelper) {
    }


    doLogin(email: string, password: string, language:string) {

        try {
            var request = {
                "email": email,
                "password": password,
                "lang": language
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'login', request)
        }
        catch (e) {
        }
    }
    logOut() {
        try {
            const company_id = localStorage.getItem('company_id');
            const user_id = localStorage.getItem('user_id');
            var request = {
                "company_id": company_id,
                "employee_id": user_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'logout', request)
        }
        catch (e) {
        }
    }

    forgotPassword(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'forgot-password', request)
        }
        catch (e) {
        }
    }

    resetPassword(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'reset-password', request)
        }
        catch (e) {
        }
    }

    getEmail(token){
        try{
            let requestParameter = '?token='+token;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-email' +requestParameter);
        }
        catch(e){}
    }
    saveVisitorData(name:string, email: string, contactNo: string) {
        try {
            var request = {
                "name": name,
                "email": email,
                "mobile": contactNo
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'admin/add-visitor', request)
        }
        catch (e) {
        }
    }

    signup(request) {

        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'register', request)
        }
        catch (e) {
        }
    }

    changePassword(current_password:string, new_password:string, verify_password:string){
        try {
            var request = {
                "current_password": current_password,
                "new_password": new_password,
                "verify_password": verify_password
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'change-password', request)
        }
        catch (e) {
        }
    }
}
