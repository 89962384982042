import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Angular2Csv } from 'angular2-csv';
import { Config } from 'src/app/config/config';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { ReportService } from 'src/app/services/report/report.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';


@Component({
  selector: 'app-kendo-example',
  templateUrl: './kendo-example.component.html',
  styleUrls: ['./kendo-example.component.css']
})
export class KendoExampleComponent implements OnInit {

  loading:boolean;
	public isDemoTenant:boolean;
  	message:string;
	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	public vehicleDetails: GridDataResult;
	page: number = 2;
	searchDetails: any = {};
	driverRequestParameter: any = {};
	userInfo: any;
	serverUrl: any;
	searchTempVal: any;
    public pageSize : number;
    public skip = 0;
    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [{
      field: 'vehicle_model_id',
      dir: 'asc'
    }];


  	constructor( private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.searchTempVal = '';
  		this.searchDetails.search_by = '';
  		this.serverUrl = Config.AuthenticationURL;
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.assignment_status = '';
  		this.searchDetails.current_status = '';
		this.getVehicleDetails(this.pageNo);
		localStorage.removeItem('editVehicleId');
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getVehicleDetails(currentPage){
		this.loading =true;
		this.pageNo = currentPage;
		console.log('page number in starting', this.pageNo)
		this.page = currentPage;
		let requestParameter = {};
		this.searchDetails.export = '';
		this.pageSize = 10;

		this.reportService.getVehicle(this.pageNo, this.pageSize, this.searchDetails).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
				this.totalItem = res.data.count;

	          	if (res.success == true) {
					  this.vehicleDetails = res.data.rows;
					  
	          	} else {
					this.commonService.showErrorMessage(res);
	          	}
	        },
	        error => {
						this.loading =false; 
						this.customErrorHandler.handleHttpError(error, 'getVehicle');
	        }
	    )
	}

	public pageChange(event: PageChangeEvent): void {
		console.log('page number on page change', this.pageNo , this.pageNo + 1)
		
        this.skip = event.skip;
        this.getVehicleDetails(this.pageNo + 1);
    }

	public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.getVehicleDetails(this.pageNo);
    }


	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getVehicleDetails(this.pageNo);
		}
	}

	exportVehicleReport(){
		this.searchDetails.export = true;
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "Vehicle List"
		  };
		
		//   this.reportService.getVehicle(0, 0, this.searchDetails).subscribe(res => {
		// 	this.loading =false; 
		// 	new Angular2Csv(res.data, 'Vehicle List', options);
		//   }, error => {
		// 		this.loading =false; 
		// 		this.customErrorHandler.handleHttpError(error, 'exportVehicleReport');
		//   });
	}

}
