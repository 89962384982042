import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})
export class WorkOrdersComponent implements OnInit {

  	isLogin : any;
  	constructor(
  				private router: Router,
	  			private http: HttpClient
	  			) {
  		this.isLogin = localStorage.getItem('isLogin');
        if(this.isLogin != 1)
        {
           router.navigate(['./login']);
        }
  	}
  	ngOnInit() {
  	}

}
