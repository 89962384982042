import { NgModule } from "@angular/core";
import { FormsModule }   from '@angular/forms';


@NgModule({
    imports: [
        FormsModule
    ],
    providers: [],
    bootstrap: [],
    declarations: []
})
export class ModulesModule { }
