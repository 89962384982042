import { Config } from 'src/app/config/config';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   public isLogin: boolean;
  //  public loading: boolean;
    constructor(public router: Router){
    }

    ngOnInit() {
        // use this code to change application theme
        //document.body.classList.add("yellow_theme");
      // document.body.classList.add("blue_theme");
      let loginchk = localStorage.getItem('isLogin');
      if(loginchk == '1'){
        this.isLogin = true;
      }else{
        this.isLogin = false;
      }   
      // this.loading = true;
    }
}
