import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';

declare var $: any;


@Injectable()
export class LocationMappingService {

    constructor(private _httpHelper: HttpHelper) {
    }

    addPlace(request){
    	try {
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'add-place', request)
        }
        catch (e) {
        }
    }

    getPlace(pageNo, rowsPerPage,searchDetails = null){
    	try {
            let searchParameter = '';
            if(searchDetails){
                if(typeof searchDetails.search_by != 'undefined'){
                    searchParameter += '&search_by='+searchDetails.search_by;
                }
            }
            let requestParameter = '?page_no='+pageNo+'&row_per_page='+rowsPerPage+''+searchParameter;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-place'+requestParameter)
        }
        catch (e) {
        }
    }

    updatePlace(request){
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL + 'update-place', request)
        }
        catch (e) {
        }
    }

    deletePlace(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL + 'delete-place', request)
        }
        catch (e) {
        }
    }

    getSinglePlace(placeId){
        try {
            let requestParameter = '?place_id='+placeId;
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-single-place-details'+requestParameter)
        }
        catch (e) {
        }
    }

    getGeofenceRadius(){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL + 'get-geo-radius')
        }
        catch (e) {
        }
    }
}
