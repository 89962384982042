import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from 'src/app/services/common/broadcaster';
import swal from 'sweetalert2';
import { ShiftService } from 'src/app/services/shift/shift.service';
import * as moment from 'moment';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.css']
})
export class ShiftListComponent implements OnInit {

  public isDemoTenant:boolean;
  loading:Boolean=false;
  shiftList = [];
  perPage: any = 10;
  pageNo: any = 1;
  page: number = 1;
  totalItem: any;
  tempSearch;
  searchTextValue = '';
  noDataMsg;
  deleteMsg: any;
	deleteLabel: any;
  cancelLabel: any;
  EditLabel:string;
  message: any = '';

  constructor(
    private router: Router,
    private customErrorHandler: CustomErrorHandler, 
    private commonService: CommonService,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
    public shiftService: ShiftService
  ) { 
    this.translate.get('DeleteOption').subscribe((res) => {
      setTimeout(() => {
        this.deleteMsg = res.DeleteAlert;
        this.deleteLabel = res.Delete;
        this.cancelLabel = res.Cancel;
        this.EditLabel = res.Edit;
      });
    });
    this.broadcaster.on<string>('langEvent').subscribe(message => {
      this.translate.get('DeleteOption').subscribe((res) => {
          setTimeout(() => {
              this.deleteMsg = res.DeleteAlert;
              this.deleteLabel = res.Delete;
              this.cancelLabel = res.Cancel;
              this.EditLabel = res.Edit;
          });
      });
    });
  }

  ngOnInit() {
    this.totalItem = 0;
    this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
    this.getShiftList(this.pageNo);
  }
  addShift(){
		this.router.navigate(['./shift-management/add-shift']);
  }

  getShiftList(currentPage) {
		this.loading = true; 
    this.pageNo = currentPage;
		this.page = currentPage;
		let queryParams = {
			row_per_page: this.perPage,
      page_no:this.page,
			search: this.searchTextValue,
    }
    
    this.shiftService.getShift(queryParams).subscribe(res => {
      this.loading = false;
      this.message = res.message;
      if (res.success == true) {
        this.totalItem = res.data.count;
        this.shiftList = res.data.rows;
        // this.shiftList.forEach(ele => {
        //   ele.shift_start_timing = moment(ele.shift_start_timing).format('hh:mm a');
        //   ele.shift_end_timing = moment(ele.shift_end_timing).format('hh:mm a');
        // })
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(error, 'getRolelist');
    });
  }

  searchByFilter(event){
		this.searchTextValue = event.target.value;
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getShiftList(1);
		}
		this.tempSearch = this.searchTextValue;
  }
  
  onEdit(data) {
    this.router.navigate(['/shift-management/add-shift'], { queryParams: { id: data.id } });
  }

  deleteShift(val){     
    let id = val.id		
    swal({
      type: 'error',
      text: this.deleteMsg,
      confirmButtonText: this.deleteLabel,
      showCancelButton:true,
      cancelButtonText: this.cancelLabel
    }).then((result) => {
      if (result.value) {
        let rawData = {
          id: id
        }
        this.loading = true;
        this.shiftService.deleteShift(rawData).subscribe(res => {
          if (res.success == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getShiftList(1);
          } else {
              this.commonService.showErrorMessage(res);
          }
          this.loading =false; 
        }, error => {
          this.customErrorHandler.handleHttpError(error, 'getVehicle');
          this.loading =false; 
        });
      }
    });   
  }
}
