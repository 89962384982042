import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomErrorHandler } from 'src/app/errorhandler/custom-errorhandler';
import { Router, ActivatedRoute } from '@angular/router';
import { ShiftService } from 'src/app/services/shift/shift.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.css']
})
export class AddShiftComponent implements OnInit {
  pageLabel;
  selectedShiftId;
  isEditMode: boolean;
  userData:any = JSON.parse(localStorage.getItem('userInfo'));
  loading:Boolean=false;
  shiftData:any = {};
  dateTime;
  dateTimeEnd;
  endDateTime: any;
  startDateTime: any;
  timeDiffMsg: any = '';

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private customErrorHandler:CustomErrorHandler,
    public commonService: CommonService,
    public shiftService: ShiftService
  ) { }

  ngOnInit() {   

    this.pageLabel = 'Add'
    this.selectedShiftId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedShiftId) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getShiftDetail(this.selectedShiftId);
    } else {
      this.isEditMode = false;
    }
  }

  addShift(data) {
    this.loading = true; 
    data.user_id = this.userData.id;
    data.shift_start_timing = moment(data.shift_start_timing).format('YYYY-MM-DD hh:mm a');
    data.shift_end_timing = moment(data.shift_end_timing).format('YYYY-MM-DD hh:mm a');
    if (this.isEditMode) {
      this.updateShift(data);
    } else {
      this.shiftService.addShift(data).subscribe(res => {
        if (res.success == true) {
          this.loading =false; 
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(['/shift-management/shift-list']);
        } else {
          this.commonService.showErrorMessage(res);
          this.loading = false; 
        }
      }, error => {
        this.loading =false; 
        this.customErrorHandler.handleHttpError(error, 'addShift');
      });
    }
  }

  updateShift(data) {
    data.shift_id = data.id;
    this.shiftService.updateShift(data).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(['/shift-management/shift-list']);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addShift');
    });
  }


  getShiftDetail(id) {
    this.loading =true; 
    let queryParam = {
      id: id
    }
    this.shiftService.getSingleShift(queryParam).subscribe(res => {
      if (res.success == true) {
        this.loading =false; 
        this.shiftData = res.data;
        this.shiftData.shift_start_timing = new Date(this.shiftData.shift_start_timing);
        this.shiftData.shift_end_timing = new Date(this.shiftData.shift_end_timing);
      } else {
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading =false; 
      this.customErrorHandler.handleHttpError(error, 'addInspection');
    });
  }

}
