import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router } from '@angular/router';
import { ReportService } from '../../../services/report/report.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from 'src/app/constants/constants';
import swal from 'sweetalert2';
import { Config } from '../../../config/config';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as moment from 'moment';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {

  	loading:boolean;
	isDemoTenant:boolean;
	message:string;
  	totalItem: any;
	perPage: any = 10;
	pageNo: any = 1;
	leaveDetails: any = [];
	page: number = 2;
	searchDetails: any = {};
	userInfo: any;
	deleteDetails: any= {};
	serverUrl: any;
	searchTempVal: any;
	start_date;
	end_date;
	noDataMsg;
	selected: any;
	ranges: any = {
	  	'Today': [moment(), moment()],
	  	'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	  	'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	  	'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	  	'This Month': [moment().startOf('month'), moment().endOf('month')],
	  	'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}

  	constructor(private router: Router, private commonService:CommonService, private http: HttpClient, public reportService: ReportService,private customErrorHandler:CustomErrorHandler) {
  		this.userInfo = localStorage.getItem('userInfo');
        if(this.userInfo){
            this.userInfo = JSON.parse(this.userInfo);
        }
  	}

  	ngOnInit() {
  		this.start_date = moment().startOf('month');
		this.end_date = moment().endOf('month');
  		this.selected = {startDate: this.start_date, endDate:  this.end_date};
		this.searchTempVal = '';
		this.searchDetails.search_by = '';
		this.loading = false;
  		this.totalItem = 0;
  		this.searchDetails.company_id = this.userInfo.company_id;
  		this.getLeaveDetails(this.pageNo);
  		localStorage.removeItem('editDriverId');
  		this.searchDetails.status = '';
		this.serverUrl = Config.AuthenticationURL;		
		this.isDemoTenant=  (localStorage.getItem('isDemoTenant')=='true');
  	}

	getLeaveDetails(currentPage){
		this.loading =true; 
		this.pageNo = currentPage;
		this.page = currentPage;
		let requestParameter = {
			page_no: this.pageNo,
			row_per_page: this.perPage,
			search: this.searchDetails.search_by,
			start_date: this.start_date == undefined ? '' : moment(this.start_date).format('D MMM YYYY'),
			end_date: this.end_date == undefined ? '' : moment(this.end_date).format('D MMM YYYY')
		};
		this.reportService.getLeaveDetails(requestParameter).subscribe(
	        res => {
				this.loading =false; 
				this.message = res.message;
	          if (res.success == true) {
	          	this.totalItem = res.data.count;
				this.leaveDetails = res.data.rows;
				this.noDataMsg = res.message;
	          } else {
				this.commonService.showErrorMessage(res);
	          }
	        },
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
	}

	searchByFilter(){
		if(this.searchTempVal != this.searchDetails.search_by){
			this.searchTempVal = this.searchDetails.search_by;
			this.pageNo = 1;
			this.getLeaveDetails(this.pageNo);
		}
	}

    exportReport(){
    	var options = {
	      fieldSeparator: ',',
	      quoteStrings: '"',
	      decimalseparator: '.',
	      showLabels: true,
	      showTitle: true,
	      useBom: true,
	      title: "Leave Report"

	    };
    	let requestParameter = {
			page_no: this.pageNo,
			row_per_page: this.perPage,
			search: this.searchDetails.search_by,
			export: 'yes'
		};
    	this.reportService.getLeaveDetails(requestParameter).subscribe(
	        res => {
	        	 new Angular2Csv(res.data, 'Leave Report', options);
			},
	        error => {
						this.loading =false; 
				this.customErrorHandler.handleHttpError(error, 'getDriver');
	        }
	    )
	}
	
	onSelect(item: any) {
		this.getLeaveDetails(1);
	}

	onDeSelect(item: any) {
		this.getLeaveDetails(1);
	}
	changedDate(item: any){
		this.start_date = item.startDate.format('YYYY-MM-DD');
		this.end_date = item.endDate.format('YYYY-MM-DD')
		this.getLeaveDetails(1);
	}

}
