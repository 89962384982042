import { Component, OnInit } from '@angular/core';
import { HttpClient  } from '@angular/common/http'; 
import { RouterModule, Router,ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	userDetails: any = {};
    userEmail: string = '';
    userToken:string;
    isPassword: boolean = false;
  	constructor(private router:Router,private route: ActivatedRoute, public _loginService: LoginService,private customErrorHandler:CustomErrorHandler, private commonService:CommonService) { 
        // this.route.params.subscribe( params => {
        //   this.userToken = params.token;
        // });
    }

  	ngOnInit() {
      let loginchk = localStorage.getItem('isLogin');
      if(loginchk == '1'){
        this.doLogout();
      }
        document.body.classList.add("login-page");
        var url = window.location.href;
        this.userToken = decodeURIComponent(url.substr(url.indexOf("=") + 1));       
        this.getEmail();
    }
    
    getEmail(){
      this._loginService.getEmail(this.userToken).subscribe(
        res => {      
          if (res.success == true) {
            this.userDetails.email = res.data.email;
          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'getEmail');
        }
      )
    }
    doLogout() {
      this.commonService.logout();
    }
    ngOnDestroy() {
      document.body.classList.remove("login-page");
    }
    onInputFocus(e:any) {
      e.target.parentElement.classList.add("focusWithText");

    }

    onInputBlur(e:any) {
        if(e.target.value == "") {
            e.target.parentElement.classList.remove("focusWithText");
        }
    }
  	resetPassword(){
      this.isPassword = false;
      if (this.userDetails.password != this.userDetails.confirm_password) {
        this.isPassword = true;
        return false;
      }
       this._loginService.resetPassword(this.userDetails).subscribe(
        res => {           
          if (res.success == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(['/login']);

          } else {
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'resetPassword');
        }
      )
  	}

}
